import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { KEY_CODE } from '../../../constants/constants';

class Input extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    const { minRows, maxRows, value } = props;
    this.state = {
      rows: minRows || 3,
      minRows: minRows || 3,
      maxRows: maxRows || 10,
    };
  }
  componentDidMount() {
    if (this.input && this.input.current && this.props.focus) {
      setTimeout(() => {
        if (this.input && this.input.current && this.props.focus) {
          this.input.current.focus();
        }
      }, 200);
    }
    if (this.props.elementType === 'textarea') {
      this.calculateTextAreaRows();
    }
    this.input.current.addEventListener('keydown', this.onKeyDown);
    this.input.current.addEventListener('keyup', this.onKeyUp);
    this.input.current.addEventListener('keypress', this.onKeyPress);
  }

  calculateTextAreaRows() {
    if (this.props.elementType !== 'textarea') {
      return;
    }
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = this.input.current.rows;
    this.input.current.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(this.input.current.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      this.input.current.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      this.input.current.rows = maxRows;
      this.input.current.scrollTop = this.input.current.scrollHeight;
    }

    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  }

  componentDidUpdate(prevProps, prevState): void {
    if (document.activeElement !== this.input.current) {
      setTimeout(() => {
        if (this.input && this.input.current && this.props.focus) {
          this.input.current.focus();
        }
      }, 200);
    }
    if (this.props.value !== prevProps.value) {
      this.calculateTextAreaRows();
    }
  }

  onKeyDown = e => {
    if (!this.props.enablePropagation) {
      e.stopPropagation();
    }
    if (this.props.keyPressMap && this.props.keyPressMap[KEY_CODE[e.keyCode + '']]) {
      this.props.keyPressMap[KEY_CODE[e.keyCode + '']].call(this, e);
    }
    if (this.props.keydown) {
      this.props.keyDown(e);
    }
  };

  onKeyUp = e => {
    if (!this.props.enablePropagation) {
      e.stopPropagation();
    }

    if (this.props.keyUp) {
      this.props.keyUp(e);
    }
  };

  onKeyPress = e => {
    if (!this.props.enablePropagation) {
      e.stopPropagation();
    }
    if (this.props.keyPress) {
      this.props.keyPress(e);
    }
  };

  render() {
    if (this.props.show === false) {
      return null;
    }
    const { formatMessage } = this.props.intl;
    let inputElement = null;
    let classes = ['al-input-wrapper'];
    let inputClasses = [];
    let placeHolder;
    if (this.props.placeholder) {
      placeHolder = formatMessage({
        id: this.props.placeholder || ' ',
        defaultMessage: '',
      });
    }

    classes.push(this.props.className);

    switch (this.props.elementType) {
      case 'input':
        inputElement = (
          <input
            disabled={this.props.disabled === true}
            ref={this.input}
            className={inputClasses.join(' ')}
            {...this.props.elementConfig}
            value={this.props.value}
            placeholder={placeHolder}
            onChange={this.props.changed}
          />
        );
        break;
      case 'textarea':
        inputClasses.push('al-textarea');
        inputElement = (
          <textarea
            disabled={this.props.disabled === true}
            rows={this.state.rows}
            ref={this.input}
            className={inputClasses.join(' ')}
            {...this.props.elementConfig}
            placeholder={placeHolder}
            value={this.props.value}
            onChange={this.props.changed}
          />
        );
        break;
      case 'select':
        inputElement = (
          <select
            disabled={this.props.disabled === true}
            className={inputClasses.join(' ')}
            value={this.props.value}
            onChange={this.props.changed}
          >
            {this.props.elementConfig.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.displayValue}
              </option>
            ))}
          </select>
        );
        break;
      default:
        inputElement = (
          <input
            disabled={this.props.disabled === true}
            ref={node => (this.input = node)}
            className={inputClasses.join(' ')}
            {...this.props.elementConfig}
            value={this.props.value}
            onChange={this.props.changed}
          />
        );
    }

    // let errorMessage = (this.props.invalid && this.props.touched) ? <div className={"al-error-msg"}>{this.props.errorMessage}</div> : null;

    return (
      <div className={classes.join(' ')}>
        {inputElement}
        {/*{errorMessage}*/}
      </div>
    );
  }
}

Input.propTypes = {
  focus: PropTypes.bool,
  invalid: PropTypes.bool,
  enablePropagation: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  elementType: PropTypes.string,
  value: PropTypes.string,
  changed: PropTypes.func,
  elementConfig: PropTypes.object,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  disabled: PropTypes.bool
};

export default injectIntl(Input);
