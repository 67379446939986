import * as actionTypes from '../../actions/actionType';

const fetchContractStatisticsStart = (state, action) => {
  const { projectId, contractId } = action;
  const fetchingContractStatistics = {
    ...state.fetchingContractStatistics,
    projectId,
    contractId,
    loading: true,
    success: false,
    error: null,
  };
  return {
    ...state,
    fetchingContractStatistics,
  };
};

const fetchContractAggregationsStatisticsSuccess = (state, action) => {
  const { projectId, contractId, data } = action;
  const fetchingContractStatistics = {
    ...state.fetchingContractStatistics,
    projectId,
    contractId,
    data,
    loading: false,
    success: true,
    error: null,
  };
  return {
    ...state,
    fetchingContractStatistics,
  };
};
const fetchContractStatisticsFail = (state, action) => {
  const { projectId, contractId, error } = action;
  const fetchingContractStatistics = {
    ...state.fetchingContractStatistics,
    projectId,
    contractId,
    error,
    success: false,
    loading: false,
  };
  return {
    ...state,
    fetchingContractStatistics,
  };
};

const fetchContractOriginsStatisticsSuccess = (state, action) => {
  const { projectId, contractId, origins, excerptType } = action;
  let originsObj = origins.reduce((obj, item) => ({ ...obj, [item.key]: item.count }), {});
  originsObj['excerptType'] = excerptType;
  originsObj['contractId'] = contractId;
  const fetchingContractStatistics = {
    ...state.fetchingContractStatistics,
    projectId,
    contractId,
    origins: originsObj,
    loading: false,
    success: true,
    error: null,
  };
  return {
    ...state,
    fetchingContractStatistics,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONTRACT_STATISTICS_START:
      return fetchContractStatisticsStart(state, action);
    case actionTypes.FETCH_CONTRACT_STATISTICS_AGGREGATIONS_SUCCESS:
      return fetchContractAggregationsStatisticsSuccess(state, action);
    case actionTypes.FETCH_CONTRACT_STATISTICS_FAIL:
      return fetchContractStatisticsFail(state, action);
    case actionTypes.FETCH_CONTRACT_STATISTICS_ORIGINS_SUCCESS:
      return fetchContractOriginsStatisticsSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
