import * as actionTypes from '../actions/actionType';

const initialState = {
  health: false,
  error: null,
};

const healthStart = (state, action) => {
  return {
    ...state,
    error: null,
  };
};

const healthSuccess = (state, action) => {
  return {
    ...state,
    health: true,
    error: null,
  };
};

const healthFail = (state, action) => {
  return { ...state, error: action.error, health: false };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HEALTH_START:
      return healthStart(state, action);
    case actionTypes.HEALTH_SUCCESS:
      return healthSuccess(state, action);
    case actionTypes.HEALTH_FAIL:
      return healthFail(state, action);
    default:
      return state;
  }
};

export default reducer;
