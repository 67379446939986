// @flow
import rangy from 'rangy';
import type { Section } from '../../../types/reduxTypes';
import type { Excerpt } from '../../../types';

const SELECTION_CLASS = 'al-context-selection';
export const NEW_CLASS = 'new';
/**
 *  replaces jquery unwrap
 * @param el
 */
export const unwrap = (el: HTMLElement): void => {
  const parent = el.parentNode;
  if (!parent) return;
  while (el.firstChild) {
    parent.insertBefore(el.firstChild, el);
  }
  parent.removeChild(el);
};

export const stringToColour = (str: string): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const getPosition = (
  e: SyntheticMouseEvent<HTMLElement>,
): { top: number, left: number } => ({
  top: e.pageY,
  left: e.pageX,
});

export const getSelectionOffsetsInSection = (range: any, element: HTMLElement) => {
  const preCaretRange = range.cloneRange();
  preCaretRange.selectNodeContents(element);
  preCaretRange.setEnd(range.startContainer, range.startOffset);
  const start = preCaretRange.toString().length;
  preCaretRange.setEnd(range.endContainer, range.endOffset);
  const end = preCaretRange.toString().length;
  return { start, end };
};

export const findMatchingParentInDict = (el: HTMLElement, matches: Object): null | HTMLElement => {
  if (!el.parentNode) {
    return null;
  }
  const elId = (el.parentNode: HTMLElement).id;
  if (elId && matches[elId]) {
    return el.parentNode;
  }
  return findMatchingParentInDict(el.parentNode, matches);
};
export const surroundRange = (node: HTMLElement, container: HTMLElement) => {
  let range,
    sel = rangy.getSelection();
  let ranges = sel.getAllRanges();
  let textNodes, textNode, el, i, len, j, jLen;
  for (i = 0, len = ranges.length; i < len; ++i) {
    range = ranges[i];
    // If one or both of the range boundaries falls in the middle
    // of a text node, the following line splits the text node at the
    // boundary
    range.splitBoundaries();

    // The first parameter below is an array of valid nodeTypes
    // (in this case, text nodes only)
    textNodes = range.getNodes([3]);

    for (j = 0, jLen = textNodes.length; j < jLen; ++j) {
      textNode = textNodes[j];
      if (textNode.textContent.trim().length > 0) {
        if (container.contains(textNode.parentElement)) {
          el = node.cloneNode(false);
          textNode.parentNode.insertBefore(el, textNode);
          el.appendChild(textNode);
        }
      }
    }
  }
  sel.removeAllRanges();
  return el;
};

export const wrapSelectionWithSpan = (marker: any, container: HTMLElement) => {
  let sel = rangy.getSelection();
  if (sel.rangeCount > 0) {
    let range = sel.getRangeAt(0);
    let cloned = range.cloneContents();
    let div = document.createElement('div');
    div.appendChild(cloned);
    let spanTagWrapper = document.createElement('span');
    if (marker) {
      spanTagWrapper.style.background = stringToColour(marker);
    }
    spanTagWrapper.className = SELECTION_CLASS + ' ' + NEW_CLASS;
    surroundRange(spanTagWrapper, container);
    sel.removeAllRanges();
    return div;
  }
};

export const clearSelection = (container: HTMLElement) => {
  let selected = container.getElementsByClassName(NEW_CLASS);
  while (selected.length > 0) {
    for (let el of selected) {
      unwrap(el);
    }
    selected = container.getElementsByClassName(NEW_CLASS);
  }
};

export const clearIssues = (container: HTMLElement) => {
  let selected = container.getElementsByClassName(SELECTION_CLASS);
  while (selected.length > 0) {
    for (let el of selected) {
      unwrap(el);
    }
    selected = container.getElementsByClassName(SELECTION_CLASS);
  }
};

export const selectIssueInSection = (
  section: Section,
  startOffset: number,
  endOffset: number,
  issue: Excerpt,
  container: HTMLElement,
) => {
  try {
    let range = rangy.createRange();
    range.selectNodeContents(section);
    let clonedRange = range;
    range.splitBoundaries();
    let textNodes = range.getNodes([3]);
    let key;
    // setStart
    let prev = 0;
    let startOffsetFound = false;

    for (key in textNodes) {
      let offset = textNodes[key].length + prev;
      if (!startOffsetFound && startOffset < offset) {
        clonedRange.setStart(textNodes[key], startOffset - prev);
        startOffsetFound = true;
      }
      // setEnd
      // setEnd
      if (startOffsetFound) {
        if (endOffset < offset) {
          clonedRange.setEnd(textNodes[key], endOffset - prev);
          break;
        }
      }
      prev = textNodes[key].length + prev;
    }

    let sel = rangy.getSelection();
    sel.removeAllRanges();
    if (!startOffsetFound) {
      return;
    }
    sel.setSingleRange(clonedRange);
    let span = document.createElement('span');
    span.setAttribute('data-excerptType', issue.excerptType);
    span.setAttribute('data-excerptId', issue.excerptId);
    span.setAttribute(
      'data-origin',
      `${issue.origin}${issue.isNegativeAiTrained ? '-negative' : ''}`,
    );
    span.className = span.className + ' ' + SELECTION_CLASS;
    surroundRange(span, container);
  } catch (e) {
    console.error(e);
  }
};

export function buildSelectedSectionParts(spans, sectionRefs: Object) {
  const selectedSectionParts = [];
  for (let span of spans) {
    let section = findMatchingParentInDict(span, sectionRefs);
    if (section && section.id) {
      let range = rangy.createRange();
      range.selectNodeContents(span);
      let offset = getSelectionOffsetsInSection(range, section);
      let connectedParts = selectedSectionParts.filter(
        ({ id, startOffset, endOffset }) =>
          section.id === id && (startOffset === offset.end || endOffset === offset.start),
      );
      if (!connectedParts.length) {
        //selection is new or unconnected
        selectedSectionParts.push({
          id: section.id,
          startOffset: offset.start,
          endOffset: offset.end,
        });
      } else {
        // trying to merge selection in case they are seamless
        if (connectedParts.length > 1) {
          console.warn('more than one part to connect, there will be broken sections');
        }
        for (let sameSectionPart of connectedParts) {
          // console.log('merge' + span.innerHTML + sameSectionPart.html);
          const { startOffset, endOffset } = sameSectionPart;
          if (startOffset === offset.end) {
            //existing match start is the end of this section part -> merge to one part with old end and extended
            // start
            sameSectionPart.startOffset = offset.start;
          } else if (endOffset === offset.start) {
            //existing match end is the start of this section part -> merge to one part with old start and extended
            // end
            sameSectionPart.endOffset = offset.end;
          } else {
            console.warn('merging failed' + span.innerHTML);
          }
        }
      }
    }
  }
  return selectedSectionParts;
}
