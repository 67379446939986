import React, { Component } from 'react';
import Diff from './Diff/Diff';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import validate from '../../services/validation';
import { FormattedMessage } from 'react-intl';
import { diffTypes } from '../../services/diffService';

class CompareText extends Component {
  state = {
    controls: {
      oldText: {
        elementType: 'textarea',
        elementConfig: {
          type: 'text',
          placeholder: 'app.compare.oldtext-placeholder',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
      },
      newText: {
        elementType: 'textarea',
        elementConfig: {
          type: 'text',
          placeholder: 'app.compare.oldtext-placeholder',
        },
        value: '',
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
      },
    },
    oldText: '',
    newText: '',
  };

  compare = () => {
    this.setState({
      oldText: this.state.controls.oldText.value,
      newText: this.state.controls.newText.value,
    });
  };

  inputChangedHandler = (event, controlName) => {
    let valid = validate(event.target.value, this.state.controls[controlName].validation);
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        touched: true,
        valid: valid,
      },
    };
    this.setState({ controls: updatedControls });
  };

  isControlsValid = () => {
    let isValid = true;
    for (let index in this.state.controls) {
      if (
        ['input', 'textarea'].indexOf(this.state.controls[index].elementType.toLowerCase()) > -1
      ) {
        isValid =
          validate(this.state.controls[index].value, this.state.controls[index].validation) &&
          isValid;
      }
    }
    return isValid;
  };

  render() {
    const controlsArray = [];
    for (let key in this.state.controls) {
      controlsArray.push({
        id: key,
        config: this.state.controls[key],
      });
    }

    let controls = controlsArray.map((formElement) => {
      return (
        <Input
          className={'al-full-height'}
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          placeholder={formElement.config.elementConfig.placeholder}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={(event) => this.inputChangedHandler(event, formElement.id)}
        />
      );
    });

    return (
      <div className="al-container">
        <div className="al-compare-container">
          <div className="al-compare-sections">
            <div className="al-compare-check">
              <h2 className="al-headline sentence">
                <FormattedMessage id="app.compare.text1" defaultMessage="Text 1" />
              </h2>
              {controls[0]}
            </div>
            <div className="al-compare-compare">
              <h2 className="al-headline">
                <FormattedMessage id="app.compare.compare" defaultMessage="Compare" />
              </h2>
              <div className="al-compare-text">
                <Diff
                  oldText={this.state.oldText}
                  newText={this.state.newText}
                  type={diffTypes.sentences}
                />
              </div>
            </div>
            <div className="al-compare-source">
              <h2 className="al-headline">
                <FormattedMessage id="app.compare.text2" defaultMessage="Text 2" />
              </h2>
              {controls[1]}
            </div>
          </div>
          <div className="al-compare-description-container">
            <span className={'sentence'}>
              <FormattedMessage id="app.compare.green" defaultMessage="Green" />
            </span>
            :&nbsp;
            <span className={'sentence'}>
              <FormattedMessage
                id="app.compare.green-info"
                defaultMessage="passages added to Text 1"
              />
            </span>
            <br />
            <span className={'sentence'}>
              <FormattedMessage id="app.compare.red" defaultMessage="red" />
            </span>
            :&nbsp;
            <span className={'sentence'}>
              <FormattedMessage
                id="app.compare.red-info"
                defaultMessage="passages removed to Text 1"
              />
            </span>
          </div>
          <div className="al-compare-button-container">
            <Button disabled={!this.isControlsValid()} btnType="al-primary" clicked={this.compare}>
              <FormattedMessage id="app.compare.compare" defaultMessage="Compare" />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default CompareText;
