import * as actionTypes from '../actions/actionType';
import {
  DEFAULT_NO_FILTER_STATUS,
  DEFAULT_PAGE_NUMBER,
  DOCUMENT_ANALYZED_STATUS,
  FILE_LIST_SORT_BY,
} from '../../constants/constants';

const initialState = {
  error: null,
  loading: false,
  success: false,
  documents: [],
  documentsCount: 0 /* store docs count for the selected contract type to set number of pages in Dashboard */,
  data: null /* data from loaded document in Review page */,
  docId: null,
  updateLoading: false,
  updateSuccess: false,
  updateFail: false,
  uploadQueue: [],
  uploadError: null,
  uploadingFiles: false,
  errorFiles: [],
  successFiles: [],
  retryingProcessingDocIds: '',
  retryingProcessingDocuments: false,
  retryingProcessingDocumentError: null,
  reProcessingDocuments: false,
  reProcessingDocumentError: null,
  updatingContract: false,
  updatingContractError: null,
  reProcessingStaticFindingsDocIds: '',
  reProcessingStaticFindings: '',
  reProcessingStaticFindingError: '',
  noFindingTypes: [],
  fetchingNoFindingTypes: false,
  fetchingNoFindingTypesError: null,
  globallySelectedStatusFilter: DEFAULT_NO_FILTER_STATUS,
  globallySelectedSortDocsBy: FILE_LIST_SORT_BY.DATE_DESC,
  globallySelectedFilterDocsBy: null,
  globallySelectedPageNumber: DEFAULT_PAGE_NUMBER,
};

const fetchDocumentStart = (state) => {
  return {
    ...state,
    error: null,
    loading: true,
    success: false,
  };
};

const fetchDocumentSuccess = (state, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    success: true,
    data: action.data,
    docId: action.docId,
  };
};

const fetchDocumentFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    success: false,
  };
};

const getUpdatedDocumentsListStart = (state) => {
  return {
    ...state,
    error: null,
  };
};

const getUpdatedDocumentsListSuccess = (state, action) => {
  return {
    ...state,
    documents: action.data,
    docId: action.docId,
  };
};

const getUpdatedDocumentsListFail = (state, action) => {
  return {
    ...state,
    error: action.error,
  };
};

const setStatusStart = (state) => {
  return {
    ...state,
    updateLoading: true,
    updateFail: false,
    updateSuccess: false,
  };
};

const setStatusFail = (state) => {
  return {
    ...state,
    updateLoading: false,
    updateFail: true,
    updateSuccess: false,
  };
};

const setStatusSuccess = (state, action) => {
  return {
    ...state,
    data: action.data,
    updateLoading: false,
    updateFail: false,
    updateSuccess: true,
  };
};

const updateDocumentsContractIdStart = (state, action) => {
  return {
    ...state,
    loading: true,
    updatingContract: true,
    updatingContractError: null,
  };
};

const updateDocumentsContractIdSuccess = (state, action) => {
  const updatedDocuments = action.data;
  const documents = state.documents.map((document) => {
    const updatedDocument = updatedDocuments.find(
      (updatedDocument) => updatedDocument.id === document.id,
    );
    return !!updatedDocument ? updatedDocument : document;
  });
  return {
    ...state,
    success: true,
    loading: false,
    documents: documents,
    updatingContract: false,
  };
};

const updateDocumentsContractIdFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    updatingContract: false,
    updatingContractError: action.error,
  };
};

const fetchDocumentsByStart = (state, action) => {
  return {
    ...state,
    error: null,
    loading: true,
    success: false,
  };
};

const fetchDocumentsBySuccess = (state, action) => {
  return {
    ...state,
    error: null,
    loading: false,
    success: true,
    documents: action.data,
    documentsCount:
      typeof action.documentsCount !== 'undefined'
        ? parseInt(action.documentsCount)
        : state.documentsCount,
  };
};

const fetchDocumentsByFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    success: false,
  };
};

const deleteDocumentFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    success: false,
    loading: false,
  };
};

const deleteMultiDocumentsByIdsStart = (state, action) => {
  return {
    ...state,
    error: null,
    success: false,
    loading: true,
  };
};

const deleteMultiDocumentsByIdsSuccess = (state, action) => {
  return {
    ...state,
    success: true,
    loading: false,
    documents: state.documents.filter((item) => action.docIds.indexOf(item.id) === -1),
    documentsCount: state.documentsCount - action.docIds.length,
  };
};

const deleteMultiDocumentsByIdsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const resetUpload = (state) => {
  return {
    ...state,
    uploadingFiles: false,
    uploadError: null,
    successFiles: [],
    uploadQueue: [],
    errorFiles: [],
  };
};

const uploadFileStart = (state, action) => {
  let updatedDocuments = [...state.documents];
  updatedDocuments.push({
    id: Math.random().toString(),
    title: action.fileName,
    projectId: action.projectId,
    contractId: action.contractTypeId,
  });
  return {
    ...state,
    uploadingFiles: true,
    uploadError: null,
    //documents: updatedDocuments,
    uploadQueue: state.uploadQueue.concat([action.fileName]),
  };
};

const uploadFileSuccess = (state, action) => {
  let updatedDocuments = [...state.documents];
  updatedDocuments = updatedDocuments.filter(
    (file) =>
      !(file.title === action.fileName && file.projectId === action.projectId && !file.status),
  );
  updatedDocuments.push(action.data);
  return {
    ...state,
    uploadingFiles: state.uploadQueue.length - 1 !== 0,
    successFiles: state.successFiles.concat([action.fileName]),
    uploadQueue: state.uploadQueue.filter((name) => name !== action.fileName),
    errorFiles: state.errorFiles.filter((errorFile) => errorFile.file.name !== action.fileName),
    documentsCount: state.documentsCount + 1,
  };
};

const uploadFileFail = (state, action) => {
  const { projectId, error, file } = action;
  return {
    ...state,
    uploadError: error,
    uploadingFiles: state.uploadQueue.length - 1 !== 0,
    uploadQueue: state.uploadQueue.filter((name) => name !== file.name),
    errorFiles: state.errorFiles.concat([{ error, projectId, file }]),
  };
};

const selectProject = (state, action) => {
  const { projectId } = action;
  if (state.data && state.data.projectId === projectId) {
    return state;
  } else {
    return initialState;
  }
};

const updateDocumentStatus = (state, action) => {
  let { document } = action;
  let updatedDocuments = [...state.documents];
  let updatedDocumentIndex = updatedDocuments.findIndex((doc) => doc.id === document.id);
  if (updatedDocuments[updatedDocumentIndex]) {
    updatedDocuments[updatedDocumentIndex].analyzeStatus = document.analyzeStatus;
    updatedDocuments[updatedDocumentIndex].withStaticFindings = document.withStaticFindings;
  }

  return {
    ...state,
    documents: updatedDocuments,
  };
};

const retryDocumentStart = (state, action) => {
  const { docIds } = action;
  let updatedDocuments = [...state.documents];
  for (const docId of docIds) {
    let updatedDocumentIndex = updatedDocuments.findIndex((doc) => doc.id === docId);
    if (updatedDocumentIndex !== -1) {
      updatedDocuments[updatedDocumentIndex].analyzeStatus = DOCUMENT_ANALYZED_STATUS.OPEN;
      updatedDocuments[updatedDocumentIndex].withStaticFindings = false;
    }
  }
  return {
    ...state,
    retryingProcessingDocIds: docIds,
    retryingProcessingDocuments: true,
    documents: updatedDocuments,
  };
};

const retryDocumentSuccess = (state, action) => {
  const { docIdsData } = action;
  return {
    ...state,
    retryingProcessingDocuments: false,
    retryingProcessingDocIds: docIdsData,
  };
};

const retryDocumentFail = (state, action) => {
  const { docIds, error } = action;
  return {
    ...state,
    retryingProcessingDocuments: false,
    retryingProcessingDocIds: docIds,
    retryingProcessingDocumentError: error,
  };
};

const reProcessDocumentStart = (state, action) => {
  const { docIdsWithStatus } = action;
  let updatedDocuments = [...state.documents];
  for (const doc of updatedDocuments) {
    if (typeof docIdsWithStatus[doc.id] !== 'undefined') {
      doc.analyzeStatus = DOCUMENT_ANALYZED_STATUS.OPEN;
      doc.withStaticFindings = false;
    }
  }
  return {
    ...state,
    reProcessingDocumentIds: docIdsWithStatus,
    reProcessingDocuments: true,
    documents: updatedDocuments,
  };
};

const reProcessDocumentSuccess = (state, action) => {
  const { docIdsWithStatus } = action;
  return {
    ...state,
    reProcessingDocuments: false,
    reProcessingDocumentIds: docIdsWithStatus,
  };
};

const reProcessDocumentFail = (state, action) => {
  const { docIdsWithStatus, error } = action;
  return {
    ...state,
    reProcessingDocuments: false,
    reProcessingDocumentIds: docIdsWithStatus,
    reProcessingDocumentError: error,
  };
};

const reProcessStaticFindingsStart = (state, action) => {
  const { docIds } = action;
  let updatedDocuments = [...state.documents];
  docIds.forEach((docId) => {
    let updatedDocumentIndex = updatedDocuments.findIndex((doc) => doc.id === docId);
    updatedDocuments[updatedDocumentIndex].withStaticFindings = false;
  });
  return {
    ...state,
    reProcessingStaticFindingsDocIds: docIds,
    reProcessingStaticFindings: true,
    documents: updatedDocuments,
  };
};

const reProcessStaticFindingsSuccess = (state, action) => {
  const { docIds } = action;
  return {
    ...state,
    reProcessingStaticFindings: false,
    reProcessingStaticFindingsDocIds: docIds,
  };
};

const reProcessStaticFindingsFail = (state, action) => {
  const { docIds, error } = action;
  return {
    ...state,
    reProcessingStaticFindings: false,
    reProcessingStaticFindingsDocIds: docIds,
    reProcessingStaticFindingError: error,
  };
};

const fetchDocumentNoFindingTypesStart = (state) => {
  return {
    ...state,
    fetchingNoFindingTypes: true,
    fetchingNoFindingTypesError: null,
  };
};

const fetchDocumentNoFindingTypesSuccess = (state, action) => {
  return {
    ...state,
    fetchingNoFindingTypes: false,
    noFindingTypes: action.noFindingTypes,
  };
};

const fetchDocumentNoFindingTypesFail = (state, action) => {
  return {
    ...state,
    fetchingNoFindingTypes: false,
    fetchingNoFindingTypesError: action.error,
  };
};

const selectStatusFilterGlobally = (state, action) => {
  return {
    ...state,
    globallySelectedStatusFilter: action.statusFilter,
  };
};

const selectSortDocsByGlobally = (state, action) => {
  return {
    ...state,
    globallySelectedSortDocsBy: action.sortBy,
  };
};

const selectFilterDocsByGlobally = (state, action) => {
  return {
    ...state,
    globallySelectedFilterDocsBy: action.filterBy,
  };
};

const selectPageNumberGlobally = (state, action) => {
  return {
    ...state,
    globallySelectedPageNumber: action.page,
  };
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_START:
      return fetchDocumentStart(state, action);
    case actionTypes.FETCH_DOCUMENT_SUCCESS:
      return fetchDocumentSuccess(state, action);
    case actionTypes.FETCH_DOCUMENT_FAIL:
      return fetchDocumentFail(state, action);
    case actionTypes.FETCH_LATEST_DOCS_START:
      return getUpdatedDocumentsListStart(state, action);
    case actionTypes.FETCH_LATEST_DOCS_SUCCESS:
      return getUpdatedDocumentsListSuccess(state, action);
    case actionTypes.FETCH_LATEST_DOCS_FAIL:
      return getUpdatedDocumentsListFail(state, action);

    case actionTypes.SET_STATUS_START:
      return setStatusStart(state, action);
    case actionTypes.SET_STATUS_SUCCESS:
      return setStatusSuccess(state, action);
    case actionTypes.SET_STATUS_FAIL:
      return setStatusFail(state, action);

    case actionTypes.UPDATE_CONTRACT_TYPE_START:
      return updateDocumentsContractIdStart(state, action);
    case actionTypes.UPDATE_CONTRACT_TYPE_SUCCESS:
      return updateDocumentsContractIdSuccess(state, action);
    case actionTypes.UPDATE_CONTRACT_TYPE_FAIL:
      return updateDocumentsContractIdFail(state, action);

    case actionTypes.FETCH_DOCUMENTS_START:
      return fetchDocumentsByStart(state, action);
    case actionTypes.FETCH_DOCUMENTS_SUCCESS:
      return fetchDocumentsBySuccess(state, action);
    case actionTypes.FETCH_DOCUMENTS_FAIL:
      return fetchDocumentsByFail(state, action);

    case actionTypes.DELETE_DOCUMENT_FAIL:
      return deleteDocumentFail(state, action);

    case actionTypes.DELETE_MULTI_DOCUMENTS_START:
      return deleteMultiDocumentsByIdsStart(state, action);
    case actionTypes.DELETE_MULTI_DOCUMENTS_SUCCESS:
      return deleteMultiDocumentsByIdsSuccess(state, action);
    case actionTypes.DELETE_MULTI_DOCUMENTS_FAIL:
      return deleteMultiDocumentsByIdsFail(state, action);

    case actionTypes.UPLOAD_FILE_START:
      return uploadFileStart(state, action);
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return uploadFileSuccess(state, action);
    case actionTypes.UPLOAD_FILE_FAIL:
      return uploadFileFail(state, action);

    case actionTypes.SELECT_PROJECT:
      return selectProject(state, action);

    case actionTypes.UPDATE_DOCUMENT_STATUS:
      return updateDocumentStatus(state, action);

    case actionTypes.RESET_UPLOAD_PROCESS:
      return resetUpload(state, action);

    case actionTypes.RETRY_PROCESS_DOCUMENT_START:
      return retryDocumentStart(state, action);
    case actionTypes.RETRY_PROCESS_DOCUMENT_SUCCESS:
      return retryDocumentSuccess(state, action);
    case actionTypes.RETRY_PROCESS_DOCUMENT_FAIL:
      return retryDocumentFail(state, action);

    case actionTypes.RE_PROCESS_DOCUMENT_START:
      return reProcessDocumentStart(state, action);
    case actionTypes.RE_PROCESS_DOCUMENT_SUCCESS:
      return reProcessDocumentSuccess(state, action);
    case actionTypes.RE_PROCESS_DOCUMENT_FAIL:
      return reProcessDocumentFail(state, action);

    case actionTypes.RETRY_PROCESS_STATIC_FINDING_START:
      return reProcessStaticFindingsStart(state, action);
    case actionTypes.RETRY_PROCESS_STATIC_FINDING_SUCCESS:
      return reProcessStaticFindingsSuccess(state, action);
    case actionTypes.RETRY_PROCESS_STATIC_FINDING_FAIL:
      return reProcessStaticFindingsFail(state, action);

    case actionTypes.FETCH_DOCUMENT_NO_FINDING_TYPES_START:
      return fetchDocumentNoFindingTypesStart(state);
    case actionTypes.FETCH_DOCUMENT_NO_FINDING_TYPES_SUCCESS:
      return fetchDocumentNoFindingTypesSuccess(state, action);
    case actionTypes.FETCH_DOCUMENT_NO_FINDING_TYPES_FAIL:
      return fetchDocumentNoFindingTypesFail(state, action);

    case actionTypes.SELECT_STATUS_FILTER:
      return selectStatusFilterGlobally(state, action);
    case actionTypes.SELECT_SORT_DOCS_BY:
      return selectSortDocsByGlobally(state, action);
    case actionTypes.SELECT_FILTER_DOCS_BY:
      return selectFilterDocsByGlobally(state, action);
    case actionTypes.SELECT_PAGE:
      return selectPageNumberGlobally(state, action);
    default:
      return state;
  }
};

export default reducer;
