import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { KEY_CODE } from '../../../constants/constants';

class HotKeys extends Component {

  _keyPress = event => {
    if (this.props.keyPressMap && this.props.keyPressMap[KEY_CODE[event.keyCode + '']]) {
      if (this.props.outerRef) {
        if (this.props.outerRef.contains(event.target)) {
          this.props.keyPressMap[KEY_CODE[event.keyCode + '']].call(this, event);
        }
      } else {
        this.props.keyPressMap[KEY_CODE[event.keyCode + '']].call(this, event);
      }
    }
  };

  componentDidMount () {
    document.removeEventListener('keydown', this._keyPress);
    document.addEventListener('keydown',  this._keyPress);
  };

  componentWillUnmount () {
    document.removeEventListener('keydown', this._keyPress);
  };

  render() {
    return <>{this.props.children}</>;
  }
}

HotKeys.propTypes = {
  keyPressMap: PropTypes.object,
  outerRef: PropTypes.node,
};

export default HotKeys;
