// @flow
import React from 'react';
import type { DropDownAction } from '../../../types';
type Props = {
  top: number,
  right: number,
  left: number,
  show: boolean,
  actions: Map<string, DropDownAction>,
};

class DropDown extends React.Component {
  constructor(props: Props) {
    super(props);
    this.elementRef = React.createRef();
    const dropDownClass = 'al-dropdown';
    this.classes = [dropDownClass];
    this.classes.push(props.className);
  }

  /**
   * clicked on outside of element
   */
  handleClickOutside = event => {
    if (this.elementRef.current && !this.elementRef.current.contains(event.target)) {
      this.props.toggleDropDown(event);
    }
  };

  getMenuPosition = (x = 0, y = 0) => {
    let menuStyles = {
      top: y,
      left: x,
    };

    if (!this.elementRef.current) return menuStyles;

    const { innerWidth, innerHeight } = window;
    const rect = this.elementRef.current.getBoundingClientRect();

    if (y + rect.height > innerHeight) {
      menuStyles.top -= rect.height;
    }

    if (x + rect.width > innerWidth) {
      menuStyles.left -= rect.width;
    }

    if (menuStyles.top < 0) {
      menuStyles.top = rect.height < innerHeight ? (innerHeight - rect.height) / 2 : 0;
    }

    if (menuStyles.left < 0) {
      menuStyles.left = rect.width < innerWidth ? (innerWidth - rect.width) / 2 : 0;
    }
    return menuStyles;
  };
  componentDidMount() {
    let position = { top: this.props.top, left: this.props.left, right: this.props.right };
    if (!this.elementRef.current) return;
    this.elementRef.current.style.top = `${position.top}px`;
    if (position.right === undefined) {
      this.elementRef.current.style.left = `${position.left}px`;
    } else {
      this.elementRef.current.style.right = `${position.right}px`;
    }
    this.elementRef.current.style.position = 'absolute';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && this.props.show) {
      if (this.props.listenOnClickoutside === undefined || this.props.listenOnClickoutside === true) {
        document.addEventListener('click', this.handleClickOutside);
      }
      const wrapper = window.requestAnimationFrame || setTimeout;
      let position = { top: this.props.top, left: this.props.left, right: this.props.right };
      wrapper(() => {
        const { top, left } = this.getMenuPosition(position.left, position.top);

        wrapper(() => {
          if (!this.elementRef.current) return;
          this.elementRef.current.style.top = `${top}px`;
          if (position.right === undefined) {
            this.elementRef.current.style.left = `${left}px`;
          } else {
            this.elementRef.current.style.right = `${position.right}px`;
          }
          this.elementRef.current.style.position = 'absolute';
        });
      });
    } else {
      if (this.props.listenOnClickoutside === undefined || this.props.listenOnClickoutside === true) {
        document.removeEventListener('click', this.handleClickOutside);
      }
    }
  }

  getComponent(key) {
    if (!this.props.children) {
      return;
    }
    return this.props.children.filter(comp => comp.key === key);
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    return (
      <div
        onClick={e => {
          e.nativeEvent.stopImmediatePropagation();
          e.stopPropagation();
        }}
        ref={this.elementRef}
        style={{
          position: 'absolute',
          top: `${this.props.top}px`,
          left: `${this.props.left}px`,
          right: `${this.props.right}px`,
        }}
        className={this.classes.join(' ')}
      >
        {this.getComponent('header')}
        {this.getComponent('body')}
        {this.getComponent('footer')}
      </div>
    );
  }
}

export default DropDown;
