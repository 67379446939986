import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import IssuesGroupedByType from '../../../components/Review/Issues/IssuesGroupedByType';
import type { Excerpt, Project } from '../../../types';
import { fetchDocumentNoFindingTypes, fetchIssues } from '../../../store/actions';
import { ORIGIN } from '../../../constants/constants';
import { withRouter } from 'react-router-dom';
import CustomTooltip from '../../../components/UI/Tooltip/Tooltip';
import { CreateGlobalConfig } from '../../ContractTypes/ContractTypes';
import { usePrevious } from '../../../hooks/prevValue';

type Props = {
  project: Project,
  document: Document,
  isAllTypesExpanded: boolean,
  selectIssue: (issue: Excerpt) => void,
  selectedIssue: Excerpt,
  setSortBy: (sortBy: any) => void,
  showLeftSideMenu: boolean,
  isMarkedAs: (category: string, findingName: string) => boolean,
  hasTrainingPermission: boolean,
};

const LeftSideMenu = ({
  project,
  document,
  isAllTypesExpanded,
  selectIssue,
  selectedIssue,
  match,
  setSortBy,
  showLeftSideMenu,
  toggleLeftSide,
  setExpandAllTypes,
  isMarkedAs,
  hasTrainingPermission,
}: Props) => {
  const [sortOptions, setSortOptions] = useState([
    {
      key: 'excerptType.keyword',
      active: true,
      type: 'ascending',
      show: true,
      title: 'Sort alphabetical ascending',
    },
    {
      key: 'excerptType.keyword_desc',
      active: false,
      type: 'descending',
      show: false,
      title: 'Sort alphabetical descending',
    },
    {
      key: 'globalStartOffset',
      active: false,
      type: 'doc-descending',
      show: false,
      title: 'Document order descending',
    },
    {
      key: 'globalStartOffset_desc',
      active: false,
      type: 'doc-ascending',
      show: false,
      title: 'Document order ascending',
    },
  ]);
  const [showCreateSetting, setShowCreateSetting] = useState(false);

  const updateLoading = useSelector((state) => state.project.updateLoading);
  const prevUpdateLoading = usePrevious(updateLoading);

  useEffect(() => {
    if (prevUpdateLoading && !updateLoading) {
      dispatch(fetchDocumentNoFindingTypes(document.id));
    }
    // eslint-disable-next-line
  }, [updateLoading]);

  const onSort = (item) => {
    let updatedSortOptions = [...sortOptions];
    updatedSortOptions = updatedSortOptions.map((option) => {
      if (option.key === item.key) {
        option.show = option.active = !option.active;
      } else {
        option.active = false;
      }
      return option;
    });
    updatedSortOptions = updatedSortOptions.map((option) => {
      if (
        item.key !== option.key &&
        (option.key === item.key.split('_')[0] || option.key === item.key + '_desc')
      ) {
        option.show = option.active = !item.active;
      }
      return option;
    });
    setSortOptions(updatedSortOptions);
    dispatch(
      fetchIssues(document.id, false, getSortingBy(sortOptions), true, match.params.excerptId),
    );
  };
  const getSortingBy = (sortOptions) => {
    let sortedByOption = sortOptions.find((option) => option.active);
    let sortBy = {
      fieldName: '',
      asc: false,
    };
    if (sortedByOption) {
      let sortedByKey = sortedByOption.key.split('_');
      sortBy = {
        fieldName: sortedByKey[0],
        asc: sortedByKey.length < 2,
      };
    }
    setSortBy(sortBy);
    return sortBy;
  };
  const dispatch = useDispatch();
  const issuesProps = useSelector((state) => state.issues.issues);

  const [excerptTypeFilter, setExcerptTypeFilter] = useState('');
  const [issues, setIssues] = useState(issuesProps);

  useEffect(() => {
    setIssues(filterByExcerptType(excerptTypeFilter));
    // eslint-disable-next-line
  }, [issuesProps]);

  const inputChangeHandler = (event) => {
    let filteredIssues = filterByExcerptType(event.target.value);
    setExcerptTypeFilter(event.target.value);
    setIssues(filteredIssues);
  };
  const filterByExcerptType = (filter) =>
    _.filter(issuesProps, (item) => {
      return item.excerptType.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    });

  return (
    <div className={`al-review-entries ${showLeftSideMenu ? '' : 'hide'}`}>
      <CustomTooltip
        title={`${showLeftSideMenu ? 'Hide findings' : 'Show findings'}`}
        enterDelay={1000}
        leaveDelay={200}
        placement="top-end"
        classNames={{ root: 'al-tooltip' }}
        interactive={false}
      >
        <span className="toggle-left-side-menu" onClick={() => toggleLeftSide()}>
          {showLeftSideMenu ? (
            <ArrowBackIosIcon
              className={'al-icons'}
              style={{
                marginLeft: '7px',
                marginTop: '4px',
              }}
            />
          ) : (
            <ArrowForwardIosIcon
              className={'al-icons'}
              style={{
                marginLeft: '5px',
                marginTop: '4px',
              }}
            />
          )}
        </span>
      </CustomTooltip>
      <div className={'al-review-entries-toolbar'}>
        <div className={'al-review-entries-toolbar-left'}>
          <Button
            iconType={'add'}
            withIcon={true}
            clicked={() => setShowCreateSetting(true)}
            title="Add new finding type"
          />
          {/*<button className="al-button al-icon-only" title="Add new finding type">*/}
          {/*  <i className="al-icons al-icon-add" />*/}
          {/*</button>*/}
          {sortOptions.map((option, index) => {
            if (option.show) {
              return (
                <Button
                  key={index}
                  iconType={option.type}
                  style={{ color: '#805394' }}
                  withIcon={true}
                  clicked={() => onSort(option)}
                  className={option.active ? 'al-active' : ''}
                  title={option.title}
                />
              );
            }
          })}
          <Button
            withIcon={true}
            iconType={isAllTypesExpanded ? 'double-up-arrows' : 'double-down-arrows'}
            clicked={() => setExpandAllTypes(!isAllTypesExpanded)}
            title={isAllTypesExpanded ? 'Collapse all' : 'Expand all'}
          />
        </div>
        <div className={'al-review-entries-toolbar-right'}>
          <div className="al-search-filter">
            <Input
              key={'input'}
              placeholder={'app.review.issues.search.finding'}
              className={'al-input-text'}
              elementType={'input'}
              value={excerptTypeFilter}
              changed={(event) => inputChangeHandler(event)}
              elementConfig={{ type: 'text' }}
              keyDown={(e) => e.stopPropagation()}
            />
            <Button withIcon={true} iconType={'search'} />
          </div>
        </div>
      </div>
      <IssuesGroupedByType
        project={project}
        docId={document.id}
        docContractId={document.contractId}
        isAllTypesExpanded={isAllTypesExpanded}
        selectedIssue={selectedIssue}
        selectIssue={selectIssue}
        excerptTypeDetails={document.excerptTypeDetails ? document.excerptTypeDetails : []}
        issues={issues.filter((issue) => issue.origin !== ORIGIN.ADVANCED)}
        isMarkedAs={isMarkedAs}
        hasTrainingPermission={hasTrainingPermission}
      />
      {showCreateSetting && (
        <CreateGlobalConfig
          projectId={project.id}
          contractId={document.contractId}
          globalConfigs={
            project.contractConfig.find(
              (contractConfig) => contractConfig.id === document.contractId,
            ).globalConfig
          }
          onCancel={() => {
            setShowCreateSetting(false);
          }}
        />
      )}
    </div>
  );
};

export default withRouter(LeftSideMenu);
