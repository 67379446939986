import React, { useEffect, useState } from 'react';
import Icon from '../../UI/Icon/Icon';
import Button from '../../UI/Button/Button';
import Spinner from '../../UI/Spinner/Spinner';

import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../UI/Modal/Modal';

type Props = {
  successFiles: Array<string>,
  errorFiles: Array<Object>,
  uploadQueue: Array<string>,
  uploadingFiles: boolean,
  replaceFile: (file: Object) => void,
  closeModal: () => void,
};
const DUPLICATE_FILE_ERROR = 409;

const UploadModal = ({
  errorFiles,
  successFiles,
  uploadingFiles,
  uploadQueue,
  replaceFile,
  closeModal,
}: Props) => {
  const [isReplacingAll, setIsReplacingAll] = useState(false);
  useEffect(() => {
    if (
      errorFiles.filter((errorFile) => errorFile.error.response?.status === DUPLICATE_FILE_ERROR)
        .length === 0
    ) {
      setIsReplacingAll(false);
    }
  }, [errorFiles]);
  const isThereDocumentToReplace = () => {
    return (
      errorFiles.filter((errorFile) => errorFile.error.response?.status === DUPLICATE_FILE_ERROR)
        .length > 0
    );
  };
  const replaceAll = () => {
    setIsReplacingAll(true);
    for (let errorFile of errorFiles) {
      if (errorFile.error.response.status === DUPLICATE_FILE_ERROR) {
        replaceFile(errorFile.file);
      }
    }
  };
  return (
    <Modal show={true}>
      <ModalHeader title={'Uploading documents'} onClose={closeModal} />
      <ModalContent contentStyle={{ display: 'flex', overflow: 'hidden' }}>
        <div className="upload-wrapper">
          {uploadingFiles && (
            <>
              Please don't leave the page until the upload is done, remaining documents:{' '}
              <b>{uploadQueue.length}</b>
            </>
          )}
          {uploadingFiles && <Spinner />}
          {!uploadingFiles && (
            <div className="documents-uploaded-wrapper">
              <div className="documents-wrapper">
                <div className="doc-list-title">Uploaded documents: </div>
                <div className="single-docmuent-wrapper">
                  {successFiles.map((f, index) => {
                    return (
                      <div key={index} className="single-document">
                        <Icon style={{ color: 'green' }} icon="success" />
                        <span className="doc-title">{f}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="documents-wrapper">
                <div className="doc-list-title">
                  <div className="failed-title">Failed documents:</div>
                  <Button
                    show={isThereDocumentToReplace()}
                    clicked={replaceAll}
                    disabled={isReplacingAll}
                  >
                    Replace All
                  </Button>
                </div>
                <div className="single-docmuent-wrapper">
                  {errorFiles.map((f, index) => {
                    return (
                      <div key={index} className="single-document">
                        {f.error.response?.status === 409 ? (
                          <Icon
                            title={'Document already exists'}
                            style={{ color: 'orange' }}
                            icon="duplicate"
                          />
                        ) : (
                          <Icon
                            title={`${f.error.message} , ${f.error.response?.data}`}
                            style={{ color: 'red' }}
                            icon="failed"
                          />
                        )}
                        <span className="doc-title">{f.file.name}</span>
                        {f.error.response?.status === 409 && (
                          <Icon
                            clicked={() => {
                              replaceFile(f.file);
                            }}
                            disabled={isReplacingAll}
                            title="Replace the document"
                            style={{ color: '#6b7282' }}
                            icon="replace"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button disabled={uploadingFiles} className={'al-primary'} clicked={closeModal}>
          ok
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadModal;
