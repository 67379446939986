import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import Button from '../../components/UI/Button/Button';
import questionMark from '../../assets/images/question-mark.png';
import { IMG_FILE_EXTENSIONS_REGEX } from '../../constants/constants';

const CustomLogos = ({ customLogos, addLogo, deleteLogo, updateActiveLogo }) => {
  const [currentFile, setCurrentFile] = useState(undefined);
  const [previewImage, setPreviewImage] = useState(questionMark);
  const [isActiveLogo, setIsActiveLogo] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadLogoSize, setUploadLogoSize] = useState('max');

  const MAX_LOGO_COUNT = 6;
  const logosLength = [...Array(MAX_LOGO_COUNT).keys()];

  const toggleActiveLogo = () => {
    setIsActiveLogo(!isActiveLogo);
  };

  const selectFile = (event) => {
    const file = event.target.files[0];
    const isValidFile = IMG_FILE_EXTENSIONS_REGEX.test(file.name);
    if (isValidFile) {
      setCurrentFile(file);
      setPreviewImage(URL.createObjectURL(file));
      setErrorMessage('');
    } else {
      setErrorMessage('Error: only .jpg, .jpeg and .png formats are allowed');
    }
  };

  const changeActiveLogo = (logoIndex, logoId, activeLogoSize) => {
    let selectedLogo = customLogos[logoIndex];
    selectedLogo.imageStat = selectedLogo.imageStat === activeLogoSize ? 'default' : activeLogoSize;
    updateActiveLogo(selectedLogo, activeLogoSize);
  };

  const deleteCustomLogo = (logoId) => {
    deleteLogo(logoId);
  };

  const upload = () => {
    const imageStat = isActiveLogo ? uploadLogoSize : 'default';
    addLogo(currentFile, imageStat);
    setCurrentFile(undefined);
    setPreviewImage(questionMark);
    setIsActiveLogo(true);
    setErrorMessage('');
    setUploadLogoSize('max');
  };

  const onUploadAreaClick = (event) => {
    event.target.value = '';
  };
  const maxCountReached = customLogos.length === MAX_LOGO_COUNT;

  return (
    <div className="custom-logos-container">
      <div className="custom-logos-list">
        <p style={{ textAlign: 'center' }}>Click on an image to set as current logo</p>
        {logosLength.map((index) => {
          const logo = customLogos[index];
          return customLogos.length && index < customLogos.length ? (
            <div
              key={index}
              className="logo-img-container"
              style={{
                padding: '20px',
              }}
            >
              <div
                className={`al-manage-logo-options ${
                  logo.imageStat === 'max' || logo.imageStat === 'min' ? 'with-active-logo' : ''
                }`}
              >
                <div className={`min-max-icons-container`}>
                  <Button
                    iconType={'screen-full'}
                    className={`full-screen ${logo.imageStat === 'max' ? 'active' : ''}`}
                    withIcon={true}
                    clicked={() => {
                      changeActiveLogo(index, logo.id, 'max');
                    }}
                    title={`${
                      logo.imageStat === 'max' ? 'Remove' : 'Set'
                    } as full screen active logo`}
                  />
                  <Button
                    iconType={'screen-small'}
                    className={`small-screen ${logo.imageStat === 'min' ? 'active' : ''}`}
                    withIcon={true}
                    clicked={() => {
                      changeActiveLogo(index, logo.id, 'min');
                    }}
                    title={`${
                      logo.imageStat === 'min' ? 'Remove' : 'Set'
                    } as small screen active logo`}
                  />
                </div>
                <Button
                  iconType={'delete'}
                  withIcon={true}
                  clicked={() => {
                    deleteCustomLogo(logo.id);
                  }}
                  title={'Delete logo'}
                />
              </div>
              <div className={`loaded`}>
                <img
                  src={`data:image/png;base64, ${logo.picByte}`}
                  alt={logo.title}
                  height="80px"
                  className="mr20"
                />
              </div>
            </div>
          ) : (
            <div
              key={index}
              className={`logo-img-container`}
              style={{ padding: '40px', cursor: 'auto' }}
            >
              <div className={`empty`}>
                <img src={questionMark} alt={'no-logo'} height="80px" className="mr20" />
              </div>
            </div>
          );
        })}
      </div>

      <div className="custom-logo-upload al-uploader">
        <div className={`al-upload-button-wrapper ${maxCountReached ? 'disabled' : ''}`}>
          <button>
            <FormattedMessage
              id={`drag-drop-image-area`}
              defaultMessage={
                !maxCountReached
                  ? 'Drag & drop or click here to upload a new image'
                  : 'Max number of images has been reached, please remove an existing logo to add a new one'
              }
            />

            {errorMessage && (
              <Typography variant="subtitle" className={'upload-error-message'}>
                {errorMessage}
              </Typography>
            )}

            <div className={'preview-img'}>
              <img className="preview my20" src={previewImage} alt="" />
            </div>
          </button>
          <input
            type="file"
            onChange={selectFile}
            onClick={onUploadAreaClick}
            multiple
            title="Upload image"
          />
        </div>

        <div className={'upload-img-settings'}>
          <div className={'upload-options-right'}>
            <Checkbox
              className={`upload-active-checkbox ${currentFile ? 'show' : 'hide'}`}
              onClick={toggleActiveLogo}
              checked={currentFile ? isActiveLogo : false}
            >
              Set as current logo
            </Checkbox>
            <button
              className="btn-upload btn-logo-img-upload"
              color="primary"
              disabled={!currentFile}
              onClick={upload}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomLogos;
