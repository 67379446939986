import React from 'react';
import Input from '../../../components/UI/Input/Input';

const Form = props => {
  const formElementsArray = [];
  for (let key in props.controls) {
    formElementsArray.push({
      id: key,
      config: props.controls[key],
    });
  }

  let form = formElementsArray.map(formElement => {
    return (
      <Input
        focus={formElement.config.focus}
        key={formElement.id}
        placeholder={formElement.config.elementConfig.placeholder}
        elementType={formElement.config.elementType}
        elementConfig={formElement.config.elementConfig}
        value={formElement.config.value}
        invalid={!formElement.config.valid}
        shouldValidate={formElement.config.validation}
        touched={formElement.config.touched}
        changed={event => formElement.config.inputChangeHandler(event, formElement.id)}
      />
    );
  });

  return (
    <form noValidate onSubmit={props.submit}>
      {form}
      {props.children}
    </form>
  );
};

export default Form;
