/**
 * @deprecated
 */

import React, { Component } from 'react';
import Form from '../../UI/Form/Form';
import Button from '../../UI/Button/Button';
import Spinner from '../../UI/Spinner/Spinner';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../UI/Modal/Modal';
import validate from '../../../services/validation';

class ActionTypeModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      excerptType: this.props.excerptType,
      controls: {
        actionArea: {
          elementType: 'input',
          elementConfig: {
            type: 'text',
            placeholder: 'app.review.globalaction.placeholder',
          },
          value: props.globalConfig.excerptActions && props.globalConfig.excerptActions.length
            ? props.globalConfig.excerptActions[0].label
            : '',
          validation: {
            required: false,
          },
          inputChangeHandler: this.inputChangedHandler,
          valid: true,
          touched: false,
          errorMessage: 'app.review.globalaction.label.errormsg',
        },
      },
    };
    this.submitHasClicked = false;
  }

  submitHandler = event => {
    this.submitHasClicked = true;
    event.preventDefault();
    let globalactions = {};
    globalactions.excerptType = this.props.excerptType;
    globalactions.excerptOrigin = this.props.excerptOrigin;
    let excerptActions = [];
    let action = {};
    action.label = this.state.controls.actionArea.value;
    action.enabled = true;
    excerptActions.push(action);
    this.props.submitHandler(excerptActions);
    let controls = { ...this.state.controls };

    this.setState({ controls });
  };

  inputChangedHandler = (event, controlName) => {
    let updatedControls = { ...this.state.controls };
    updatedControls[controlName].value = event.target.value;
    updatedControls[controlName].touched = true;
    this.setState({ controls: updatedControls });
  };

  inputChangedHandler = (event, controlName) => {
    let valid = validate(event.target.value, this.state.controls[controlName].validation);
    const updatedControls = {
      ...this.state.controls,
      [controlName]: {
        ...this.state.controls[controlName],
        value: event.target.value,
        touched: true,
        valid: valid,
      },
    };

    this.setState({ controls: updatedControls });
  };

  isControlsValid = () => {
    let isValid = true;
    for (let index in this.state.controls) {
      if (['input', 'text'].indexOf(this.state.controls[index].elementType.toLowerCase()) > -1) {
        isValid =
          validate(this.state.controls[index].value, this.state.controls[index].validation) &&
          isValid;
      }
    }
    return isValid;
  };

  render() {
    let globalconfigs = null;

    let classes = ['al-actions'];
    classes.push(this.props.className);
    if (this.props.loading) {
      globalconfigs = <Spinner />;
    } else if (this.props.error !== null) {
      globalconfigs = <div className={'error'}>{"can't load data"} </div>;
    }
    let errorMessage = null;

    if (this.props.error) {
      errorMessage = <div className="al-error-msg">{this.props.error.message}</div>;
    }
    return (
      <Modal
        show={this.props.show}
      >
        <ModalHeader
          title={this.props.title}
          onClose={this.props.onClose}
        >
        </ModalHeader>
        <ModalContent>
          <Form
            disabled={this.props.loading || this.props.error !== null}
            controls={this.state.controls}
            submitText={'Assign Action'}
          />
        </ModalContent>
        <ModalFooter>
          <Button
            id={'createAction'}
            disabled={!this.isControlsValid()}
            btnType="al-primary"
            clicked={this.submitHandler}
            title="Assign Action"
          >
            {'Assign Action'}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ActionTypeModel;
