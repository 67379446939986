import { axiosInstance as axios } from '../../services/axiosInstance';
import * as actionTypes from './actionType';

export const fetchDocumentCommentsStart = docId => {
  return {
    type: actionTypes.FETCH_DOCUMENT_COMMENTS_START,
    documentId: docId,
  };
};

export const fetchDocumentCommentsSuccess = (documentId, data) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_COMMENTS_SUCCESS,
    data: data,
    documentId: documentId,
  };
};

export const fetchDocumentCommentsFail = (docId, error) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_COMMENTS_FAIL,
    error: error,
    documentId: docId,
  };
};

export const fetchDocumentComments = documentId => {
  return dispatch => {
    dispatch(fetchDocumentCommentsStart(documentId));
    axios
      .get('/api/comments/document/' + documentId)
      .then(response => {
        dispatch(fetchDocumentCommentsSuccess(documentId, response.data));
      })
      .catch(err => {
        dispatch(fetchDocumentCommentsFail(documentId, err));
      });
  };
};

export const fetchIssueCommentsStart = issueId => {
  return {
    type: actionTypes.FETCH_ISSUE_COMMENTS_START,
    issueId: issueId,
  };
};


export const fetchIssueCommentsSuccess = (issueId, data) => {
  return {
    type: actionTypes.FETCH_ISSUE_COMMENTS_SUCCESS,
    data: data,
    issueId: issueId,
  };
};

export const fetchIssueCommentsFail = (issueId, error) => {
  return {
    type: actionTypes.FETCH_ISSUE_COMMENTS_FAIL,
    error: error,
    issueId: issueId,
  };
};

export const fetchIssueComments = issueId => {
  return dispatch => {
    dispatch(fetchIssueCommentsStart(issueId));
    axios
      .get('/api/comments/issue/' + issueId)
      .then(response => {
        dispatch(fetchIssueCommentsSuccess(issueId, response.data));
      })
      .catch(err => {
        dispatch(fetchIssueCommentsFail(issueId, err));
      });
  };
};

export const deleteComment = comment => {
  return dispatch => {
    dispatch(deleteCommentStart(comment));
    axios
      .delete('/api/comments/' + comment.commentId, { params: { excerptId: comment.excerptId } })
      .then(response => {
        if (comment.excerptId) {
          dispatch(fetchIssueComments(comment.excerptId));
        } else {
          dispatch(fetchDocumentComments(comment.docId));
        }
      })
      .catch(err => {
        dispatch(deleteCommentFail(comment, err));
      });
  };
};

export const deleteCommentStart = comment => {
  return {
    type: actionTypes.DELETE_COMMENT_START,
    issueId: comment.excerptId,
  };
};

export const deleteCommentFail = (comment, error) => {
  return {
    type: actionTypes.DELETE_DOCUMENT_FAIL,
    error: error,
    issueId: comment.excerptId,
  };
};

export const createComment = data => {
  return dispatch => {
    dispatch(createCommentStart(data));
    axios
      .post('/api/comments/create', data)
      .then(response => {
        if (data.excerptId) {
          dispatch(fetchIssueComments(data.excerptId));
        } else {
          dispatch(fetchDocumentComments(data.docId));
        }
      })
      .catch(err => {
        dispatch(createCommentFail(data, err));
      });
  };
};

export const createCommentStart = comment => {
  return {
    type: actionTypes.CREATE_COMMENT_START,
    issueId: comment.excerptId,
    documentId: comment.documentId,
  };
};

export const createCommentFail = (comment, error) => {
  return {
    type: actionTypes.CREATE_COMMENT_FAIL,
    error: error,
    issueId: comment.excerptId,
    documentId: comment.docId,
  };
};

export const updateComment = data => {
  return dispatch => {
    dispatch(updateCommentStart(data));
    axios
      .put('/api/comments/' + data.commentId, data)
      .then(response => {
        if (data.excerptId) {
          dispatch(fetchIssueComments(data.excerptId));
        } else {
          dispatch(fetchDocumentComments(data.docId));
        }
      })
      .catch(err => {
        dispatch(updateCommentFail(data, err));
      });
  };
};

export const updateCommentStart = comment => {
  return {
    type: actionTypes.UPDATE_ISSUE_COMMENT_START,
    issueId: comment.excerptId,
    documentId: comment.docId,
    comment: comment,
  };
};

export const updateCommentFail = comment => {
  return {
    type: actionTypes.UPDATE_ISSUE_COMMENT_FAIL,
    issueId: comment.excerptId,
    documentId: comment.documentId,
    comment: comment,
  };
};
