// @flow
import React from 'react';

const DocumentLink = ({ id }) => <a href={`/review/${id}`}>here</a>;

type Props = {
  error: {
    message: string,
    name: string,
    response: {
      data: {
        duplicateIds: Array<number>,
      },
      status: number,
    },
  },
};
const UploadFileError = (props: Props) => {
  const {
    error: { message, response },
  } = props;
  if (!response) {
    return null;
  }
  const {
    data: { duplicateIds, fileName },
    status,
  } = response;
  return (
    <div>
      {status === 409 ? (
        <span>
          The uploaded file <strong>{fileName}</strong> is already present. It cannot be uploaded
          twice.
          <br /> The file can be found{' '}
          {duplicateIds.map(id => (
            <DocumentLink id={id} />
          ))}
        </span>
      ) : (
        { message }
      )}
    </div>
  );
};

export default UploadFileError;
