import * as actionTypes from '../actions/actionType';

const initialState = {
  advancedLearningIssues: [],
  documentId: null,
  isFetching: false,
  fetchingError: null,
  isRecognizing: false,
  recognizingError: null,
  issueId: ''
};

export const fetchAdvancedLearningIssuesStart = (state, action) => {
  return {
    ...state,
    isFetching: true,
    error: null,
    documentId: action.documentId
  };
};

export const fetchAdvancedLearningIssuesSuccess = (state, action) => {
  return {
    ...state,
    isFetching: false,
    error: null,
    documentId: action.documentId,
    advancedLearningIssues: action.data
  };
};

export const fetchAdvancedLearningIssuesFail = (state, action) => {
  return {
    ...state,
    isFetching: false,
    documentId: action.documentId,
    error: action.error
  };
};

export const recognizeIssueStart = (state, action) => {
  const {issueId} = action;
  return {
    ...state,
    isRecognizing: true,
    recognizingError: null,
    issueId: issueId,
  };
};

export const recognizeIssueSuccess = (state, action) => {
  const {issueId} = action;
  let updatedAdvancedLearningIssues = [...state.advancedLearningIssues];
  updatedAdvancedLearningIssues = updatedAdvancedLearningIssues.filter(issue => issue.excerptId !== issueId);
  return {
    ...state,
    advancedLearningIssues:updatedAdvancedLearningIssues,
    isRecognizing: false,
    issueId: issueId,
  };
};

export const recognizeIssueFail = (state, action) => {
  const {error, issueId} = action;
  return {
    ...state,
    isRecognizing: false,
    recognizingError: error,
    issueId: issueId,
  };
};


const advancedLearningIssues = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADVANCED_LEARNING_ISSUES_START:
      return fetchAdvancedLearningIssuesStart(state, action);
    case actionTypes.FETCH_ADVANCED_LEARNING_ISSUES_SUCCESS:
      return fetchAdvancedLearningIssuesSuccess(state, action);
    case actionTypes.FETCH_ADVANCED_LEARNING_ISSUES_FAIL:
      return fetchAdvancedLearningIssuesFail(state, action);
    case actionTypes.RECOGNIZE_ISSUE_START:
      return recognizeIssueStart(state, action);
    case actionTypes.RECOGNIZE_ISSUE_SUCCESS:
      return recognizeIssueSuccess(state, action);
    case actionTypes.RECOGNIZE_ISSUE_FAIL:
      return recognizeIssueFail(state, action);

    default:
      return state;
  }

};

export default advancedLearningIssues;
