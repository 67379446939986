import React, { useRef, useState } from 'react';

import Icon from '../../UI/Icon/Icon';
import DropDown from '../../UI/DropDown/DropDown';
import { PROCESSING_STATUS } from '../../../constants/constants';
import useOutsideClick from '../../../hooks/useOutsideClick';
import ReprocessFilesModal from '../Modal/ReprocessFilesModal';

type Props = {
  status: string,
  title: string,
  retryProcessDocument?: () => void,
  reProcessStaticFindings?: () => void,
  withStaticFindings?: boolean,
  reProcessDocument?: () => void,
};
const Status = ({
  status,
  title,
  retryProcessDocument,
  reProcessStaticFindings,
  withStaticFindings,
  reProcessDocument,
}: Props) => {
  const [showActions, setShowActions] = useState(false);
  const [showWarningReprocess, setShowWarningReprocess] = useState(false);
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (showActions) {
      setShowActions(false);
    }
  });

  return (
    <div
      className={`al-status al-mr-20  ${status || PROCESSING_STATUS.INITIAL}`}
      ref={ref}
      onClick={(e) => {
        setShowActions(!showActions);
      }}
    >
      <Icon title={title} icon={status || PROCESSING_STATUS.INITIAL} />
      <DropDown
        className={'al-transform'}
        show={status !== PROCESSING_STATUS.INITIAL && showActions}
        top={30}
        left={10}
        listenOnClickoutside={false}
        toggleDropDown={() => {
          setShowActions(false);
        }}
      >
        <div key="header" />
        <div className="process-info-wrapper" key="body">
          <Icon icon={status || PROCESSING_STATUS.INITIAL} />
          <span className="process-info-title">{title}</span>
          {[PROCESSING_STATUS.PENDING, PROCESSING_STATUS.FAILED].indexOf(status) > -1 && (
            <Icon
              className="retry-icon"
              icon={'retry'}
              title={'Retry processing the document again'}
              clicked={(e) => {
                setShowActions(false);
                if (PROCESSING_STATUS.FAILED.indexOf(status) > -1) {
                  retryProcessDocument();
                } else {
                  reProcessStaticFindings();
                }
              }}
            />
          )}
          {([PROCESSING_STATUS.SUCCESS].indexOf(status) > -1 ||
            [PROCESSING_STATUS.SUCCESS_WITH_ERROR].indexOf(status) > -1) &&
            !withStaticFindings && (
              <Icon
                className="reprocess-icon"
                icon={'reprocess'}
                title={'Process document again'}
                clicked={() => {
                  setShowWarningReprocess(true);
                }}
              />
            )}
          {([PROCESSING_STATUS.SUCCESS].indexOf(status) > -1 ||
            [PROCESSING_STATUS.SUCCESS_WITH_ERROR].indexOf(status) > -1) &&
            withStaticFindings && (
              <Icon
                className="reprocess-icon"
                icon={'reprocess'}
                title={'Analyze document again'}
                clicked={() => {
                  setShowActions(false);
                  reProcessStaticFindings();
                }}
              />
            )}
        </div>
      </DropDown>

      {showWarningReprocess && (
        <ReprocessFilesModal
          closeModal={() => {
            setShowWarningReprocess(false);
          }}
          filesToReprocessCount={1}
          reProcessDocument={reProcessDocument}
        />
      )}
    </div>
  );
};

export default Status;
