// @flow
import React, { useEffect, useState } from 'react';
import type { CompareDatabaseFinding, Excerpt } from '../../types';
import Diff from '../CompareText/Diff/Diff';
import { diffTypes } from '../../services/diffService';
import Icon from '../UI/Icon/Icon';
import DatabaseFindingModal from '../CompareDatabase/DatabaseFindingModal';
import stringSimilarity from 'string-similarity';

type Props = {
  activeType: string,
  comparedFileName: string,
  comparedExcerpts: Array<Excerpt>,
  referenceExcerpts: Array<Excerpt>,
  hasCreatePermission: boolean,
};

const CompareExcerpts = ({
  activeType,
  comparedFileName,
  comparedExcerpts,
  referenceExcerpts,
  hasCreatePermission,
}: Props) => {
  const [reference: Excerpt | CompareDatabaseFinding, setReference] = useState(
    referenceExcerpts[0],
  );
  const [createNewFindingShown, showCreateNewFindingModal] = useState(false);
  const [findingText, setFindingText] = useState('');
  const [editingFindingId, setEditingFindingId] = useState(null);
  const [comparedContentSorted, sortComparedExcerpts] = useState(comparedExcerpts);

  useEffect(() => {
    if (reference) {
      const comparedContent = comparedExcerpts.map((excerpt) => excerpt.bodyHTML);
      if (comparedContent.length) {
        // order the excerpts of the comparison document by similarity with the selected reference excerpt
        const matches = stringSimilarity.findBestMatch(reference.bodyHTML, comparedContent).ratings;
        const sortedList = matches.sort((a, b) => (a.rating > b.rating ? -1 : 1));
        sortComparedExcerpts(
          sortedList.map((excerpt) => {
            return { bodyHTML: excerpt.target };
          }),
        );
      } else {
        sortComparedExcerpts([]);
      }
    }
    // eslint-disable-next-line
  }, [reference, comparedExcerpts]);

  useEffect(() => {
    setReference(referenceExcerpts[0]);
    // eslint-disable-next-line
  }, [referenceExcerpts]);

  const onStartEditingDBFinding = (id, content) => {
    setEditingFindingId(id);
    setFindingText(content);
    showCreateNewFindingModal(true);
  };

  const closeModal = () => {
    setEditingFindingId(null);
    setFindingText('');
    showCreateNewFindingModal(false);
  };

  return (
    <div className={'al-compareExcerpt-body'}>
      <div className={'al-compareExcerpt-body-reference'}>
        {referenceExcerpts.map((excerpt) => {
          const id = excerpt.id ? excerpt.id : excerpt.excerptId;
          const refExcerptId = reference?.id ? reference?.id : reference?.excerptId;
          return (
            <div
              key={id}
              className={`content-reference ${id === refExcerptId ? 'active' : ''}`}
              onClick={() => {
                setReference(excerpt);
              }}
            >
              {excerpt.bodyHTML}
            </div>
          );
        })}
      </div>

      <div className="al-compare-text">
        {comparedContentSorted.length ? (
          comparedContentSorted.map((comparedExcerpt, index) => {
            return (
              <div key={index} className={'diff-compared-text-wrapper'}>
                <div className="al-compareExcerpt-body-diff">
                  {reference && (
                    <Diff
                      oldText={reference.bodyHTML}
                      newText={comparedExcerpt.bodyHTML}
                      type={diffTypes.sentences}
                    />
                  )}
                </div>
                <div className={'content-comparison'}>
                  {comparedExcerpt.bodyHTML}
                  {hasCreatePermission && (
                    <div className={'add-icon-container'}>
                      <Icon
                        icon={'add-circle'}
                        className="add-circle-compare-database"
                        title={'Add to compare database'}
                        clicked={() => {
                          onStartEditingDBFinding(null, comparedExcerpt.bodyHTML);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className={'no-finding-to-compare-msg'}>
            {activeType
              ? `${comparedFileName} does not have the finding type '${activeType}'.`
              : referenceExcerpts.length
              ? `Select a finding type.`
              : `There are no findings to compare.`}
          </div>
        )}
      </div>

      {createNewFindingShown && (
        <DatabaseFindingModal
          activeType={activeType}
          editingFindingId={editingFindingId}
          findingText={findingText}
          setFindingText={setFindingText}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default CompareExcerpts;
