import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import Icon from '../../../UI/Icon/Icon';
import { addExcerptTypeComment } from '../../../../store/actions';

type Props = {
  docId: string,
  excerptType: string,
  isExpanded: boolean,
  excerptTypeDetails: Array<{ type: string, comment: string }>,
  showCollapse: boolean,
  showImportant: boolean,
  showCritical: boolean,
  count?: number,
};

export function ExcerptType({
  children,
  docId,
  excerptType,
  isExpanded,
  excerptTypeDetails,
  showCollapse,
  showImportant,
  showCritical,
  count,
}: Props) {
  const dispatch = useDispatch();

  const getExcerptTypeComment = () => {
    let excerptTypeDetail = excerptTypeDetails.find(
      (excerptTypeDetail) =>
        excerptTypeDetail.type.toLowerCase().trim() === excerptType.toLowerCase().trim(),
    );
    return excerptTypeDetail ? excerptTypeDetail.comment : '';
  };

  const addComment = (type, comment) => {
    let updatedExcerptTypeDetails = excerptTypeDetails.filter(
      (excerptTypeDetail) =>
        excerptTypeDetail.type.toLowerCase().trim() !== type.toLowerCase().trim(),
    );
    updatedExcerptTypeDetails.push({ type, comment });
    dispatch(
      addExcerptTypeComment(docId, {
        excerptTypeDetails: updatedExcerptTypeDetails,
      }),
    );
  };

  const [expand, setExpand] = useState(isExpanded || false);
  const [touched, setTouched] = useState(false);
  const initialExcerptTypeComment = getExcerptTypeComment();
  const [comment, setComment] = useState(initialExcerptTypeComment);

  useEffect(() => {
    setTouched(comment !== initialExcerptTypeComment);
    // eslint-disable-next-line
  }, [comment]);

  useEffect(() => {
    setExpand(isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    setTouched(comment !== getExcerptTypeComment());
    // eslint-disable-next-line
  }, [excerptTypeDetails]);

  const commentChanged = (event) => {
    setComment(event.target.value);
  };

  return (
    <div
      key={excerptType + count}
      className={'al-issues-group-by'}
      style={{ background: `${showCritical ? 'lightcoral' : 'white'}` }}
    >
      <div
        className={'al-issues-group-by-header'}
        onClick={() => {
          setExpand(!expand);
        }}
      >
        <label onClick={() => setExpand(!expand)}>
          {excerptType} {!!count ? `(${count})` : null}
        </label>
        <div className={'icons-container'}>
          <Icon
            className={'warning-icon'}
            title="Important missing finding"
            icon="exclamation-triangle-fill active"
            show={showImportant}
          />
          {showCollapse && (
            <span className={'expand-area'} onClick={() => setExpand(!expand)}>
              {expand ? (
                <ExpandLessIcon className={'al-icons'} />
              ) : (
                <ExpandMoreIcon className={'al-icons'} />
              )}
            </span>
          )}
        </div>
      </div>
      {expand && (
        <>
          <div className={'al-issues-group-by-comment'}>
            <Input
              elementType={'textarea'}
              placeholder={'Add a comment'}
              value={comment}
              className={'al-input-text'}
              elementConfig={{ type: 'text' }}
              changed={commentChanged}
              minRows={1}
              maxRows={3}
            />
            {touched && (
              <div className={'al-issues-group-by-comment-footer'}>
                <Button className={'al-primary'} clicked={() => addComment(excerptType, comment)}>
                  Save
                </Button>
                <Button
                  clicked={() => {
                    setComment(initialExcerptTypeComment);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          {children} {/* renders the excerpts corresponding to this excerpt type */}
        </>
      )}
      {}
    </div>
  );
}
