export const HEALTH_START = 'HEALTH_START';
export const HEALTH_SUCCESS = 'HEALTH_SUCCESS';
export const HEALTH_FAIL = 'HEALTH_FAIL';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTHENTICATION_FROM_CACHE = 'SET_AUTHENTICATION_FROM_CACHE';
export const IS_LOGIN_SUCCESS = 'IS_LOGIN_SUCCESS';

export const FETCH_ISSUE_SUCCESS = 'FETCH_ISSUE_SUCCESS';
export const FETCH_ISSUE_FAIL = 'FETCH_ISSUE_FAIL';
export const FETCH_ISSUE_START = 'FETCH_ISSUE_START';

export const FETCH_ADVANCED_LEARNING_ISSUES_SUCCESS = 'FETCH_ADVANCED_LEARNING_ISSUE_SUCCESS';
export const FETCH_ADVANCED_LEARNING_ISSUES_FAIL = 'FETCH_ADVANCED_LEARNING_ISSUE_FAIL';
export const FETCH_ADVANCED_LEARNING_ISSUES_START = 'FETCH_ADVANCED_LEARNING_ISSUE_START';

export const UPDATE_ISSUE_SUCCESS = 'UPDATE_ISSUE_SUCCESS';
export const UPDATE_ISSUE_FAIL = 'UPDATE_ISSUE_FAIL';
export const UPDATE_ISSUE_START = 'UPDATE_ISSUE_START';
export const START_UPDATE = 'START_UPDATE';

export const DELETE_ISSUE_SUCCESS = 'DELETE_ISSUE_SUCCESS';
export const DELETE_ISSUE_FAIL = 'DELETE_ISSUE_FAIL';
export const DELETE_ISSUE_START = 'DELETE_ISSUE_START';

export const RECOGNIZE_ISSUE_SUCCESS = 'RECOGNIZE_ISSUE_SUCCESS';
export const RECOGNIZE_ISSUE_FAIL = 'RECOGNIZE_ISSUE_FAIL';
export const RECOGNIZE_ISSUE_START = 'RECOGNIZE_ISSUE_START';

export const LOCATION_PATH_NAME_CHANGED_START = 'LOCATION_PATH_NAME_CHANGED_START';
export const LOCATION_PATH_NAME_CHANGED_SUCCESS = 'LOCATION_PATH_NAME_CHANGED_SUCCESS';
export const LOCATION_PATH_NAME_CHANGED_FAIL = 'LOCATION_PATH_NAME_CHANGED_FAIL';

export const APPROVE_ALL_ISSUES_START = 'APPROVE_ALL_SUCCES_START';
export const APPROVE_ALL_ISSUES_FAIL = 'APPROVE_ALL_ISSUES_FAIL';
export const APPROVE_ALL_ISSUES_SUCCESS = 'APPROVE_ALL_ISSUES_SUCCESS';

export const CREATE_ISSUE_SUCCESS = 'CREATE_ISSUE_SUCCESS';
export const CREATE_ISSUE_FAIL = 'CREATE_ISSUE_FAIL';
export const CREATE_ISSUE_START = 'CREATE_ISSUE_START';

export const FETCH_EXCERPT_BY_ID_START = 'FETCH_EXCERPT_BY_ID_START';
export const FETCH_EXCERPT_BY_ID_SUCCESS = 'FETCH_EXCERPT_BY_ID_SUCCESS';
export const FETCH_EXCERPT_BY_ID_FAIL = 'FETCH_EXCERPT_BY_ID_FAIL';

export const TRAIN_ISSUE_SUCCESS = 'TRAIN_ISSUE_SUCCESS';
export const TRAIN_ISSUE_FAIL = 'TRAIN_ISSUE_FAIL';

export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAIL = 'CREATE_COMMENT_FAIL';
export const CREATE_COMMENT_START = 'CREATE_COMMENT_START';

export const CREATE_GLOBALCONFIG_SUCCESS = 'CREATE_GLOBALCONFIG_SUCCESS';
export const CREATE_GLOBALCONFIG_FAIL = 'CREATE_GLOBALCONFIG_FAIL';
export const CREATE_GLOBALCONFIG_START = 'CREATE_GLOBALCONFIG_START';

export const FETCH_GLOBALCONFIG_SUCCESS = 'FETCH_GLOBALCONFIG_SUCCESS';
export const FETCH_GLOBALCONFIG_FAIL = 'FETCH_GLOBALCONFIG_FAIL';
export const FETCH_GLOBALCONFIG_START = 'FETCH_GLOBALCONFIG_START';

export const FETCH_ALL_GLOBALCONFIGS_SUCCESS = 'FETCH_ALL_GLOBALCONFIGS_SUCCESS';
export const FETCH_ALL_GLOBALCONFIGS_FAIL = 'FETCH_ALL_GLOBALCONFIGS_FAIL';
export const FETCH_ALL_GLOBALCONFIGS_START = 'FETCH_ASS_GLOBALCONFIGS_START';

export const UPDATE_GLOBALCONFIG_START = 'UPDATE_GLOBALCOPNFIG_START';
export const UPDATE_GLOBALCONFIG_SUCCESS = 'UPDATE_GLOBALCONFIG_SUCCESS';
export const UPDATE_GLOBALCONFIG_FAIL = 'UPDATE_GLOBALCONFIGS_FAIL';

export const UPDATE_GLOBALCONFIG_FOR_ALL_START = 'UPDATE_GLOBALCOPNFIG_FOR_ALL_START';
export const UPDATE_GLOBALCONFIG_FOR_ALL_SUCCESS = 'UPDATE_GLOBALCONFIG_FOR_ALL_SUCCESS';
export const UPDATE_GLOBALCONFIG_FOR_ALL_FAIL = 'UPDATE_GLOBALCONFIGS__FOR_ALL_FAIL';

export const FETCH_DOCUMENT_COMMENTS_START = 'FETCH_DOCUMENT_COMMENTS_START';
export const FETCH_DOCUMENT_COMMENTS_FAIL = 'FETCH_DOCUMENT_COMMENTS_FAIL';
export const FETCH_DOCUMENT_COMMENTS_SUCCESS = 'CREATE_COMFETCH_DOCUMENT_COMMENTS_SUCCESS';

export const FETCH_ISSUE_COMMENTS_START = 'FETCH_ISSUE_COMMENTS_START';
export const FETCH_ISSUE_COMMENTS_FAIL = 'FETCH_ISSUE_COMMENTS_FAIL';
export const FETCH_ISSUE_COMMENTS_SUCCESS = 'FETCH_ISSUE_COMMENTS_SUCCESS';

export const DELETE_COMMENT_START = 'DELETE_COMMENT_START';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';

export const FETCH_DOCUMENT_START = 'FETCH_DOCUMENT_START';
export const FETCH_DOCUMENT_FAIL = 'FETCH_DOCUMENT_FAIL';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';

export const FETCH_THRESHOLD_START = 'FETCH_THRESHOLD_START';
export const FETCH_THRESHOLD_FAIL = 'FETCH_THRESHOLD_FAIL';
export const FETCH_THRESHOLD_SUCCESS = 'FETCH_THRESHOLD_SUCCESS';

export const SET_STATUS_START = 'SET_STATUS_START';
export const SET_STATUS_FAIL = 'SET_STATUS_FAIL';
export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';

export const EXPORT_ISSUES_DOCUMENT_START = 'EXPORT_ISSUES_DOCUMENT_START';
export const EXPORT_ISSUES_DOCUMENT_FAIL = 'EXPORT_ISSUES_DOCUMENT_FAIL';
export const EXPORT_ISSUES_DOCUMENT_SUCCESS = 'EXPORT_ISSUES_DOCUMENT_SUCCESS';

export const CREATE_GLOBAL_CONFIG_START = 'CREATE_GLOBAL_CONFIG_START';
export const CREATE_GLOBAL_CONFIG_SUCCESS = 'CREATE_GLOBAL_CONFIG_SUCCESS';
export const CREATE_GLOBAL_CONFIG_FAIL = 'CREATE_GLOBAL_CONFIG_FAIL';

export const DELETE_USER_GLOBAL_CONFIG_START = 'DELETE_USER_GLOBAL_CONFIG_START';
export const DELETE_USER_GLOBAL_CONFIG_SUCCESS = 'DELETE_USER_GLOBAL_CONFIG_SUCCESS';
export const DELETE_USER_GLOBAL_CONFIG_FAIL = 'DELETE_USER_GLOBAL_CONFIG_FAIL';

export const UPDATE_GLOBAL_CONFIG_START = 'UPDATE_GLOBAL_CONFIG_START';
export const UPDATE_GLOBAL_CONFIG_SUCCESS = 'UPDATE_GLOBAL_CONFIG_SUCCESS';
export const UPDATE_GLOBAL_CONFIG_FAIL = 'UPDATE_GLOBAL_CONFIG_FAIL';

export const UPDATE_EXCERPT_ACTIONS_START = 'UPDATE_EXCERPT_ACTIONS_START';
export const UPDATE_EXCERPT_ACTIONS_SUCCESS = 'UPDATE_EXCERPT_ACTIONS_SUCCESS';
export const UPDATE_EXCERPT_ACTIONS_FAIL = 'UPDATE_EXCERPT_ACTIONS_FAIL';

export const RESET_UPLOAD_PROCESS = 'RESET_UPLOAD_PROCESS';

export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const UPLOAD_FILES_START = 'UPLOAD_FILES_START';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAIL = 'UPLOAD_FILES_FAIL';

export const FETCH_CONTRACT_STATISTICS_START = 'FETCH_CONTRACT_STATISTICS_START';
export const FETCH_CONTRACT_STATISTICS_FAIL = 'FETCH_CONTRACT_STATISTICS_FAIL';
export const FETCH_CONTRACT_STATISTICS_AGGREGATIONS_SUCCESS =
  'FETCH_CONTRACT_STATISTICS_AGGREGATIONS_SUCCESS';
export const FETCH_CONTRACT_STATISTICS_ORIGINS_SUCCESS =
  'FETCH_CONTRACT_STATISTICS_ORIGINS_SUCCESS';

export const FETCH_STATISTICS_BY_TYPE_START = 'FETCH_STATISTICS_BY_TYPE_START';
export const FETCH_STATISTICS_BY_TYPE_SUCCESS = 'FETCH_STATISTICS_BY_TYPE_SUCCESS';
export const FETCH_STATISTICS_BY_TYPE_FAIL = 'FETCH_STATISTICS_BY_TYPE_FAIL';

export const FETCH_DOCS_BY_ORIGIN_START = 'FETCH_DOCS_BY_ORIGIN_START';
export const FETCH_DOCS_BY_ORIGIN_SUCCESS = 'FETCH_DOCS_BY_ORIGIN_SUCCESS';
export const FETCH_DOCS_BY_ORIGIN_FAIL = 'FETCH_DOCS_BY_ORIGIN_FAIL';

export const UPDATE_STATISTICS_BY_TYPE_SUCCESS = 'UPDATE_STATISTICS_BY_TYPE_SUCCESS';

export const FETCH_DIFF_START = 'FETCH_DIFF_START';
export const FETCH_DIFF_SUCCESS = 'FETCH_DIFF_SUCCESS';
export const FETCH_DIFF_FAIL = 'FETCH_DIFF_FAIL';

export const FETCH_DIFF_TYPES_START = 'FETCH_DIFF_TYPES_START';
export const FETCH_DIFF_TYPES_SUCCESS = 'FETCH_DIFF_TYPES_SUCCESS';
export const FETCH_DIFF_TYPES_FAIL = 'FETCH_DIFF_TYPES_FAIL';

export const SET_REFERENCE_FILE = 'SET_REFERENCE_FILE';
export const SET_COMPARED_FILE = 'SET_COMPARED_FILE';
export const RESET_REFERENCE_FILE = 'RESET_REFERENCE_FILE';
export const RESET_COMPARED_FILE = 'RESET_COMPARED_FILE';

export const SET_IS_USING_COMPARE_DATABASE = 'SET_IS_USING_COMPARE_DATABASE';

export const FETCH_COMPARE_DB_FINDINGS_START = 'FETCH_COMPARE_DB_FINDINGS_START';
export const FETCH_COMPARE_DB_FINDINGS_SUCCESS = 'FETCH_COMPARE_DB_FINDINGS_SUCCESS';
export const FETCH_COMPARE_DB_FINDINGS_FAIL = 'FETCH_COMPARE_DB_FINDINGS_FAIL';

export const CREATE_COMPARE_DB_FINDING_START = 'CREATE_COMPARE_DB_FINDINGS_START';
export const CREATE_COMPARE_DB_FINDING_SUCCESS = 'CREATE_COMPARE_DB_FINDINGS_SUCCESS';
export const CREATE_COMPARE_DB_FINDING_FAIL = 'CREATE_COMPARE_DB_FINDINGS_FAIL';

export const UPDATE_COMPARE_DB_FINDINGS_START = 'UPDATE_COMPARE_DB_FINDINGS_START';
export const UPDATE_COMPARE_DB_FINDINGS_SUCCESS = 'UPDATE_COMPARE_DB_FINDINGS_SUCCESS';
export const UPDATE_COMPARE_DB_FINDINGS_FAIL = 'UPDATE_COMPARE_DB_FINDINGS_FAIL';

export const DELETE_DOCUMENT_FAIL = 'DELETE_DOCUMENT_FAIL'; // used for deleting comments

export const UPDATE_DOCUMENT_STATUS = 'UPDATE_DOCUMENT_STATUS';

export const RETRY_PROCESS_DOCUMENT_START = 'RETRY_PROCESS_DOCUMENT_START';
export const RETRY_PROCESS_DOCUMENT_SUCCESS = 'RETRY_PROCESS_DOCUMENT_SUCCESS';
export const RETRY_PROCESS_DOCUMENT_FAIL = 'RETRY_PROCESS_DOCUMENT_FAIL';

export const RETRY_PROCESS_STATIC_FINDING_START = 'RETRY_PROCESS_STATIC_FINDING_START';
export const RETRY_PROCESS_STATIC_FINDING_SUCCESS = 'RETRY_PROCESS_STATIC_FINDING_SUCCESS';
export const RETRY_PROCESS_STATIC_FINDING_FAIL = 'RETRY_PROCESS_STATIC_FINDING_FAIL';

export const RE_PROCESS_DOCUMENT_START = 'RE_PROCESS_DOCUMENT_START';
export const RE_PROCESS_DOCUMENT_SUCCESS = 'RE_PROCESS_DOCUMENT_SUCCESS';
export const RE_PROCESS_DOCUMENT_FAIL = 'RE_PROCESS_DOCUMENT_FAIL';

export const DELETE_MULTI_DOCUMENTS_START = 'DELETE_MULTI_DOCUMENTS_START';
export const DELETE_MULTI_DOCUMENTS_SUCCESS = 'DELETE_MULTI_DOCUMENTS_SUCCESS';
export const DELETE_MULTI_DOCUMENTS_FAIL = 'DELETE_MULTI_DOCUMENTS_FAIL';

export const UPDATE_ISSUE_COMMENT_START = 'UPDATE_ISSUE_COMMENT_START';
export const UPDATE_ISSUE_COMMENT_FAIL = 'UPDATE_ISSUE_COMMENT_FAIL';
export const UPDATE_ISSUE_COMMENT_SUCCESS = 'UPDATE_ISSUE_COMMENT_SUCCESS';

export const BOOTSTRAP_STATICS_ISSUES_START = 'BOOTSTRAP_STATICS_ISSUES_START';
export const BOOTSTRAP_STATICS_ISSUES_SUCCESS = 'BOOTSTRAP_STATICS_ISSUES_SUCCESS';
export const BOOTSTRAP_STATICS_ISSUES_FAIL = 'BOOTSTRAP_STATICS_ISSUES_FAIL';

export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';

export const FETCH_PROJECT_BY_ID_START = 'FETCH_PROJECT_BY_ID_START';
export const FETCH_PROJECT_BY_ID_SUCCESS = 'FETCH_PROJECT_BY_ID_SUCCESS';
export const FETCH_PROJECT_BY_ID_FAIL = 'FETCH_PROJECT_BY_ID_FAIL';

export const CREATE_PROJECT_START = 'CREATE_PROJECT_START';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAIL = 'CREATE_PROJECT_FAIL';

export const RENAME_PROJECT_START = 'RENAME_PROJECT_START';
export const RENAME_PROJECT_SUCCESS = 'RENAME_PROJECT_SUCCESS';
export const RENAME_PROJECT_FAIL = 'RENAME_PROJECT_FAIL';

export const UPDATE_PROJECT_START = 'UPDATE_PROJECT_START';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';

export const DELETE_PROJECT_START = 'DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';

/* To globally save the latest selected options */
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const SELECT_CONTRACT = 'SELECT_CONTRACT';
// Explore page
export const SELECT_FINDING_TYPE = 'SELECT_FINDING_TYPE';
export const SELECT_ORIGIN = 'SELECT_ORIGIN';
export const SELECT_USER_ANNOTATION_FILTERS = 'SELECT_USER_ANNOTATION_FILTERS';
export const SELECT_PAGE_EXPLORE = 'SELECT_PAGE_EXPLORE';
// Upload page
export const SELECT_STATUS_FILTER = 'SELECT_STATUS_FILTER';
export const SELECT_SORT_DOCS_BY = 'SELECT_SORT_DOCS_BY';
export const SELECT_FILTER_DOCS_BY = 'SELECT_FILTER_DOCS_BY';
export const SELECT_PAGE = 'SELECT_PAGE';

export const CREATE_CONTRACT_TYPE_START = 'CREATE_CONTRACT_TYPE_START';
export const CREATE_CONTRACT_TYPE_SUCCESS = 'CREATE_CONTRACT_TYPE_SUCCESS';
export const CREATE_CONTRACT_TYPE_FAIL = 'CREATE_CONTRACT_TYPE_FAIL';

export const RENAME_CONTRACT_TYPE_START = 'RENAME_CONTRACT_TYPE_START';
export const RENAME_CONTRACT_TYPE_SUCCESS = 'RENAME_CONTRACT_TYPE_SUCCESS';
export const RENAME_CONTRACT_TYPE_FAIL = 'RENAME_CONTRACT_TYPE_FAIL';

export const UPDATE_CONTRACT_TYPE_START = 'UPDATE_CONTRACT_TYPE_START';
export const UPDATE_CONTRACT_TYPE_SUCCESS = 'UPDATE_CONTRACT_TYPE_SUCCESS';
export const UPDATE_CONTRACT_TYPE_FAIL = 'UPDATE_CONTRACT_TYPE_FAIL';

export const DELETE_CONTRACT_START = 'DELETE_CONTRACT_START';
export const DELETE_CONTRACT_SUCCESS = 'DELETE_CONTRACT_SUCCESS';
export const DELETE_CONTRACT_FAIL = 'DELETE_CONTRACT_FAIL';

export const SET_DEFAULT_PROJECT = 'SET_DEFAULT_PROJECT';

export const FETCH_DOCUMENTS_START = 'FETCH_DOCUMENTS_START';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_FAIL = 'FETCH_DOCUMENTS_FAIL';

export const FETCH_LATEST_DOCS_START = 'FETCH_LATEST_DOCS_START';
export const FETCH_LATEST_DOCS_SUCCESS = 'FETCH_LATEST_DOCS_SUCCESS';
export const FETCH_LATEST_DOCS_FAIL = 'FETCH_LATEST_DOCS_FAIL';

export const FETCH_DOCUMENTS_COUNT_START = 'FETCH_DOCUMENTS_COUNT_START';
export const FETCH_DOCUMENTS_COUNT_SUCCESS = 'FETCH_DOCUMENTS_COUNT_SUCCESS';
export const FETCH_DOCUMENTS_COUNT_FAIL = 'FETCH_DOCUMENTS_COUNT_FAIL';

export const FETCH_DYNAMIC_TABS_START = 'FETCH_DYNAMIC_TABS_START';
export const FETCH_DYNAMIC_TABS_SUCCESS = 'FETCH_DYNAMIC_TABS_SUCCESS';
export const FETCH_DYNAMIC_TABS_FAIL = 'FETCH_DYNAMIC_TABS_FAIL';

export const ADD_DYNAMIC_TAB_START = 'ADD_DYNAMIC_TAB_START';
export const ADD_DYNAMIC_TAB_SUCCESS = 'ADD_DYNAMIC_TAB_SUCCESS';
export const ADD_DYNAMIC_TAB_FAIL = 'ADD_DYNAMIC_TAB_FAIL';

export const UPDATE_DYNAMIC_TAB_START = 'UPDATE_DYNAMIC_TAB_START';
export const UPDATE_DYNAMIC_TAB_SUCCESS = 'UPDATE_DYNAMIC_TAB_SUCCESS';
export const UPDATE_DYNAMIC_TAB_FAIL = 'UPDATE_DYNAMIC_TAB_FAIL';

export const DELETE_DYNAMIC_TAB_START = 'DELETE_DYNAMIC_TAB_START';
export const DELETE_DYNAMIC_TAB_SUCCESS = 'DELETE_DYNAMIC_TAB_SUCCESS';
export const DELETE_DYNAMIC_TAB_FAIL = 'DELETE_DYNAMIC_TAB_FAIL';

export const FETCH_SEARCH_QUERY_START = 'FETCH_SEARCH_QUERY_START';
export const FETCH_SEARCH_QUERY_SUCCESS = 'FETCH_SEARCH_QUERY_SUCCESS';
export const FETCH_SEARCH_QUERY_FAIL = 'FETCH_SEARCH_QUERY_FAIL';

export const CREATE_SEARCH_QUERY_START = 'CREATE_SEARCH_QUERY_START';
export const CREATE_SEARCH_QUERY_SUCCESS = 'CREATE_SEARCH_QUERY_SUCCESS';
export const CREATE_SEARCH_QUERY_FAIL = 'CREATE_SEARCH_QUERY_FAIL';

export const EDIT_SEARCH_QUERY_START = 'EDIT_SEARCH_QUERY_START';
export const EDIT_SEARCH_QUERY_SUCCESS = 'EDIT_SEARCH_QUERY_SUCCESS';
export const EDIT_SEARCH_QUERY_FAIL = 'EDIT_SEARCH_QUERY_FAIL';

export const DELETE_SEARCH_QUERY_START = 'DELETE_SEARCH_QUERY_START';
export const DELETE_SEARCH_QUERY_SUCCESS = 'DELETE_SEARCH_QUERY_SUCCESS';
export const DELETE_SEARCH_QUERY_FAIL = 'DELETE_SEARCH_QUERY_FAIL';

export const FETCH_SEARCH_QUERY_HIGHLIGHTS_START = 'FETCH_SEARCH_QUERY_HIGHLIGHTS_START';
export const FETCH_SEARCH_QUERY_HIGHLIGHTS_FAIL = 'FETCH_SEARCH_QUERY_HIGHLIGHTS_FAIL';
export const FETCH_SEARCH_QUERY_HIGHLIGHTS_SUCCESS = 'FETCH_SEARCH_QUERY_HIGHLIGHTS_SUCCESS';
export const CLEAR_SEARCH_QUERY_HIGHLIGHTS = 'CLEAR_SEARCH_QUERY_HIGHLIGHTS';

export const SELECT_PAGE_QUERY_HIGHLIGHTS = 'SELECT_PAGE_QUERY_HIGHLIGHTS';

export const APPLY_QUERY_START = 'APPLY_QUERY_START';
export const APPLY_QUERY_SUCCESS = 'APPLY_QUERY_SUCCESS';
export const APPLY_QUERY_FAIL = 'APPLY_QUERY_FAIL';

export const SET_SELECTED_CONTRACTID = 'SET_SELECTED_CONTRACTID';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const CREATE_ROLE_START = 'CREATE_ROLE_START';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';

export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';

export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';

export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';

export const CREATE_PERMISSION_SET_START = 'CREATE_PERMISSION_SET_START';
export const CREATE_PERMISSION_SET_SUCCESS = 'CREATE_PERMISSION_SET_SUCCESS';
export const CREATE_PERMISSION_SET_FAIL = 'CREATE_PERMISSION_SET_FAIL';

export const UPDATE_PERMISSION_SET_SUCCESS = 'UPDATE_PERMISSION_SET_SUCCESS';
export const UPDATE_PERMISSION_SET_START = 'UPDATE_PERMISSION_SET_START';
export const UPDATE_PERMISSION_SET_FAIL = 'UPDATE_PERMISSION_SET_FAIL';

export const DELETE_PERMISSION_SET_SUCCESS = 'DELETE_PERMISSION_SET_SUCCESS';
export const DELETE_PERMISSION_SET_START = 'DELETE_PERMISSION_SET_START';
export const DELETE_PERMISSION_SET_FAIL = 'DELETE_PERMISSION_SET_FAIL';

export const FETCH_PERMISSION_SETS_START = 'FETCH_PERMISSION_SETS_START';
export const FETCH_PERMISSION_SETS_SUCCESS = 'FETCH_PERMISSION_SETS_SUCCESS';
export const FETCH_PERMISSION_SETS_FAIL = 'FETCH_PERMISSION_SETS_FAIL';

export const FETCH_PERMISSIONS_START = 'FETCH_PERMISSIONS_START';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL';

export const SET_VIEW_SELECTED_ISSUE = 'SET_VIEW_SELECTED_ISSUE';
export const DELETE_VIEW_SELECTED_ISSUE = 'DELETE_VIEW_SELECTED_ISSUE';

export const FETCH_DOCUMENT_DYNAMIC_THRESHOLD_START = 'FETCH_DOCUMENT_DYNAMIC_THRESHOLD_START';
export const FETCH_DOCUMENT_DYNAMIC_THRESHOLD_FAIL = 'FETCH_DOCUMENT_DYNAMIC_THRESHOLD_FAIL';
export const FETCH_DOCUMENT_DYNAMIC_THRESHOLD_SUCCESS = 'FETCH_DOCUMENT_DYNAMIC_THRESHOLD_SUCCESS';

export const FETCH_DOCUMENT_NO_FINDING_TYPES_START = 'FETCH_DOCUMENT_NO_FINDING_TYPES_START';
export const FETCH_DOCUMENT_NO_FINDING_TYPES_SUCCESS = 'FETCH_DOCUMENT_NO_FINDING_TYPES_SUCCESS';
export const FETCH_DOCUMENT_NO_FINDING_TYPES_FAIL = 'FETCH_DOCUMENT_NO_FINDING_TYPES_FAIL';

export const FETCH_GLOBAL_SETTINGS_START = 'FETCH_GLOBAL_SETTINGS_START';
export const FETCH_GLOBAL_SETTINGS_SUCCESS = 'FETCH_GLOBAL_SETTINGS_SUCCESS';
export const FETCH_GLOBAL_SETTINGS_FAIL = 'FETCH_GLOBAL_SETTINGS_FAIL';

export const UPDATE_GLOBAL_SETTINGS_START = 'UPDATE_GLOBAL_SETTINGS_START';
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = 'UPDATE_GLOBAL_SETTINGS_SUCCESS';
export const UPDATE_GLOBAL_SETTINGS_FAIL = 'UPDATE_GLOBAL_SETTINGS_SUCCESS';

export const UPLOAD_IMG_FILE_START = 'UPLOAD_IMG_FILE_START';
export const UPLOAD_IMG_FILE_SUCCESS = 'UPLOAD_IMG_FILE_SUCCESS';
export const UPLOAD_IMG_FILE_FAIL = 'UPLOAD_IMG_FILE_FAIL';

export const UPDATE_IMG_FILE_START = 'UPDATE_IMG_FILE_START';
export const UPDATE_IMG_FILE_SUCCESS = 'UPDATE_IMG_FILE_SUCCESS';
export const UPDATE_IMG_FILE_FAIL = 'UPDATE_IMG_FILE_FAIL';

export const DELETE_IMG_FILE_START = 'DELETE_IMG_FILE_START';
export const DELETE_IMG_FILE_SUCCESS = 'DELETE_IMG_FILE_SUCCESS';
export const DELETE_IMG_FILE_FAIL = 'DELETE_IMG_FILE_FAIL';

export const UPDATE_CSS_LOGO_START = 'UPDATE_CSS_LOGO_START';
export const UPDATE_CSS_LOGO_SUCCESS = 'UPDATE_CSS_LOGO_SUCCESS';
export const UPDATE_CSS_LOGO_FAIL = 'UPDATE_CSS_LOGO_FAIL';

export const UPDATE_FREEZE_TRAINING_START = 'UPDATE_FREEZE_TRAINING_START';
export const UPDATE_FREEZE_TRAINING_SUCCESS = 'UPDATE_FREEZE_TRAINING_SUCCESS';
export const UPDATE_FREEZE_TRAINING_FAIL = 'UPDATE_FREEZE_TRAINING_FAIL';
