import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import _ from 'lodash';
import moment, { min } from 'moment';
import Issues from '../../../components/Review/Issues/Issues';
import {
  fetchAdvancedLearningIssues,
  fetchDynamicThresholds,
  fetchDocumentNoFindingTypes,
} from '../../../store/actions';
import CustomSlider from '../../../components/UI/Slider/Slider';
import CustomTooltip from '../../../components/UI/Tooltip/Tooltip';
import { usePrevious } from '../../../hooks/prevValue';
import { RightSideToolBar } from './RightSideToolBar';
import useReferredState from '../../../hooks/useReferredState';
import { round } from '../../../services/round';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { FINDING_CATEGORY, ORIGIN } from '../../../constants/constants';
import Icon from '../../../components/UI/Icon/Icon';
import Button from '../../../components/UI/Button/Button';
import { ExcerptType } from '../../../components/Review/Issues/ExcerptType/ExcerptType';

type Props = {
  selectedIssue: any,
  selectIssue: (issueId: string) => void,
  showRightSide: boolean,
  toggleRightSide: void,
  threshold: number,
  changeThreshold: (threshold: number) => void,
  excerptTypeDetails: [],
  isMarkedAs: (category: string, findingName: string) => boolean,
  hasConvertToUserPermission: boolean,
  hasRecognizeCorrectTrainPermission: boolean,
  hasRecognizeIncorrectTrainPermission: boolean,
};

export const RightSideMenu = ({
  selectIssue,
  selectedIssue,
  showRightSide,
  toggleRightSide,
  threshold,
  changeThreshold,
  excerptTypeDetails,
  isMarkedAs,
  hasConvertToUserPermission,
  hasRecognizeCorrectTrainPermission,
  hasRecognizeIncorrectTrainPermission,
}: Props) => {
  const dispatch = useDispatch();
  const {
    issuesProps,
    document,
    projects,
    dynamicThresholds,
    isFetching,
    fetchingDynamicThreshold,
    noFindingTypes,
  } = useSelector(
    (state) => ({
      issuesProps: state.advancedLearningIssues.advancedLearningIssues,
      isFetching: state.advancedLearningIssues.isFetching,
      document: state.document.data,
      projects: state.project.projects,
      dynamicThresholds: state.dynamicThresholds.dynamicThresholds,
      fetchingDynamicThreshold: state.dynamicThresholds.fetchingDynamicThreshold,
      noFindingTypes: state.document.noFindingTypes,
    }),
    shallowEqual,
  );
  const project = projects.get(document.projectId);
  const contractType = project.contractConfig.find((ct) => ct.id === document.contractId);
  const [modelVersion, setModelVersion] = useState('');
  const [isModelVersionChanged, setIsModelVersionChanged] = useState(false);
  const [isAdvancedLearningTypesChanged, setIsAdvancedLearningTypesChanged] = useState(false);
  const [selectedAdvancedLearningType, setSelectedAdvancedLearningType] = useReferredState('');
  const [showHistory, setShowHistory] = useState(false);
  const prevSelectedAdvancedLearningType = usePrevious(selectedAdvancedLearningType);
  const isMounting = useRef(true);

  useEffect(() => {
    if (
      selectedIssue &&
      selectedIssue.origin === ORIGIN.ADVANCED &&
      (threshold || dynamicThresholds.get(selectedIssue.excerptType))
    ) {
      setSelectedAdvancedLearningType(selectedIssue.excerptType);
    }
    return () => {
      isMounting.current = true;
    };
    // eslint-disable-next-line
  }, [selectedIssue]);

  useEffect(() => {
    setNewSelectedConfigValues();
    const minThresholdValue = round(
      dynamicThresholds.get(selectedAdvancedLearningType.current) * 100,
      0,
    );
    if (minThresholdValue && prevSelectedAdvancedLearningType) {
      changeThreshold(minThresholdValue);
    }
    // eslint-disable-next-line
  }, [selectedAdvancedLearningType.current]);

  useEffect(() => {
    if (!isMounting.current) {
      setIsAdvancedLearningTypesChanged(getIsAdvancedLearningTypesChanged());
      if (selectedAdvancedLearningType.current) {
        const currentModelVersion = getModelVersion();
        if (currentModelVersion !== modelVersion) {
          setIsModelVersionChanged(true);
          setShowHistory(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [projects]);

  useEffect(() => {
    if (!isMounting.current) {
      if (dynamicThresholds.size) {
        setIsAdvancedLearningTypesChanged(getIsAdvancedLearningTypesChanged());
        setNewSelectedConfigValues();
        if (!threshold && selectedAdvancedLearningType.current && dynamicThresholds.size > 0) {
          dispatch(
            fetchAdvancedLearningIssues(
              document.id,
              selectedAdvancedLearningType.current,
              round(dynamicThresholds.get(selectedAdvancedLearningType.current)),
            ),
          );
        }
      }

      const selectedTypeScore = round(
        dynamicThresholds.get(selectedAdvancedLearningType.current) * 100,
        0,
      );
      if (!Number.isNaN(selectedTypeScore)) {
        changeThreshold(selectedTypeScore);
      }
    } else {
      isMounting.current = false;
    }
    // eslint-disable-next-line
  }, [dynamicThresholds]);

  const getIsAdvancedLearningTypesChanged = () => {
    const advancedLearningConfigsTypes = contractType.globalConfig
      .filter((gb) => gb.origin === ORIGIN.ADVANCED)
      .map((gb) => {
        return gb.excerptType;
      });
    const existingTypes = Array.from(dynamicThresholds).map(([key, value]) => {
      return key;
    });
    return existingTypes.length !== advancedLearningConfigsTypes.length;
  };

  const getModelVersion = () => {
    const excerptConfigs = contractType.globalConfig.find(
      (gb) =>
        gb.excerptType === selectedAdvancedLearningType.current && gb.origin === ORIGIN.ADVANCED,
    );
    return excerptConfigs ? excerptConfigs.modelVersion : '';
  };

  const setNewSelectedConfigValues = () => {
    if (selectedAdvancedLearningType.current) {
      setModelVersion(getModelVersion());
      setShowHistory(false);
      setIsModelVersionChanged(false);
    }
  };

  const onThresholdChange = (updatedThreshold) => {
    if (isModelVersionChanged) {
      dispatch(fetchDynamicThresholds(document.id));
    }
    changeThreshold(updatedThreshold);
    dispatch(
      fetchAdvancedLearningIssues(
        document.id,
        selectedAdvancedLearningType.current,
        updatedThreshold / 100,
      ),
    );
  };

  const updateAdvancedLearningListWithCurrentVersion = () => {
    dispatch(fetchDynamicThresholds(document.id));
    dispatch(
      fetchAdvancedLearningIssues(
        document.id,
        selectedAdvancedLearningType.current,
        threshold / 100,
      ),
    );
    dispatch(fetchDocumentNoFindingTypes(document.id));
  };

  let issues = issuesProps.filter(
    (issue) =>
      issue.excerptType.toLowerCase() === selectedAdvancedLearningType.current.toLowerCase(),
  );

  let currentExcerptsGroupByDate = _.chain(issues)
    .filter((issue) => {
      if (issue.modelVersion === modelVersion) {
        return issue;
      }
    })
    .sortBy('createdDate')
    .reverse()
    .groupBy('createdDate')
    .toPairs()
    .value();

  let historyExcerptsGroupByDate = _.chain(issues)
    .filter((issue) => {
      if (issue.modelVersion !== modelVersion) {
        return issue;
      }
    })
    .sortBy('createdDate')
    .reverse()
    .groupBy('createdDate')
    .toPairs()
    .value();

  return (
    <div className={`al-review-al-entries right-side-menu ${showRightSide ? 'show' : 'hide'}`}>
      <CustomTooltip
        title={
          showRightSide ? 'Hide advanced learning findings' : 'Show advanced learning findings'
        }
        enterDelay={1000}
        leaveDelay={200}
        placement="top-end"
        classNames={{ root: 'al-tooltip' }}
        interactive={false}
      >
        <span className="toggle-right-side-menu" onClick={() => toggleRightSide()}>
          {showRightSide ? (
            <ArrowForwardIosIcon
              className={'al-icons'}
              style={{
                marginLeft: '5px',
                marginTop: '4px',
              }}
            />
          ) : (
            <ArrowBackIosIcon
              className={'al-icons'}
              style={{
                marginLeft: '7px',
                marginTop: '4px',
              }}
            />
          )}
        </span>
      </CustomTooltip>
      <RightSideToolBar
        docId={document.id}
        preSelectedType={selectedAdvancedLearningType.current}
        setParentAdvancedLearningType={setSelectedAdvancedLearningType}
        isMarkedAs={isMarkedAs}
        findingCategory={FINDING_CATEGORY.CRITICAL}
      />
      {showRightSide ? (
        <div className={'al-review-right-content'}>
          <div
            className={'al-selected-al-type-content'}
            style={{
              maxHeight: noFindingTypes.length === 0 ? '100%' : '72%',
              background: `${
                selectedAdvancedLearningType.current &&
                isMarkedAs(
                  FINDING_CATEGORY.CRITICAL,
                  selectedAdvancedLearningType.current,
                  false,
                  true,
                )
                  ? 'lightcoral'
                  : 'transparent'
              }`,
            }}
          >
            {(isModelVersionChanged || isAdvancedLearningTypesChanged) && (
              <div className={'model-change-msg'}>
                <Icon
                  style={{ color: '#f69c55', float: 'right' }}
                  icon={'bulb'}
                  title={'Training model has been changed'}
                />
                <span style={{ padding: '10px', color: '#6e7582', fontStyle: 'italic' }}>
                  {isModelVersionChanged
                    ? `New changes are available for type 
                     '${
                       selectedAdvancedLearningType.current.charAt(0).toUpperCase() +
                       selectedAdvancedLearningType.current.slice(1)
                     }', 
                     click on the refresh icon to get the latest changes.`
                    : `Advanced learning types list is not up to date, click on the refresh icon to get the latest changes.`}
                </span>
                <Button
                  title={'Update advanced learning list with the new version changes'}
                  style={{ float: 'right' }}
                  withIcon={true}
                  iconType={'sync'}
                  clicked={updateAdvancedLearningListWithCurrentVersion}
                />
              </div>
            )}
            {fetchingDynamicThreshold ? (
              <Spinner />
            ) : selectedAdvancedLearningType.current.length ? (
              <>
                <div
                  style={{ padding: '0px 15px', marginBottom: '10px' }}
                  className={'al-headline'}
                >
                  <span>{selectedAdvancedLearningType.current}</span>
                </div>
                <div className="al-select-score-wrapper">
                  <CustomSlider
                    min={5}
                    max={100}
                    marks={[
                      { value: 5, label: '5%' },
                      { value: 100, label: '100%' },
                    ]}
                    defaultValue={threshold}
                    steps={1}
                    title={'Scoring:'}
                    onSliderValueChanged={(event, value) => {
                      onThresholdChange(value);
                    }}
                  />
                </div>
                {isFetching ? (
                  <Spinner />
                ) : (
                  <div
                    className={`al-review-entries-list-right 
                    ${
                      isModelVersionChanged || isAdvancedLearningTypesChanged
                        ? 'with-notification'
                        : ''
                    }`}
                  >
                    {currentExcerptsGroupByDate.length === 0 && (
                      <div className="no-advanced-learnings">
                        {`No advanced learning for the current training version for type ${selectedAdvancedLearningType.current}`}
                      </div>
                    )}
                    {currentExcerptsGroupByDate.map(([keyName, value], key) => {
                      return (
                        <div className={'al-issues-container'} key={keyName}>
                          <div className={'al-issues-toolbar'}>
                            <div className={'al-issues-toolbar-info'}>
                              {moment(keyName).format('ddd, MMM D YYYY h:mm:ss A')}
                            </div>
                          </div>
                          <Issues
                            project={project}
                            selectedIssue={selectedIssue}
                            selectIssue={selectIssue}
                            issues={_.sortBy(value, 'probability').reverse()}
                            document={document}
                            createAction={() => {}}
                            setIssueStatus={() => {}}
                            deleteIssue={() => {}}
                            hasConvertToUserPermission={hasConvertToUserPermission}
                            hasRecognizeCorrectTrainPermission={hasRecognizeCorrectTrainPermission}
                            hasRecognizeIncorrectTrainPermission={
                              hasRecognizeIncorrectTrainPermission
                            }
                          />
                        </div>
                      );
                    })}
                    <div
                      onClick={() => setShowHistory(!showHistory)}
                      style={{
                        color: 'rgb(41, 51, 71)',
                        cursor: 'pointer',
                        padding: '10px',
                        textDecoration: 'underline',
                        textAlign: 'right',
                      }}
                    >
                      {!historyExcerptsGroupByDate.length
                        ? ''
                        : showHistory
                        ? 'Hide history'
                        : 'Show history'}
                    </div>
                    {showHistory &&
                      historyExcerptsGroupByDate.map(([keyName, value], key) => {
                        return (
                          <div className={'al-issues-container'} key={keyName}>
                            <div className={'al-issues-toolbar'}>
                              <div className={'al-issues-toolbar-info'}>
                                {moment(keyName).format('ddd, MMM D YYYY h:mm:ss A')}
                              </div>
                            </div>
                            <Issues
                              project={project}
                              selectedIssue={selectedIssue}
                              selectIssue={selectIssue}
                              issues={_.sortBy(value, 'probability').reverse()}
                              document={document}
                              createAction={() => {}}
                              setIssueStatus={() => {}}
                              deleteIssue={() => {}}
                              hasConvertToUserPermission={hasConvertToUserPermission}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            ) : (
              <div className="no-advanced-learnings">Please select an advanced learning type.</div>
            )}
          </div>
          {noFindingTypes.length > 0 && (
            <div className={'al-unactive-finding-types'}>
              <label className={'al-unactive-finding-types-title'}>
                {`No relevant findings from the following type${
                  noFindingTypes.length > 1 ? 's' : ''
                }:`}
              </label>
              <div className={'al-review-entries'}>
                {noFindingTypes.sort().map((type, index) => {
                  return (
                    <ExcerptType
                      key={index}
                      docId={document.id}
                      excerptType={type}
                      isExpanded={false}
                      excerptTypeDetails={excerptTypeDetails}
                      showCollapse={true}
                      showImportant={isMarkedAs(FINDING_CATEGORY.IMPORTANT, type, false, true)}
                      showCritical={false}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};
