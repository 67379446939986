import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ANNOTATION } from '../../constants/constants';
import ExcerptToggleAiTrainer from '../Review/Issues/Issue/ExcerptToggleAiTrainer';
import Button from '../UI/Button/Button';
import { deleteIssue } from '../../store/actions';
import { usePrevious } from '../../hooks/prevValue';

const UserExcerpt = ({ excerpt, openDocument, hasTrainingPermission }: Props) => {
  const dispatch = useDispatch();
  const isDeleting = useSelector((state) => state.issues.issue.isDeleting);
  const deletingError = useSelector((state) => state.issues.issue.deleteError);
  const deletingExcerptId = useSelector((state) => state.issues.issue.issueId);
  const isFrozenTraining = useSelector(
    (state) => state.globalSettings.globalSettings.freezeTraining,
  );
  const [disabledIssue, setDisabledIssue] = useState(false);
  const prevIsDeleting = usePrevious(isDeleting);

  useEffect(() => {
    if (
      prevIsDeleting &&
      prevIsDeleting !== isDeleting &&
      deletingError &&
      deletingExcerptId === excerpt.excerptId
    ) {
      setDisabledIssue(false);
    }
    // eslint-disable-next-line
  }, [isDeleting]);

  return (
    <article
      key={excerpt.excerptId}
      className={`${
        excerpt.aiTrained
          ? ANNOTATION.POSITIVE
          : excerpt.isNegativeAiTrained
          ? ANNOTATION.NEGATIVE
          : ''
      } ${disabledIssue ? 'disabled-excerpt' : ''}`}
    >
      <p
        className={'al-result-content'}
        title="Go to document"
        onClick={() => openDocument(excerpt.docId, excerpt.excerptId)}
      >
        {excerpt.bodyHTML}
      </p>
      <div className={'user-excerpt-options'}>
        {!excerpt.isNegativeAiTrained && !isFrozenTraining && (
          <ExcerptToggleAiTrainer
            excerpt={excerpt}
            hasTrainingPermission={hasTrainingPermission}
            setDisabledIssue={setDisabledIssue}
          />
        )}
        <Button
          iconType={'delete'}
          title={'Delete'}
          withIcon={true}
          disabled={false}
          clicked={() => {
            dispatch(deleteIssue(excerpt.excerptId));
            setDisabledIssue(true);
          }}
        />
      </div>
    </article>
  );
};

export default UserExcerpt;
