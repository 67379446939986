//@flow
import React, { useState } from 'react';
import type { ExcerptTypeAggregations } from '../../types';
import _ from 'lodash';
import AdvancedLearningExcerpt from './AdvancedLearningExcerpt';
import CustomSlider from '../UI/Slider/Slider';
import File from './File';

type Props = {
  excerptTypeAggregations: any,
  openDocument: (docId: string, excerptId: string) => void,
  threshold: number,
  hasConvertToUserPermission: boolean,
  hasRecognizeCorrectTrainPermission: boolean,
  hasRecognizeIncorrectTrainPermission: boolean,
  onThresholdChange: (value: string) => void,
  openReviewDocument: (docId: string) => void,
  fetchExcerptsList: (docId: string) => void,
};

const AdvancedLearningExcerpts = ({
  excerptTypeAggregations,
  openDocument,
  threshold,
  hasConvertToUserPermission,
  hasRecognizeCorrectTrainPermission,
  hasRecognizeIncorrectTrainPermission,
  onThresholdChange,
  openReviewDocument,
  fetchExcerptsList,
}: Props) => {
  const [showHistory, setShowHistory] = useState(false);

  const showHistoryStyle = {
    color: '#805394',
    float: 'right',
  };

  const getFilteredExcerpts = (excerpts) => {
    return _.sortBy(
      excerpts.filter((excerpt) => {
        return Math.round(excerpt.probability * 100) >= threshold;
      }),
      'probability',
    ).reverse();
  };

  return (
    <>
      <div className="al-select-score-wrapper">
        <CustomSlider
          min={10}
          max={100}
          marks={[
            { value: 10, label: '10%' },
            { value: 100, label: '100%' },
          ]}
          defaultValue={threshold}
          steps={1}
          title={'Scoring:'}
          onSliderValueChanged={(event, value) => {
            onThresholdChange(value);
          }}
        />
      </div>
      {Object.keys(excerptTypeAggregations).map((docId: ExcerptTypeAggregations, index: number) => {
        const statisticByType = excerptTypeAggregations[docId];
        if (statisticByType.excerpts.length === 0) return;
        let excerptsGroupByDate = _.chain(statisticByType.excerpts)
          .sortBy('createdDate')
          .reverse()
          .groupBy('createdDate')
          .toPairs()
          .value();
        let showShowHistoryButton = excerptsGroupByDate.length > 1;
        if (!showHistory) {
          excerptsGroupByDate = [excerptsGroupByDate[0]];
        }
        const filteredExcerpts = getFilteredExcerpts(excerptsGroupByDate[0][1]);
        return (
          <File
            key={index}
            filteredExcerpts={filteredExcerpts}
            docId={docId}
            openReviewDocument={openReviewDocument}
            statisticByType={statisticByType}
            date={excerptsGroupByDate ? excerptsGroupByDate[0][0] : ''}
            threshold={threshold}
            fetchExcerptsList={fetchExcerptsList}
          >
            <AdvancedLearningExcerpt
              key={index}
              openDocument={openDocument}
              hasConvertToUserPermission={hasConvertToUserPermission}
              hasRecognizeCorrectTrainPermission={hasRecognizeCorrectTrainPermission}
              hasRecognizeIncorrectTrainPermission={hasRecognizeIncorrectTrainPermission}
            />
          </File>
        );
      })}
    </>
  );
};

export default AdvancedLearningExcerpts;
