import React, { isValidElement } from 'react';

const TabNav = (props) => {
  const childrenWithProps = props.children
    .filter((child) => isValidElement(child))
    .map((child, index) =>
      React.cloneElement(child, {
        key: index,
      }),
    );
  const classes = ['al-tab-nav', props.class];
  return <div className={classes.join(' ')}> {childrenWithProps} </div>;
};

export default TabNav;
