//@flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recognizeIssue } from '../../../../store/actions';
import Button from '../../../UI/Button/Button';

type Props = {
  excerptId: string,
  loading: boolean,
  disableEventsWhileRecognizing: () => void,
  hasConvertToUserPermission: boolean,
  hasRecognizeCorrectTrainPermission: boolean,
  hasRecognizeIncorrectTrainPermission: boolean,
};

const ExcerptRecognize = ({
  excerptId,
  loading,
  disableEventsWhileRecognizing,
  hasConvertToUserPermission,
  hasRecognizeCorrectTrainPermission,
  hasRecognizeIncorrectTrainPermission,
}: Props) => {
  const dispatch = useDispatch();
  const aiTrainerEnabled = useSelector(
    (state) => state.globalSettings.globalSettings.aiTrainerEnabled,
  );
  const isFrozenTraining = useSelector(
    (state) => state.globalSettings.globalSettings.freezeTraining,
  );

  const handleRecognize = (isCorrect, useForTraining) => {
    dispatch(recognizeIssue(excerptId, isCorrect, useForTraining));
    disableEventsWhileRecognizing();
  };

  return (
    <div className={'al-rate-score'}>
      {hasConvertToUserPermission && (
        <Button
          iconType={'add'}
          withIcon={true}
          title={'Add as user-annotated finding'}
          className={'al-create-finding'}
          disabled={loading}
          clicked={() => handleRecognize(true, false)}
        />
      )}
      {aiTrainerEnabled && (
        <>
          {hasRecognizeCorrectTrainPermission && !isFrozenTraining && (
            <Button
              iconType={'thumb-up'}
              withIcon={true}
              title={'Positive training'}
              className={'al-correct'}
              disabled={loading}
              clicked={() => handleRecognize(true, true)}
            />
          )}
          {hasRecognizeIncorrectTrainPermission && !isFrozenTraining && (
            <Button
              iconType={'thumb-down'}
              withIcon={true}
              title={'Negative training'}
              className={'al-incorrect'}
              disabled={loading}
              clicked={() => handleRecognize(false, true)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ExcerptRecognize;
