import { Dispatch } from 'redux';
import { axiosInstance as axios } from '../../services/axiosInstance';
import * as actionTypes from './actionType';

const fetchDynamicThresholdsStart = (docId: string) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_DYNAMIC_THRESHOLD_START,
    docId,
  };
};
const fetchDynamicThresholdsSuccess = (docId: string, data: any) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_DYNAMIC_THRESHOLD_SUCCESS,
    docId,
    data,
  };
};

const fetchDynamicThresholdsFail = (docId: string, err: any) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_DYNAMIC_THRESHOLD_FAIL,
    docId,
    err,
  };
};

export const fetchDynamicThresholds = (docId: string) => {
  return (dispatch: Dispatch) => {
    dispatch(fetchDynamicThresholdsStart(docId));
    axios
      .get(`/api/documents/${docId}/dynamic-thresholds`)
      .then((response) => {
        dispatch(fetchDynamicThresholdsSuccess(docId, response.data));
      })
      .catch((err) => {
        dispatch(fetchDynamicThresholdsFail(docId, err));
      });
  };
};
