import * as actionTypes from '../actions/actionType';
import type { UserState } from '../../types/reduxTypes';

const initialState: UserState = {
  creatingUser: false,
  creatingUserError: null,
  updatingUser: false,
  updatingUserError: null,
  deletingUser: false,
  deletingUserError: null,
  fetchingUsers: false,
  fetchingUsersError: null,
  users: [
    { id: '1', username: 'a', allProject: false, roles: ['1'], projectIds:[] },
    { id: '2', username: 'b', allProject: false, roles: ['1','2'], projectIds:[] },
    { id: '3', username: 'c', allProject: false, roles: ['3'], projectIds:[] },
  ],
};

const createUserStart = (state, action) => {
  return {
    ...state,
    creatingUser: true,
    creatingUserError: null
  }
};

const createUserSuccess = (state, action) => {
  const updatedUsers = [...state.users];
  updatedUsers.push(action.user);
  return {
    ...state,
    creatingUser: false,
    users: updatedUsers
  }
};

const createUserFail = (state, action) => {
  return {
    ...state,
    creatingUser: false,
    creatingUserError: action.error
  }
};


const updateUserStart = (state, action) => {
  return {
    ...state,
    updatingUser: true,
    updatingUserError: null
  }
};

const updateUserSuccess = (state, action) => {
  const users = state.users.map(u => u.id === action.user.id ? action.user : u);
  return {
    ...state,
    updatingUser: false,
    updatingUserError: null,
    users
  }
};

const updateUserFail = (state, action) => {
  const {error} = action;
  return {
    ...state,
    updatingUser: false,
    updatingUserError: error
  }
};

const deleteUserStart = (state, action) => {
  return {
    ...state,
    deletingUser: true,
    deletingUserError: null,
  }
};

const deleteUserSuccess = (state, action) => {
  const users = state.users.filter(u => u.id !== action.userId);
  return {
    ...state,
    deletingUser: false,
    deletingUserError: null,
    users
  }
};

const deleteUserFail = (state, action) => {
  const {error} = action;
  return {
    ...state,
    creatingUser: false,
    creatingUserError: error
  }
};

const fetchUsersStart = (state, action) => {
  return {
    ...state,
    fetchingUser: true,
    fetchingUserError: null
  }
};

const fetchUsersSuccess = (state, action) => {
  const {users} = action;
  return {
    ...state,
    fetchingUsers: false,
    users
  }
};

const fetchUsersFail = (state, action) => {
  return {
    ...state,
    fetchingUsers: false,
    creatingUserError: action.error
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_START:
      return createUserStart(state, action);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);

    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state, action);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);

    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);

    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state, action);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);

    default:
      return state;
  }
};

export default reducer;
