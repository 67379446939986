import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  downloadAllExcerptsToExcel,
  fetchContractAggregationsStatistics,
  fetchExcerptTypeOriginsStatistics,
  selectContract,
  selectOriginGlobally,
  selectFindingType,
} from '../../store/actions';
import _ from 'lodash';
import { EXPORT_TYPE, PERMISSIONS, SERVER_EVENTS } from '../../constants/constants';
import ExploreWorkspace from '../../components/Explore/ExploreWorkspace';
import ExploreTypesList from '../../components/Explore/ExploreTypesList';
import Button from '../../components/UI/Button/Button';
import { usePrevious } from '../../hooks/prevValue';
import { getAppEventSource } from '../../SSEApp';
import { withPermissions } from '../../contexts/PermissionsContext/withPermissions';

const Explore = ({ PermissionsContext }) => {
  const dispatch = useDispatch();
  const {
    loading,
    error,
    allTypesAggregations,
    projectId,
    projectContractId,
    contractTypes,
    exportLoading,
    exportType,
    globalSelectedFindingType,
    globalSelectedOrigin,
  } = useSelector(
    (state) => ({
      loading: state.statistics.statisticsByType.loading,
      error: state.statistics.statisticsByType.error,
      allTypesAggregations: state.statistics.statisticsAll.fetchingContractStatistics.data,
      projectId: state.project.projectId,
      projectContractId: state.project.contractId,
      contractTypes: state.project.projects.find(
        (project) => project.id === state.project.projectId,
      ).contractConfig,
      exportLoading: state.issues.exportLoading,
      exportType: state.issues.exportType,
      globalSelectedFindingType: state.project.selectedFindingType,
      globalSelectedOrigin: state.project.selectedOrigin,
    }),
    shallowEqual,
  );

  const [selectedType, setSelectedType] = useState(globalSelectedFindingType);
  const [selectedOrigin, setSelectedOrigin] = useState(globalSelectedOrigin);
  const [filter, setFilter] = useState('');
  const [filteredAllTypesAggregations, setFilteredAllTypesAggregations] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState(
    contractTypes.find((contractConfig) => {
      return contractConfig.id === projectContractId;
    }),
  );
  const [isModelVersionChanged, setIsModelVersionChanged] = useState(false);
  const prevAllTypesAggregations = usePrevious(allTypesAggregations);
  const prevProjectId = usePrevious(projectId);

  useEffect(() => {
    dispatch(fetchContractAggregationsStatistics(projectId, selectedContractType.id));

    const timerModelChanges = _.debounce(
      () => {
        setIsModelVersionChanged(true);
      },
      3000,
      { trailing: true },
    );
    getAppEventSource().addEventListener(SERVER_EVENTS.UPDATE_ISSUES, timerModelChanges);

    return () => {
      getAppEventSource().removeEventListener(SERVER_EVENTS.UPDATE_ISSUES, timerModelChanges);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeof prevProjectId !== 'undefined' && prevProjectId !== projectId) {
      selectContractType(projectContractId);
      setIsModelVersionChanged(false);
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    // enter condition only on component update, not on load
    if (typeof prevAllTypesAggregations !== 'undefined') {
      if (
        (filter === '' && !filteredAllTypesAggregations.length && allTypesAggregations.length) ||
        JSON.stringify(allTypesAggregations) !== JSON.stringify(prevAllTypesAggregations)
      ) {
        let newSelectedType;
        const filteredTypes = filterByExcerptType(filter);
        setFilteredAllTypesAggregations(filteredTypes);

        if (selectedType) {
          newSelectedType =
            filteredTypes.find((item) => item.key === selectedType.key) || allTypesAggregations[0];
        } else {
          if (allTypesAggregations.length === 0) {
            return;
          }
          // on tab change, if there is a globally selected type, set it as active, o/w set first type as active
          newSelectedType = globalSelectedFindingType || allTypesAggregations[0];
        }
        selectType(newSelectedType);
      } else if (selectedType) {
        selectType(selectedType);
      }
    }
    // eslint-disable-next-line
  }, [allTypesAggregations]);

  const selectContractType = (key) => {
    const contractType = contractTypes.find((contractConfig) => {
      return contractConfig.id === key;
    });
    if (contractType.id !== selectedContractType.id) {
      setSelectedType(null);
      selectOrigin(null);
      setFilteredAllTypesAggregations([]);
    }
    setSelectedContractType(contractType);
    dispatch(selectContract(contractType.id));
    dispatch(fetchContractAggregationsStatistics(projectId, contractType.id));
  };

  const selectType = (type) => {
    const excerptType = type.topHits[0].excerptType;
    dispatch(selectFindingType(type));
    setSelectedType(type);
    dispatch(fetchExcerptTypeOriginsStatistics(projectId, projectContractId, excerptType));
  };

  const selectOrigin = (origin) => {
    dispatch(selectOriginGlobally(origin));
    setSelectedOrigin(origin);
  };

  const selectFilter = (filter) => {
    let filteredGlobalConfigs = filterByExcerptType(filter);
    setFilter(filter);
    setFilteredAllTypesAggregations(filteredGlobalConfigs);
  };

  const filterByExcerptType = (filter) => {
    return allTypesAggregations.filter((type) => {
      return type.topHits[0].excerptType.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    });
  };

  const updateAdvancedLearningListWithCurrentVersion = () => {
    selectContractType(projectContractId);
    setIsModelVersionChanged(false);
  };

  return (
    <>
      <div
        className={'al-explore-header-container'}
        style={{ margin: '0 20px', paddingTop: '20px' }}
      >
        <div className="al-headline">
          Explore
          <Button
            disabled={exportLoading && exportType === EXPORT_TYPE.EXCEL}
            show={true}
            iconType={'excel'}
            title={'Download all excerpts documents'}
            clicked={() => dispatch(downloadAllExcerptsToExcel(projectContractId, projectId))}
          />
        </div>
        <div className="al-description-text">Search for findings within a project</div>
        {isModelVersionChanged && (
          <div className={'model-change-msg'}>
            <p>{`You received new updates, click on the refresh icon to get the latest changes.`}</p>
            <Button
              title={'Update excerpts list with the new version changes'}
              style={{ float: 'right' }}
              withIcon={true}
              iconType={'sync'}
              clicked={updateAdvancedLearningListWithCurrentVersion}
            />
          </div>
        )}
      </div>
      <div className="al-container al-inspect">
        <ExploreTypesList
          selectFilter={selectFilter}
          selectType={selectType}
          selectedType={selectedType}
          selectedContractType={selectedContractType}
          contractTypes={contractTypes}
          selectContract={(key) => selectContractType(key)}
          filteredAllTypesAggregations={filteredAllTypesAggregations}
        />
        {selectedType && filteredAllTypesAggregations.length > 0 && (
          <ExploreWorkspace
            projectId={projectId}
            contractId={projectContractId}
            isLoading={loading}
            error={error}
            excerptType={selectedType.topHits[0].excerptType}
            selectOrigin={selectOrigin}
            selectedOrigin={selectedOrigin}
            hasConvertToUserPermission={PermissionsContext.hasPermission(
              PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_CONVERT_TO_USER_ID,
            )}
            hasRecognizeCorrectTrainPermission={PermissionsContext.hasPermission(
              PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_RECOGNIZE_CORRECT_TRAIN_ID,
            )}
            hasRecognizeIncorrectTrainPermission={PermissionsContext.hasPermission(
              PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_RECOGNIZE_INCORRECT_TRAIN_ID,
            )}
            hasTrainingPermission={PermissionsContext.hasPermission(
              PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_CREATE_TRAIN,
            )}
          />
        )}
      </div>
    </>
  );
};

export default withPermissions(Explore);
