// @flow
import React from 'react';
import Icon from '../Icon/Icon';
import CustomTooltip from '../Tooltip/Tooltip';

type Props = {
  clicked: () => void,
  preventDefault: boolean,
  activeClassName: string,
  children?: React$Node,
  show?: boolean,
  withIcon?: boolean,
  iconType?: string,
  btnType?: string,
  className?: string,
  active?: boolean,
  id?: string,
  style?: any,
};

const Button = (props: Props) => {
  const { show, preventDefault } = props;
  if (show === false) {
    return null;
  }
  let icon = null;
  let classes = ['al-button'];

  classes.push(props.btnType);
  classes.push(props.className);

  if (props.active) {
    classes.push(props.activeClassName);
  }

  if (props.hide) {
    return null;
  }

  if (props.iconType) {
    icon = <Icon icon={props.iconType} />;
  }

  // Icon only button
  if (props.withIcon) {
    classes.push('al-icon-only');
    icon = <Icon icon={props.iconType} />;
  }

  // Modal close button
  if (props.btnType === 'al-close') {
    icon = <Icon icon={'close'} />;
  }

  // Search button
  if (props.btnType === 'al-search') {
    icon = <Icon icon={'search'} />;
  }
  const onClick = preventDefault
    ? (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.clicked(e);
      }
    : props.clicked;

  let btnLabel;
  if (props.children && props.children.length !== 0) btnLabel = <span>{props.children}</span>;

  if (props.title) {
    return (
      <CustomTooltip
        title={props.title}
        enterDelay={1000}
        leaveDelay={200}
        placement="top-end"
        classNames={{ root: 'al-tooltip' }}
        interactive={true}
      >
        <button
          style={props.style}
          id={props.id}
          disabled={props.disabled}
          className={classes.filter((el) => el && !!el.length).join(' ')}
          onClick={onClick}
          ref={props.setRef}
        >
          {btnLabel}
          {icon}
        </button>
      </CustomTooltip>
    );
  } else {
    return (
      <button
        style={props.style}
        id={props.id}
        disabled={props.disabled}
        className={classes.filter((el) => el && !!el.length).join(' ')}
        onClick={onClick}
        ref={props.setRef}
      >
        {btnLabel}
        {icon}
      </button>
    );
  }
};

Button.defaultProps = {
  preventDefault: false,
  activeClassName: 'al-active',
  clicked: () => {},
};

export default Button;
