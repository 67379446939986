import { Map } from 'immutable';
import { INPUT_NAME_REGEX } from '../constants/constants';

export default function validate(value, rules) {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
}

export const getValidationError = (name, inputType, isExistingName) => {
  let error = new Map();
  const hasValidName = INPUT_NAME_REGEX.test(name);
  if (isExistingName) {
    return error.set('message', `This ${inputType} already exists`);
  } else if (name.length > 0 && !hasValidName) {
    return error.set('message', `Please enter only letters, numbers, _ or -`);
  } else {
    return null;
  }
};

export const normalizeWhitespaces = (name) => {
  return name.replace(/\s+/g, ' ').trim();
};
