// @flow
import React from 'react';
import type { Project } from '../../types';
import Button from '../UI/Button/Button';

type Props = {
  project: Project,
  active: boolean,
  onEditProject: (project: Project) => void,
  onDeleteProject: (project: Project) => void,
};

const ProjectListItem = ({ project, active, onEditProject, onDeleteProject }: Props) => {
  return (
    <div className={'al-projectListItem' + (active ? ' active' : '')}>
      <div className={'al-projectListItem-header'}>
        <span className={'al-project-title'}>{project.projectType}</span>
        <span className={'al-projectListItem-actions'}>
          <Button
            withIcon={true}
            className={'editProject'}
            iconType={'edit'}
            clicked={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onEditProject({ project });
            }}
          />
          {onDeleteProject && (
            <Button
              withIcon={true}
              className={'deleteProject'}
              iconType={'delete'}
              clicked={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDeleteProject({ project });
              }}
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default ProjectListItem;
