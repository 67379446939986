import * as actionTypes from '../actions/actionType';
import type { SearchQueryState } from '../../types/reduxTypes';
import { DEFAULT_PAGE_NUMBER } from '../../constants/constants';

const initialState: SearchQueryState = {
  error: null,
  fetchingSearchQueries: false,
  fetchingSearchQueriesError: null,
  creatingSearchQueries: false,
  creatingSearchQueriesError: null,
  editingSearchQueries: false,
  editingSearchQueriesError: null,
  deletingSearchQueries: false,
  deletingSearchQueriesError: null,
  searchQueries: [],
  fetchingDocumentHighlights: false,
  fetchingDocumentHighlightsError: null,
  applyingQuery: false,
  applyingQueryError: null,
  createdExcerptsIds: [],
  docId: '',
  queryHighlights: [],
  searchQueryId: '',
  searchQueryName: '',
  selectedContractId: '',
  pageQueryHighlights: DEFAULT_PAGE_NUMBER,
};

const createSearchQueryStart = (state) => {
  return {
    ...state,
    creatingSearchQueriesError: null,
    creatingSearchQueries: true,
  };
};

const createSearchQuerySuccess = (state, action) => {
  let searchQueries = [...state.searchQueries];
  searchQueries.push(action.searchQuery);
  return {
    ...state,
    creatingSearchQueriesError: null,
    creatingSearchQueries: false,
    searchQueries,
  };
};

const createSearchQueryFail = (state, action) => {
  return {
    ...state,
    creatingSearchQueriesError: action.error,
    creatingSearchQueries: false,
  };
};

const editSearchQueryStart = (state) => {
  return {
    ...state,
    editingSearchQueriesError: null,
    editingSearchQueries: true,
  };
};

const editSearchQuerySuccess = (state, action) => {
  let searchQueries = [...state.searchQueries];
  searchQueries = searchQueries.map((el) =>
    el.id !== action.searchQuery.id ? el : action.searchQuery,
  );
  return {
    ...state,
    editingSearchQueriesError: null,
    editingSearchQueries: false,
    searchQueries,
  };
};

const editSearchQueryFail = (state, action) => {
  return {
    ...state,
    editingSearchQueriesError: action.error,
    editingSearchQueries: false,
  };
};

const deleteSearchQueryStart = (state) => {
  return {
    ...state,
    deletingSearchQueriesError: null,
    deletingSearchQueries: true,
  };
};

const deleteSearchQuerySuccess = (state, action) => {
  let searchQueries = [...state.searchQueries];
  const { searchQuery } = action;
  searchQueries = searchQueries.filter((sq) => sq.id !== searchQuery.id);
  return {
    ...state,
    deletingSearchQueries: false,
    searchQueries,
  };
};

const deleteSearchQueryFail = (state, action) => {
  return {
    ...state,
    deletingSearchQueriesError: action.error,
    deletingSearchQueries: false,
  };
};

const fetchSearchQueriesStart = (state) => {
  return {
    ...state,
    fetchingSearchQueriesError: null,
    fetchingSearchQueries: true,
  };
};

const fetchSearchQueriesSuccess = (state, action) => {
  return {
    ...state,
    fetchingSearchQueriesError: null,
    fetchingSearchQueries: false,
    searchQueries: action.searchQueries,
  };
};

const fetchSearchQueriesFail = (state, action) => {
  return {
    ...state,
    fetchingSearchQueriesError: action.error,
    fetchingSearchQueries: false,
  };
};

const fetchSearchQueryHighlightsStart = (state, action) => {
  return {
    ...state,
    searchQueryId: action.searchQueryId,
    searchQueryName: action.searchQueryName,
    fetchingDocumentHighlights: true,
  };
};

const fetchSearchQueryHighlightsSuccess = (state, action) => {
  return {
    ...state,
    searchQueryId: action.searchQueryId,
    searchQueryName: action.searchQueryName,
    fetchingDocumentHighlights: false,
    queryHighlights: action.queryHighlights,
  };
};

const fetchSearchQueryHighlightsFail = (state, action) => {
  return {
    ...state,
    searchQueryId: action.searchQueryId,
    fetchingDocumentHighlights: false,
    fetchingDocumentHighlightsError: action.error,
  };
};

const clearSearchQueryHighlights = (state, action) => {
  return {
    ...state,
    searchQueryId: action.searchQueryId,
    searchQueryName: action.searchQueryName,
    queryHighlights: action.queryHighlights,
  };
};

const applyQueryStart = (state, action) => {
  const { projectId, contractId } = action;
  return {
    ...state,
    projectId,
    contractId,
    applyingQuery: true,
    applyingQueryError: null,
  };
};

const applyQuerySuccess = (state, action) => {
  const { projectId, contractId, createdExcerptsIds } = action;
  console.log('created: ', createdExcerptsIds);
  return {
    ...state,
    projectId,
    contractId,
    createdExcerptsIds,
    applyingQuery: false,
    applyingQueryError: null,
  };
};

const applyQueryFail = (state, action) => {
  const { projectId, contractId, error } = action;
  return {
    ...state,
    projectId,
    contractId,
    applyingQuery: false,
    applyingQueryError: error,
  };
};

const setSelectedContractId = (state, action) => {
  const { selectedContractId } = action;
  return {
    ...state,
    selectedContractId,
  };
};

const selectPage = (state, action) => {
  return {
    ...state,
    pageQueryHighlights: action.page,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SEARCH_QUERY_START:
      return createSearchQueryStart(state, action);
    case actionTypes.CREATE_SEARCH_QUERY_SUCCESS:
      return createSearchQuerySuccess(state, action);
    case actionTypes.CREATE_SEARCH_QUERY_FAIL:
      return createSearchQueryFail(state, action);

    case actionTypes.EDIT_SEARCH_QUERY_START:
      return editSearchQueryStart(state, action);
    case actionTypes.EDIT_SEARCH_QUERY_SUCCESS:
      return editSearchQuerySuccess(state, action);
    case actionTypes.EDIT_SEARCH_QUERY_FAIL:
      return editSearchQueryFail(state, action);

    case actionTypes.DELETE_SEARCH_QUERY_START:
      return deleteSearchQueryStart(state, action);
    case actionTypes.DELETE_SEARCH_QUERY_SUCCESS:
      return deleteSearchQuerySuccess(state, action);
    case actionTypes.DELETE_SEARCH_QUERY_FAIL:
      return deleteSearchQueryFail(state, action);

    case actionTypes.FETCH_SEARCH_QUERY_START:
      return fetchSearchQueriesStart(state, action);
    case actionTypes.FETCH_SEARCH_QUERY_SUCCESS:
      return fetchSearchQueriesSuccess(state, action);
    case actionTypes.FETCH_SEARCH_QUERY_FAIL:
      return fetchSearchQueriesFail(state, action);

    case actionTypes.FETCH_SEARCH_QUERY_HIGHLIGHTS_START:
      return fetchSearchQueryHighlightsStart(state, action);
    case actionTypes.FETCH_SEARCH_QUERY_HIGHLIGHTS_SUCCESS:
      return fetchSearchQueryHighlightsSuccess(state, action);
    case actionTypes.FETCH_SEARCH_QUERY_HIGHLIGHTS_FAIL:
      return fetchSearchQueryHighlightsFail(state, action);
    case actionTypes.CLEAR_SEARCH_QUERY_HIGHLIGHTS:
      return clearSearchQueryHighlights(state, action);

    case actionTypes.APPLY_QUERY_START:
      return applyQueryStart(state, action);
    case actionTypes.APPLY_QUERY_SUCCESS:
      return applyQuerySuccess(state, action);
    case actionTypes.APPLY_QUERY_FAIL:
      return applyQueryFail(state, action);

    case actionTypes.SET_SELECTED_CONTRACTID:
      return setSelectedContractId(state, action);
    case actionTypes.SELECT_PAGE_QUERY_HIGHLIGHTS:
      return selectPage(state, action);

    default:
      return state;
  }
};

export default reducer;
