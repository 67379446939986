import React from 'react';
import type { ContractType } from '../../types';
import ContractTypeItem from './ContractType/ContractTypeItem';
import Button from '../UI/Button/Button';
import { sortBy } from '../../services/sorting';

type Props = {
  projectId: string,
  contractTypes: Array<ContractType>,
  onCreateContractType: () => void,
  onDeleteContractType: (contractType: ContractType) => void,
  showEditContractTypeModal: (contractType: ContractType) => void,
};

const ContractTypesList = ({
  projectId,
  contractTypes,
  onCreateContractType,
  onDeleteContractType,
  showEditContractTypeModal,
}: Props) => {
  return (
    <div className={'al-contractTypes'}>
      <div className="al-contractTypes-headline">
        <div className="al-project-info">
          <div className={'al-headline'}>Document types</div>
          <div className="al-description-text">Add, update or delete document types</div>
        </div>
        <Button className={'al-primary al-create-new-contract'} clicked={onCreateContractType}>
          {' '}
          ADD NEW Document TYPE{' '}
        </Button>
      </div>
      <div className={'al-contractTypes-list'}>
        {contractTypes.sort(sortBy('contractType')).map((contractType) => (
          <ContractTypeItem
            key={contractType.id}
            projectId={projectId}
            contractType={contractType}
            globalConfigs={contractType.globalConfig}
            showEditContractTypeModal={showEditContractTypeModal}
            onDeleteContractType={onDeleteContractType}
          />
        ))}
      </div>
    </div>
  );
};

export default ContractTypesList;
