import {Map} from 'immutable';
/**
 * helper to create Map from Excerpts to bundle excerpts by type
 * @param excerpts
 * @returns {T|Map<any, any>|Map<string, any>}
 */
import type { Excerpt, ExcerptData,ExcerptDiffResult,ExcerptDiffItem } from '../types';

export const buildExcerptDict = (excerpts: Array<Excerpt>):Map<string,Array<Excerpt>> =>
  excerpts.reduce((excerptMap: Map<string, Array<Excerpt>>, current: Excerpt) => {
    const { excerptType } = current;
    const excerpts = excerptMap.get(excerptType);
    if (excerpts) {
      return excerptMap.set(excerptType, excerpts.concat([current]));
    } else {
      return excerptMap.set(excerptType, [current]);
    }
  }, (Map(): Map<string, Array<Excerpt>>));

/**
 *  helper to create a universal diff item (visualization depends on the context)
 * @param type
 * @param source
 * @param target
 * @param sourceItems
 * @param targetItems
 * @returns {{sourceCount: number, name: *, difference, source: string, type: *, targetCount: number, target: string}}
 */
export const createExcerptDiffItem = (
  type,
  source: string,
  target: string,
  sourceItems: Array<any>,
  targetItems: Array<any>,
): ExcerptDiffItem => ({
  source,
  target,
  type,
  name: type,
  sourceCount: sourceItems.length,
  targetCount: targetItems.length,
  difference: targetItems.length - sourceItems.length,
});

/**
 * building an Excerpt Diff //TODO move to backend
 * @returns {ExcerptDiffResult}
 */
export function buildExcerptDiffResult(originalData: ExcerptData, compareData: ExcerptData): ExcerptDiffResult {
  const originalId = originalData.id;
  const compareId = compareData.id;
  let excerptDiff: ExcerptDiffResult = {
    original: {
      missing: [],
      // only: [],
    },
    compared: {
      missing: [],
      // only: [],
    },
    match: [],
    diff: [],
  };
  const originalDict = buildExcerptDict(originalData.excerpts);
  const comparedDict = buildExcerptDict(compareData.excerpts);
  originalDict.entrySeq().forEach(([excerptType, excerpts]) => {
    if (!comparedDict.has(excerptType)) {
      const missing: ExcerptDiffItem = createExcerptDiffItem(
        excerptType,
        compareId,
        originalId,
        [],
        excerpts,
      );
      excerptDiff.compared.missing.push(missing);
    } else {
      // $FlowFixMe (comparedDict has excerpt type)
      const rightItems = comparedDict.get(excerptType);
      if (rightItems) {
        const diffItem: ExcerptDiffItem = createExcerptDiffItem(
          excerptType,
          originalId,
          compareId,
          excerpts,
          rightItems,
        );
        if(rightItems.length!==excerpts.length) {
          excerptDiff.diff.push(diffItem);
        }
        else{
          excerptDiff.match.push(diffItem);
        }
      }
    }
  });

  // find the missing types in the leftDict using the rightDict (occuring Entries in both dicts are already handled)
  comparedDict.entrySeq().forEach(([excerptType, excerpts]) => {
    if (!originalDict.has(excerptType)) {
      const missing: ExcerptDiffItem = createExcerptDiffItem(
        excerptType,
        compareId,
        originalId,
        [],
        excerpts,
      );
      excerptDiff.original.missing.push(missing);
    }
  });

  return excerptDiff;
}