// @flow
import type { ContractType, ContractTypeSetting } from '../types';
import cloneDeep from 'lodash/cloneDeep';

export const createContractType = ({
  name,
  copyFrom,
  shouldCopyTraining,
}: {
  name: string,
  copyFrom: ContractType | null,
  shouldCopyTraining: boolean,
}): ContractType => {
  if (shouldCopyTraining) {
    throw Error('copying training is not implemented');
  }
  if (copyFrom) {
    return {
      id: null,
      contractType: name,
      globalConfig: initGlobalConfigWithExisting(copyFrom.globalConfig),
    };
  }
  return {
    id: null,
    contractType: name,
    globalConfig: [],
  };
};
// {"id":"-1709994074","excerptType":"Feste
// Vergütungen","enabled":true,"description":"","origin":"alTagger","excerptActions":[]},{"id":"-1650657020","excerptType":"Exklusivitätsrechte","enabled":true,"description":"","origin":"alTagger","excerptActions":[]},{"id":"-1649640005","excerptType":"contract_title","enabled":true,"description":"","origin":"alTagger","excerptActions":[]},{"id":"-1454086675","excerptType":"Contract_time_duration","enabled":true,"description":"","origin":"alTagger","excerptActions":[]},{"id":"-1179038717","excerptType":"FUZZY_TYPE_OF_WORK","enabled":true,"description":"","origin":"user","excerptActions":[]},{"id":"11849797","excerptType":"kündigung","enabled":true,"description":"","origin":"aiTrainer","excerptActions":[]},{"id":"190126921","excerptType":"Contract_time_start","enabled":true,"description":"","origin":"alTagger","excerptActions":[]},{"id":"222720343","excerptType":"provision","enabled":true,"description":"","origin":"user","excerptActions":[]},{"id":"335941795","excerptType":"kündigung","enabled":true,"description":"","origin":"user","excerptActions":[]}]}],"deleted":false},{"id":"60fbef46-a283-4723-9e4a-ac14ec36c31c","projectType":"whatever","contractConfig":[{"id":"c3370cb7-5b4a-42fe-956b-f28745315ade","contractType":"Others","globalConfig":[]}
const initGlobalConfigWithExisting = (globalConfig: Array<ContractTypeSetting>) =>
  globalConfig.map(setting => ({ ...cloneDeep(setting), id: null }));
