import { CHANGE_LANGUAGE } from '../actions/actionType';

import { ENGLISH_TRANSLATION } from '../../translations/en';
import { GERMAN_TRANSLATION } from '../../translations/de';

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';

const browserLang = 'en'; //(window.navigator.language || window.navigator.userLanguage).split('-')[0];
const initialState = {
  lang: browserLang,
  messages: browserLang === 'de' ? GERMAN_TRANSLATION.messages : ENGLISH_TRANSLATION.messages,
};

export default function translation(state = initialState, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      switch (action.lang) {
        case 'en':
          moment.locale('en-GB');
          return {
            ...initialState,
            lang: ENGLISH_TRANSLATION.lang,
            messages: ENGLISH_TRANSLATION.messages,
          };
        case 'de':
          moment.locale('de');
          return {
            ...initialState,
            lang: GERMAN_TRANSLATION.lang,
            messages: GERMAN_TRANSLATION.messages,
          };
        default:
          moment.locale('en-GB');
          return {
            ...initialState,
            lang: ENGLISH_TRANSLATION.lang,
            messages: ENGLISH_TRANSLATION.messages,
          };
      }
    default:
      return state;
  }
}
