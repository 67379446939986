// @flow
import React from 'react';
import Button from '../Button/Button';
import { nop } from '../../../services/dummy';
type Props={
  onClick:(any)=>void,
  checked:boolean,
  children:React$Node,
  preventDefault:boolean,
  className:string
}

const flexStyle = {
  display: 'flex',
  alignItems: 'center',
};

function Checkbox({ onClick, checked, children,preventDefault, className}:Props) {
  return <div style={flexStyle} className={className}>
    <Button
      preventDefault={preventDefault}
      clicked={onClick}
      withIcon={true}
      iconType={checked ? 'checkbox-checked' : 'checkbox-unchecked'}
    />
    {children && <label onClick={onClick} style={{paddingLeft: '5px'}} htmlFor="">{children}</label>}
  </div>;
}
Checkbox.defaultProps={
  onClick:nop,
  preventDefault:true,
}

export default Checkbox;
