import * as actionTypes from '../actions/actionType';
import { Map } from 'immutable';

const initialState = {
  dynamicThresholds: new Map(),
  docId: null,
  fetchingDynamicThreshold: false,
  fetchingDynamicThresholdError: null,
};

const fetchDynamicThresholdsStart = (state, actions) => {
  const { docId } = actions;
  return {
    ...state,
    dynamicThresholds: new Map(),
    docId,
    fetchingDynamicThreshold: true,
    fetchingDynamicThresholdError: null,
  };
};
const fetchDynamicThresholdsSuccess = (state, actions) => {
  const { docId, data } = actions;
  return {
    ...state,
    dynamicThresholds: new Map(Object.entries(data).sort((a, b) => (a[1] < b[1] ? 1 : -1))),
    docId,
    fetchingDynamicThreshold: false,
    fetchingDynamicThresholdError: null,
  };
};

const fetchDynamicThresholdsFail = (state, actions) => {
  const { docId, err } = actions;
  return {
    ...state,
    dynamicThresholds: new Map(),
    docId,
    fetchingDynamicThreshold: false,
    fetchingDynamicThresholdError: err,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_DYNAMIC_THRESHOLD_START:
      return fetchDynamicThresholdsStart(state, action);
    case actionTypes.FETCH_DOCUMENT_DYNAMIC_THRESHOLD_SUCCESS:
      return fetchDynamicThresholdsSuccess(state, action);
    case actionTypes.FETCH_DOCUMENT_DYNAMIC_THRESHOLD_FAIL:
      return fetchDynamicThresholdsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
