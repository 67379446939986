import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import RightLeftController from '../../components/UI/RightLeftCollector/RightLeftCollector';
import type { Project, Role, User } from '../../types';
import { createUser, updateUser, fetchRoles } from '../../store/actions';
import Spinner from '../../components/UI/Spinner/Spinner';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import Warning from '../../components/UI/Typo/Warning';
import { getValidationError, normalizeWhitespaces } from '../../services/validation';
import { INPUT_TYPE } from '../../constants/constants';

type Props = {
  user: User,
  roles: Array<Role>,
  projects: Array<Project>,
  onClose: () => void,
  createUser: (user: User) => void,
  updateUser: (user: User) => void,
  fetchRoles: () => void,
  fetchingRoles: boolean,
  users: Array<User>,
};

const CreateUserModal = ({ user, onClose, users }: Props) => {
  const userProjectIds = user.projectIds || [];
  const [selectedRoles, setSelectedRoles] = useState(user.roles);
  const [selectedProjects, setSelectedProjects] = useState(userProjectIds);
  const [userName, setUserName] = useState(user.username);
  const [password, setPassword] = useState(user.password);
  const [allProjectAccess, selectAllProjectsAccess] = useState(user.allProjectsAccess);

  const roles = useSelector((state) => state.roles.roles);
  const fetchingRoles = useSelector((state) => state.roles.fetchingRoles);
  const projects = useSelector((state) => [...state.project.projects.values()]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const onMoveRoles = (leftItems: Array<string>, rightItems: Array<string>) => {
    const selectedRoles: Array<Role> = roles.filter((role) => leftItems.indexOf(role.name) > -1);
    setSelectedRoles(selectedRoles.map((role) => role.id));
  };

  const onMoveProjects = (leftItems: Array<string>, rightItems: Array<string>) => {
    const selectedProjects: Array<Project> = projects.filter(
      (project) => leftItems.indexOf(project.projectType) > -1,
    );
    setSelectedProjects(selectedProjects.map((project) => project.id));
  };

  const getLeftRoles = () => {
    const leftRoles = roles.filter((role) => user.roles.indexOf(role.id) > -1);
    return leftRoles.map((role) => role.name);
  };

  const getRightRoles = () => {
    const rightRoles = roles.filter((role) => user.roles.indexOf(role.id) === -1);
    return rightRoles.map((role) => role.name);
  };

  const getLeftProjects = () => {
    const userProjects = user.projectIds || [];
    const leftProjects = projects.filter((project) => userProjects.indexOf(project.id) > -1);
    return leftProjects.map((project) => project.projectType);
  };

  const getRightProjects = () => {
    const userProjects = user.projectIds || [];
    const rightProjects = projects.filter((project) => userProjects.indexOf(project.id) === -1);
    return rightProjects.map((project) => project.projectType);
  };

  const save = () => {
    let updatedUser: User = { ...user };
    updatedUser.username = normalizeWhitespaces(userName);
    updatedUser.password = password;
    updatedUser.roles = selectedRoles;
    updatedUser.allProjectsAccess = allProjectAccess;
    updatedUser.projectIds = selectedProjects;
    if (!updatedUser.id) {
      dispatch(createUser(updatedUser));
    } else {
      dispatch(updateUser(updatedUser));
    }
    onClose();
  };

  const normalizedName = normalizeWhitespaces(userName);
  const validationError = getValidationError(
    normalizedName,
    INPUT_TYPE.USER,
    users.filter((u) => {
      return (
        u.username.trim().toLowerCase() === normalizedName.trim().toLowerCase() && u.id !== user.id
      );
    }).length > 0,
  );

  const getDisabledStatusCreateNewUser = () => {
    const noRolesSelected = selectedRoles.length === 0;
    const noProjectsSelected = selectedProjects.length === 0;
    if (!normalizedName.length || validationError) {
      return true;
    } else if (!noRolesSelected && allProjectAccess) {
      return false;
    } else return !(!noRolesSelected && !noProjectsSelected);
  };

  return (
    <Modal show={true}>
      <ModalHeader onClose={onClose} title={!user.id ? 'Create a new user' : 'Edit user'} />
      <ModalContent>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: '1 1 auto', marginRight: '25px' }}>
            <Input
              elementType={'input'}
              elementConfig={{ type: 'text' }}
              placeholder={'Username'}
              value={userName}
              disabled={!!user.id}
              changed={(e) => setUserName(e.target.value)}
            />
            {!!validationError && <Warning>{validationError.get('message')}</Warning>}
          </div>
          <div style={{ flex: '1 1 auto' }}>
            <Input
              elementType={'input'}
              elementConfig={{ type: 'password' }}
              placeholder={'Password'}
              value={password}
              disabled={!!user.id}
              changed={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <label></label>
        <div className={'al-right-left-ui'} style={{ marginTop: '5px' }}>
          {fetchingRoles ? (
            <Spinner />
          ) : (
            <RightLeftController
              onMove={onMoveRoles}
              leftTitle={'Assigned roles:'}
              leftItemsProps={getLeftRoles()}
              rightTitle={'All roles:'}
              rightItemsProps={getRightRoles()}
            />
          )}
        </div>

        <label></label>
        <div style={{ margin: '10px 0px 5px -4px' }}>
          <Checkbox
            checked={allProjectAccess}
            onClick={() => {
              selectAllProjectsAccess(!allProjectAccess);
            }}
          >
            {'Select all projects'}
          </Checkbox>
        </div>
        {!allProjectAccess ? (
          <div
            className={'al-right-left-ui'}
            style={{
              'grid-column': '1 / span 5',
              height: '250px',
              'grid-row': '7',
              marginBottom: '20px',
            }}
          >
            {fetchingRoles ? (
              <Spinner />
            ) : (
              <RightLeftController
                onMove={onMoveProjects}
                leftTitle={'Assigned projects:'}
                leftItemsProps={getLeftProjects()}
                rightTitle={'All projects:'}
                rightItemsProps={getRightProjects()}
              />
            )}
          </div>
        ) : null}
      </ModalContent>
      <ModalFooter>
        <Button className={'al-primary'} clicked={save} disabled={getDisabledStatusCreateNewUser()}>
          Save
        </Button>
        <Button clicked={onClose}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateUserModal;
