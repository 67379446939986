import React from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
  const apiVersion = useSelector((state) => state.version.apiVersion);
  const isAuthenticated = localStorage.getItem('token') !== null;

  return isAuthenticated ? (
    <div className={'al-app-footer'}>
      <div className={'al-footer-logo'}>
        <div className={'footer-logo'} id={'footer-logo'} />
      </div>
      <div className={'al-footer-version'}>Version: {apiVersion}</div>
    </div>
  ) : null;
};

export default Footer;
