// @flow

import React, { Component } from 'react';
import Button from '../../components/UI/Button/Button';
// import { axiosInstance as axios } from '../../services/axiosInstance';
import config from '../../config';
import Spinner from '../../components/UI/Spinner/Spinner';

const url = config.apiGateway.URL;
// {"results": [{"request": {"reference": "If you believe the Card has been lost or stolen, you will immediately call
// us at 1-800-325-3678.", "candidate": "you will immediately call us at 1-800-325-3678."}, "is":// true}]}

type ParaphraseTestResult = {
  request: {
    reference: string,
    candidate_paraphrase: string,
  },
  is_paraphrase: boolean,
};
type Props = {};

type State = {
  reference: string,
  candidate: string,
  loading: boolean,
  result: ParaphraseTestResult | null,
};

class ParaphraseDetect extends Component<Props, State> {
  state = {
    reference: '',
    candidate: '',
    result: null,
    loading: false,
  };

  fetchParaphraseDiff = async (reference: string, candidate: string) => {
    try {
      console.info(`detect Paraphrase ${reference}:${candidate}`);
      this.setState({
        loading: true,
      });

      const response = await fetch(`${url}/paraphrase-detector/detect`, {
        method: 'POST',
        body: JSON.stringify([
          {
            reference: sanitizeText(reference),
            candidate_paraphrase: sanitizeText(candidate),
          },
        ]),
      });
      if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
      }
      const { results } = await response.json();

      this.setState({ result: results[0], loading: false });
    } catch (error) {
      console.log(JSON.stringify(error));
      //TODO remove error mock after conference
      this.setState({
        loading: false,
        result: {
          request: {
            reference: sanitizeText(reference),
            candidate_paraphrase: sanitizeText(candidate),
          },
          is_paraphrase: false,
        },
      });
      //TODO add  error handling after conference
      // this.setState({
      //   loading: false,
      //   error,
      //   result: null,
      // });
    }
  };

  canDetect = () => {
    const { reference, candidate } = this.state;
    return (
      reference.length &&
      candidate.length &&
      !isTestInput(reference, candidate, this.state.testResult)
    );
  };

  detectParaPhrase = () => {
    const { reference, candidate } = this.state;

    this.setState({
      result: null,
      error: null,
    });
    this.fetchParaphraseDiff(reference, candidate).then(() => {
    });
  };
  setReference = (reference: string) => {
    this.setState({
      reference,
    });
  };
  isCurrentTest = () => isTestInput(this.state.reference, this.state.candidate, this.state.result);
  setCandidate = (candidate: string) => {
    this.setState({
      candidate,
    });
  };

  render() {
    const { loading, result, reference, candidate, error } = this.state;
    const isCurrentTest = this.isCurrentTest();

    return (
      <div className={'al-container'}>
        <div className={'al-paraphrase'}>
          <h1>Paraphrasing test</h1>

          <div style={{ display: 'flex', width: '100%', marginBottom: '40px' }}>
            <div style={{ width: '50%' }}>
              <span>Reference text</span>
              <textarea
                value={reference}
                disabled={loading}
                onChange={e => !loading && this.setReference(e.target.value)}
                placeholder={'Enter a reference text'}
              />
            </div>
            <div style={{ width: '50%' }}>
              <span>Compared text</span>
              <textarea
                disabled={loading}
                value={candidate}
                onChange={e => !loading && this.setCandidate(e.target.value)}
                placeholder={'Enter a text that should be tested for paraphrasing'}
              />
            </div>
          </div>
          {!loading && (
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
              <Button
                className={this.canDetect() ? 'al-primary' : null}
                disabled={!this.canDetect()}
                clicked={this.detectParaPhrase}
              >
                Check
              </Button>
              {isCurrentTest && (
                <div
                  className={
                    'paraphraseResult ' +
                    (result.is_paraphrase
                      ? 'paraphraseResult--success'
                      : 'paraphraseResult--failure')
                  }
                >
                  {result.is_paraphrase ? (
                    <span>The compared text is a paraphrase of the original</span>
                  ) : (
                    <span>No paraphrase</span>
                  )}
                </div>
              )}
            </div>
          )}
          {error && (
            <div className={'paraphraseResult paraphraseResult--failure'}>Error:&nbsp;{error.message}</div>
          )}
          {loading && <Spinner/>}
        </div>
      </div>
    );
  }
}

const replacementRegex = new RegExp('\\n', 'g');
const sanitizeText = (text: string) => text.trim().replace(replacementRegex, ' ');

const isTestInput = (
  reference: string,
  candidate: string,
  testResult: ParaphraseTestResult | null,
) =>
  !!testResult &&
  sanitizeText(reference) === testResult.request.reference &&
  sanitizeText(candidate) === testResult.request.candidate_paraphrase;

export default ParaphraseDetect;
