import statisticsAll from './statisticsAll';
import statisticsByType from './statisticsByExcerptType';

const initialState = {
  statisticsAll: {
    data: [],
    loading: false,
    error: false,
    success: false,
    fetchingContractStatistics: {
      error: null,
      data: [],
      loading: false,
      success: false,
      origins: [],
    },
  },
  statisticsByType: {
    excerpts: {},
    loading: false,
    error: false,
    success: false,
    docsExcerptsCounts: [],
    isRetrievingExcerpts: false,
    loadingExcerptsDocId: null,
  },
};

const reducer = (state = initialState, action) => {
  return {
    statisticsAll: statisticsAll(state.statisticsAll, action),
    statisticsByType: statisticsByType(state.statisticsByType, action),
  };
};

export default reducer;
