import React from 'react';
import Warning from '../../UI/Typo/Warning';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';

type Props = {
  closeModal: () => void,
  filesToReprocessCount: number,
  reProcessDocument: () => Array<string>,
};

const ReprocessFilesModal = ({ closeModal, filesToReprocessCount, reProcessDocument }: Props) => {
  return (
    <>
      <Modal show={true}>
        <ModalHeader title={`Warning`} onClose={closeModal} />
        <ModalContent>
          <Warning>
            Processing the document
            {filesToReprocessCount > 1 ? 's' : ''} again will delete all user annotations and
            advanced learning findings.
            <br />
            Do you really want to process the document
            {filesToReprocessCount > 1 ? 's' : ''} again?
          </Warning>
        </ModalContent>
        <ModalFooter>
          <Button
            clicked={() => {
              reProcessDocument();
              closeModal();
            }}
            className={'al-primary'}
          >
            Reprocess
          </Button>
          <Button clicked={closeModal} preventDefault={true}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReprocessFilesModal;
