import * as actionTypes from './actionType';

export const setViewSelectedIssue = (selectedIssueToView) => {
  return {
    type: actionTypes.SET_VIEW_SELECTED_ISSUE,
    selectedIssueToView,
  };
};
export const deleteViewSelectedIssue = () => {
  return {
    type: actionTypes.DELETE_VIEW_SELECTED_ISSUE
  };
};