import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import type { AppState } from '../../types/reduxTypes';
import type { Project } from '../../types';
import * as actions from '../../store/actions';
import Select from '../UI/Select/Select';
import project from '../../store/reducers/project';

type Props = {
  projectId: string,
  projects: Map<string, Project>,
  assignedProjectsIds: Array<string>,
  accessAllProjects: boolean,
  selectProject: (projectId: string) => void,
};

const ProjectSelect = ({
  projects,
  projectId,
  selectProject,
  fetchProjects,
  accessAllProjects,
  assignedProjectsIds,
}: Props) => {
  useEffect(() => {
    //fetchProjects();
  }, []);
  const selectedProject = projects.get(projectId);
  projects = projects.valueSeq().toArray();
  if (!accessAllProjects && projects.length) {
    projects = projects.filter(p => assignedProjectsIds.indexOf(p.id) !== -1);
    if (assignedProjectsIds.indexOf(projectId) === -1) {
      selectProject(projects[0].id);
    }
  }
  const projectOptions = projects.map(project => ({
    key: project.id,
    value: project.projectType,
    active: false,
  }));
  const onSelectProject = (selectedProjectId: string) => {
    if (selectedProjectId !== projectId) {
      selectProject(selectedProjectId);
    }
  };
  if (projectOptions.length) {
    return (
      <Select
        options={projectOptions}
        size="large"
        label={selectedProject ? selectedProject.projectType : 'SELECT PROJECT'}
        onSelect={project => {
          onSelectProject(project.key);
        }}
      />
    );
  } else {
    return <div>NO PROJECT</div>;
  }
};

const mapStateToProps = (state: AppState) => ({
  projectId: state.project.projectId,
  projects: state.project.projects,
  assignedProjectsIds: state.auth.assignedProjectsIds,
  accessAllProjects: state.auth.accessAllProjects,
});

const mapDispatchToProps = dispatch => {
  return {
    selectProject: (projectId: string) => dispatch(actions.selectProject(projectId)),
    fetchProjects: () => dispatch(actions.fetchProjects()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelect);
