//flow
import React, { useEffect, useRef, useState } from 'react';
import type { SelectOption } from '../../../types';

type Props = {
  id?: string,
  show?: boolean,
  onSelect: Function,
  options: Array<SelectOption>,
  label: string,
  size?: 'large' | null,
  dropDownStyle?: Object,
};

function Select({
  id,
  show,
  onSelect,
  options,
  label,
  size,
  children,
  dropDownStyle,
  clickedOutside,
  resetClickedOutside,
}: Props) {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSelect = () => {
    setOpen(!isOpen);
  };
  if (!options || show === false) {
    return null;
  }
  let classes = ['al-select'];
  if (size === 'large') {
    classes.push('al-large');
  }

  if (isOpen) {
    classes.push('al-open');
  }
  const ul = options.length ? (
    <ul>
      {options.map((item, key) => {
        let classNames = [];
        if (item.active) {
          classNames.push('al-active');
        }
        if (item.className) {
          classNames.push(item.className);
        }
        const className = classNames.join(' ');
        return (
          <li
            style={item.style || {}}
            key={item.key || key}
            className={className}
            onClick={(e) => onSelect(item, e)}
          >
            <span>{item.value}</span>
            <i className="al-icons al-icon-check" />
          </li>
        );
      })}
    </ul>
  ) : null;

  return (
    <div ref={ref} className={classes.join(' ')} onClick={toggleSelect} id={id}>
      <div className="al-select-open-close-btn">
        <div className="al-select-label">{label}</div>
        <div className="al-select-open-close-selected">
          <i className="al-icons al-icon-select-open" />
          <i className="al-icons al-icon-select-close" />
          <i className="al-icons al-icon-check" />
        </div>
      </div>
      <div style={dropDownStyle || {}} className="al-select-items">
        {ul}
        {children}
      </div>
    </div>
  );
}

export default Select;
