// @flow
import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';
import type { PermissionSet } from '../../types';
import { Dispatch } from 'redux';

const createRPermissionSetStart = () => ({
  type: actionTypes.CREATE_PERMISSION_SET_START,
});

const createPermissionSetSuccess = (permissionSet: PermissionSet) => ({
  type: actionTypes.CREATE_PERMISSION_SET_SUCCESS,
  permissionSet,
});

const createPermissionSetFail = (error: any) => {
  return {
    type: actionTypes.CREATE_PERMISSION_SET_FAIL,
    error,
  };
};

export const createPermissionSet = (data: PermissionSet) => async (dispatch: Dispatch) => {
  dispatch(createRPermissionSetStart());
  try {
    const response = await axios.post('api/permissionset/', [data]);
    dispatch(createPermissionSetSuccess(response.data));
  } catch (err) {
    dispatch(createPermissionSetFail(err));
  }
};

const updatePermissionSetStart = () => ({
  type: actionTypes.UPDATE_PERMISSION_SET_START,
});

const updatePermissionSetSuccess = (permissionSet: PermissionSet) => ({
  type: actionTypes.UPDATE_PERMISSION_SET_SUCCESS,
  permissionSet,
});

const updatePermissionSetFail = (error: any) => ({
  type: actionTypes.UPDATE_PERMISSION_SET_FAIL,
  error,
});

export const updatePermissionSet = (data: PermissionSet) => async (dispatch: Dispatch) => {
  dispatch(updatePermissionSetStart());
  try {
    const response = await axios.put('/api/permissionset/', data);
    dispatch(updatePermissionSetSuccess(response.data));
  } catch (err) {
    dispatch(updatePermissionSetFail(err));
  }
};

const deletePermissionSetStart = (permissionSetId: string) => ({
  type: actionTypes.DELETE_PERMISSION_SET_START,
  permissionSetId,
});

const deletePermissionSetSuccess = (permissionSetId: string) => ({
  type: actionTypes.DELETE_PERMISSION_SET_SUCCESS,
  permissionSetId,
});

const deletePermissionSetFail = (permissionSetId: string, error: any) => ({
  type: actionTypes.DELETE_PERMISSION_SET_FAIL,
  error,
  permissionSetId,
});

export const deletePermissionSet = (permissionSet: PermissionSet) => async (dispatch: Dispatch) => {
  dispatch(deletePermissionSetStart(permissionSet.id));
  try {
    await axios.delete('/api/permissionset/' + permissionSet.id);
    dispatch(deletePermissionSetSuccess(permissionSet.id));
  } catch (error) {
    dispatch(deletePermissionSetFail(permissionSet.id, error));
  }
};

const fetchPermissionSetsStart = () => ({
  type: actionTypes.FETCH_PERMISSION_SETS_START,
});
const fetchPermissionSetsSuccess = (permissionSets: Array<PermissionSet>) => {
  return { type: actionTypes.FETCH_PERMISSION_SETS_SUCCESS, permissionSets };
};

const fetchPermissionSetsFail = (error: any) => ({
  type: actionTypes.FETCH_PERMISSION_SETS_FAIL,
  error,
});

export const fetchPermissionSets = () => async (dispatch: Dispatch) => {
  dispatch(fetchPermissionSetsStart());
  try {
    const response = await axios.get('/api/permissionset/');
    dispatch(fetchPermissionSetsSuccess(response.data));
  } catch (err) {
    dispatch(fetchPermissionSetsFail(err));
  }
};
