import * as actionTypes from '../actions/actionType';
import type { PermissionSetState } from '../../types/reduxTypes';
import { sortBy } from '../../services/sorting';

const initialState: PermissionSetState = {
  creatingPermissionSet: false,
  creatingPermissionSetError: null,
  updatingPermissionSet: false,
  updatingPermissionSetError: null,
  deletingPermissionSet: false,
  deletingPermissionSetError: null,
  fetchingPermissionSets: false,
  fetchingPermissionSetsError: null,
  permissionSets: [],
};

const createPermissionSetStart = (state, action) => {
  return {
    ...state,
    creatingPermissionSet: true,
    creatingPermissionSetError: null,
  };
};

const createPermissionSetSuccess = (state, action) => {
  let updatedPermissionSets = [...state.permissionSets];
  updatedPermissionSets = updatedPermissionSets.concat(action.permissionSet);
  return {
    ...state,
    creatingPermissionSet: false,
    creatingPermissionSetError: null,
    permissionSets: updatedPermissionSets.sort(sortBy('name')),
  };
};

const createPermissionSetFail = (state, action) => {
  const { error } = action;
  return {
    ...state,
    creatingPermissionSet: false,
    creatingPermissionSetError: error,
  };
};

const updatePermissionSetStart = (state, action) => {
  return {
    ...state,
    updatingPermissionSet: true,
    updatingPermissionSetError: null,
  };
};

const updatePermissionSetSuccess = (state, action) => {
  const permissionSets = state.permissionSets.map((ps) =>
    ps.id === action.permissionSet.id ? action.permissionSet : ps,
  );
  return {
    ...state,
    updatingPermissionSet: false,
    updatingPermissionSetError: null,
    permissionSets,
  };
};

const updatePermissionSetFail = (state, action) => {
  const { error } = action;
  return {
    ...state,
    updatingPermissionSet: false,
    updatingPermissionSetError: error,
  };
};

const deletePermissionSetStart = (state, action) => {
  return {
    ...state,
    updatingPermissionSet: true,
    updatingPermissionSetError: null,
  };
};

const deletePermissionSetSuccess = (state, action) => {
  const permissionSets = state.permissionSets.filter((ps) => ps.id !== action.permissionSetId);
  return {
    ...state,
    updatingPermissionSet: false,
    updatingPermissionSetError: null,
    permissionSets,
  };
};

const deletePermissionSetFail = (state, action) => {
  const { error } = action;
  return {
    ...state,
    updatingPermissionSet: false,
    updatingPermissionSetError: error,
  };
};

const fetchPermissionSetsStart = (state, action) => {
  return {
    ...state,
    fetchingPermissionSets: true,
    fetchingPermissionSetsError: null,
  };
};

const fetchPermissionSetsSuccess = (state, action) => {
  const { permissionSets } = action;
  return {
    ...state,
    fetchingPermissionSets: false,
    fetchingPermissionSetsError: null,
    permissionSets,
  };
};

const fetchPermissionSetsFail = (state, action) => {
  const { error } = action;
  return {
    ...state,
    fetchingPermissionSets: false,
    fetchingPermissionSetsError: error,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PERMISSION_SET_START:
      return createPermissionSetStart(state, action);
    case actionTypes.CREATE_PERMISSION_SET_SUCCESS:
      return createPermissionSetSuccess(state, action);
    case actionTypes.CREATE_PERMISSION_SET_FAIL:
      return createPermissionSetFail(state, action);

    case actionTypes.FETCH_PERMISSION_SETS_START:
      return fetchPermissionSetsStart(state, action);
    case actionTypes.FETCH_PERMISSION_SETS_SUCCESS:
      return fetchPermissionSetsSuccess(state, action);
    case actionTypes.FETCH_PERMISSION_SETS_FAIL:
      return fetchPermissionSetsFail(state, action);

    case actionTypes.UPDATE_PERMISSION_SET_START:
      return updatePermissionSetStart(state, action);
    case actionTypes.UPDATE_PERMISSION_SET_SUCCESS:
      return updatePermissionSetSuccess(state, action);
    case actionTypes.UPDATE_PERMISSION_SET_FAIL:
      return updatePermissionSetFail(state, action);

    case actionTypes.DELETE_PERMISSION_SET_START:
      return deletePermissionSetStart(state, action);
    case actionTypes.DELETE_PERMISSION_SET_SUCCESS:
      return deletePermissionSetSuccess(state, action);
    case actionTypes.DELETE_PERMISSION_SET_FAIL:
      return deletePermissionSetFail(state, action);

    default:
      return state;
  }
};

export default reducer;
