import React from 'react';
import type { AllTypesAggregation, ContractType } from '../../types';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import Select from '../../components/UI/Select/Select';
import Icon from '../UI/Icon/Icon';
import { ORIGIN } from '../../constants/constants';

type Props = {
  selectFilter: (filter: string) => void,
  selectType: (type: AllTypesAggregation) => void,
  selectedType: AllTypesAggregation,
  selectedContractType: ContractType,
  contractTypes: Array<ContractType>,
  selectContract: (contractId: string) => void,
  filteredAllTypesAggregations: Array<AllTypesAggregation>,
};

const ExploreTypesList = ({
  selectFilter,
  selectType,
  selectedType,
  selectedContractType,
  contractTypes,
  selectContract,
  filteredAllTypesAggregations,
}: Props) => {
  return (
    <div className="al-inspect-types">
      <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '1rem' }}>
        <span className={'al-headline'}>Document type: </span>
        <Select
          options={contractTypes.map((contractType) => {
            return {
              key: contractType.id,
              value:
                contractType.contractType.trim().toLowerCase() === 'others'
                  ? 'UNASSIGNED'.toUpperCase()
                  : contractType.contractType.toUpperCase(),
            };
          })}
          label={
            selectedContractType.contractType.trim().toLowerCase() === 'others'
              ? 'UNASSIGNED'.toLowerCase()
              : selectedContractType.contractType.toLowerCase()
          }
          onSelect={(option) => selectContract(option.key)}
        />
      </div>

      <div className="al-search-filter">
        <Input
          key={'input'}
          placeholder={'app.document.searchForType'}
          className={'al-input-text'}
          elementType={'input'}
          changed={(event) => selectFilter(event.target.value)}
          elementConfig={{ type: 'text' }}
          keyDown={(e) => e.stopPropagation()}
        />
        <Button withIcon={true} iconType={'search'} />
      </div>
      <div className="al-inspect-types-wrapper">
        {filteredAllTypesAggregations.map((type, index) => {
          const isAdvancedLearningFound =
            selectedContractType.globalConfig.filter(
              (gc) =>
                gc.excerptType.trim().toLowerCase() ===
                  type.topHits[0].excerptType.trim().toLowerCase() && gc.origin === ORIGIN.ADVANCED,
            ).length > 0;
          return (
            <div
              key={index}
              onClick={() => selectType(type)}
              className={type.key === selectedType.key ? 'al-active' : ''}
            >
              <Icon
                title="Advanced learning results found"
                icon="bulb"
                show={isAdvancedLearningFound}
              />
              <span style={{ marginLeft: isAdvancedLearningFound ? '0' : '2.4rem' }}>
                {type.topHits[0].excerptType}
              </span>
              <span>{type.count}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ExploreTypesList;
