import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import type { QueryHighlight, Excerpt } from '../../../../types';
import Button from '../../../../components/UI/Button/Button';
import { setViewSelectedIssue, createIssue } from '../../../../store/actions';
import { uuid } from '../../../../services/idService';
import { ORIGIN } from '../../../../constants/constants';

type Props = {
  docTitle: string,
  items: Array<QueryHighlight>,
};

const SearchQueryResult = ({ docTitle, docId, items, aiTrainerEnabled }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [buttonsDisabledIds, setButtonsDisabledIds] = useState([]);

  const openDocument = (docId) => {
    history.push('/review/' + docId);
  };

  const openExcerptInDocument = (docId: string, excerptId: string, newExcerpt: Excerpt) => {
    dispatch(setViewSelectedIssue(newExcerpt));
    history.push(`/review/${docId}`);
  };

  const onCreateIssue = (event: any, trainingExcerpt: Excerpt) => {
    event.stopPropagation();
    trainingExcerpt.origin = ORIGIN.USER;
    dispatch(createIssue(trainingExcerpt, true));
  };

  return (
    <div className={'al-search-queries-preview-list-item-container'}>
      <label className="al-search-query-document-title" onClick={() => openDocument(docId)}>
        {docTitle}
      </label>
      <div style={{ marginBottom: '30px' }}>
        {items.map((queryHighlight: QueryHighlight, key: Number) => {
          const newExcerpt: Excerpt = {
            excerptId: uuid(),
            excerptType: queryHighlight.type,
            bodyHTML: queryHighlight.result,
            docId: queryHighlight.docId,
            docTitle: queryHighlight.docTitle,
            comments: [],
            assignee: null,
            status: 'OPEN',
            dateLastModified: '',
            createdDate: '',
            contextIds: queryHighlight.sectionOffsets,
            globalStartOffset: queryHighlight.globalStart,
            globalEndOffset: queryHighlight.globalEnd,
            origin: ORIGIN.ADVANCED,
            deleted: false,
            hasComment: false,
            probability: 1,
            aiTrained: true,
          };

          return (
            <div
              key={queryHighlight.docId + key}
              className="al-search-queries-preview-list-item-container-item"
            >
              <span
                className="al-search-queries-preview-result"
                onClick={() =>
                  openExcerptInDocument(
                    queryHighlight.docId,
                    queryHighlight.sectionOffsets[0].id,
                    newExcerpt,
                  )
                }
              >
                {queryHighlight.result}
              </span>
              <span className="al-search-queries-preview-result-button-wrapper">
                <Button
                  className={'al-primary'}
                  disabled={
                    buttonsDisabledIds.includes(queryHighlight.docId + key) || !aiTrainerEnabled
                  }
                  clicked={(e) => {
                    const buttonsDisabled = buttonsDisabledIds.concat(queryHighlight.docId + key);
                    setButtonsDisabledIds(buttonsDisabled);
                    onCreateIssue(e, newExcerpt);
                  }}
                >
                  {'Annotate'}
                </Button>
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchQueryResult;
