import { axiosInstance as axios } from '../../services/axiosInstance';

import * as actionTypes from './actionType';

let firstTry = true;
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, iFinderJwt, apiVersion, userId, authData, data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    apiVersion,
    authData,
    data,
    iFinderJwt,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const auth = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      username: email,
      password: password,
    };
    axios
      .post('/api/auth/login', authData, { withCredentials: true })
      .then((response) => {
        dispatch(
          authSuccess(
            response.headers.authorization,
            response.headers['ifinderjwt'],
            response.headers['al-api-version'],
            response.data.localId,
            authData,
            response.data,
          ),
        );
      })
      .catch((err) => {
        if (firstTry) {
          firstTry = false;
          dispatch(auth(email, password));
        }
        dispatch(authFail(err));
      });
  };
};

export const setAuthenticationFromCache = () => {
  return {
    type: actionTypes.SET_AUTHENTICATION_FROM_CACHE,
  };
};

export const isLoggedInSuccess = (apiVersion) => {
  localStorage.setItem('apiVersion', apiVersion);
  return {
    type: actionTypes.IS_LOGIN_SUCCESS,
    apiVersion,
  };
};

export const isLoggedIn = () => {
  return (dispatch) => {
    axios
      .get('/api/is-loggedin')
      .then((response) => {
        dispatch(isLoggedInSuccess(response.headers['al-api-version']));
      })
      .catch(() => {
        dispatch(logout());
      });
  };
};
