import * as actionTypes from '../actions/actionType';
import { ISSUE_STATUS, ORIGIN } from '../../constants/constants';
import _ from 'lodash';
import {
  getDifferencesBetweenRemoteAndLocalById,
  mergeLocalWithDiffByLastModifiedDate,
} from '../../services/comparator';

const initialState = {
  issues: [],
  documentId: null,
  error: null,
  loading: false,
  success: false,
  bootstrapSuccess: false,
  bootstrapError: null,
  issue: {
    data: {},
    updateError: null,
    updateLoading: false,
    issueId: null,
    success: false,
    isDeleting: false,
    deleteError: null,
  },
  selectedExcerpt: null,
  newInsertedIssues: [],
  newDeletedIssues: [],
  actionType: '',
  trainingIssue: null,
  trainingIssueError: null,
  exportLoading: false,
  exportType: '',
};

const startUpdate = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    loading: false,
    success: false,
    error: null,
    issue: {
      ...state.issue,
      updateError: null,
      updateLoading: true,
      issueId: null,
      success: false,
    },
  };
};

const fetchIssuesStart = (state, action) => {
  if (state.documentId !== action.documentId) {
    return {
      ...state,
      actionType: action.type,
      loading: true,
      success: false,
      error: null,
      documentId: action.documentId,
      issues: [],
    };
  }
  return {
    ...state,
    actionType: action.type,
    loading: true,
    success: false,
    error: null,
  };
};

const fetchIssuesSuccess = (state, action) => {
  let issues = action.data.filter((issue) => issue.origin !== ORIGIN.ADVANCED);
  if (action.forceUpdate) {
    return {
      ...state,
      actionType: action.type,
      loading: false,
      success: true,
      error: null,
      issues: action.data,
      documentId: action.documentId,
      newInsertedIssues: [],
      newDeletedIssues: [],
    };
  } else {
    let diff = getDifferencesBetweenRemoteAndLocalById(issues, state.issues, 'excerptId');
    let unprocessedDeletedIssues = _.intersectionBy(
      state.newDeletedIssues,
      diff.added,
      'excerptId',
    );
    if (unprocessedDeletedIssues.length) {
      return state;
    }
    let unprocessedAddedIssues = _.intersectionBy(
      state.newInsertedIssues,
      diff.deleted,
      'excerptId',
    );
    if (unprocessedAddedIssues.length) {
      return state;
    }
    let updatedIssues = [...state.issues];
    updatedIssues = mergeLocalWithDiffByLastModifiedDate(updatedIssues, diff);
    if (!_.isEqual(updatedIssues, state.issues)) {
      return {
        ...state,
        actionType: action.type,
        loading: false,
        success: true,
        error: null,
        issues: updatedIssues,
        documentId: action.documentId,
        newInsertedIssues: [],
        newDeletedIssues: [],
      };
    }
    return {
      ...state,
      actionType: action.type,
      loading: false,
      success: true,
      error: null,
      issues: updatedIssues,
      newInsertedIssues: [],
      newDeletedIssues: [],
    };
  }
};

const fetchIssuesFail = (state, action) => {
  return { ...state, actionType: action.type, loading: false, success: false, error: true };
};

const updateIssueStart = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    issue: {
      ...state.issue,
      updateError: null,
      updateLoading: true,
      issueId: action.issueId,
      success: false,
    },
  };
};

const updateIssueSuccess = (state, action) => {
  let issues = [...state.issues];

  for (let key in issues) {
    if (issues[key].excerptId === action.data.excerptId) {
      let update = { ...issues[key] };
      update = action.data;
      issues[key] = update;
    }
  }

  return {
    ...state,
    actionType: action.type,
    issues: issues,
    issue: {
      ...state.issue,
      data: action.data,
      issueId: action.issueId,
      updateError: null,
      updateLoading: false,
      success: true,
    },
  };
};

const updateIssueFail = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    issue: {
      ...state.issue,
      issueId: action.issueId,
      updateError: action.error,
      updateLoading: false,
    },
  };
};

const deleteIssueStart = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    error: null,
    success: false,
    issue: {
      ...state.issue,
      isDeleting: true,
      deleteError: null,
      issueId: action.issueId,
      success: false,
    },
  };
};

const deleteIssueSuccess = (state, action) => {
  let issues = [...state.issues];
  let deletedIssue = issues.find(function (issue) {
    return issue.excerptId === action.issueId;
  });
  let updatedIssues = issues.filter(function (issue) {
    return issue.excerptId !== action.issueId;
  });
  let newDeletedIssues = [...state.newDeletedIssues];
  newDeletedIssues.push(deletedIssue);
  return {
    ...state,
    actionType: action.type,
    issues: updatedIssues,
    error: null,
    success: true,
    issue: {
      ...state.issue,
      isDeleting: false,
      issueId: action.issueId,
      success: true,
    },
    newDeletedIssues: newDeletedIssues,
  };
};

const deleteIssueFail = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    error: action.error,
    success: false,
    issue: {
      ...state.issue,
      isDeleting: false,
      deleteError: action.error,
      issueId: action.issueId,
      success: false,
    },
  };
};

const recognizeIssueSuccess = (state, action) => {
  if (!action.isCorrect) {
    return state;
  }
  let approvedIssue = action.data;
  let updatedIssues = [...state.issues];
  if (!updatedIssues.filter((f) => f.excerptId === approvedIssue.excerptId).length) {
    updatedIssues.push(approvedIssue);
    return {
      ...state,
      actionType: action.type,
      issues: updatedIssues,
    };
  } else {
    return state;
  }
};

const approveAllIssuesStart = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    error: null,
    loading: true,
  };
};

const approveAllIssuesSuccess = (state, action) => {
  let approvedIssues = action.data;
  let updatedIssues = [];
  for (let updatedIssue of state.issues) {
    let update = { ...updatedIssue };
    if (approvedIssues.indexOf(updatedIssue.excerptId) > -1) {
      update.status = ISSUE_STATUS.APPROVED;
      updatedIssues.push(update);
    }
  }
  return {
    ...state,
    actionType: action.type,
    issues: updatedIssues,
    documentId: action.documentId,
    error: null,
    loading: false,
  };
};

const approveAllIssuesFail = (state, action) => {
  return { ...state, actionType: action.type, error: true, loading: false };
};

const createIssueStart = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    error: null,
    loading: true,
    success: false,
    issue: {
      tempId: action.tempId,
      success: false,
      updateError: false,
      updateLoading: true,
    },
  };
};

const createIssueSuccess = (state, action) => {
  let issues = [...state.issues];
  let newInsertedIssues = [...state.newInsertedIssues];
  newInsertedIssues.push(action.data);
  if (
    !_.find(issues, (s) => {
      return s.excerptId === action.data.excerptId;
    })
  ) {
    issues.unshift(action.data);
    if (action.sortBy) {
      issues = _.sortBy(issues, [action.sortBy.fieldName.split('.')[0].toLowerCase()]);
      if (!action.sortBy.asc) {
        issues.reverse();
      }
    }
  }
  return {
    ...state,
    actionType: action.type,
    issues: issues,
    loading: false,
    success: true,
    newInsertedIssues: newInsertedIssues,
    issue: {
      tempId: action.tempId,
      issueId: action.data.excerptId,
      data: action.data,
      success: true,
      updateError: false,
      updateLoading: false,
    },
  };
};

const createIssueFail = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    loading: false,
    error: action.error,
    issue: {
      tempId: action.tempId,
      success: true,
      updateError: action.error,
      updateLoading: false,
    },
  };
};

const fetchExcerptByIdStart = (state) => {
  return {
    ...state,
    selectedExcerpt: null,
  };
};

const fetchExcerptByIdSuccess = (state, action) => {
  return {
    ...state,
    selectedExcerpt: action.data,
  };
};

const fetchExcerptByIdFail = (state) => {
  return {
    ...state,
    selectedExcerpt: state.selectedExcerpt,
  };
};

const trainIssueSuccess = (state, action) => {
  return {
    ...state,
    trainingIssue: action.excerpt,
    trainingIssueError: null,
  };
};

const trainIssueFail = (state, action) => {
  return {
    ...state,
    trainingIssue: action.excerpt,
    trainingIssueError: action.error,
  };
};

const exportIssuesStart = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    error: null,
    exportLoading: true,
    exportType: action.exportType,
    success: false,
  };
};

const exportIssuesSuccess = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    exportLoading: false,
    exportType: '',
    success: true,
  };
};

const exportIssuesFail = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    exportLoading: false,
    exportType: '',
    error: action.error,
    success: false,
  };
};

const bootStrapStaticStart = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    loading: true,
    bootstrapSuccess: false,
    bootstrapError: null,
  };
};

const bootStrapStaticSuccess = (state, action) => {
  return {
    ...state,
    actionType: action.type,
    loading: false,
    bootstrapSuccess: true,
    excerptType: action.excerptType,
    data: action.data,
  };
};

const bootStrapStaticFail = (state, action) => {
  action.error.data = action.excerptType;
  return {
    ...state,
    actionType: action.type,
    loading: false,
    bootstrapSuccess: false,
    bootstrapError: action.error,
  };
};

const issues = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ISSUE_START:
      return fetchIssuesStart(state, action);
    case actionTypes.FETCH_ISSUE_SUCCESS:
      return fetchIssuesSuccess(state, action);
    case actionTypes.FETCH_ISSUE_FAIL:
      return fetchIssuesFail(state, action);

    case actionTypes.UPDATE_ISSUE_START:
      return updateIssueStart(state, action);
    case actionTypes.UPDATE_ISSUE_SUCCESS:
      return updateIssueSuccess(state, action);
    case actionTypes.UPDATE_ISSUE_FAIL:
      return updateIssueFail(state, action);
    case actionTypes.START_UPDATE:
      return startUpdate(state, action);

    case actionTypes.DELETE_ISSUE_START:
      return deleteIssueStart(state, action);
    case actionTypes.DELETE_ISSUE_SUCCESS:
      return deleteIssueSuccess(state, action);
    case actionTypes.DELETE_ISSUE_FAIL:
      return deleteIssueFail(state, action);

    case actionTypes.APPROVE_ALL_ISSUES_START:
      return approveAllIssuesStart(state, action);
    case actionTypes.APPROVE_ALL_ISSUES_SUCCESS:
      return approveAllIssuesSuccess(state, action);
    case actionTypes.APPROVE_ALL_ISSUES_FAIL:
      return approveAllIssuesFail(state, action);

    case actionTypes.CREATE_ISSUE_START:
      return createIssueStart(state, action);
    case actionTypes.CREATE_ISSUE_SUCCESS:
      return createIssueSuccess(state, action);
    case actionTypes.CREATE_ISSUE_FAIL:
      return createIssueFail(state, action);

    case actionTypes.FETCH_EXCERPT_BY_ID_START:
      return fetchExcerptByIdStart(state, action);
    case actionTypes.FETCH_EXCERPT_BY_ID_SUCCESS:
      return fetchExcerptByIdSuccess(state, action);
    case actionTypes.FETCH_EXCERPT_BY_ID_FAIL:
      return fetchExcerptByIdFail(state, action);

    case actionTypes.TRAIN_ISSUE_SUCCESS:
      return trainIssueSuccess(state, action);
    case actionTypes.TRAIN_ISSUE_FAIL:
      return trainIssueFail(state, action);

    case actionTypes.EXPORT_ISSUES_DOCUMENT_START:
      return exportIssuesStart(state, action);
    case actionTypes.EXPORT_ISSUES_DOCUMENT_SUCCESS:
      return exportIssuesSuccess(state, action);
    case actionTypes.EXPORT_ISSUES_DOCUMENT_FAIL:
      return exportIssuesFail(state, action);

    case actionTypes.RECOGNIZE_ISSUE_SUCCESS:
      return recognizeIssueSuccess(state, action);

    case actionTypes.BOOTSTRAP_STATICS_ISSUES_START:
      return bootStrapStaticStart(state, action);
    case actionTypes.BOOTSTRAP_STATICS_ISSUES_SUCCESS:
      return bootStrapStaticSuccess(state, action);
    case actionTypes.BOOTSTRAP_STATICS_ISSUES_FAIL:
      return bootStrapStaticFail(state, action);

    default:
      return state;
  }
};

export default issues;
