// @flow
/**
 *  Compare Findings Component
 *
 *  enables the user to compare the findings of two different files or against the compare database.
 *  enables to select reference / comparison excerpts lists and shows a diff between those
 *
 */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../hooks/prevValue';
import { Map } from 'immutable';
import type { Excerpt, FileItemType, CompareDatabaseFinding } from '../../types';
import Spinner from '../../components/UI/Spinner/Spinner';
import SelectFiles from '../../components/CompareFindings/SelectFiles';
import ExcerptDiffSelect from '../../components/CompareFindings/ExcerptDiffSelect';
import CompareExcerpts from '../../components/CompareFindings/CompareExcerpts';
import Switch from '@material-ui/core/Switch';
import {
  fetchDocumentsBy,
  fetchDiff,
  fetchDiffTypes,
  selectReferenceFile,
  selectComparedFile,
  resetReferenceFile,
  resetComparedFile,
  fetchDiffDatabaseTypes,
  toggleUseCompareDatabase,
} from '../../store/actions';
import { withPermissions } from '../../contexts/PermissionsContext/withPermissions';
import { PERMISSIONS } from '../../constants/constants';

type Props = {
  loading: boolean,
  fileItems: Array<FileItemType>,
  referenceFile: null | FileItemType,
  comparedFile: null | FileItemType,
  referenceExcerptMap: Map<string, Array<Excerpt>> | null,
  comparedExcerptMap: Map<string, Array<Excerpt>> | null,
  diffTypesResult: Object,
  projectId: string,
  compareDatabaseFindings: Array<CompareDatabaseFinding>,
  isUsingCompareDatabase: boolean,
};

type State = {
  activeType: null | string,
  referenceExcerpts: Array<Excerpt> | Array<CompareDatabaseFinding>,
  comparedExcerpts: Array<Excerpt>,
};

const CompareFindings = ({ match, PermissionsContext }) => {
  const dispatch = useDispatch();
  const {
    loading,
    fileItems,
    referenceFile,
    comparedFile,
    referenceExcerptMap,
    comparedExcerptMap,
    diffTypesResult,
    projectId,
    compareDatabaseFindings,
    isUsingCompareDatabase,
  }: Props = useSelector(
    (state) => ({
      loading: state.compare.loading,
      fileItems: state.document.documents,
      referenceFile: state.compare.referenceFile,
      comparedFile: state.compare.comparedFile,
      referenceExcerptMap: state.compare.referenceExcerptMap,
      comparedExcerptMap: state.compare.comparedExcerptMap,
      diffTypesResult: state.compare.diffTypesResult,
      projectId: state.project.projectId,
      compareDatabaseFindings: state.compare.compareDatabaseFindings,
      isUsingCompareDatabase: state.compare.isUsingCompareDatabase,
    }),
    shallowEqual,
  );

  const getExcerptsLists = (excerptMap, activeType) => {
    let excerpts = [];
    excerptMap?.forEach((excerptsByType) => {
      for (const excerpt of excerptsByType) {
        if (excerpt.excerptType === activeType) {
          excerpts.push(excerpt);
        }
      }
    });
    return excerpts;
  };

  const [activeType, setActive] = useState(null);
  const [referenceExcerpts, setReferenceExcerpts] = useState(
    getExcerptsLists(referenceExcerptMap, activeType),
  );
  const [comparedExcerpts, setComparedExcerpts] = useState(
    getExcerptsLists(comparedExcerptMap, activeType),
  );
  const prevProjectId = usePrevious(projectId);
  const prevFileItems = usePrevious(fileItems);
  const prevIsUsingCompareDatabase = usePrevious(isUsingCompareDatabase);
  const prevReferenceExcerptMap = usePrevious(referenceExcerptMap);

  useEffect(() => {
    dispatch(fetchDocumentsBy(projectId)); // fetch all documents in a project to set list for comparison
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newActiveType = Object.keys(diffTypesResult).find((type) => type === activeType)
      ? activeType
      : null;

    updateComparingData(newActiveType);
    // eslint-disable-next-line
  }, [diffTypesResult]);

  const resetComparingData = () => {
    setActive(null);
    setReferenceExcerpts([]);
    setComparedExcerpts([]);
  };

  const updateComparingData = (findingType?: string) => {
    let newActiveType = findingType;
    if (!findingType) {
      const sortedDiffTypesResult = Object.keys(diffTypesResult).sort((a, b) => a.localeCompare(b));
      newActiveType = sortedDiffTypesResult[0];
      setActive(newActiveType);
    } else if (findingType !== activeType) {
      setActive(findingType);
    }
    setReferenceExcerpts(getExcerptsLists(referenceExcerptMap, newActiveType));
    setComparedExcerpts(getExcerptsLists(comparedExcerptMap, newActiveType));
  };

  useEffect(() => {
    if (prevProjectId && prevProjectId !== projectId) {
      dispatch(fetchDocumentsBy(projectId));
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    // skip on component load
    if (typeof prevFileItems !== 'undefined') {
      const fileToReference = fileItems.find(({ id }) => id === referenceFile?.id);
      // select comparison file based on id in url (navigating from Review.js) or on redux's comparedFile (if exists)
      const selectedDocId = match.params.docId;
      const comparedId = selectedDocId ? selectedDocId : comparedFile?.id;
      const fileToCompare = fileItems.find(({ id }) => id === comparedId);

      // reset selected reference/comparison files if the updated fileItems does not contain them
      if (!fileToReference) {
        dispatch(resetReferenceFile());
      }
      if (!fileToCompare) {
        dispatch(resetComparedFile());
      } else if (selectedDocId !== comparedFile?.id) {
        dispatch(selectComparedFile(fileToCompare));
      }
      compareFindings(fileToReference, fileToCompare);
    }
    // eslint-disable-next-line
  }, [fileItems]);

  useEffect(() => {
    // skip on component load
    if (typeof prevReferenceExcerptMap !== 'undefined') {
      // Fetch list of reference finding types only after retrieving the excerpts to compare
      if (!isUsingCompareDatabase) {
        if (referenceFile && comparedFile) {
          dispatch(fetchDiffTypes(referenceFile.id, comparedFile.id));
        }
      } else if (comparedFile) {
        dispatch(fetchDiffDatabaseTypes(projectId, comparedFile.id));
      }
    }
    // eslint-disable-next-line
  }, [referenceExcerptMap]);

  const onSelectReferenceFile = (fileId) => {
    const newReferenceFile = fileItems.find(({ id }) => id === fileId);
    if (!newReferenceFile) {
      dispatch(resetReferenceFile());
      resetComparingData();
    } else {
      if (!comparedFile) {
        dispatch(selectReferenceFile(newReferenceFile));
      }
      compareFindings(newReferenceFile, comparedFile);
    }
  };

  const onSelectComparedFile = (fileId) => {
    const newComparedFile = fileItems.find(({ id }) => id === fileId);
    if (!newComparedFile) {
      dispatch(resetComparedFile());
      resetComparingData();
    } else {
      if (!referenceFile && !isUsingCompareDatabase) {
        dispatch(selectComparedFile(newComparedFile));
      }
      compareFindings(referenceFile, newComparedFile);
    }
  };

  /**
   * compareFindings uses the currently selected files (local state) to fetch a new diff and update the comparison
   * redux-state
   */
  const compareFindings = (reference: FileItemType | null, comparison: FileItemType) => {
    if (canCompareFindings(reference, comparison)) {
      dispatch(
        fetchDiff(
          {
            referenceFile: reference,
            comparedFile: comparison,
          },
          isUsingCompareDatabase,
          projectId,
        ),
      );
    }
  };

  useEffect(() => {
    if (typeof prevIsUsingCompareDatabase !== 'undefined') {
      resetComparingData();
      compareFindings(isUsingCompareDatabase ? null : referenceFile, comparedFile);
    }
    // eslint-disable-next-line
  }, [isUsingCompareDatabase]);

  useEffect(() => {
    // allow to compare on component load
    if (isUsingCompareDatabase) {
      compareFindings(null, comparedFile);
    }
    // eslint-disable-next-line
  }, [compareDatabaseFindings]);

  const canCompareFindings = (reference, compared) =>
    compared && (reference || isUsingCompareDatabase);

  return (
    <div className={'al-container al-compare-container'}>
      <div className={'al-compare-header-container'}>
        <div>
          <div className="al-headline">Compare</div>
          {!isUsingCompareDatabase ? (
            <div className="al-description-text">
              Compare the findings of two different documents
            </div>
          ) : (
            <div className="al-description-text">
              Compare the findings of the database with a selected document
            </div>
          )}
        </div>
        <div className={'switch-container'}>
          <div className={'switch-label'}>Use reference document</div>
          <Switch
            size={'medium'}
            color={'default'}
            checked={isUsingCompareDatabase}
            onChange={() => {
              dispatch(toggleUseCompareDatabase(!isUsingCompareDatabase));
            }}
            name="checked Compare Database"
          />
          <div className={'switch-label'}>Use database</div>
        </div>
      </div>

      <div className={'al-compare-content'}>
        <div className={'al-compare-content-header'}>
          <div className={'compare-table-header-label finding-types finding-types-header'}>
            Finding types
          </div>
          <SelectFiles
            fileItems={fileItems}
            selectedReferenceFile={referenceFile}
            selectedComparedFile={comparedFile}
            onSelectReferenceFile={onSelectReferenceFile}
            onSelectComparedFile={onSelectComparedFile}
            swapDocsToCompare={() => {
              compareFindings(comparedFile, referenceFile);
            }}
            isUsingCompareDatabase={isUsingCompareDatabase}
          />
        </div>
        <div className={'al-compare-content-body'}>
          <div className={'finding-types finding-types-body'}>
            <div className={'al-compareFindings-selectType'}>
              {(referenceFile || isUsingCompareDatabase) && (
                <ExcerptDiffSelect
                  activeType={activeType}
                  onSelect={updateComparingData}
                  findingTypes={diffTypesResult}
                  isComparingFindings={true}
                />
              )}
            </div>
          </div>
          <div className={'al-compareExcerpt'}>
            {canCompareFindings(referenceFile, comparedFile) ? (
              <CompareExcerpts
                comparedExcerpts={comparedExcerpts}
                referenceExcerpts={referenceExcerpts}
                comparedFileName={comparedFile.title}
                activeType={activeType}
                hasCreatePermission={PermissionsContext.hasPermission(
                  PERMISSIONS.SHOW_COMPARE_DATABASE_POST,
                )}
              />
            ) : (
              <>
                {loading ? (
                  <Spinner />
                ) : (
                  (!referenceFile || !comparedFile) && (
                    <div className={'select-message-container'}>
                      <div className="al-info-msg al-mt-40">
                        {`Please select
                        ${
                          isUsingCompareDatabase ? '' : ' the reference document and'
                        } the document to compare with`}
                      </div>
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPermissions(CompareFindings);
