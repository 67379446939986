import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import _ from 'lodash';

import type { SearchQuery } from '../../../types';
import Button from '../../../components/UI/Button/Button';
import * as actions from '../../../store/actions';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { DEFAULT_PAGE_NUMBER, PERMISSIONS } from '../../../constants/constants';

type Props = {
  searchQueries: Array<SearchQuery>,
  contractId: string,
  projectId: string,
  onEditSearchQuery: (
    query: string,
    excerptType: string,
    characterLength: number,
    searchQueryId: string,
  ) => void,
};

const SearchQueriesList = ({
  searchQueries,
  contractId,
  projectId,
  onEditSearchQuery,
  PermissionsContext,
}: Props) => {
  const loading = useSelector(
    (state) =>
      state.searchQueries.fetchingSearchQueries ||
      state.searchQueries.creatingSearchQueries ||
      state.searchQueries.editingSearchQueries ||
      state.searchQueries.applyingQuery ||
      state.searchQueries.deletingSearchQueries,
  );
  const searchQueryId = useSelector((state) => state.searchQueries.searchQueryId);
  const applyingQuery = useSelector((state) => state.searchQueries.applyingQuery);

  const dispatch = useDispatch();
  const deleteSearchQuery = (searchQuery: SearchQuery) =>
    dispatch(actions.deleteSearchQuery(searchQuery));
  const fetchSearchQueryHighlights = (searchQueryId: string, searchQueryName: string) =>
    dispatch(
      actions.fetchSearchQueryHighlights(searchQueryId, searchQueryName, DEFAULT_PAGE_NUMBER),
    );
  const applyQuery = (projectId: string, contractId: string, searchQueryId: string) =>
    dispatch(actions.applyQuery(projectId, contractId, searchQueryId));

  return (
    <div className="al-search-queries-list">
      <div className={'al-search-queries-list-header'}>{`Queries (${searchQueries.length})`}</div>
      <div className={'al-search-queries-list-items'}>
        <div className="al-search-queries-header-table">
          <span>Name</span>
          <span>Query</span>
          <span>Static finding</span>
          <span>Min Char Length</span>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          _.orderBy(searchQueries, [(el) => el.excerptType.toLowerCase()], ['asc']).map(
            (searchQuery: SearchQuery) => {
              return (
                <div
                  key={searchQuery.id}
                  className={`al-search-queries-list-item ${
                    searchQueryId === searchQuery.id ? 'active' : ''
                  }`}
                  onClick={() =>
                    fetchSearchQueryHighlights(
                      searchQuery.id,
                      searchQuery.excerptType,
                      DEFAULT_PAGE_NUMBER,
                    )
                  }
                >
                  <span className={'al-query-type'}>{searchQuery.excerptType}</span>
                  <span className="al-query-name">{searchQuery.query}</span>
                  <span>
                    {searchQuery.applied ? (
                      <CheckCircleIcon className={`al-icons applied`} />
                    ) : (
                      <Button
                        className={'view-button'}
                        clicked={() => applyQuery(projectId, contractId, searchQueryId)}
                        preventDefault={true}
                        disabled={applyingQuery}
                      >
                        {'Create'}
                      </Button>
                    )}
                  </span>
                  <span>{searchQuery.minCharLength === 0 ? '-' : searchQuery.minCharLength}</span>
                  <span className="al-search-queries-list-item-actions">
                    {PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_PUT) && (
                      <Button
                        withIcon={true}
                        iconType={'edit'}
                        clicked={() => {
                          onEditSearchQuery(
                            searchQuery.query,
                            searchQuery.excerptType,
                            searchQuery.minCharLength === 0 ? null : searchQuery.minCharLength,
                            searchQuery.id,
                          );
                        }}
                      />
                    )}

                    {PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_DELETE) && (
                      <Button
                        clicked={() => deleteSearchQuery(searchQuery)}
                        iconType={'delete'}
                        withIcon={true}
                        preventDefault={true}
                      />
                    )}
                  </span>
                </div>
              );
            },
          )
        )}
      </div>
    </div>
  );
};

export default SearchQueriesList;
