import React from 'react';
import { Pagination, PaginationItem } from '@material-ui/lab';

type Props = {
  numPages: number,
  setPage: (page: number) => void, // for controlled pagination
  page: number,
};

const PageNumbering = ({ numPages, setPage, page }: Props) => {
  return (
    <div style={{ margin: '5px auto' }}>
      <Pagination
        boundaryCount={3}
        count={numPages}
        variant={'outlined'}
        onChange={(event, page) => {
          setPage(page);
        }}
        page={page}
        renderItem={(item) => {
          return <PaginationItem {...item} />;
        }}
      />
    </div>
  );
};

export default PageNumbering;
