import * as actionTypes from '../actions/actionType';

const initialState = {
  globalSettings: [],
  loadingSettings: false,
  errorSettings: null,
  updatingSettings: false,
  updatingImage: false,
  updatingImageError: null,
  updatingCSS: false,
  updatingCSSError: null,
  updatingFreeze: false,
  updatingFreezeError: null,
};

const fetchGlobalSettingsStart = (state) => {
  return {
    ...state,
    loadingSettings: true,
    errorSettings: null,
  };
};

const fetchGlobalSettingsSuccess = (state, action) => {
  return {
    ...state,
    loadingSettings: false,
    globalSettings: action.data,
  };
};

const fetchGlobalSettingsFail = (state, action) => {
  return {
    ...state,
    loadingSettings: false,
    errorSettings: action.error,
  };
};

const updateGlobalSettingsStart = (state) => {
  return {
    ...state,
    updatingSettings: true,
    updatingSettingsError: null,
  };
};

const updateGlobalSettingsSuccess = (state) => {
  return {
    ...state,
    updatingSettings: false,
  };
};

const updateGlobalSettingsFail = (state, action) => {
  return {
    ...state,
    updatingSettings: false,
    updatingSettingsError: action.error,
  };
};

const uploadImageFileStart = (state) => {
  return {
    ...state,
    updatingImage: true,
    updatingImageError: null,
  };
};

const uploadImageFileSuccess = (state) => {
  return {
    ...state,
    updatingImage: false,
  };
};

const uploadImageFileFail = (state, action) => {
  return {
    ...state,
    updatingImage: false,
    updatingImageError: action.error,
  };
};

const updateImageFileStart = (state) => {
  return {
    ...state,
    updatingImage: true,
    updatingImageError: null,
  };
};

const updateImageFileSuccess = (state) => {
  return {
    ...state,
    updatingImage: false,
  };
};

const updateImageFileFail = (state, action) => {
  return {
    ...state,
    updatingImage: false,
    updatingImageError: action.error,
  };
};

const deleteImageFileStart = (state) => {
  return {
    ...state,
    updatingImage: true,
    updatingImageError: null,
  };
};

const deleteImageFileSuccess = (state) => {
  return {
    ...state,
    updatingImage: false,
  };
};

const deleteImageFileFail = (state, action) => {
  return {
    ...state,
    updatingImage: false,
    updatingImageError: action.error,
  };
};

const updateCSSLogoStart = (state) => {
  return {
    ...state,
    updatingCSS: true,
    updatingCSSError: null,
  };
};

const updateCSSLogoSuccess = (state) => {
  return {
    ...state,
    updatingCSS: false,
  };
};

const updateCSSLogoFail = (state, action) => {
  return {
    ...state,
    updatingCSS: false,
    updatingCSSError: action.error,
  };
};

const updateFreezeTrainingStart = (state) => {
  return {
    ...state,
    updatingFreeze: true,
    updatingFreezeError: null,
  };
};

const updateFreezeTrainingSuccess = (state) => {
  return {
    ...state,
    updatingFreeze: false,
  };
};

const updateFreezeTrainingFail = (state, action) => {
  return {
    ...state,
    updatingFreeze: false,
    updatingFreezeError: action.error,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GLOBAL_SETTINGS_START:
      return fetchGlobalSettingsStart(state);
    case actionTypes.FETCH_GLOBAL_SETTINGS_SUCCESS:
      return fetchGlobalSettingsSuccess(state, action);
    case actionTypes.FETCH_GLOBAL_SETTINGS_FAIL:
      return fetchGlobalSettingsFail(state, action);
    case actionTypes.UPDATE_GLOBAL_SETTINGS_START:
      return updateGlobalSettingsStart(state);
    case actionTypes.UPDATE_GLOBAL_SETTINGS_SUCCESS:
      return updateGlobalSettingsSuccess(state, action);
    case actionTypes.UPDATE_GLOBAL_SETTINGS_FAIL:
      return updateGlobalSettingsFail(state, action);
    case actionTypes.UPLOAD_IMG_FILE_START:
      return uploadImageFileStart(state, action);
    case actionTypes.UPLOAD_IMG_FILE_SUCCESS:
      return uploadImageFileSuccess(state, action);
    case actionTypes.UPLOAD_IMG_FILE_FAIL:
      return uploadImageFileFail(state, action);
    case actionTypes.UPDATE_IMG_FILE_START:
      return updateImageFileStart(state, action);
    case actionTypes.UPDATE_IMG_FILE_SUCCESS:
      return updateImageFileSuccess(state, action);
    case actionTypes.UPDATE_IMG_FILE_FAIL:
      return updateImageFileFail(state, action);
    case actionTypes.DELETE_IMG_FILE_START:
      return deleteImageFileStart(state, action);
    case actionTypes.DELETE_IMG_FILE_SUCCESS:
      return deleteImageFileSuccess(state, action);
    case actionTypes.DELETE_IMG_FILE_FAIL:
      return deleteImageFileFail(state, action);
    case actionTypes.UPDATE_CSS_LOGO_START:
      return updateCSSLogoStart(state, action);
    case actionTypes.UPDATE_CSS_LOGO_SUCCESS:
      return updateCSSLogoSuccess(state, action);
    case actionTypes.UPDATE_CSS_LOGO_FAIL:
      return updateCSSLogoFail(state, action);
    case actionTypes.UPDATE_FREEZE_TRAINING_START:
      return updateFreezeTrainingStart(state, action);
    case actionTypes.UPDATE_FREEZE_TRAINING_SUCCESS:
      return updateFreezeTrainingSuccess(state, action);
    case actionTypes.UPDATE_FREEZE_TRAINING_FAIL:
      return updateFreezeTrainingFail(state, action);
    default:
      return state;
  }
};

export default reducer;
