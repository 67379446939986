import React, { useState } from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
type Props = {
  leftItemsProps: Array<string>,
  rightItemsProps: Array<string>,
  onMove: (Array<string>, Array<string>) => void,
  usedPermissions?: Set<string> | null,
};

const RightLeftController = ({
  leftItemsProps,
  rightItemsProps,
  onMove,
  leftTitle,
  rightTitle,
  usedPermissions,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [leftItems, setLeftItems] = useState(leftItemsProps);
  const [rightItems, setRightItems] = useState(rightItemsProps);

  const moveRight = () => {
    if (leftItems.indexOf(selectedItem) > -1) {
      const updatedLeftItems = leftItems.filter((item) => item !== selectedItem);
      setLeftItems(updatedLeftItems);
      const updatedRightItems = [...rightItems];
      updatedRightItems.push(selectedItem);
      setRightItems(updatedRightItems);
      onMove(updatedLeftItems, updatedRightItems);
    }
  };

  const moveLeft = () => {
    if (rightItems.indexOf(selectedItem) > -1) {
      const updatedRightItems = rightItems.filter((item) => item !== selectedItem);
      setRightItems(updatedRightItems);
      const updatedLeftItems = [...leftItems];
      updatedLeftItems.push(selectedItem);
      setLeftItems(updatedLeftItems);
      onMove(updatedLeftItems, updatedRightItems);
    }
  };

  return (
    <div
      className={'al-right-left-component'}
      style={{
        display: 'flex',
        maxHeight: '450px',
      }}
    >
      <div
        style={{
          width: 'calc(50% - 30px - 1px)',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        {leftTitle && <div style={{ marginBottom: '5px' }}>{leftTitle}</div>}
        <div style={{ border: '1px solid rgb(153, 161, 182)', flexGrow: '1', overflow: 'auto' }}>
          {leftItems.map((item: string, key: number) => {
            return (
              <p
                key={key}
                onClick={() => setSelectedItem(item)}
                className={`${item === selectedItem ? 'active' : ''}`}
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
      <div
        style={{
          width: '60px',
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '29px',
        }}
      >
        <ArrowBackIosIcon
          onClick={moveLeft}
          style={{ fontSize: '25px' }}
          className={'al-icons arrow-icon'}
        />
        <ArrowForwardIosIcon
          onClick={moveRight}
          style={{ fontSize: '25px' }}
          className={'al-icons arrow-icon'}
        />
      </div>
      <div
        style={{
          width: 'calc(50% - 30px - 1px)',
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        {rightTitle && <div style={{ marginBottom: '5px' }}>{rightTitle}</div>}
        <div style={{ border: '1px solid rgb(153, 161, 182)', flexGrow: '1', overflow: 'auto' }}>
          {rightItems.map((item: string, key) => {
            return (
              <p
                key={key}
                onClick={() => setSelectedItem(item)}
                className={`${item === selectedItem ? 'active' : ''} ${
                  usedPermissions ? (usedPermissions.has(item) ? '' : 'unused') : ''
                }`}
              >
                {item}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RightLeftController;
