import { useDispatch, useSelector } from 'react-redux';
import { updateIssue } from '../../../../store/actions';
import Button from '../../../UI/Button/Button';
import Icon from '../../../UI/Icon/Icon';
import type { Excerpt } from '../../../../types';

type Props = {
  excerpt: Excerpt,
  loading?: boolean,
  hasTrainingPermission: boolean,
  setDisabledIssue?: (disable: boolean) => void,
};

export const ExcerptToggleAiTrainer = ({
  excerpt,
  loading,
  hasTrainingPermission,
  setDisabledIssue,
}: Props) => {
  const dispatch = useDispatch();
  const aiTrainerEnabled = useSelector(
    (state) => state.globalSettings.globalSettings.aiTrainerEnabled,
  );

  const enableAITrained = (event, excerptId, isUsingAITraining) => {
    event.stopPropagation();
    dispatch(updateIssue(excerptId, { aiTrained: isUsingAITraining }));
  };

  return (
    <>
      {aiTrainerEnabled && hasTrainingPermission ? (
        <Button
          withIcon={true}
          iconType={`bulb ${excerpt.aiTrained ? 'ai-trained' : 'not-ai-trained'}`}
          title={`${excerpt.aiTrained ? 'Disable' : 'Enable'} use for AI Training`}
          clicked={(e) => {
            enableAITrained(e, excerpt.excerptId, !excerpt.aiTrained);
            setDisabledIssue(true);
          }}
          disabled={loading}
        />
      ) : (
        <Icon
          icon={`bulb ${excerpt.aiTrained ? 'ai-trained' : 'not-ai-trained'}`}
          title={`${excerpt.aiTrained ? 'Is' : 'Not'} used for AI training`}
        />
      )}
    </>
  );
};

export default ExcerptToggleAiTrainer;
