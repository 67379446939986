import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: 'rgba(0, 142, 183, 0.7)',
  },
}));

type Props = {
  title: string, 
  enterDelay: number,
  leaveDelay: number,
  placement: string,
  interactive: boolean,
  children: React$Node,
};

const CustomTooltip = ({
  title,
  enterDelay=1000,
  leaveDelay=200,
  placement="top-end",
  interactive = false,
  children
}: Props) => {
  const classes = useStyles();

  return (
    <Tooltip 
      classes={{ 
        tooltip: classes.customTooltip
      }}
      title={title} 
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      placement={placement}
      interactive={interactive}
    > 
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
