// @flow
import React, { Component } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import _ from 'lodash';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { addLocaleData } from 'react-intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';

import './App.scss';
import * as actions from './store/actions';
import config from './config';
//import Raven from 'raven-js';
import Home from './containers/Dashboard/Dashboard';
import Review from './containers/Review/Review/Review.js';
import Explore from './containers/Explore/Explore.js';
import Header from './containers/Header/Header';
import Footer from './containers/Footer/Footer';
import Login from './containers/Auth/Login/Login.js';
import ConnectedIntlProvider from './containers/container_intlProvider';
import SwaggerUiRoute from './SwaggerUiRoute';
import CompareText from './components/CompareText/CompareText';
import CompareFindings from './containers/Compare/CompareFindings';
import CompareDatabase from './components/CompareDatabase/CompareDatabase';
import ParaphraseDetect from './containers/ParaphraseDetect/ParaphraseDetect';
import Projects from './containers/Projects/Projects';
import type { AsyncActionState, Project } from './types';
import ContractTypes from './containers/ContractTypes/ContractTypes';
import ErrorBoundary from './ErrorBoundary';
import NotificationCenter from './containers/Notifications/NotificationCenter';
import type { AppState } from './types/reduxTypes';
import DynamicTabs from './containers/DynamicTabs/DynamicTabs';
import DynamicTabPage from './containers/DynamicTabs/DynamicTabPage';
import SearchQueries from './containers/SearchQueries/SearchQueries';
import Users from './containers/Users/Users';
import Roles from './containers/Roles/Roles';
import General from './containers/General/General';
import BrowseAdvanced from './containers/BrowseAdvanced/BrowseAdvanced';
import PermissionsProvider from './contexts/PermissionsContext/PermissionsProvider';
import { SERVER_EVENTS } from './constants/constants';
import { getAppEventSource, initAppEventSource } from './SSEApp';

addLocaleData(en);
addLocaleData(de);

// const CHECK_LOGIN_TIMER = 15 * 60 * 1000;
type NotificationType = {
  msg: string,
  type: 'info',
};

type Props = {
  fetchHealth: () => void,
  isAuthenticated: boolean,
  isLoggedIn: () => void,
  location: { search: string, pathname: string },
  error?: string,
  appStatus: { health: boolean },
  notifications: Array<NotificationType>,
  project: Project,
  fetchProjects: () => void,
  fetchingProjects: AsyncActionState,
  projectId?: string | null,
  logout: () => void,
  globalSettings: Object,
  fetchGlobalSettings: () => void,
};

type State = {
  showNotifications: boolean,
};

const mapStateToProps = (state: AppState) => ({
  isAuthenticated: localStorage.getItem('token') !== null,
  apiVersion: state.version.apiVersion,
  appStatus: state.health,
  fetchingProjects: state.project.fetchingProjects,
  projects: state.project.projects,
  project: state.project.projects?.get(state.project.projectId),
  projectId: state.project.projectId,
  globalSettings: state.globalSettings.globalSettings,
  currentLocation: state.locationPathName.currentLocation,
});

class App extends Component<Props, State> {
  isHealthInterval: boolean;
  healthTimer: Function;
  isLoginTimer: Function;
  evtSource: any;

  state = {
    showNotifications: true,
  };

  constructor(props: Props) {
    super(props);
    // Raven.config('https://e46fc6798714482a8cfe11da13556b72@sentry.io/1237795').install();
    props.fetchHealth();
    if (props.isAuthenticated) {
      props.isLoggedIn();
    }
    this.isHealthInterval = false;
  }

  setupEventSource = () => {
    initAppEventSource();
    getAppEventSource().onmessage = (e) => {
      // Handle even here
      // console.debug('Got general message');
      //console.debug(e.readyState, e.data);
    };

    getAppEventSource().onopen = (e) => {
      // this.props.isLoggedIn();
      // console.debug('open sse connection');
    };

    getAppEventSource().addEventListener(SERVER_EVENTS.KEEP_ALIVE, (e) => {
      console.debug('Emitter:', e.type);
    });

    getAppEventSource().addEventListener(SERVER_EVENTS.COMPLETE, (e) => {
      console.debug('Connection is completed & closing it');
      getAppEventSource().close();
    });

    getAppEventSource().addEventListener(
      SERVER_EVENTS.UPDATE_GLOBAL_CONFIGS,
      _.debounce((e) => {
        if (this.props.projectId) {
          console.debug(`Emitter: update-global-configs for project: ${this.props.projectId}`);
          this.props.fetchProjects();
        }
      }, 1000),
    );

    getAppEventSource().onerror = (e) => {
      if (this.props.isAuthenticated) {
        this.props.isLoggedIn();
      }
      console.debug('Application SSE Connection error, readystate:', e.target.readyState);
      if (e.target.readyState === 2) {
        console.error('Application SSE Connection is closed, readystate:', e.target.readyState);
        getAppEventSource().close();
        this.props.logout();
        /*if (this.props.isAuthenticated && this.props.appStatus.health) {
          this.setupEventSource();
        }*/
      }
    };
  };

  componentDidMount() {
    // we need this to communicate with the host if the user rendered CA in iFrame.
    window.addEventListener('message', (e) => {
      try {
        let payload = JSON.parse(e.data);
        if (payload.key === 'token') {
          localStorage.setItem(payload.key, payload.data);
        }
      } catch (e) {}
    });
    const {
      appStatus: { health },
    } = this.props;
    if (!health && !this.isHealthInterval) {
      this.setHealthCheck();
    }

    if (this.props.location.pathname.includes('/error')) {
      this.props.logout();
    }

    /* fetch projects after refreshing the page */
    if (health && this.props.isAuthenticated) {
      this.props.fetchGlobalSettings();
      this.props.fetchProjects();
      this.props.fetchDynamicTabs();
      this.setupEventSource();
      this.unSetHealthCheck();
    }
  }

  setHealthCheck = () => {
    this.isHealthInterval = true;
    this.props.fetchHealth();
    this.healthTimer = setInterval(() => {
      if (!this.props.appStatus.health) this.props.fetchHealth();
    }, 2000);
  };

  unSetHealthCheck = () => {
    this.isHealthInterval = false;
    clearInterval(this.healthTimer);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      appStatus: { health },
      isAuthenticated,
      location,
    } = this.props;

    if (!health && !this.isHealthInterval) {
      this.setHealthCheck();
    }

    /* fetch projects after login */
    if (health && isAuthenticated && !prevProps.isAuthenticated) {
      this.props.fetchProjects();
      this.props.fetchDynamicTabs();
      this.setupEventSource();
      this.unSetHealthCheck();
    }
    if (
      health &&
      isAuthenticated &&
      !this.props.project &&
      this.props.projectId === undefined &&
      !this.props.fetchingProjects
    ) {
      this.props.fetchProjects();
    }
    if (location.pathname !== prevProps.location.pathname) {
      this.props.locationPathNameChange(location.pathname);
    }

    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props.fetchGlobalSettings();
    }

    if (!_.isEqual(this.props.globalSettings, prevProps.globalSettings)) {
      this.setState({ globalSettings: this.props.globalSettings });
    }
    if (prevProps.currentLocation !== this.props.currentLocation) {
      /* stores URL to redirect user after opening a tab where credentials need to be inserted before showing the
      wanted URL (e.g., opening incognito tab or different browser) */
      if (this.props.currentLocation === '/login') {
        // location is set to '' after log out
        if (localStorage.getItem('location') !== '') {
          localStorage.setItem('location', prevProps.currentLocation);
        }
      }
    }
  }

  /**
   *  possible app states and render conditions
   *  are
   *  * not healthy or  not authenticated or no projects fetched
   *  * healthy authenticated projects fetched but no project available
   *  * healthy authenticated projects project available
   *
   ***/
  render() {
    const { appStatus, isAuthenticated, projectId, project, fetchingProjects } = this.props;

    if (!appStatus.health) {
      return <div className="connecting">Connecting...</div>;
    } else if (isAuthenticated && (fetchingProjects === true || projectId === undefined)) {
      return <div className="connecting">Fetching Projects ...</div>;
    }

    let routes = (
      <Switch>
        <Route path="/swagger-ui" component={SwaggerUiRoute} />
        <Route path="/login" exact component={Login} />
        <Redirect to="/login" />
      </Switch>
    );
    if (isAuthenticated && project) {
      routes = (
        <Switch>
          {this.props.globalSettings.indexAlFindingsiFinder && (
            <Route path="/search" exact component={BrowseAdvanced} />
          )}
          <Route path="/swagger-ui" component={SwaggerUiRoute} />
          <Route path="/" exact component={Home} />
          <Route path="/error" exact component={Home} />
          <Route exact path="/403.html" render={() => <Redirect to="/error" />} />
          <Route path="/compare" component={CompareText} />
          <Route path="/compareFindings" exact component={CompareFindings} />
          <Route path="/compareFindings/:docId" exact component={CompareFindings} />
          <Route path="/explore" component={Explore} />
          <Route path="/review/" exact component={Review} />
          <Route path="/review/:docId" exact component={Review} />
          <Route path="/review/:docId/:excerptId" component={Review} />
          <Route path="/paraphrase/" component={ParaphraseDetect} />
          <Route
            path="/settings/projects/:projectId/contractTypes"
            exact
            component={ContractTypes}
          />
          <Route path="/settings/general" component={General} />
          <Route path="/settings/projects" exact component={Projects} />
          <Route path="/settings/dynamicTabs/" exact component={DynamicTabs} />
          <Route path="/settings/dynamicTabs/:id" component={DynamicTabPage} />
          <Route path="/settings/searchQueries" component={SearchQueries} />
          <Route path="/settings/compareDatabase" component={CompareDatabase} />
          <Route path="/settings/users" component={Users} />
          <Route path="/settings/roles" component={Roles} />
          <Redirect to="/" />
        </Switch>
      );
    }
    if (isAuthenticated && !project) {
      routes = (
        <Switch>
          <Route path="/" component={Projects} />
          <Redirect to="/" />
        </Switch>
      );
    }
    return (
      <ConnectedIntlProvider>
        <PermissionsProvider>
          <div className={`al-app`}>
            <Header globalSettings={this.props.globalSettings} />
            <ErrorBoundary>
              <NotificationCenter />
              <div className="al-main">
                {routes}
                {/*<Modal show={true}>
                  <ModalHeader>Disconnected</ModalHeader>
                  <ModalContent>Are you still there !!</ModalContent>
                  <ModalFooter>
                    <Button>OK</Button>
                  </ModalFooter>
                </Modal>*/}
              </div>
            </ErrorBoundary>
            <Footer />
          </div>
        </PermissionsProvider>
      </ConnectedIntlProvider>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) => dispatch(actions.auth(username, password)),
  setAuthenticationFromCache: () => dispatch(actions.setAuthenticationFromCache()),
  fetchHealth: () => dispatch(actions.health()),
  isLoggedIn: () => dispatch(actions.isLoggedIn()),
  fetchProjects: () => dispatch(actions.fetchProjects()),
  fetchDynamicTabs: () => dispatch(actions.fetchDynamicTabs()),
  locationPathNameChange: (locationName) => dispatch(actions.locationPathNameChange(locationName)),
  logout: () => dispatch(actions.logout()),
  fetchGlobalSettings: () => dispatch(actions.fetchGlobalSettings()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
