import React from 'react';
import './_spinner.scss';

const Spinner = props => {
  let classes = ['al-spinner-container'];
  classes.push(props.className);
  if (props.show === false) {
    return null;
  }
  let msg = null;
  if (props.message) {
    msg = <div className="al-spinner-message">{props.message}</div>;
  }
  return (
    <div className={classes.join(' ')}>
      <div className="al-spinner" />
      {msg}
      {props.children}
    </div>
  );
};

export default Spinner;
