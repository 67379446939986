//@flow
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '../../hooks/prevValue';
import ExcerptRecognize from '../Review/Issues/Issue/ExcerptRecognize';

type Props = {
  openDocument: (docId: string, excerptId: string) => void,
  hasConvertToUserPermission: boolean,
  hasRecognizeCorrectTrainPermission: boolean,
  hasRecognizeIncorrectTrainPermission: boolean,
};

const AdvancedLearningExcerpt = ({
  excerpt,
  openDocument,
  hasConvertToUserPermission,
  hasRecognizeCorrectTrainPermission,
  hasRecognizeIncorrectTrainPermission,
}: Props) => {
  const isRecognizing = useSelector((state) => state.advancedLearningIssues.isRecognizing);
  const recognizingError = useSelector((state) => state.advancedLearningIssues.recognizingError);
  const issueId = useSelector((state) => state.advancedLearningIssues.issueId);
  const [disabledIssue, setDisabledIssue] = useState(false);
  const prevIsRecognizing = usePrevious(isRecognizing);

  const score = Math.round(excerpt.probability * 100);
  useEffect(() => {
    if (
      prevIsRecognizing &&
      prevIsRecognizing !== isRecognizing &&
      recognizingError &&
      issueId === excerpt.excerptId
    ) {
      setDisabledIssue(false);
    }
    // eslint-disable-next-line
  }, [isRecognizing]);

  return (
    <article key={excerpt.excerptId} className={disabledIssue ? 'disabled-excerpt' : ''}>
      <p
        className={'al-result-content'}
        title="Go to document"
        onClick={() =>
          openDocument(excerpt.docId, excerpt.excerptId, excerpt.excerptType, excerpt.probability)
        }
      >
        {excerpt.bodyHTML}
      </p>
      <div className={'al-explore-entry-type-result'}>
        <span className={'al-current-score'}>{score + '%'}</span>
        <ExcerptRecognize
          excerptId={excerpt.excerptId}
          loading={false}
          disableEventsWhileRecognizing={() => {
            setDisabledIssue(true);
          }}
          hasConvertToUserPermission={hasConvertToUserPermission}
          hasRecognizeCorrectTrainPermission={hasRecognizeCorrectTrainPermission}
          hasRecognizeIncorrectTrainPermission={hasRecognizeIncorrectTrainPermission}
        />
      </div>
    </article>
  );
};

export default AdvancedLearningExcerpt;
