// @flow
import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';
import { DOCUMENT_STATUS, FILE_EXTENSIONS_REGEX } from '../../constants/constants';

export const uploadFileStart = (projectId: string, contractTypeId: string, fileName: string) => ({
  type: actionTypes.UPLOAD_FILE_START,
  fileName,
  projectId,
  contractTypeId,
});

export const uploadFileSuccess = (projectId, contractId, fileName, data) => ({
  type: actionTypes.UPLOAD_FILE_SUCCESS,
  data,
  fileName,
  projectId,
  contractId,
});

export const uploadFileFail = (projectId, contractId, file, error) => {
  return {
    type: actionTypes.UPLOAD_FILE_FAIL,
    error: error,
    file,
    projectId,
    hideErrorModal: true, // avoids showing the modal popup for general errors
  };
};

export const uploadFile = (file, projectId, contractId) => async (dispatch) => {
  await dispatch(uploadFileStart(projectId, contractId, file.name));
  if (!FILE_EXTENSIONS_REGEX.test(file.name)) {
    const error = { message: 'Has invalid file extension', response: { status: null } };
    await dispatch(uploadFileFail(projectId, contractId, file, error));
  } else {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('status', DOCUMENT_STATUS.WORKING);
    formData.append('projectId', projectId);
    axios
      .post(`api/files/${projectId}/${contractId}`, formData)
      .then((response) => {
        dispatch(uploadFileSuccess(projectId, contractId, file.name, response.data));
      })
      .catch((err) => {
        dispatch(uploadFileFail(projectId, contractId, file, err));
      });
  }
};

export const replaceFile = (file, projectId, contractId) => async (dispatch) => {
  await dispatch(uploadFileStart(projectId, contractId, file.name));
  let formData = new FormData();
  formData.append('file', file);
  formData.append('status', DOCUMENT_STATUS.WORKING);
  formData.append('projectId', projectId);
  axios
    .post(`api/files/replace/${projectId}/${contractId}`, formData)
    .then((response) => {
      dispatch(uploadFileSuccess(projectId, contractId, file.name, response.data));
    })
    .catch((err) => {
      dispatch(uploadFileFail(projectId, contractId, file, err));
    });
};

export const resetUpload = () => {
  return {
    type: actionTypes.RESET_UPLOAD_PROCESS,
  };
};
