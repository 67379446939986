import React from 'react';
import { useSelector } from 'react-redux';
import Issue from './Issue/Issue';
import type { Project, Excerpt } from '../../../types';

type Props = {
  project: Project,
  docContractId: string,
  issues: Array<Excerpt>,
  selectedIssue: Excerpt,
  selectIssue: (issue: Excerpt) => void,
  createAction?: () => void,
  hasConvertToUserPermission?: boolean,
  hasRecognizeCorrectTrainPermission?: boolean,
  hasRecognizeIncorrectTrainPermission?: boolean,
  hasTrainingPermission?: boolean,
};

const Issues = ({
  project,
  docContractId,
  issues,
  selectedIssue,
  selectIssue,
  createAction,
  hasConvertToUserPermission,
  hasRecognizeCorrectTrainPermission,
  hasRecognizeIncorrectTrainPermission,
  hasTrainingPermission,
}: Props) => {
  const updatedIssue = useSelector((state) => state.issues.issue);

  const getGlobalConfig = (excerptType, origin) => {
    const contractType = project.contractConfig.find(
      (contractType) => contractType.id === docContractId,
    );
    return contractType
      ? contractType.globalConfig.find(
          (globalConfig) =>
            globalConfig.excerptType === excerptType && globalConfig.origin === origin,
        )
      : [];
  };

  return (
    <div className="al-review-entries-list">
      {issues.map((item) => {
        return (
          <Issue
            key={item.excerptId}
            selectIssue={selectIssue}
            globalConfig={getGlobalConfig(item.excerptType, item.origin)}
            isSelected={selectedIssue ? item.excerptId === selectedIssue.excerptId : false}
            issueId={item.excerptId}
            itemProps={item}
            updatedIssue={updatedIssue}
            createAction={createAction}
            hasConvertToUserPermission={hasConvertToUserPermission}
            hasRecognizeCorrectTrainPermission={hasRecognizeCorrectTrainPermission}
            hasRecognizeIncorrectTrainPermission={hasRecognizeIncorrectTrainPermission}
            hasTrainingPermission={hasTrainingPermission}
          />
        );
      })}
    </div>
  );
};

export default Issues;
