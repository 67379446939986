import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissionSets, deletePermissionSet } from '../../store/actions';
import Button from '../../components/UI/Button/Button';
import type { PermissionSet } from '../../types';
import CreatePermissionSetModal from './CreatePermissionSetModal';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import Warning from '../../components/UI/Typo/Warning';

const ModalTypes = {
  edit: 'edit',
  create: 'create',
  delete: 'delete',
};

const PermissionSets = () => {
  const [modalType, setModalType] = useState('');
  const [permissionSet, setPermissionSet] = useState(null);
  const permissionSets = useSelector((state) => state.permissionSets.permissionSets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermissionSets());
  }, [dispatch]);

  const onCreateNewPermissionSet = () => {
    setModalType(ModalTypes.create);
    let permissionSet: PermissionSet = { id: null, name: '', permissions: [] };
    setPermissionSet(permissionSet);
  };

  const onEditPermissionSet = (permissionSet: PermissionSet) => {
    setModalType(ModalTypes.edit);
    setPermissionSet(permissionSet);
  };

  const onDeletePermissionSet = (permissionSet: PermissionSet) => {
    setModalType(ModalTypes.delete);
    setPermissionSet(permissionSet);
  };

  return (
    <div className={'al-users'} style={{ flex: '0 0 50%' }}>
      <div className={'al-users-workspace'}>
        <span />
        <span />
        {/*<span>Filter permission sets</span>*/}
        {/*<Input elementType={'input'} elementConfig={{ type: 'text' }} />*/}
        <span />
        <Button show={true} className={'al-primary'} clicked={onCreateNewPermissionSet}>
          Add new permission set
        </Button>
      </div>
      <div className="al-users-list" style={{ height: '220px' }}>
        <div className="al-users-list-header">
          <span>Permission set name</span>
          <span>Permissions</span>
          <span></span>
        </div>
        <div className="al-users-list-wrapper">
          {permissionSets.map((ps: PermissionSet) => {
            return (
              <div key={ps.id} className="al-users-list-item">
                <span>{ps.name}</span>
                <span>{ps.permissions ? ps.permissions.join(', ') : ''}</span>
                <span>{''}</span>
                <span className="al-users-list-item-actions">
                  <Button
                    withIcon={true}
                    iconType={'edit'}
                    clicked={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onEditPermissionSet(ps);
                    }}
                  />

                  <Button
                    withIcon={true}
                    className={'deleteProject'}
                    iconType={'delete'}
                    clicked={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onDeletePermissionSet(ps);
                    }}
                  />
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {modalType === ModalTypes.create && (
        <CreatePermissionSetModal
          title={'Create new permission set'}
          permissionSet={permissionSet}
          allPermissionSets={permissionSets}
          onClose={() => setModalType('')}
        />
      )}
      {modalType === ModalTypes.edit && (
        <CreatePermissionSetModal
          title={'Edit permission set'}
          permissionSet={permissionSet}
          allPermissionSets={permissionSets}
          onClose={() => setModalType('')}
        />
      )}
      {modalType === ModalTypes.delete && (
        <Modal show={true}>
          <ModalHeader title={'Delete permission set'} onClose={() => setModalType('')} />
          <ModalContent>
            <Warning>{`Are you sure you want to delete permission set ${permissionSet.name}?`}</Warning>
          </ModalContent>
          <ModalFooter>
            <Button
              className={'al-primary'}
              clicked={() => {
                dispatch(deletePermissionSet(permissionSet));
                setModalType('');
              }}
            >
              Delete
            </Button>
            <Button className={'al-button'} clicked={() => setModalType('')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default PermissionSets;
