// @flow
import React, { Component, createRef } from 'react';
import rangy from 'rangy';
import 'rangy/lib/rangy-selectionsaverestore';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-classapplier';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-serializer';
import _ from 'lodash';
import validate from '../../../services/validation';
import type { Excerpt, ReactRefT, SectionPart } from '../../../types';
import type { Document as IDocument, Section } from '../../../types/reduxTypes';
import DocumentContextMenu from './DocumentContextMenu';
import {
  buildSelectedSectionParts,
  clearIssues,
  clearSelection,
  getPosition,
  NEW_CLASS,
  selectIssueInSection,
  unwrap,
  wrapSelectionWithSpan,
} from './documentUtils';
import { ORIGIN } from '../../../constants/constants';

type Props = {
  issues: Array<Excerpt>,
  controls: any,
  show: boolean,
  document: IDocument,
  searchTerm: string,
  onSelectText: (text: string) => void,
  pageNumber?: number,
  selectedIssue: Excerpt,
  textContent?: Array<string>,
  globalOffset?: number,
  onRef: Function,
  marker: any,
  createIssue: (
    excerptType: string,
    sectionParts: Array<SectionPart>,
    selectedText: string,
    excerptUsedForTraining: boolean,
  ) => void,
  deleteIssue: (issue: Excerpt) => void,
  recognizeIssue: (excerptId: string, someFlag: boolean) => void,
  selectIssue: (issue: Excerpt) => void,
  globalConfigsTypes: Array<Object>,
  hasCreateWithTrainingPermission: boolean,
};

type State = {
  issues?: Array<Excerpt>,
  excerptType: string,
  excerptId: string,
  excerptUsedForTraining: boolean,
  editMode?: boolean,
  issuesTypes?: Object,
  mode: {
    editSystem?: boolean,
    editRange?: any,
    edit: boolean,
    insert: boolean,
    invalidSelection: boolean,
  },
  contextMenu: {
    show: boolean,
    position: {
      top: number,
      left: number,
    },
  },
};

class DocumentSections extends Component<Props, State> {
  container: ReactRefT<HTMLDivElement>;
  inputRules: any;
  initialState: State;
  selectedSectionParts: Array<SectionPart>;
  sectionRefs = {};
  keyPressMap: Object;
  selectedText: string | void;
  delayedSearch: Function;
  searchResultApplier: Function;
  isTextSelected: boolean;

  static defaultProps = {
    onSelectText: () => {},
  };

  constructor(props: Props) {
    super(props);
    this.container = React.createRef();
    for (let section of this.props.document.sections) {
      this.sectionRefs[section.id] = createRef();
    }
    this.initialState = {
      mode: {
        editSystem: false,
        edit: false,
        insert: false,
        invalidSelection: false,
      },
      contextMenu: {
        show: false,
        position: {
          top: 0,
          left: 0,
        },
      },
      issuesTypes: this.getActions(),
      excerptType: '',
      excerptId: '',
      excerptUsedForTraining: this.props.hasCreateWithTrainingPermission,
      issues: this.props.issues || [],
    };

    this.state = { ...this.initialState };
    this.inputRules = {
      required: true,
    };
    this.selectedSectionParts = [];
    this.selectedText = '';
    this.delayedSearch = _.debounce(() => {
      this.highlightSearch();
    }, 1000);

    this.keyPressMap = {
      Enter: this.createIssue,
      Escape: this.resetDocumentMode,
    };
  }

  isValid = () => validate(this.state.excerptType, this.inputRules);

  highlightSearch = (searchTerm?: string) => {
    try {
      searchTerm = this.props.searchTerm;
      if (!this.searchResultApplier) {
        this.searchResultApplier = rangy.createClassApplier('al-search-term-selection');
      }
      // Remove existing highlights
      let range = rangy.createRange();
      let caseSensitive = false;
      let searchScopeRange = rangy.createRange();
      searchScopeRange.selectNodeContents(document.querySelector('.al-review-document-content'));
      let options = {
        caseSensitive: caseSensitive,
        wholeWordsOnly: false,
        withinRange: searchScopeRange,
        direction: 'forward', // This is redundant because "forward" is the default
      };
      range.selectNodeContents(document.body);
      this.searchResultApplier.undoToRange(range);
      // Create search term
      if (searchTerm !== '') {
        let query = new RegExp('(' + searchTerm + ')', 'gim');
        // Iterate over matches
        while (range.findText(query, options)) {
          // range now encompasses the first text match
          this.searchResultApplier.applyToRange(range);
          // Collapse the range to the position immediately after the match
          range.collapse(false);
        }
      }
    } catch (e) {
      console.warn("can't select text", e);
    }
  };

  handleIssueClick = (event: SyntheticMouseEvent<HTMLElement>) => {
    if (event.target.dataset.excerptid && event.target.dataset.origin !== ORIGIN.ADVANCED) {
      event.stopPropagation();
      event.preventDefault();
      if (!this.isTextSelected) {
        clearSelection(this.container.current);
        const excerptId = this.getOuterParentId(event);
        let selectedIssue = this.props.issues.find((issue) => issue.excerptId === excerptId);
        this.props.selectIssue(selectedIssue);
      }
    }
  };

  getOuterParentId = (event) => {
    let element = event.target;
    while (element.parentNode.getAttribute('data-excerptId')) {
      element = element.parentNode;
    }
    return element.getAttribute('data-excerptId');
  };

  handleSelectionChange = (event: SyntheticMouseEvent<HTMLElement>) => {
    if (!this.state.contextMenu.show) {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }
    this.handleIssueClick(event);
    this.isTextSelected = false;
    clearSelection(this.container.current);
    let wrapper = wrapSelectionWithSpan(this.props.marker, this.container.current);
    if (!wrapper) {
      return;
    }
    let selectedText = wrapper.innerText;
    if (selectedText && selectedText.length) {
      this.isTextSelected = true;
    }
    let spans = this.container.current.getElementsByClassName(NEW_CLASS);
    if (!spans.length) {
      this.resetDocumentMode();
      return;
    }
    this.selectedSectionParts = buildSelectedSectionParts(spans, this.sectionRefs);
    this.selectedText = selectedText;
    if (!this.state.mode.editRange) {
      this.enableInsertMode(event);
    } else {
      this.setState({
        contextMenu: {
          show: true,
          position: getPosition(event),
        },
      });
    }
    let range = rangy.createRange();
    range.setStartBefore(spans[0]);
    range.setEndAfter(spans[spans.length - 1]);
    let sel = rangy.getSelection();
    sel.setSingleRange(range);
    let selection = rangy.saveSelection();
    this.setSelection(selection);
  };

  isEmptyFinding = () => {
    return validate(this.state.excerptType, { required: true });
  };

  toggleExcerptUsedForTraining = () => {
    this.setState({ excerptUsedForTraining: !this.state.excerptUsedForTraining });
  };

  inputChangeHandler = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ excerptType: event.target.value });
    this.filterTypes(event.target.value);
  };

  createIssue = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // just needed if the creation is done by a MouseEvent while clicking on the button
    if (event.nativeEvent) {
      event.nativeEvent.stopPropagation();
    }
    const { mode, excerptType, excerptUsedForTraining } = this.state;
    const { selectedSectionParts, selectedText } = this;
    if (!this.isValid()) {
      return;
    }
    if (mode.insert && selectedText) {
      this.props.createIssue(
        excerptType,
        selectedSectionParts,
        selectedText,
        excerptUsedForTraining,
      );
    }
    this.resetDocumentMode();
  };

  toggleDropDown = () => {
    this.setState({
      contextMenu: {
        show: !this.state.contextMenu.show,
        position: this.state.contextMenu.position,
      },
    });
  };

  deleteIssue = () => {
    this.props.deleteIssue(this.state.excerptId);
    this.resetDocumentMode();
  };

  markAsCorrect = () => {
    this.props.recognizeIssue(this.state.excerptId, true);
    this.resetDocumentMode();
  };

  selectType = (e: any, key: string, label: string) => {
    this.setState({ excerptType: label });
  };

  renderSections = (sections: Array<Section>): any =>
    sections.map(({ body, id }: Section) => (
      <div key={id}>
        <p
          id={id}
          ref={this.sectionRefs[id]}
          className={'section'}
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    ));

  resetDocumentMode = () => {
    this.setState({
      contextMenu: this.initialState.contextMenu,
      mode: this.initialState.mode,
      excerptId: this.initialState.excerptId,
      excerptType: this.initialState.excerptType,
      excerptUsedForTraining: this.props.hasCreateWithTrainingPermission,
    });
  };

  enableInsertMode(event: SyntheticMouseEvent<HTMLElement>) {
    this.setState({
      contextMenu: {
        show: true,
        position: getPosition(event),
      },
      mode: {
        edit: false,
        insert: true,
        invalidSelection: false,
        editRange: false,
      },
      issuesTypes: this.getActions(),
    });
  }

  getActions = (): Object => {
    let actions = {};
    for (let type of this.props.globalConfigsTypes) {
      actions[type] = {
        key: type,
        icon: '',
        label: type,
        click: this.selectType,
      };
    }
    return actions;
  };

  filterTypes = (filter: string) => {
    let filteredIssuesTypes = _.chain(this.getActions())
      .filter((item) => {
        return item.key.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      })
      .sortBy((item) => item.key.toLowerCase())
      .value();
    this.setState({ issuesTypes: filteredIssuesTypes });
  };

  scrollToSectionById = (sectionId: string) => {
    setTimeout(() => {
      try {
        this.sectionRefs[sectionId].current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      } catch (e) {
        console.error(`can't scroll to section ${sectionId} `, e.message);
      }
    }, 200);
  };

  selectIssues = (issues: Array<Excerpt>) => {
    let container = this.container.current;
    for (let issue of issues) {
      this.selectIssueInDocument(issue.contextIds, issue);
      for (let el of container.querySelectorAll(`span[data-excerptId="${issue.excerptId}"]`)) {
        el.setAttribute('hover', true);
      }
    }
  };

  unSelectIssueById = (issueId) => {
    for (let el of this.container.current.querySelectorAll(`span[data-excerptId="${issueId}"]`)) {
      unwrap(el);
    }
  };

  markIssue = (issueId) => {
    let container = this.container.current;
    for (let el of container.querySelectorAll(`span[data-excerptId="${issueId}"]`)) {
      el.classList.add('al-selected');
    }
  };

  unmarkIssue = (issueId) => {
    let container = this.container.current;
    for (let el of container.querySelectorAll(`span[data-excerptId= "${issueId}"]`)) {
      el.classList.remove('al-selected');
    }
  };

  selectIssueInDocument = (selectedSections: Array<Section>, issue: Excerpt) => {
    for (let section of selectedSections) {
      try {
        selectIssueInSection(
          this.sectionRefs[section.id].current,
          section.startOffset,
          section.endOffset,
          issue,
          this.container.current,
        );
      } catch (e) {
        console.error(`can't select section  ${section.id} for issue`, issue);
      }
    }
  };

  setSelection(selection) {
    this.selection = selection;
  }

  getSelection() {
    return this.selection;
  }

  onCopy = (event) => {
    if (this.selectedText.length && !this.state.excerptType.length) {
      let sel = this.getSelection();
      rangy.restoreSelection(sel);
    }
  };

  handleClickOutside = (event) => {
    this.isTextSelected = false;
    this.selectedText = '';
    clearSelection(this.container.current);
    this.setState({
      contextMenu: {
        show: !this.state.contextMenu.show,
        position: this.state.contextMenu.position,
      },
    });
  };

  componentDidMount() {
    this.props.onRef(this);
    this.selectIssues(this.props.issues);
    this.highlightSearch(this.props.searchTerm);
    window.addEventListener('copy', this.onCopy);
    //document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.selectedIssue) {
      if (this.props.selectedIssue.origin === ORIGIN.ADVANCED) {
        this.selectIssueInDocument(this.props.selectedIssue.contextIds, this.props.selectedIssue);
      }
      this.markIssue(this.props.selectedIssue.excerptId);
      this.scrollToSectionById(this.props.selectedIssue.contextIds[0].id);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State, prevContext: any) {
    if (
      JSON.stringify(this.props.issues) !== JSON.stringify(this.state.issues) &&
      !(this.state.mode.edit || this.state.mode.insert)
    ) {
      clearIssues(this.container.current);
      this.selectIssues(this.props.issues);
      if (this.props.selectedIssue) {
        if (this.props.selectedIssue.origin === ORIGIN.ADVANCED) {
          this.selectIssueInDocument(this.props.selectedIssue.contextIds, this.props.selectedIssue);
        }
        this.markIssue(this.props.selectedIssue.excerptId);
      }
      this.setState({ issues: this.props.issues });
    } else if (this.props.selectedIssue) {
      if (!prevProps.selectedIssue) {
        if (this.props.selectedIssue.origin === ORIGIN.ADVANCED) {
          this.selectIssueInDocument(this.props.selectedIssue.contextIds, this.props.selectedIssue);
        }
        this.markIssue(this.props.selectedIssue.excerptId);
        this.scrollToSectionById(this.props.selectedIssue.contextIds[0].id);
      }
      if (
        prevProps.selectedIssue &&
        prevProps.selectedIssue.excerptId !== this.props.selectedIssue.excerptId
      ) {
        if (prevProps.selectedIssue.origin === ORIGIN.ADVANCED) {
          // check if the excerpt still advanced and hasn't changed to user excerpt on recognizing it as correct.
          if (
            this.props.issues.filter(
              (excerpt) =>
                excerpt.excerptId === prevProps.selectedIssue.excerptId &&
                excerpt.origin === ORIGIN.ADVANCED,
            ).length
          ) {
            this.unSelectIssueById(prevProps.selectedIssue.excerptId);
          }
        }
        if (this.props.selectedIssue.origin === ORIGIN.ADVANCED) {
          this.selectIssueInDocument(this.props.selectedIssue.contextIds, this.props.selectedIssue);
        }
        this.unmarkIssue(prevProps.selectedIssue.excerptId);
        this.markIssue(this.props.selectedIssue.excerptId);
        this.scrollToSectionById(this.props.selectedIssue.contextIds[0].id);
      }
    }

    if (prevProps.searchTerm !== this.props.searchTerm) {
      this.delayedSearch();
    }
  }

  render() {
    const { document } = this.props;
    const { contextMenu, mode, issuesTypes, excerptType, editMode } = this.state;
    let classes = ['al-review-document-content'];
    if (editMode) {
      classes.push('edit-mode');
    }
    const className = classes.join(' ');

    return (
      <div
        className={className}
        ref={this.container}
        onClick={this.handleSelectionChange}
        //onMouseUp={this.handleSelectionChange}
      >
        <div>{this.renderSections(document.sections)}</div>
        <DocumentContextMenu
          mode={mode}
          keyPressMap={this.keyPressMap}
          show={contextMenu.show && mode.insert}
          left={contextMenu.position.left}
          top={contextMenu.position.top}
          issuesTypes={issuesTypes}
          excerptType={excerptType}
          inputChangeHandler={this.inputChangeHandler}
          isEmptyFinding={this.isEmptyFinding()}
          valid={this.isValid()}
          toggleDropDown={this.handleClickOutside}
          createIssue={this.createIssue}
          excerptUsedForTraining={this.state.excerptUsedForTraining}
          toggleExcerptUsedForTraining={this.toggleExcerptUsedForTraining}
          hasCreateWithTrainingPermission={this.props.hasCreateWithTrainingPermission}
        />
      </div>
    );
  }
}

export default DocumentSections;
