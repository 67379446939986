import React, { useCallback, useEffect, useState } from 'react';
import * as actions from '../../store/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import type { User } from '../../types';
import Button from '../../components/UI/Button/Button';
import CreateUserModal from './CreateUserModal';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import Warning from '../../components/UI/Typo/Warning';
import _ from 'lodash';

const ModalTypes = {
  edit: 'edit',
  create: 'create',
  delete: 'delete',
};

const Users = () => {
  const { users, roles, projects } = useSelector(
    (state) => ({
      users: state.user.users,
      roles: state.roles.roles,
      projects: [...state.project.projects.values()],
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const deleteUser = (user: User) => dispatch(actions.deleteUser(user));
  const fetchUsers = useCallback(() => dispatch(actions.fetchUsers()), [dispatch]);
  const fetchRoles = useCallback(() => dispatch(actions.fetchRoles()), [dispatch]);
  const [modalType, setModalType] = useState('');
  const [user, setUser] = useState(null);
  const userListSortedByName = _.sortBy(users, function (u) {
    return u.username.toLowerCase();
  });

  useEffect(() => {
    fetchUsers();
    fetchRoles();
  }, [fetchUsers, fetchRoles]);

  const onCreateNewUser = () => {
    setModalType(ModalTypes.create);
    let user: User = { id: null, roles: [], username: '', allProjectsAccess: false };
    setUser(user);
  };

  const onEditUser = (user: User) => {
    setModalType(ModalTypes.edit);
    setUser(user);
  };

  const onDeleteUser = (user: User) => {
    setModalType(ModalTypes.delete);
    setUser(user);
  };

  return (
    <div className={'al-container al-users'}>
      <div className={'al-headline'}>{'User administration'}</div>
      <div className={'al-description-text'}>
        Add, update or delete users and assign roles to them
      </div>
      <div className={'al-users-workspace'}>
        <Button show={true} className={'al-primary'} clicked={onCreateNewUser}>
          Add new user
        </Button>
      </div>
      <div className="al-users-list">
        <div className="al-users-list-header">
          <span>Username</span>
          <span>Assigned roles</span>
          <span>Assigned projects</span>
        </div>
        {userListSortedByName.map((usr: User) => {
          return (
            <div key={usr.id} className="al-users-list-item">
              <span>{usr.username}</span>
              <span>
                {usr.roles
                  ? roles
                      .filter((r) => usr.roles.indexOf(r.id) > -1)
                      .map((r) => r.name)
                      .join(', ')
                  : ''}
              </span>
              <span>
                {usr.allProjectsAccess
                  ? 'all'
                  : usr.projectIds
                  ? projects
                      .filter((r) => usr.projectIds.indexOf(r.id) > -1)
                      .map((r) => r.projectType)
                      .join(', ')
                  : ''}
              </span>
              <span className="al-users-list-item-actions">
                <Button
                  withIcon={true}
                  iconType={'edit'}
                  clicked={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onEditUser(usr);
                  }}
                />

                <Button
                  withIcon={true}
                  className={'deleteProject'}
                  iconType={'delete'}
                  clicked={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onDeleteUser(usr);
                  }}
                />
              </span>
            </div>
          );
        })}
      </div>
      {modalType === ModalTypes.create && (
        <CreateUserModal
          onClose={() => setModalType('')}
          title={'Create new user'}
          user={user}
          users={userListSortedByName}
        />
      )}
      {modalType === ModalTypes.edit && (
        <CreateUserModal
          title={'Edit User'}
          user={user}
          onClose={() => setModalType('')}
          users={userListSortedByName}
        />
      )}
      {modalType === ModalTypes.delete && (
        <Modal show={true}>
          <ModalHeader title={'Delete user'} onClose={() => setModalType('')} />
          <ModalContent>
            <Warning>{`Are you sure you want to delete user ${user.username}?`}</Warning>
          </ModalContent>
          <ModalFooter>
            <Button
              className={'al-primary'}
              clicked={() => {
                deleteUser(user);
                setModalType('');
              }}
            >
              Delete
            </Button>
            <Button className={'al-button'} clicked={() => setModalType('')}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default Users;
