import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { usePrevious } from '../../hooks/prevValue';
import { selectProject, updateDocumentsContractId, fetchDocumentsBy } from '../../store/actions';
import Button from '../../components/UI/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '../../components/UI/Modal/Modal';
import Warning from '../../components/UI/Typo/Warning';
import Select from '../../components/UI/Select/Select';
import { auth } from '../../store/actions';

const BrowseAdvanced = () => {
  const dispatch = useDispatch();
  const didMount = useRef(false);
  const [error, setError] = useState(window.location.pathname.includes('error'));
  const [projectState, setProjectState] = useState(null);
  const [newContractId, setNewContractId] = useState('');
  const [newContractName, setNewContractName] = useState('');
  const [movingDocsNames, setMovingDocsNames] = useState({});
  const [stayingDocsNames, setStayingDocsNames] = useState({});
  const [docsContracts, setDocsContracts] = useState(new Map());
  const { iFinderJwt, projects, projectId, documents } = useSelector(
    (state) => ({
      iFinderJwt: state.auth.iFinderJwt,
      projects: state.project.projects,
      projectId: state.project.projectId,
      documents: state.document.documents,
    }),
    shallowEqual,
  );
  const prevProjectId = usePrevious(projectId);
  const documentsRef = useRef(documents);
  documentsRef.current = documents;

  const config = { childList: true };

  /* This observer inits the config source of the latest selected project and updates the app's selected project */
  const onLoadDOMSearchBar = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.addedNodes.length) {
        // sync search-profile's selection with app's selected project
        window.IFS.jQuery('.ifs-dropdown-sources li').click((e) => {
          const profileName = e.target.innerText;
          const project = getProject(profileName);
          setProjectState(project);
          if (project) {
            dispatch(selectProject(project.id));
          }
        });
        syncConfigSource();
      }
    }
  };
  const observerSearchBar = new MutationObserver(onLoadDOMSearchBar);

  /* This observer waits for the DOM to load the results' list to add event listeners and dropdown for moving docs */
  const onLoadDOMResultList = (mutationsList) => {
    localStorage.setItem('deeplinkSearch', window.location.search);

    for (const mutation of mutationsList) {
      if (mutation.addedNodes.length) {
        drawMoveDocsSelect();
        window.IFS.jQuery('#moveDocsSelectSelector').hide();
        addOnCheckboxClickListener();
        window.IFS.jQuery('.ifs-btn-loadmore').click(() => {
          /* add event listeners to new DOM elems to be created */
          const hitListNode = document.getElementsByClassName('ifs-hitlist')[0];
          observerHitList.observe(hitListNode, config);
        });
      }
    }
  };
  const observerResultList = new MutationObserver(onLoadDOMResultList);

  /* This observer waits for the DOM to 'LOAD MORE RESULTS' to add listeners to the checkboxes of documents */
  const onLoadDOMHitList = (mutationsList) => {
    for (const mutation of mutationsList) {
      if (mutation.addedNodes.length) {
        addOnCheckboxClickListener();
      }
    }
  };
  const observerHitList = new MutationObserver(onLoadDOMHitList);

  const syncConfigSource = () => {
    const project = projects.get(projectId);
    if (project) {
      try {
        initConfigSource(project);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addOnCheckboxClickListener = () => {
    window.IFS.jQuery('.ifs-hitlist-checkbox, .ifs-checkbox-mark-all').click(() => {
      setTimeout(() => {
        window.IFS.jQuery('.ifs-icon-checkbox-checked').length > 0
          ? window.IFS.jQuery('#moveDocsSelectSelector').show()
          : window.IFS.jQuery('#moveDocsSelectSelector').hide();
      }, 100);
    });
  };

  const observeOnLoadSearchBar = () => {
    const projectSearchBarNode = document.getElementById('ifs-searchbar');
    observerSearchBar.observe(projectSearchBarNode, config);
  };

  const observeOnLoadResultListNode = () => {
    const projectResultListNode = document.getElementById('ifs-resultlist');
    observerResultList.observe(projectResultListNode, config);
  };

  useEffect(() => {
    dispatch(auth(localStorage.getItem('username'), localStorage.getItem('password')));
    return () => {
      destroy();
    };
    // eslint-disable-next-line
  }, []);

  const destroy = () => {
    window.IFS.destroyClient();
    observerSearchBar.disconnect();
    observerResultList.disconnect();
    observerHitList.disconnect();
  };

  useEffect(() => {
    if (didMount.current) {
      if (window.IFS.jwtToken !== iFinderJwt) {
        if (window.IFS) {
          destroy();
        }
        window.IFS.jwtToken = iFinderJwt;
        initClient(iFinderJwt);
      }
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line
  }, [iFinderJwt]);

  useEffect(() => {
    if (projects.size > 0) {
      observeOnLoadSearchBar();
      observeOnLoadResultListNode();
    }
    // eslint-disable-next-line
  }, [projects]);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchDocumentsBy(projectId));

      if (prevProjectId && projectId !== prevProjectId) {
        syncConfigSource();
        observeOnLoadResultListNode();
      }
    }
    // eslint-disable-next-line
  }, [projectId]);

  useEffect(() => {
    /* selecting 'Global' in search-profile's dropdown leads to projectState = null */
    if (projectState) {
      observeOnLoadResultListNode();
    }
    // eslint-disable-next-line
  }, [projectState]);

  const initClient = (iFinderJwt) => {
    window.IFS.initClient({
      sbTarget: '#ifs-searchbar',
      rlTarget: '#ifs-resultlist',
      configurl: '/data/config.json',
      sitesearch: false,
      cors: true,
      withCredentials: true,
      appName: 'searchbar',
      callbacks: {
        persistentState: true,
        statusCodeHandler: {
          403: {
            action: function (ajaxOptions) {
              window.IFS.eventbus.dispatch('show-status-code-info', {
                containerSelector: '.ifs-main',
                emptyContainer: true,
                templateData: {
                  title: 'Usage forbidden',
                  message:
                    'Usage is either forbidden for current user or there is no valid searchprofile. Please contact your system administrator.',
                  link: '.',
                  linkText: 'Continue searching',
                },
              });
              console.log(ajaxOptions.getAllResponseHeaders());
              console.log(ajaxOptions.responseJSON);
            },
            clearStorage: true,
          },
        },
        jwtTokenProvider: function () {
          return iFinderJwt;
        },
      },
    });
    observeOnLoadSearchBar();
    observeOnLoadResultListNode();
  };

  const initConfigSource = (project) => {
    window.IFS.configs.clientOptions.searchConfig.results.forEach((config) => {
      if ('sources' in config) {
        config['sources'].forEach((source) => {
          const sourceName = source.name.trim().toLowerCase();
          const projectName = project.projectType.toLowerCase();
          if (projectName === sourceName) {
            /// This Selects the profile
            let appState = window.IFS.$.ifs.appState.state;
            appState.currentSearchProfile._name = source.name;
            appState.currentSearchProfile._id = source.value;
            appState.currentSourceId = source.value;

            let label = document.getElementById('ifs-current-sources');
            label.innerHTML = source.name;
            label.setAttribute('data-value', source.value);

            document.getElementById('ifs-perform-search').click();
            ///
          }
        });
      }
    });
    observerSearchBar.disconnect();
  };

  const drawMoveDocsSelect = () => {
    const profileName = window.IFS.$.ifs.appState.state.currentSearchProfile._name
      .trim()
      .toLowerCase();
    const project = getProject(profileName);

    if (project) {
      let selectorElement = (
        <Select
          id="moveDocsSelectSelector"
          show={true}
          label={'Allocate to...'}
          options={project.contractConfig.map((config) => ({
            key: config.id,
            value:
              config.contractType === 'Others'
                ? 'Unassigned'
                : config.contractType.charAt(0).toUpperCase() + config.contractType.slice(1),
            active: false,
          }))}
          onSelect={(contract) => {
            showModalMoveDocsContractId(contract.key, contract.value);
          }}
        />
      );
      if (document.getElementById('moveDocsSelect')) {
        ReactDOM.hydrate(selectorElement, document.getElementById('moveDocsSelect'));
      }
    }
  };

  const getProject = (profileName) => {
    let project = null;
    for (const proj of projects) {
      if (proj[1].projectType.toLowerCase() === profileName.toLowerCase()) {
        project = proj[1];
      }
    }
    return project;
  };

  const showModalMoveDocsContractId = (selectedContractId, contractName) => {
    let docsContracts = new Map();
    let newMovingDocsNames = {};
    let newStayingDocsNames = {};
    window.IFS.jQuery('.ifs-checked').each(function () {
      const selectedDocId = window.IFS.jQuery(this).data('doc-id');
      for (const doc of documentsRef.current) {
        if (selectedDocId === doc.id) {
          if (doc.contractId !== selectedContractId) {
            docsContracts.set(selectedDocId, selectedContractId);
            newMovingDocsNames[selectedDocId] = doc.title;
          } else {
            newStayingDocsNames[selectedDocId] = doc.title;
          }
        }
      }
    });
    setNewContractId(selectedContractId);
    setNewContractName(contractName);
    setDocsContracts(docsContracts);
    setMovingDocsNames(newMovingDocsNames);
    setStayingDocsNames(newStayingDocsNames);
  };

  const hideModal = () => {
    setNewContractId('');
    setNewContractName('');
  };

  const moveDocumentsContractId = () => {
    dispatch(updateDocumentsContractId(docsContracts, projectId));

    /* Clear selection */
    setDocsContracts(new Map());
    setMovingDocsNames({});
    setStayingDocsNames({});
    document.querySelectorAll('.ifs-checked').forEach((node) => {
      node.classList.remove('ifs-checked');
    });
    document.querySelectorAll('i.ifs-icon-checkbox-checked').forEach((checkbox) => {
      checkbox.className = 'ifs-icons ifs-icon-checkbox';
    });
    window.IFS.jQuery('#moveDocsSelectSelector').hide();
  };

  const isDocsToMove = docsContracts.size > 0;
  const stayingDocsLength = Object.keys(stayingDocsNames).length;

  return (
    <div className={'al-container al-search'} style={{ width: '100%' }}>
      {error ? (
        <div className="ifs-searchareas">
          There was an error, please{' '}
          <button onClick={() => window.location.reload()}>reload</button> the page.
        </div>
      ) : (
        <div className="ifs-main" style={{ display: 'block' }}>
          <div id="ifs-searchareas" className="ifs-searchareas" />
          <div id="ifs-searchbar" className="ifs-searchbar" data-comp-id="ifs-searchbar" />
          <div id="ifs-resultlist" />
        </div>
      )}
      <Modal show={newContractId}>
        <ModalHeader
          title={isDocsToMove ? `Warning` : `Reallocate documents`}
          onClose={hideModal}
        />
        <ModalContent
          contentStyle={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
        >
          {isDocsToMove ? (
            <>
              <Warning>
                Allocating documents to another document type will delete all user annotations and
                advanced learning findings.
                <br />
                Do you really want to allocate the selected documents?
              </Warning>

              <div className="documents-moving-wrapper">
                <div className="documents-wrapper">
                  <div className="doc-list-title">
                    <b>{docsContracts.size}</b>&nbsp;
                    {`document${docsContracts.size > 1 ? 's' : ''} will be moved:`}
                  </div>
                  <div className="single-document-wrapper">
                    {Object.values(movingDocsNames).map((docName, index) => {
                      return (
                        <div key={index} className="single-document">
                          <span className="doc-title">{docName}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="documents-wrapper">
                  {stayingDocsLength > 0 ? (
                    <>
                      <div className="doc-list-title">{`These documents already belong to the document type '${newContractName}':`}</div>
                      <div className="single-document-wrapper">
                        {Object.values(stayingDocsNames).map((docName, index) => {
                          return (
                            <div key={index} className="single-document">
                              <span className="doc-title">{docName}</span>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <div>
              {`The selected document${stayingDocsLength > 1 ? 's' : ''} already 
                belong${
                  stayingDocsLength > 1 ? '' : 's'
                } to the document type '${newContractName}'.`}
            </div>
          )}
        </ModalContent>
        <ModalFooter>
          {isDocsToMove ? (
            <>
              <Button
                className={'al-primary'}
                clicked={() => {
                  moveDocumentsContractId();
                  hideModal();
                }}
              >
                Ok
              </Button>
              <Button clicked={hideModal}>Cancel</Button>
            </>
          ) : (
            <Button clicked={hideModal}>Close</Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BrowseAdvanced;
