import _ from 'lodash';

export const getDifferencesBetweenRemoteAndLocalById = function(remoteList, localList, id) {
  let added = _.differenceBy(remoteList, localList, id);
  let deleted = _.differenceBy(localList, remoteList, id);
  let intersection = _.intersectionBy(remoteList, localList, id);
  let edited = _.differenceWith(intersection, localList, _.isEqual);
  return {
    added: added,
    deleted: deleted,
    edited: edited,
  };
};

export const mergeLocalWithDiffByLastModifiedDate = function(updatedIssues, diff) {
  if (diff.added.length > 0) {
    updatedIssues = updatedIssues.concat(diff.added);
  }
  if (diff.deleted.length > 0) {
    updatedIssues = _.differenceBy(updatedIssues, diff.deleted, 'excerptId');
  }
  if (diff.edited.length > 0) {
    for (let updatedIssue of diff.edited) {
      let index = _.findIndex(updatedIssues, {
        excerptId: updatedIssue.excerptId,
      });
      if (updatedIssue.dateLastModified > updatedIssues[index].dateLastModified) {
        updatedIssues.splice(index, 1, updatedIssue);
      }
    }
  }
  return updatedIssues;
};
