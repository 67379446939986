import { axiosInstance as axios } from '../../services/axiosInstance';
import * as actionTypes from './actionType';

export const healthStart = () => {
  return {
    type: actionTypes.HEALTH_START,
  };
};

export const healthSuccess = () => {
  return {
    type: actionTypes.HEALTH_SUCCESS,
  };
};

export const healthFail = error => {
  return {
    type: actionTypes.HEALTH_FAIL,
    error: error,
  };
};

export const health = () => {
  return dispatch => {
    dispatch(healthStart());
    axios
      .get('/api/elasticsearch-health')
      .then(response => {
        dispatch(healthSuccess());
      })
      .catch(err => {
        dispatch(healthFail());
      });
  };
};
