import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FileHeader from './FileHeader';
import Spinner from '../UI/Spinner/Spinner';
import type { Excerpt } from '../../types';

type Props = {
  filteredExcerpts: Array<Excerpt>,
  docId: string,
  openReviewDocument: () => void,
  statisticByType: { count: number, docTitle: string, excerpts: Array<Excerpt>, expanded: boolean },
  date: Date | '',
  threshold: number,
  fetchExcerptsList: () => void,
};

const File = (props: Props) => {
  const [showExcerpts, setShowExcerpts] = useState(props.statisticByType.expanded);
  const loadingExcerptsDocId = useSelector(
    (state) => state.statistics.statisticsByType.loadingExcerptsDocId,
  );

  useEffect(() => {
    setShowExcerpts(props.statisticByType.expanded);
  }, [props.statisticByType.expanded]);

  return (
    <div className="al-inspect-result-group">
      <FileHeader
        docId={props.docId}
        openReviewDocument={props.openReviewDocument}
        statisticByType={props.statisticByType}
        fetchExcerptsList={props.fetchExcerptsList}
        showExcerpts={showExcerpts}
        setShowExcerpts={setShowExcerpts}
        date={props.date}
        filteredExcerptsLength={props.filteredExcerpts.length}
      />
      <span>
        {showExcerpts ? (
          loadingExcerptsDocId === props.docId ? (
            <Spinner />
          ) : props.filteredExcerpts.length > 0 ? (
            props.filteredExcerpts.map((excerpt, index) => {
              return React.cloneElement(props.children, {
                key: index,
                excerpt: excerpt,
              });
            })
          ) : typeof props.threshold !== 'undefined' ? (
            <p className={'no-result-text'}>
              {`No advanced learning results with at least ${props.threshold}% score found.`}
            </p>
          ) : (
            <p className={'no-result-text'}>There are no excerpts with the selected annotation.</p>
          )
        ) : null}
      </span>
    </div>
  );
};

export default File;
