import React, { Component } from 'react';

class Tabs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const childrenWithProps = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child),
    );
    return <div className="al-tab"> {childrenWithProps} </div>;
  }
}

export default Tabs;
