import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createComment } from '../../../store/actions';

import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../components/UI/Modal/Modal';
import Form from '../../../components/UI/Form/Form';
import Button from '../../../components/UI/Button/Button';
import Comments from '../../../containers/Review/Comments/Comments';
import validate from '../../../services/validation';

type Props = {
  docId: string,
  onClose: () => void,
  show: boolean,
  title?: string,
  issueId?: string,
};

const CommentsModal = ({ docId, onClose, show, title, issueId }: Props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.comments.loading);
  const error = useSelector((state) => state.comments.error);

  const inputChangedHandler = (event, controlName) => {
    let valid = validate(event.target.value, controls[controlName].validation);
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        touched: true,
        valid: valid,
      },
    };

    setControls(updatedControls);
  };

  const [controls, setControls] = useState({
    commentArea: {
      elementType: 'textarea',
      elementConfig: {
        type: 'textarea',
        placeholder: 'app.container.review.comments.comment',
      },
      value: '',
      validation: {
        required: true,
      },
      inputChangeHandler: inputChangedHandler,
      valid: true,
      touched: false,
      errorMessage: 'Comment is required',
    },
  });

  const submitHandler = (event) => {
    event.preventDefault();
    dispatch(
      createComment({
        docId: docId,
        excerptId: issueId,
        body: controls.commentArea.value,
      }),
    );
    let updatedControls = { ...controls };
    updatedControls.commentArea.value = '';
    setControls(updatedControls);
  };

  const isControlsValid = () => {
    let isValid = true;
    for (let index in controls) {
      if (['input', 'textarea'].indexOf(controls[index].elementType.toLowerCase()) > -1) {
        isValid = validate(controls[index].value, controls[index].validation) && isValid;
      }
    }
    return isValid;
  };

  return (
    <Modal show={show}>
      <ModalHeader title={`${title || 'Comments on this document'}`} onClose={onClose} />
      <ModalContent>
        <Comments docId={docId} issueId={issueId} />
        <Form disabled={loading || error !== null} controls={controls} submitText={'Add Comment'} />
      </ModalContent>
      <ModalFooter>
        <Button disabled={!isControlsValid()} btnType="al-primary" clicked={submitHandler}>
          {'Save comment'}
        </Button>
        <Button clicked={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CommentsModal;
