import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createRole, updateRole, fetchPermissionSets } from '../../store/actions';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import RightLeftController from '../../components/UI/RightLeftCollector/RightLeftCollector';
import type { PermissionSet, Role } from '../../types';

type Props = {
  title: string,
  role: Role,
  permissionSets: Array<PermissionSet>,
  onClose: () => void,
  createRole: (role: Role) => void,
  updateRole: (role: Role) => void,
  fetchPermissionSets: () => void,
};

const CreateRoleModal = ({ onClose, title, role }: Props) => {
  const [selectedPermissionSets, setSelectedPermissionSets] = useState(role.permissionSets);
  const [roleName, setRoleName] = useState(role.name);
  const permissionSets = useSelector((state) => state.permissionSets.permissionSets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPermissionSets());
  }, [dispatch]);

  const onMove = (leftItems: Array<string>, rightItems: Array<string>) => {
    const selectedPermissionSets: Array<Role> = permissionSets.filter(
      (permissionSet) => leftItems.indexOf(permissionSet.name) > -1,
    );
    setSelectedPermissionSets(selectedPermissionSets.map((role) => role.id));
  };

  const getLeftPermissionSets = () => {
    const leftPermissionSets = permissionSets.filter(
      (permissionSet) => role.permissionSets.indexOf(permissionSet.id) > -1,
    );
    return leftPermissionSets.map((permissionSet) => permissionSet.name);
  };

  const getRightPermissionSets = () => {
    const rightPermissionSets = permissionSets.filter(
      (permissionSet) => role.permissionSets.indexOf(permissionSet.id) === -1,
    );
    return rightPermissionSets.map((rightPermissionSet) => rightPermissionSet.name);
  };

  const save = () => {
    let updatedRole: Role = { ...role };
    updatedRole.name = roleName;
    updatedRole.permissionSets = selectedPermissionSets;
    if (!updatedRole.id) {
      dispatch(createRole(updatedRole));
    } else {
      dispatch(updateRole(updatedRole));
    }
    onClose();
  };

  return (
    <Modal show={true}>
      <ModalHeader onClose={onClose} title={title} />
      <ModalContent>
        <Input
          elementType={'input'}
          elementConfig={{ type: 'text' }}
          placeholder={'Rolename'}
          value={roleName}
          changed={(e) => setRoleName(e.target.value)}
        />
        <RightLeftController
          onMove={onMove}
          leftTitle={'Assigned permission sets:'}
          leftItemsProps={getLeftPermissionSets()}
          rightTitle={'All permission sets:'}
          rightItemsProps={getRightPermissionSets()}
        />
      </ModalContent>
      <ModalFooter>
        <div></div>
        <div>
          <Button className={'al-primary'} clicked={save}>
            Save
          </Button>
          <Button clicked={onClose}>Cancel</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CreateRoleModal;
