const prod = {
  apiGateway: {
    URL: process.env.REACT_APP_API_URL || window.location.origin,
  },
};

const local = {
  apiGateway: {
    URL: process.env.REACT_APP_API_URL || 'http://localhost:8080',
  },
};

let getConfig = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'local':
      return local;
    default:
      return prod;
  }
};

const config = getConfig();

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
