import React from 'react';

const Overlay = (props) => {
  return (
    <div className={'al-overlay-container'}>
      <div className={'al-overlay-backdrop'}/>
      <div className={'al-overlay-content'}>{props.children}</div>
    </div>
  );
};

export default Overlay;
