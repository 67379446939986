import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import type { SearchQuery } from '../../../types';
import * as actions from '../../../store/actions';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { groupBy } from '../../../services/groupBy';
import SearchQueryResult from './SearchQueriesPreview/SearchQueryResult';
import { usePrevious } from '../../../hooks/prevValue';
import Button from '../../../components/UI/Button/Button';
import Icon from '../../../components/UI/Icon/Icon';
import { selectPageQueryHighlights } from '../../../store/actions/searchQueries';

type Props = {
  contractId: string,
  searchQueries: Array<SearchQuery>,
};

const DOCS_PER_PAGE = 10;
const groupByExcerptType = groupBy('docId');

const SearchQueriesPreview = ({ searchQueries, contractId }: Props) => {
  const dispatch = useDispatch();
  const fetchSearchQueryHighlights = (
    searchQueryId: string,
    searchQueryName: string,
    page: number,
  ) => dispatch(actions.fetchSearchQueryHighlights(searchQueryId, searchQueryName, page));
  const clearSearchQueryHighlights = () => dispatch(actions.clearSearchQueryHighlights());

  const loading = useSelector(
    (state) =>
      state.searchQueries.fetchingDocumentHighlights ||
      state.searchQueries.fetchingSearchQueries ||
      state.searchQueries.creatingSearchQueries ||
      state.searchQueries.editingSearchQueries ||
      state.searchQueries.applyingQuery ||
      state.searchQueries.deletingSearchQueries ||
      state.issues.loading,
  );
  const queryHighlights = useSelector((state) => state.searchQueries.queryHighlights);
  const searchQueryName = useSelector((state) => state.searchQueries.searchQueryName);
  const searchQueryId = useSelector((state) => state.searchQueries.searchQueryId);
  const globalSettings = useSelector((state) => state.globalSettings.globalSettings);
  const page = useSelector((state) => state.searchQueries.pageQueryHighlights);

  const prevSearchQueries = usePrevious(searchQueries);
  const prevContractId = usePrevious(contractId);
  const groupByQueryTypeItems = groupByExcerptType(queryHighlights);

  useEffect(() => {
    if (!_.isEqual(prevSearchQueries, searchQueries)) {
      if (searchQueries.length > 0 && prevContractId && prevContractId === contractId) {
        if (searchQueryId !== '') {
          fetchSearchQueryHighlights(searchQueryId, searchQueryName, page);
        }
      }
      if (searchQueries.length === 0) {
        clearSearchQueryHighlights();
      }
    }
    // eslint-disable-next-line
  }, [searchQueries]);

  useEffect(() => {
    if (prevContractId) {
      if (searchQueries.length > 0) {
        fetchSearchQueryHighlights(searchQueries[0].id, searchQueries[0].excerptType, page);
      }
      if (searchQueries.length === 0) {
        clearSearchQueryHighlights();
      }
    }
    // eslint-disable-next-line
  }, [contractId]);

  const getPrev = () => {
    const prevPage = page - 1;
    fetchSearchQueryHighlights(searchQueryId, searchQueryName, prevPage);
    dispatch(selectPageQueryHighlights(prevPage));
  };

  const getNext = () => {
    const nextPage = page + 1;
    fetchSearchQueryHighlights(searchQueryId, searchQueryName, nextPage);
    dispatch(selectPageQueryHighlights(nextPage));
  };

  return (
    <>
      <div className={'al-search-queries-preview'}>
        <div className="al-search-queries-preview-list">
          <div className={'al-search-queries-preview-list-header'}>
            {searchQueryName !== ''
              ? `${queryHighlights.length} results for "${searchQueryName}"`
              : `No search query available`}
          </div>
          <div className={'al-search-queries-preview-list-items'}>
            <div className={'items-body'}>
              {loading ? (
                <Spinner />
              ) : queryHighlights.length ? (
                Object.keys(groupByQueryTypeItems).map((keyName, key) => {
                  return (
                    <SearchQueryResult
                      key={groupByQueryTypeItems[keyName][0].docId}
                      docTitle={groupByQueryTypeItems[keyName][0].docTitle}
                      docId={groupByQueryTypeItems[keyName][0].docId}
                      items={groupByQueryTypeItems[keyName]}
                      aiTrainerEnabled={globalSettings.aiTrainerEnabled}
                    />
                  );
                })
              ) : searchQueryName !== '' ? (
                <div style={{ margin: '10px' }}>{'No results found'}</div>
              ) : (
                <div style={{ margin: '10px' }}>{'Please select a query to see results'}</div>
              )}
            </div>
            {queryHighlights.length > 0 && (
              <div className={'items-footer'}>
                <Button className={'prev-button'} disabled={page === 1} clicked={getPrev} title="">
                  <Icon className={''} title="" icon="keyboard-arrow-left" show={true} />
                  {'Previous'}
                </Button>
                <Button
                  className={'next-button'}
                  disabled={Object.keys(groupByQueryTypeItems).length < DOCS_PER_PAGE}
                  clicked={getNext}
                  title=""
                >
                  {'Next'}
                  <Icon className={''} title="" icon="keyboard-arrow-right" show={true} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchQueriesPreview;
