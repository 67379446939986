// @flow
import React, { Component } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import type { Excerpt, SectionPart, Document as IDocument } from '../../../types';
import DocumentSections from './DocumentSections';

type Props = {
  issues: Array<Excerpt>,
  controls: any,
  show: boolean,
  loading: boolean,
  error: string,
  searchTerm: string,
  onSelectText: (text: string) => void,
  pageNumber?: number,
  textContent?: Array<string>,
  globalOffset?: number,
  selectedIssue?: Excerpt,
  selectedSection?: any,
  selectIssue: (issue: Excerpt) => void,
  focusedSectionId?: number,
  document: IDocument,
  docId: string,
  onRef: Function,
  marker: any,
  createIssue: (
    excerptType: string,
    sectionParts: Array<SectionPart>,
    selectedText: string,
  ) => void,
  deleteIssue: (issue: Excerpt) => void,
  recognizeIssue: (excerptId: string, someFlag: boolean) => void,
  filter: any,
};

class Document extends Component<Props> {
  render() {
    const { show, loading, error, document } = this.props;
    if (!show) {
      return null;
    } else if (loading || !document) {
      return <Spinner />;
    } else if (error) {
      return <div>{'Warning, cannot load document. Reload this page please.'}</div>;
    }
    return <DocumentSections {...this.props} />;
  }
}

export default Document;
