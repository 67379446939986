// @flow
import React from 'react';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

type Props= {
  onPageChange: (page:number)=>void,
  pageNumber: number,
  visiblePages: number,
  numberOfPages: number,
};

const defaultProps = {
  visiblePages: 9,
  pageNumber: 0,
};

const getPageRange = ({visiblePages, lastPage, pageNumber}) => {
  //first and last pages are always shown, so we calculate the range around the current shown pages
  // taking into consideration that we alway want to get the number of visible pages, if possible
  const startAfter = Math.min(pageNumber - (visiblePages - 1) / 2, lastPage - visiblePages);
  const startAfterOne = Math.max(startAfter, 1);
  let endBeforeLast = Math.min(startAfterOne + visiblePages, lastPage);
  if (startAfterOne > endBeforeLast) {
    endBeforeLast = startAfterOne;
  }
  const pagesInBetween = [...Array(endBeforeLast - startAfterOne).keys()].map(i => startAfterOne + i);
  //add some ellips placeholder, if there is a gap between first page and currently shown pages
  const hasStartGap = (pagesInBetween[0]) > 1;
  //add some ellips placeholder, if there is a gap between last page and currently shown pages
  const hasEndGap = (lastPage - pagesInBetween[pagesInBetween.length - 1]) > 1;
  return {pagesInBetween, hasStartGap, hasEndGap};
};


const PageControl= ({numberOfPages, pageNumber, visiblePages,onPageChange}:Props)=>{
    const lastPage = numberOfPages;
    if (lastPage < 1) {
      return null;
    }
    const {pagesInBetween, hasStartGap, hasEndGap} = getPageRange({visiblePages, lastPage, pageNumber});
    if (hasEndGap) {
      pagesInBetween.pop();
    }
    if (hasStartGap) {
      pagesInBetween.shift();
    }
    return (
      <ul className="pageControl">
        <li className={pageNumber === 0 ? 'disabled prev' : 'prev page'}>
          <a onClick={() => pageNumber !== 0 ? onPageChange(pageNumber - 1) : null}><KeyboardArrowLeftIcon
            style={iconStyle}/> </a>
        </li>
        <li className={pageNumber === 0 ? 'disabled active first' : 'first page'}>
          <a onClick={() => onPageChange(0)}>1</a>
        </li>
        {hasStartGap && <li>
          <a>&hellip;</a>
        </li>}
        {pagesInBetween.map((middlePage, index) =>
          <li key={index} className={middlePage === pageNumber ? 'active page' : 'page'}>
            <a onClick={() => onPageChange(middlePage)}>{middlePage + 1}</a>
          </li>
        )}
        {hasEndGap && <li>
          <a>&hellip;</a>
        </li>}
        <li className={pageNumber === lastPage ? 'disabled active last' : 'last page'}>
          <a onClick={() => onPageChange(lastPage)}>{lastPage + 1}</a>
        </li>

        <li className={pageNumber === lastPage ? 'disabled next' : 'next page'}>
          <a onClick={() => pageNumber !== lastPage ? onPageChange(pageNumber + 1) : null}><KeyboardArrowRightIcon
            style={iconStyle}/></a>
        </li>

      </ul>
    );
  };

const iconStyle = {fontSize: 22, paddingTop: '1px'};

PageControl.defaultProps = defaultProps;

export default PageControl;