//@flow
import React from 'react';
import type { ExcerptTypeAggregations } from '../../types';
import File from './File';

type Props = {
  excerptTypeAggregations: any,
  openDocument: (docId: string, excerptId: string) => void,
  openReviewDocument: (docId: string) => void,
  fetchExcerptsList: (docId: string) => void,
};

const StaticExcerpts = ({
  excerptTypeAggregations,
  openDocument,
  openReviewDocument,
  fetchExcerptsList,
}: Props) => {
  return (
    <>
      {Object.keys(excerptTypeAggregations).map((docId: ExcerptTypeAggregations, index: number) => {
        const statisticByType = excerptTypeAggregations[docId];
        return (
          <File
            key={index}
            filteredExcerpts={statisticByType.excerpts}
            docId={docId}
            openReviewDocument={openReviewDocument}
            statisticByType={statisticByType}
            fetchExcerptsList={fetchExcerptsList}
          >
            <StaticExcerpt excerpt={excerptTypeAggregations} openDocument={openDocument} />
          </File>
        );
      })}
    </>
  );
};

export default StaticExcerpts;

const StaticExcerpt = ({ excerpt, openDocument }) => {
  return (
    <article key={excerpt.excerptId}>
      <p
        className={'al-result-content'}
        title="Go to document"
        onClick={() => openDocument(excerpt.docId, excerpt.excerptId)}
      >
        {excerpt.bodyHTML}
      </p>
    </article>
  );
};
