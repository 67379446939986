// @flow
import config from '../config';

const url = config.apiGateway.URL;
const apiGetOriginalFileUrl = (docId:string):string => `${url}/api/files/document/${docId}`;

export const getPDFUrlForDocument = (docId?:string):{url:string,httpHeaders:Object}|null => {
  if (!docId){
    return null;
  }
  const token = localStorage.getItem('token');
  return {
      url: apiGetOriginalFileUrl(docId),
      httpHeaders:
        {
          Authorization: token,
        }
      ,
  };
};