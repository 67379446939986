import React from 'react';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';

type Props = {
  show: boolean,
  onClose: () => void,
  lang: string,
  currentLocation: string,
};

const HelpModal = ({ show, onClose, lang, currentLocation }: Props) => {
  const getPage = (currentLocation) => {
    const pages = {
      '/search': 'Searching_for_documents.html',
      '/review': 'Reviewing_documents.html',
      '/explore': 'Exploring_findings.html',
      '/projects': 'Managing_projects.html',
      '/contractTypes': 'Defining_document_types.html',
      '/searchQueries': 'Defining_search_queries.html',
      '/users': 'Managing_users_and_roles.html',
      '/roles': 'Managing_users_and_roles.html',
      '/': 'Uploading_documents.html',
    };
    const locIndex = currentLocation.lastIndexOf('/');
    const location =
      currentLocation.includes('/review') && locIndex > 0
        ? currentLocation.substring(0, locIndex)
        : currentLocation.substring(locIndex);

    return pages[location] ? pages[location] : 'index.html';
  };

  const title = 'Documentation & Help';
  const location = getPage(currentLocation);
  return (
    <Modal show={show} className={'al-modal-container expanded'}>
      <ModalHeader title={title} onClose={onClose} />
      <ModalContent contentStyle={{ height: '100%', overflow: 'hidden' }}>
        <iframe title={title} src={`/doc/${lang}/${location}`} height={'99%'} width={'100%'} />
      </ModalContent>
      <ModalFooter>
        <Button clicked={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default HelpModal;
