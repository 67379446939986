import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { AppState } from '../../types/reduxTypes';
import { connect } from 'react-redux';
import type { DynamicTab } from '../../types';

type Props = {
  dynamicTabs: Array<DynamicTab>,
};

const DynamicTabPage = ({dynamicTabs}:Props) => {
  const { id } = useParams();
  const url = dynamicTabs.find(dynamicTap => dynamicTap.id === id).url;
  return (
      <iframe
        src={url}
        height={'100%'}
        width={'100%'}
      />
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    dynamicTabs: state.dynamicTabs.dynamicTabs,
  };
};

export default connect(
  mapStateToProps,
)(DynamicTabPage);
