import * as jsdiff from 'diff';

export const diffTypes = {
  chars: 'chars',
  words: 'words',
  sentences: 'sentences',
  json: 'json',
};

export const diffFunctions = {
  [diffTypes.chars]: jsdiff.diffChars,
  [diffTypes.words]: jsdiff.diffWords,
  [diffTypes.sentences]: jsdiff.diffSentences,
  [diffTypes.json]: jsdiff.diffJson,
};
export type DiffTypes = $Keys<typeof diffTypes>;

