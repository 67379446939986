/**
 * Renders a list of excerpt types.
 */
import React from 'react';
import CustomTooltip from '../UI/Tooltip/Tooltip';
import Icon from '../UI/Icon/Icon';

type Props = {
  activeType: string,
  onSelect: (type: string) => void,
  findingTypes: { string: 'match ' | 'un-match' } | Array<string>,
  isComparingFindings: boolean,
};

const ExcerptDiffSelect = ({ onSelect, activeType, findingTypes, isComparingFindings }: Props) => {
  const typesList = isComparingFindings ? Object.keys(findingTypes) : findingTypes;
  return (
    <div className={'al-excerptDiffSelect'}>
      {typesList
        .sort((a, b) => a.localeCompare(b))
        .map((excerptDiff) => {
          return (
            <div
              className={'al-diffSelectItem' + (activeType === excerptDiff ? ' active' : '')}
              key={excerptDiff}
              onClick={() => onSelect(excerptDiff)}
            >
              <CustomTooltip
                title={excerptDiff}
                enterDelay={1000}
                leaveDelay={50}
                placement="top-end"
                interactive={true}
              >
                <span className={'al-diffSelectItem-text'}>{excerptDiff}</span>
              </CustomTooltip>
              {isComparingFindings &&
                (findingTypes[excerptDiff] === 'match' ? (
                  <Icon title={`All equal`} icon={`success`} show={true} />
                ) : (
                  <Icon title={`Differences found`} icon={`exclamation-circle-fill`} show={true} />
                ))}
            </div>
          );
        })}
    </div>
  );
};

export default ExcerptDiffSelect;
