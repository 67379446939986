import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import CustomTooltip from '../../../components/UI/Tooltip/Tooltip';
import ArrowBack from '@material-ui/icons/ArrowBackIos';
import CommentsModal from '../../../components/Review/CommentsModal/CommentsModal';
import {
  ISSUE_STATUS,
  ORIGIN,
  DOCUMENT_ANALYZED_STATUS,
  SERVER_EVENTS,
  PDF_FILE_EXTENSION_REGEX,
  PERMISSIONS,
} from '../../../constants/constants';
import QueryString from 'query-string';
import { EventSourcePolyfill } from 'event-source-polyfill';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import * as actions from '../../../store/actions/index';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../components/UI/Modal/Modal';
import Document from '../../../components/Review/Document/Document';
import ToolBar from '../../../components/Review/Toolbar/ToolBar';
import HotKeys from '../../../components/UI/HotKeys/HotKeys';
import config from '../../../config';
import PdfView from '../../../components/PdfView/PdfView';
import { getPDFUrlForDocument } from '../../../services/documentService';
import { RightSideMenu } from '../RightListMenu/RightSideMenu';
import LeftSideMenu from '../LeftSideMenu/LeftSideMenu';
import { getDocumentEventSource, initDocumentEventSource } from './SSEDocument';
import { round } from '../../../services/round';
import { withPermissions } from '../../../contexts/PermissionsContext/withPermissions';

class Review extends Component {
  constructor(props) {
    super(props);
    this.myInput = React.createRef();
    this.state = {
      showLeftSide: true,
      showRightSide: true,
      showPdfView: false,
      issues: this.props.issues,
      isAllTypesExpanded: false,
      docId: props.match.params.docId,
      fileUrl: getPDFUrlForDocument(props.match.params.docId),
      filterByExcerptType: '',
      selectedIssue: null,
      focusedSectionId: null,
      showContext: false,
      searchTerm: '',
      debugAI: QueryString.parse(props.location.search).debugAI,
      sortBy: {
        fieldName: 'excerptType.keyword',
        asc: true,
      },
      controls: {
        showComments: {
          clicked: () => this.toggleModal('commentModal'),
          withIcon: true,
          icon: 'no-comments',
        },
        commentModal: {
          key: 'commentModal',
          open: false,
          loading: false,
          error: null,
        },
        waitingModal: {
          key: 'waitingModal',
          open: false,
          loading: false,
          error: null,
        },
      },
      threshold: 0,
    };
    this.keyPressMap = {
      ArrowUp: this.selectIssueUp,
      j: this.selectIssueUp,
      ArrowDown: this.selectIssueDown,
      k: this.selectIssueDown,
      c: this.selectCommentModal,
      //r: this.selectApproveModal,
    };
  }

  setExpandAllTypes = (isExpanded) => {
    this.setState({ isAllTypesExpanded: isExpanded });
  };

  selectCommentModal = (e) => {
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    if (!this.state.selectedIssue) {
      this.toggleModal(this.state.controls.commentModal.key);
    }
  };

  selectIssueUp = (e) => {
    e.preventDefault();
    if (this.state.issues < 2) {
      return;
    }
    let selectedIssueIndex = -1;
    if (this.state.selectedIssue) {
      selectedIssueIndex = _.findIndex(this.state.issues, (issue) => {
        return issue.excerptId === this.state.selectedIssue;
      });
    }
    if (selectedIssueIndex === 0) {
      selectedIssueIndex = this.state.issues.length;
    }
    let nextIssue = this.state.issues[selectedIssueIndex - 1];
    if (!this.issuesListComponent) return;
    this.issuesListComponent.scrollTo(nextIssue.excerptId, 'auto');
    this.documentComponent.scrollToSectionById(nextIssue.contextIds[0].id);
    this.setState({
      selectedIssue: nextIssue.excerptId,
      focusedSectionId: nextIssue.contextIds[0].id,
    });
  };

  toggleLeftSide = () => {
    this.setState({ showLeftSide: !this.state.showLeftSide });
  };

  toggleRightSide = () => {
    this.setState({ showRightSide: !this.state.showRightSide });
  };

  selectIssueDown = (e) => {
    e.preventDefault();
    if (this.state.issues < 2) {
      return;
    }
    let selectedIssueIndex = -1;
    if (this.state.selectedIssue) {
      selectedIssueIndex = _.findIndex(this.state.issues, (issue) => {
        return issue.excerptId === this.state.selectedIssue;
      });
    }

    if (selectedIssueIndex === this.state.issues.length - 1) {
      selectedIssueIndex = -1;
    }
    let nextIssue = this.state.issues[selectedIssueIndex + 1];
    if (!this.issuesListComponent) return;
    this.issuesListComponent.scrollTo(nextIssue.excerptId, 'auto');
    this.documentComponent.scrollToSectionById(nextIssue.contextIds[0].id);
    this.setState({
      selectedIssue: nextIssue.excerptId,
      focusedSectionId: nextIssue.contextIds[0].id,
    });
  };

  toggleModal = (key, editMode = false, data = null) => {
    this.props.startUpdate();
    let controls = { ...this.state.controls };
    controls[key].loading = false;
    controls[key].error = null;
    controls[key].open = !this.state.controls[key].open;
    controls[key].editMode = editMode;
    controls[key].data = null;
    if (editMode) {
      controls[key].data = data;
    }
    this.setState({ controls: controls, showContext: false });
  };

  togglePdfView = () => {
    this.setState((prevState) => ({ showPdfView: !prevState.showPdfView }));
  };

  approveAll = () => {
    let issuesIds = this.props.issues.map((issue) => {
      return issue.excerptId;
    });
    this.props.approveAll(this.state.docId, issuesIds);
    this.toggleModal(this.state.controls.waitingModal.key);
  };

  createIssue = (excerptType, contextIds, bodyHTML, excerptUsedForTraining) => {
    this.props.createIssue(
      {
        excerptType,
        contextIds,
        comments: [],
        docId: this.state.docId,
        docTitle: this.props.document.title,
        bodyHTML,
        projectId: this.props.document.projectId,
        contractId: this.props.document.contractId,
      },
      excerptUsedForTraining,
      this.state.sortBy,
    );
  };

  selectIssue = (issue, changeURL = true) => {
    if (!issue) return;
    if (changeURL) {
      this.props.history.push('/review/' + this.state.docId + '/' + issue.excerptId);
    }
    this.setState({ selectedIssue: issue });
  };

  filterByExcerptType = (filter) =>
    this.props.issues.filter((item) => {
      return item.excerptType.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
    });

  setupEventSource = () => {
    initDocumentEventSource(this.state.docId);
    getDocumentEventSource().addEventListener(SERVER_EVENTS.KEEP_ALIVE, (e) => {
      console.debug('Emitter:', e.type);
    });

    getDocumentEventSource().addEventListener(SERVER_EVENTS.COMPLETE, (e) => {
      console.debug('Connection is completed & closing it');
      getDocumentEventSource().close();
    });

    getDocumentEventSource().onerror = _.debounce((e) => {
      console.debug('Document SSE Connection error, readystate:', e.target.readyState);
      if (e.target.readyState === 2) {
        console.error('Document SSE Connection is closed, readystate:', e.target.readyState);
        getDocumentEventSource().close();
        this.props.logout();
        /*if (this.props.isAuthenticated && this.props.appStatus.health) {
          this.setupEventSource();
        }*/
      }
    });

    getDocumentEventSource().addEventListener(SERVER_EVENTS.DOCUMENT_PROCESSED, (e) => {
      if (e.data === this.props.match.params.docId) {
        console.debug(`Emitter: ${e.type} for document: ${this.props.document?.title}`);
        this.props.fetchDocument(this.props.match.params.docId);
        this.props.fetchDocumentNoFindingTypes(this.props.match.params.docId);
      }
    });

    getDocumentEventSource().addEventListener(
      SERVER_EVENTS.UPDATE_ISSUES,
      _.debounce((e) => {
        if (e.data === this.props.match.params.docId) {
          console.debug(`Emitter: ${e.type} for document: ${e.data}`);
          this.props.fetchIssues(
            this.state.docId,
            this.state.debugAI,
            this.state.sortBy,
            true,
            this.props.match.params.excerptId,
          );
        }
      }, 1000),
    );
  };

  componentDidMount() {
    if (this.state.debugAI) {
      console.debug('Setting Debug AI to ' + this.state.debugAI);
    }
    if (this.state.docId) {
      this.setupEventSource();
      this.props.fetchDocument(this.props.match.params.docId);
      this.props.fetchIssues(
        this.props.match.params.docId,
        this.state.debugAI,
        this.state.sortBy,
        true,
        this.props.match.params.excerptId,
      );
      this.props.fetchDocumentNoFindingTypes(this.props.match.params.docId);
    }

    if (this.props.selectedIssueToView) {
      this.setState({
        selectedIssue: this.props.selectedIssueToView,
      });
    }

    // if there is an excerpt id in the URL, fetch all its properties to be able to select it
    if (this.props.match.params.excerptId) {
      this.props.fetchExcerptById(this.props.match.params.excerptId);
    }
  }

  selectExcerptFromURL = (excerptFromURL) => {
    if (
      !this.state.selectedIssue ||
      (excerptFromURL && excerptFromURL.excerptId !== this.state.selectedIssue.excerptId)
    ) {
      this.selectIssue(excerptFromURL, false);
      if (excerptFromURL && excerptFromURL.origin === ORIGIN.ADVANCED) {
        this.changeThreshold(round(excerptFromURL.probability) * 100);
      }
      if (!this.state.showRightSide) {
        this.toggleRightSide();
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.document && this.props.document.projectId !== this.props.projectId) {
      this.props.selectProject(this.props.document.projectId);
    }
    if (prevProps.projectId !== this.props.projectId && !this.props.document) {
      this.props.history.push('/');
    }
    if (this.props.documentError != null) return;

    if (this.props.match.params.excerptId) {
      if (
        this.props.selectedExcerpt &&
        !_.isEqual(prevProps.selectedExcerpt, this.props.selectedExcerpt)
      ) {
        if (this.props.selectedExcerpt.origin === ORIGIN.ADVANCED) {
          // fetch advanced learning excerpts list including 'selectedExcerpt' to show on right side menu
          this.props.fetchAdvancedLearningIssues(
            this.props.selectedExcerpt.docId,
            this.props.selectedExcerpt.excerptType,
            round(this.props.selectedExcerpt.probability),
          );
        }
        this.selectExcerptFromURL(this.props.selectedExcerpt);
        if (
          this.props.selectedExcerpt.origin !== ORIGIN.ADVANCED &&
          !_.isEqual(prevProps.issues, this.props.issues)
        ) {
          this.setState({ issues: this.filterByExcerptType(this.state.filterByExcerptType) });
        }
      }
    }
  }

  isPDFDocument = () => {
    return (
      this.props.document &&
      PDF_FILE_EXTENSION_REGEX.test(this.props.document.title.trim().toLowerCase())
    );
  };

  componentWillUnmount() {
    console.debug('closing source event');
    if (this.props.selectedIssueToView) {
      this.props.deleteViewSelectedIssue();
    }
    if (getDocumentEventSource()) {
      getDocumentEventSource().close();
    }
  }

  getGlobalConfigs() {
    if (this.props.document) {
      const contractConfig = this.getContractConfig();
      if (contractConfig) {
        return contractConfig.globalConfig
          .filter((globalConfig) => globalConfig.origin !== ORIGIN.STATIC)
          .sort((a, b) => {
            return a.excerptType.toLowerCase().trim() > b.excerptType.toLowerCase().trim()
              ? 1
              : a.excerptType.toLowerCase().trim() < b.excerptType.toLowerCase().trim()
              ? -1
              : 0;
          })
          .map((globalConfig) => globalConfig.excerptType);
      } else return [];
    } else return [];
  }

  getContractConfig = () => {
    if (this.props.document) {
      const contractConfigs = this.props.projects.get(this.props.document.projectId).contractConfig;
      return contractConfigs.find(
        (contractType) => contractType.id === this.props.document.contractId,
      );
    }
    return '';
  };

  changeThreshold = (newThreshold) => {
    this.setState({ threshold: newThreshold });
  };

  isMarkedAs = (category, findingName, isOriginStatic, isOriginUser) => {
    return !!this.props.projects
      .get(this.props.document.projectId)
      .contractConfig.find((ct) => ct.id === this.props.document.contractId)
      .globalConfig.filter((excerpt) => {
        return (
          ((isOriginStatic && excerpt.origin === ORIGIN.STATIC) ||
            (isOriginUser && excerpt.origin === ORIGIN.USER)) &&
          excerpt.excerptType.trim().toLowerCase() === findingName.trim().toLowerCase() &&
          excerpt.findingCategory === category
        );
      }).length;
  };

  render() {
    const { document, documentError, loading, isAuthenticated, issues } = this.props;
    const { fileUrl, threshold } = this.state;

    const isPDFDocument = this.isPDFDocument();
    const showPdfView = this.state.showPdfView && isPDFDocument;
    let classes = ['al-container al-review-container'];
    if (!this.state.docId) {
      return (
        <div className="al-container">
          <div className="al-info-msg al-mt-40">Find a document first using SEARCH.</div>
        </div>
      );
    }
    if (documentError) {
      switch (documentError.response.status) {
        case 404:
          return (
            <div>
              <FormattedMessage
                id="app.review.error.document-not-found"
                defaultMessage="Document has been deleted."
              />
            </div>
          );
        default:
          return (
            <div>
              Error while fetching,{' '}
              <a href="javascript:location.reload();">retry fetching please.</a>.
            </div>
          );
      }
    } else if (!isAuthenticated) {
      return <Redirect to="/login" />;
    }
    if (
      document &&
      [
        DOCUMENT_ANALYZED_STATUS.COMPLETE,
        DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED_WITH_ERROR,
        DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED,
      ].indexOf(document.analyzeStatus) < 0
    ) {
      return <Spinner message={'Processing document...'} />;
    }
    if (loading || !this.props.document) {
      return <Spinner />;
    }
    let createModal = null;
    let commentModal = null;
    if (this.state.controls.commentModal.open) {
      commentModal = (
        <CommentsModal
          docId={this.state.docId}
          onClose={() => this.toggleModal(this.state.controls.commentModal.key)}
          show={this.state.controls.commentModal.open}
        />
      );
    }

    return (
      <div ref={'document'} className={classes.join(' ')}>
        <ToolBar
          approveAll={this.approveAll}
          approvedIssueCount={
            issues.filter((issue) => issue.status !== ISSUE_STATUS.APPROVED).length
          }
          document={document}
          showComments={this.state.controls.showComments}
          contractConfig={this.getContractConfig()}
          history={this.props.history}
          showCompareOption={this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_COMPARE)}
          sortBy={this.state.sortBy}
        />
        <div className={'al-review-workspace'} ref={'myInput'}>
          <HotKeys outerRef={this.myInput.current} keyPressMap={this.keyPressMap}>
            <LeftSideMenu
              project={this.props.projects.get(document.projectId)}
              document={document}
              selectedIssue={this.state.selectedIssue}
              selectIssue={this.selectIssue}
              setSortBy={(sortBy) => this.setState({ sortBy })}
              isAllTypesExpanded={this.state.isAllTypesExpanded}
              showLeftSideMenu={this.state.showLeftSide}
              toggleLeftSide={this.toggleLeftSide}
              setExpandAllTypes={this.setExpandAllTypes}
              isMarkedAs={this.isMarkedAs}
              hasTrainingPermission={this.props.PermissionsContext.hasPermission(
                PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_CREATE_TRAIN,
              )}
            />
            <div className={`al-review-document ${showPdfView ? 'al-review-document--split' : ''}`}>
              <Document
                searchTerm={this.state.searchTerm}
                onRef={(ref) => (this.documentComponent = ref)}
                selectedIssue={this.state.selectedIssue}
                selectIssue={this.selectIssue}
                document={document}
                docId={this.state.docId}
                show={true}
                loading={this.props.documentLoading}
                error={this.props.documentError}
                issues={issues.filter((issue) => issue.origin !== ORIGIN.ADVANCED) || []}
                createIssue={this.createIssue}
                filter={this.state.filterByExcerptType}
                globalConfigsTypes={this.getGlobalConfigs()}
                hasCreateWithTrainingPermission={
                  this.props.aiTrainerEnabled &&
                  this.props.PermissionsContext.hasPermission(
                    PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_CREATE_TRAIN,
                  )
                }
              />
              <div className={'al-review-divider'}>
                <div
                  className={`al-sliderBadge ${showPdfView ? 'show' : 'hide'} ${
                    !isPDFDocument ? 'disabled' : 'enabled'
                  }`}
                >
                  <CustomTooltip
                    title={
                      !isPDFDocument
                        ? `Document's format not supported`
                        : !showPdfView
                        ? 'Show original document'
                        : 'Hide original document'
                    }
                    enterDelay={1000}
                    leaveDelay={200}
                    placement="top-end"
                    classNames={{ root: 'al-tooltip' }}
                    interactive={false}
                  >
                    <span
                      onClick={() => {
                        isPDFDocument ? this.togglePdfView() : undefined;
                      }}
                    >
                      <ArrowBack style={{ fontSize: 'inherit', margin: '10px 0 0 10px' }} />
                    </span>
                  </CustomTooltip>
                </div>
              </div>
              {isPDFDocument ? (
                <div className={`al-review-pdf ${showPdfView ? '' : 'al-review-pdf--hide'}`}>
                  <PdfView fileName={document.title} fileUrl={fileUrl} />
                </div>
              ) : null}
            </div>
            <RightSideMenu
              showRightSide={this.state.showRightSide}
              selectedIssue={this.state.selectedIssue}
              selectIssue={this.selectIssue}
              toggleRightSide={this.toggleRightSide}
              threshold={threshold}
              changeThreshold={this.changeThreshold}
              excerptTypeDetails={
                document && document.excerptTypeDetails ? document.excerptTypeDetails : []
              }
              isMarkedAs={this.isMarkedAs}
              hasConvertToUserPermission={this.props.PermissionsContext.hasPermission(
                PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_CONVERT_TO_USER_ID,
              )}
              hasRecognizeCorrectTrainPermission={this.props.PermissionsContext.hasPermission(
                PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_RECOGNIZE_CORRECT_TRAIN_ID,
              )}
              hasRecognizeIncorrectTrainPermission={this.props.PermissionsContext.hasPermission(
                PERMISSIONS.EXCERPT_CONTROLLER_POST_EXCERPTS_RECOGNIZE_INCORRECT_TRAIN_ID,
              )}
            />
          </HotKeys>
        </div>

        <Modal
          show={this.state.controls.waitingModal.open}
          loading={this.props.loading}
          error={this.props.documentError}
          errorMessage={'Error while confirming'}
        >
          <ModalHeader
            onClose={() => this.toggleModal(this.state.controls.waitingModal.key)}
            title={'Acknowledge all findings'}
          />

          <ModalContent>
            <FormattedMessage
              id="app.review.confirmations-approve-issues"
              defaultMessage="All findings confirmed"
            />
            .
          </ModalContent>

          <ModalFooter>
            <Button clicked={() => this.toggleModal(this.state.controls.waitingModal.key)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        {commentModal}
        {createModal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: localStorage.getItem('token') !== null,
    loading: state.document.loading,
    issues: state.issues.issues,
    document: state.document.data,
    documentLoading: state.document.loading,
    documentError: state.document.error,
    projects: state.project.projects,
    projectId: state.project.projectId,
    selectedIssueToView: state.selectedIssueToView.selectedIssue,
    aiTrainerEnabled: state.globalSettings.globalSettings.aiTrainerEnabled,
    selectedExcerpt: state.issues.selectedExcerpt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIssues: (documentId, debug, sortingBy, forceUpdate = false, excerptId) =>
      dispatch(actions.fetchIssues(documentId, debug, sortingBy, forceUpdate, excerptId)),
    startUpdate: () => dispatch(actions.startUpdate()),
    approveAll: (docId, issuesIds) => dispatch(actions.approveAll(docId, issuesIds)),
    createIssue: (data, train, sortBy) => dispatch(actions.createIssue(data, train, sortBy)),
    exportIssues: (docId, type, title, sortingBy, baseUrl) =>
      dispatch(actions.exportIssues(docId, type, title, sortingBy, baseUrl)),
    fetchDocument: (documentId) => dispatch(actions.fetchDocument(documentId)),
    selectProject: (projectId) => dispatch(actions.selectProject(projectId)),
    logout: () => dispatch(actions.logout()),
    deleteViewSelectedIssue: () => dispatch(actions.deleteViewSelectedIssue()),
    fetchDocumentNoFindingTypes: (documentId) =>
      dispatch(actions.fetchDocumentNoFindingTypes(documentId)),
    fetchExcerptById: (id) => dispatch(actions.fetchExcerptById(id)),
    fetchAdvancedLearningIssues: (docId, excerptType, probability) =>
      dispatch(actions.fetchAdvancedLearningIssues(docId, excerptType, probability)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withPermissions(Review));
