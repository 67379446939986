import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { groupBy } from '../../../services/groupBy';
import { ExcerptType } from './ExcerptType/ExcerptType';
import { FINDING_CATEGORY, ORIGIN } from '../../../constants/constants';
import Icon from '../../UI/Icon/Icon';
import Issues from './Issues';
import type { Excerpt, Project } from '../../../types';
import { updateExcerptActions } from '../../../store/actions';

type Props = {
  project: Project,
  docId: string,
  docContractId: string,
  isAllTypesExpanded: boolean,
  selectedIssue: Excerpt,
  selectIssue: (issue: Excerpt) => void,
  excerptTypeDetails: Array<any>,
  issues: Array<Excerpt>,
  isMarkedAs: (category: string, findingName: string) => boolean,
  hasTrainingPermission: boolean,
};

const groupByExcerptType = groupBy('excerptType');

const IssuesGroupedByType = ({
  project,
  docId,
  docContractId,
  isAllTypesExpanded,
  selectedIssue,
  selectIssue,
  excerptTypeDetails,
  issues,
  isMarkedAs,
  hasTrainingPermission,
}: Props) => {
  const dispatch = useDispatch();

  const createExcerptTypeAction = (globalConfig, actions: any) => {
    globalConfig.excerptActions = actions;
    dispatch(updateExcerptActions(project.id, docContractId, [globalConfig]));
  };

  const getMissingImportantFindings = (issuesGroupedByExcerptType) => {
    const globalConfig = project.contractConfig.find(
      (contractType) => contractType.id === docContractId,
    ).globalConfig;
    return globalConfig.filter(
      (type) =>
        type.origin === ORIGIN.STATIC &&
        type.findingCategory === FINDING_CATEGORY.IMPORTANT &&
        typeof issuesGroupedByExcerptType[type.excerptType.toLowerCase().trim()] === 'undefined',
    );
  };

  const [issuesGroupedByExcerptType, setIssuesGroupedByExcerptType] = useState(
    groupByExcerptType(issues),
  );
  const [missingImportantFindings, setMissingImportantFindings] = useState(
    getMissingImportantFindings(issuesGroupedByExcerptType),
  );

  useEffect(() => {
    const groupedByType = groupByExcerptType(issues);
    setIssuesGroupedByExcerptType(groupedByType);
    setMissingImportantFindings(getMissingImportantFindings(groupedByType));
    // eslint-disable-next-line
  }, [issues]);

  return (
    <div className="al-review-entries-list">
      {issues.length > 0 ? (
        Object.keys(issuesGroupedByExcerptType).map((keyName) => {
          const excerpts = issuesGroupedByExcerptType[keyName];
          const excerptsCount = excerpts.length;
          return (
            <ExcerptType
              key={keyName + excerptsCount}
              docId={docId}
              excerptType={keyName}
              isExpanded={
                !!excerpts.find(
                  (item) => selectedIssue && item.excerptId === selectedIssue.excerptId,
                ) || isAllTypesExpanded
              }
              excerptTypeDetails={excerptTypeDetails}
              showCollapse={true}
              showImportant={false}
              showCritical={isMarkedAs(FINDING_CATEGORY.CRITICAL, keyName, true, true)}
              count={excerptsCount}
            >
              <Issues
                project={project}
                selectedIssue={selectedIssue}
                selectIssue={selectIssue}
                issues={excerpts}
                docContractId={docContractId}
                createAction={createExcerptTypeAction}
                hasTrainingPermission={hasTrainingPermission}
              />
            </ExcerptType>
          );
        })
      ) : (
        <div className={'al-review-entries'}>
          <div className="al-info-msg al-mt-40">
            No findings found yet, please give us a minute or two to process the entire document.
            However you can already start to train our system, creating findings in "Document Mode"
            by selecting text sections with your mouse.
          </div>
        </div>
      )}

      {missingImportantFindings.length > 0 && (
        <div className={'missing-issues-container'}>
          <div className={'missing-issues-header'}>
            <Icon
              className={'missing-finding-icon'}
              title="Important missing finding"
              icon="exclamation-triangle-fill active"
              show={true}
            />
            <span>{`Document doesn't have the following:`}</span>
          </div>
          {missingImportantFindings.map((type, index) => {
            return (
              <ExcerptType
                key={index}
                docId={docId}
                excerptType={type.excerptType}
                isExpanded={false}
                excerptTypeDetails={excerptTypeDetails}
                showCollapse={true}
                showImportant={false}
                showCritical={false}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default IssuesGroupedByType;
