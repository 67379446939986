// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProjectList from '../../components/Projects/ProjectList';
import { fetchProjects, createProject, renameProject, deleteProject } from '../../store/actions';
import type { Project } from '../../types';

const Projects = () => {
  useEffect(() => {
    //onFetchProjects();
  }, []);

  // const creatingProject = useSelector((state) => state.project.creatingProject);
  const projects = useSelector((state) => state.project.projects);
  const projectId = useSelector((state) => state.project.projectId);
  const userRoles = useSelector((state) => state.auth.roles);
  // const creatingError = useSelector(state => state.project.creatingProject.error);

  const dispatch = useDispatch();

  const onFetchProjects = () => {
    dispatch(fetchProjects());
  };
  const onCreateProject = (project, projectIdToCopyOver) => {
    dispatch(createProject(project, projectIdToCopyOver));
  };
  const onRenameProject = (projectId, newName) => {
    dispatch(renameProject(projectId, newName));
  };
  const onDeleteProject = (project: Project) => {
    dispatch(deleteProject(project));
  };

  return (
    <div className="al-container al-container--projectList">
      <ProjectList
        activeProject={projectId}
        projects={projects}
        onCreateProject={onCreateProject}
        onRenameProject={onRenameProject}
        onDeleteProject={projects.size > 1 ? onDeleteProject : null}
        hasUserRoles={userRoles.length > 0}
      />
    </div>
  );
};

export default Projects;
