// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ClausesSelect from '../../Settings/ClausesSelect/ClausesSelect';
import { ORIGIN } from '../../../constants/constants';
import Button from '../../UI/Button/Button';
import { Map } from 'immutable';
import type { ContractType } from '../../../types';
import { Gavel } from '@material-ui/icons';
import {
  deleteUserGlobalConfigs,
  fetchContractAggregationsStatistics,
  toggleEnableStaticGlobalConfig,
  updateGlobalConfigCategories,
  updateGlobalConfigAdvLearningTypes,
  bootStrapStatic,
} from '../../../store/actions';

type Props = {
  projectId: string,
  contractType: ContractType,
  globalConfigs: Map<string, any>,
  showEditContractTypeModal: (contract: ContractType) => void,
  onDeleteContractType: (contract: ContractType) => void,
};

const ContractTypeItem = ({
  projectId,
  contractType,
  globalConfigs,
  showEditContractTypeModal,
  onDeleteContractType,
}: Props) => {
  const dispatch = useDispatch();
  const [staticGlobalConfigEnabledDisabled, setStaticGlobalConfigEnabledDisabled] = useState({});
  const [staticGlobalConfigCategories, setStaticGlobalConfigCategories] = useState({});
  const [alGlobalConfigCategories, setAlGlobalConfigCategories] = useState({});
  const [alGlobalConfigAdvLearningTypes, setAlGlobalConfigAdvLearningTypes] = useState({});
  const [isEditingStaticFindings, setIsEditingStaticFindings] = useState(false);
  const [isEditingAlFindings, setIsEditingAlFindings] = useState(false);

  useEffect(() => {
    dispatch(fetchContractAggregationsStatistics(projectId, contractType.id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setStaticGlobalConfigsCopy(getGlobalConfigs(true));
    setAlGlobalConfigsCopy(getGlobalConfigs(false));
    // eslint-disable-next-line
  }, [globalConfigs]);

  const getGlobalConfigs = (isStaticFindings) => {
    let copyGlobalConfigs = JSON.parse(JSON.stringify(globalConfigs));
    if (isStaticFindings) {
      return copyGlobalConfigs
        .filter((gc) => gc.origin === ORIGIN.STATIC)
        .sort((a, b) =>
          a.excerptType.trim().toLowerCase() > b.excerptType.trim().toLowerCase() ? 1 : -1,
        );
    } else {
      return copyGlobalConfigs
        .filter((item) => item.origin !== ORIGIN.STATIC)
        .reduce((acc, current) => {
          const x = acc.find((item) => item.excerptType === current.excerptType);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, [])
        .sort((a, b) =>
          a.excerptType.trim().toLowerCase() > b.excerptType.trim().toLowerCase() ? 1 : -1,
        );
    }
  };

  const [staticGlobalConfigsCopy, setStaticGlobalConfigsCopy] = useState(getGlobalConfigs(true));
  const [alGlobalConfigsCopy, setAlGlobalConfigsCopy] = useState(getGlobalConfigs(false));

  const onDeleteUserGlobalConfigs = (excerptType, globalConfigId = null) => {
    dispatch(deleteUserGlobalConfigs(excerptType, projectId, contractType.id, globalConfigId));
  };

  const updateGlobalConfigs = () => {
    const contractId = contractType.id;
    const updatedEnableDisable = Object.values(staticGlobalConfigEnabledDisabled);
    const updatedStaticCategories = Object.values(staticGlobalConfigCategories);
    const updatedAdvLearningCategories = Object.values(alGlobalConfigCategories);
    const updatedAdvLearningTypes = Object.values(alGlobalConfigAdvLearningTypes);

    if (isEditingStaticFindings) {
      if (updatedEnableDisable.length) {
        dispatch(toggleEnableStaticGlobalConfig(projectId, contractId, updatedEnableDisable));
      }
      if (updatedStaticCategories.length) {
        dispatch(updateGlobalConfigCategories(projectId, contractId, updatedStaticCategories));
      }
    } else {
      if (updatedAdvLearningCategories.length) {
        dispatch(updateGlobalConfigCategories(projectId, contractId, updatedAdvLearningCategories));
      }
      if (updatedAdvLearningTypes.length) {
        dispatch(
          updateGlobalConfigAdvLearningTypes(projectId, contractId, updatedAdvLearningTypes),
        );
      }
    }
  };

  const resetChangesStaticFindings = () => {
    setIsEditingStaticFindings(false);
    setStaticGlobalConfigEnabledDisabled({});
    setStaticGlobalConfigCategories({});
  };

  const resetChangesAlFindings = () => {
    setIsEditingAlFindings(false);
    setAlGlobalConfigCategories({});
    setAlGlobalConfigAdvLearningTypes({});
  };

  return (
    <div className="al-contractType">
      <div className="al-contractType-header">
        <label className={'al-contractType-title'}>
          <Gavel />
          <span>
            {contractType.contractType.trim().toLowerCase() === 'others'
              ? 'UNASSIGNED'
              : contractType.contractType}
          </span>
        </label>
        <span className={'al-contractType-actions al-ml-20'}>
          <Button
            show={contractType.contractType.trim().toLowerCase() !== 'others'}
            withIcon={true}
            iconType={'edit'}
            clicked={() => {
              showEditContractTypeModal(contractType);
            }}
          />
          <Button
            show={contractType.contractType.trim().toLowerCase() !== 'others'}
            withIcon={true}
            iconType={'delete'}
            clicked={() => onDeleteContractType(contractType)}
          />
        </span>
      </div>

      <div className="al-settings">
        <div className={'al-clauses-wrapper'}>
          <ClausesSelect
            title={'Static findings'}
            isStaticFinding={true}
            contractType={contractType}
            globalConfigs={staticGlobalConfigsCopy}
            updateGlobalConfigEnableDisableList={(config) => {
              let updatedConfigs = staticGlobalConfigEnabledDisabled;
              updatedConfigs[config.id] = config;
              setStaticGlobalConfigEnabledDisabled(updatedConfigs);
            }}
            updateGlobalConfigCategoriesList={(config) => {
              setStaticGlobalConfigCategories({
                ...staticGlobalConfigCategories,
                [config.id]: config,
              });
            }}
            bootStrapStaticType={(staticType) =>
              dispatch(bootStrapStatic(staticType, projectId, contractType.id))
            }
            isEditingStaticFindings={isEditingStaticFindings}
            setIsEditingStaticFindings={setIsEditingStaticFindings}
          />
          {isEditingStaticFindings && (
            <div className={'bottom-buttons'}>
              <Button
                clicked={() => {
                  updateGlobalConfigs();
                  resetChangesStaticFindings();
                }}
                className={'al-primary'}
                style={{ marginRight: '10px' }}
              >
                Save
              </Button>
              <Button
                clicked={() => {
                  resetChangesStaticFindings();
                  setStaticGlobalConfigsCopy(getGlobalConfigs(true));
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>

        <div className={'al-clauses-wrapper'}>
          <ClausesSelect
            title={'Advanced learning types'}
            isStaticFinding={false}
            projectId={projectId}
            contractType={contractType}
            globalConfigs={alGlobalConfigsCopy}
            updateGlobalConfigCategoriesList={(config) => {
              setAlGlobalConfigCategories({
                ...alGlobalConfigCategories,
                [config.id]: config,
              });
            }}
            updateGlobalConfigAdvLearningTypesList={(config) => {
              setAlGlobalConfigAdvLearningTypes({
                ...alGlobalConfigAdvLearningTypes,
                [config.id]: config,
              });
            }}
            deleteUserGlobalConfigs={(globalConfigId, excerptType) =>
              onDeleteUserGlobalConfigs(excerptType, globalConfigId)
            }
            isEditingAlFindings={isEditingAlFindings}
            setIsEditingAlFindings={setIsEditingAlFindings}
          />
          <div className={'bottom-buttons'}>
            {isEditingAlFindings && (
              <div className={'bottom-buttons'}>
                <Button
                  clicked={() => {
                    updateGlobalConfigs();
                    resetChangesAlFindings();
                  }}
                  className={'al-primary'}
                  style={{ marginRight: '10px' }}
                >
                  Save
                </Button>
                <Button
                  clicked={() => {
                    resetChangesAlFindings();
                    setAlGlobalConfigsCopy(getGlobalConfigs(false));
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractTypeItem;
