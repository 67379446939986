import * as React from 'react';
import { PermissionsContext } from './permissions-context';
import { connect } from 'react-redux';

const PermissionsProvider = props => {
  const { userPermissions, userRoles, username } = props;

  const hasPermission = (permission: string) => {
    return userPermissions.indexOf(permission) > -1;
  };

  return (
    <PermissionsContext.Provider
      value={{
        PermissionsContext: {
          ...{ userPermissions, hasPermission, userRoles, username },
        },
      }}
    >
      {props.children}
    </PermissionsContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    userPermissions: state.auth.permissions,
    userRoles: state.auth.roles,
    username: state.auth.username,
  };
};
export default connect(mapStateToProps)(PermissionsProvider);
