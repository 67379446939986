import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import RightLeftController from '../../components/UI/RightLeftCollector/RightLeftCollector';
import type { PermissionSet } from '../../types';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/Spinner';
import permissionSets from '../../store/reducers/permissionSets';

type Props = {
  title: string,
  permissionSet: PermissionSet,
  permissions: Array<string>,
  onClose: () => void,
  createPermissionSet: (permissionSet: PermissionSet) => void,
  updatePermissionSet: (permissionSet: PermissionSet) => void,
  fetchPermissions: () => void,
  fetchingPermissions: boolean,
};

const CreatePermissionSetModal = ({
  title,
  allPermissionSets,
  permissionSet,
  permissions,
  onClose,
  createPermissionSet,
  updatePermissionSet,
  fetchPermissions,
  fetchingPermissions,
}: Props) => {
  const [selectedPermissions, setSelectedPermissions] = useState(permissionSet.permissions);
  const [name, setName] = useState(permissionSet.name);
  const [usedPermissions, setUsedPermissions] = useState(new Set());

  useEffect(() => {
    fetchPermissions();
    let permissionSets = [];
    allPermissionSets.forEach((permission) => {
      permissionSets = permissionSets.concat(permission.permissions);
    });
    setUsedPermissions(new Set(permissionSets));
    // eslint-disable-next-line
  }, []);

  const onMove = (leftItems: Array<string>, rightItems: Array<string>) => {
    const selectedPermissions: Array<string> = permissions.filter(
      (permission) => leftItems.indexOf(permission) > -1,
    );
    setSelectedPermissions(selectedPermissions);
  };

  const getLeftPermissions = () => {
    return permissions.filter((permission) => permissionSet.permissions.indexOf(permission) > -1);
  };

  const getRightPermissions = () => {
    return permissions.filter((permission) => permissionSet.permissions.indexOf(permission) === -1);
  };

  const save = () => {
    let updatedPermissionSet: PermissionSet = { ...permissionSet };
    updatedPermissionSet.name = name;
    updatedPermissionSet.permissions = selectedPermissions;
    if (!updatedPermissionSet.id) {
      createPermissionSet(updatedPermissionSet);
    } else {
      updatePermissionSet(updatedPermissionSet);
    }
    onClose();
  };

  return (
    <Modal show={true}>
      <ModalHeader onClose={onClose} title={title} />
      <ModalContent>
        <Input
          elementType={'input'}
          elementConfig={{ type: 'text' }}
          placeholder={'Permission set name'}
          value={name}
          changed={(e) => setName(e.target.value)}
        />
        {fetchingPermissions ? (
          <Spinner />
        ) : (
          <RightLeftController
            onMove={onMove}
            leftTitle={'Assigned permissions:'}
            leftItemsProps={getLeftPermissions()}
            rightTitle={'All permissions:'}
            rightItemsProps={getRightPermissions()}
            usedPermissions={usedPermissions}
          />
        )}
      </ModalContent>
      <ModalFooter>
        <span style={{ color: 'lightcoral' }}>
          * light red color denotes a permission that has not been assigned
        </span>
        <div>
          <Button className={'al-primary'} clicked={save}>
            Save
          </Button>
          <Button clicked={onClose}>Cancel</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    fetchingPermissions: state.permissions.fetchingPermissions,
    permissions: state.permissions.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPermissionSet: (permissionSet: PermissionSet) =>
      dispatch(actions.createPermissionSet(permissionSet)),
    updatePermissionSet: (permissionSet: PermissionSet) =>
      dispatch(actions.updatePermissionSet(permissionSet)),
    fetchPermissions: () => dispatch(actions.fetchPermissions()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePermissionSetModal);
