// @flow
import React from 'react';
import type { DropDownAction } from '../../../types';
import Icon from '../Icon/Icon';
type Props = {
  actions: Map<string, DropDownAction>,
};

class UL extends React.Component {

  render() {
    const formElementsArray = [];
    for (let key in this.props.actions) {
      if (this.props.actions[key].show !== false) {
        formElementsArray.push({
          id: key,
          config: this.props.actions[key],
        });
      }
    }

    let items = formElementsArray.map(action => (
      <li
        key={action.id}
        onClick={e => {
          action.config.click(e, action.id, action.config.label);
        }}
        className={action.config.className ? action.config.className : ''}
      >
        {action.config.icon ? <Icon icon={action.config.icon} /> : ''}
        <label>{action.config.label}</label>
      </li>
    ));

    return (
        <ul className="al-dropdown-ul">{items}</ul>
    );
  }
}

export default UL;
