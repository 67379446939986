import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAdvancedLearningIssues,
  fetchDocsByOrigin,
  fetchStatisticsByType,
  selectPageNumberExplore,
} from '../../store/actions';
import { DEFAULT_PAGE_NUMBER, ORIGIN } from '../../constants/constants';
import { FormattedMessage } from 'react-intl';
import TabNav from '../UI/Tabs/TabNav/TabNav';
import Tabs from '../UI/Tabs/Tabs';
import TabNavItem from '../UI/Tabs/TabNavItem/TabNavItem';
import TabPanel from '../UI/Tabs/TabPanel/TabPanel';
import TabPanelItem from '../UI/Tabs/TabPanelItem/TabPanelItem';
import Spinner from '../UI/Spinner/Spinner';
import StaticExcerpts from './StaticExcerpts';
import UserExcerpts from './UserExcerpts';
import AdvancedLearningExcerpts from './AdvancedLearningExcerpts';
import { round } from '../../services/round';
import { usePrevious } from '../../hooks/prevValue';
import PageNumbering from '../UI/PageNumbering/PageNumbering';

type Props = {
  projectId: string,
  contractId: string,
  isLoading: boolean,
  error: any,
  excerptType: string,
  selectOrigin: (origin: string) => void,
  selectedOrigin: string,
  hasConvertToUserPermission: boolean,
  hasRecognizeCorrectTrainPermission: boolean,
  hasRecognizeIncorrectTrainPermission: boolean,
  hasTrainingPermission: boolean,
};

const DEFAULT_THRESHOLD = 10;
const DOCS_PER_PAGE = 5;
const SHOW_ALL_EXCEPTS_IN_PAGE = true;

const ExploreWorkspace = ({
  projectId,
  contractId,
  isLoading,
  error,
  excerptType,
  selectOrigin,
  selectedOrigin,
  hasConvertToUserPermission,
  hasRecognizeCorrectTrainPermission,
  hasRecognizeIncorrectTrainPermission,
  hasTrainingPermission,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const originsAggregations = useSelector(
    (state) => state.statistics.statisticsAll.fetchingContractStatistics.origins,
  );
  const docsExcerptsCounts = useSelector(
    (state) => state.statistics.statisticsByType.docsExcerptsCounts,
  );
  const isRetrievingExcerpts = useSelector(
    (state) => state.statistics.statisticsByType.isRetrievingExcerpts,
  );
  const excerptsByDoc = useSelector((state) => state.statistics.statisticsByType.excerpts);
  const page = useSelector((state) => state.project.selectedPageNumberExplore);

  const [staticFindingsCount, setStaticFindingsCount] = useState(0);
  const [userFindingsCount, setUserFindingsCount] = useState(0);
  const [advancedLearningFindingsCount, setAdvancedLearningFindingsCount] = useState(0);
  const [threshold, setThreshold] = useState(DEFAULT_THRESHOLD);
  const [excerptTypeAggregations, setExcerptTypeAggregations] = useState({});
  const [docsExcerpts, setDocsExcerpts] = useState({});
  const prevExcerptsByDoc = usePrevious(excerptsByDoc);
  const prevDocsExcerptsCounts = usePrevious(docsExcerptsCounts);

  useEffect(() => {
    setThreshold(DEFAULT_THRESHOLD);
    changePageNumber(DEFAULT_PAGE_NUMBER);
    setDocsExcerpts({}); // reset to fill with docs aggregations of new selected type
    // eslint-disable-next-line
  }, [excerptType]);

  const handleSelectTab = (origin) => {
    setExcerptTypeAggregations({});
    selectOrigin(origin);
    dispatch(fetchDocsByOrigin(projectId, contractId, excerptType, origin));
  };

  useEffect(() => {
    // on excerpt type change
    if (Object.keys(originsAggregations).length > 0) {
      const newStaticCount = originsAggregations[ORIGIN.STATIC]
        ? originsAggregations[ORIGIN.STATIC]
        : 0;
      const newUserCount = originsAggregations[ORIGIN.USER] ? originsAggregations[ORIGIN.USER] : 0;
      const newAdvancedCount = originsAggregations[ORIGIN.ADVANCED]
        ? originsAggregations[ORIGIN.ADVANCED]
        : 0;

      setStaticFindingsCount(newStaticCount);
      setUserFindingsCount(newUserCount);
      setAdvancedLearningFindingsCount(newAdvancedCount);

      let newSelectedOrigin = selectedOrigin;
      // if prev selectedOrigin equals 0 for the new selected type, change selected
      if (selectedOrigin === ORIGIN.STATIC) {
        if (newStaticCount === 0) {
          newSelectedOrigin = newUserCount > 0 ? ORIGIN.USER : ORIGIN.ADVANCED;
        }
      } else if (selectedOrigin === ORIGIN.USER) {
        if (newUserCount === 0) {
          newSelectedOrigin = newStaticCount > 0 ? ORIGIN.STATIC : ORIGIN.ADVANCED;
        }
      } else if (selectedOrigin === ORIGIN.ADVANCED) {
        if (newAdvancedCount === 0) {
          newSelectedOrigin = newStaticCount > 0 ? ORIGIN.STATIC : ORIGIN.USER;
        }
      } else {
        newSelectedOrigin = newStaticCount > 0 ? ORIGIN.STATIC : ORIGIN.USER;
      }
      selectOrigin(newSelectedOrigin);
      dispatch(fetchDocsByOrigin(projectId, contractId, excerptType, newSelectedOrigin));
    }
    // eslint-disable-next-line
  }, [originsAggregations]);

  useEffect(() => {
    if (
      typeof prevDocsExcerptsCounts !== 'undefined' &&
      docsExcerptsCounts.length > 0 &&
      selectedOrigin
    ) {
      const startIndex = DOCS_PER_PAGE * (page - 1);
      const endIndex = startIndex + DOCS_PER_PAGE;
      const docsInPage = docsExcerptsCounts.slice(startIndex, endIndex);
      setDocsExcerpts({});
      for (const [i, doc] of docsInPage.entries()) {
        let docInfo = docsExcerpts[doc.key];
        if (typeof docInfo === 'undefined') {
          docInfo = {
            docTitle: doc.topHits[0].docTitle,
            count: doc.count,
            expanded: true,
            excerpts: [],
          };
          docsExcerpts[doc.key] = docInfo;
        } else {
          docsExcerpts[doc.key].count = doc.count;
        }
        if (SHOW_ALL_EXCEPTS_IN_PAGE) {
          fetchExcerptsList(doc.key);
          docInfo.expanded = true;
        } else if (i === 0) {
          fetchExcerptsList(docsInPage[0].key);
          docInfo.expanded = true;
        }
      }
      setExcerptTypeAggregations(docsExcerpts);
    }
    // eslint-disable-next-line
  }, [page, docsExcerptsCounts]);

  const fetchExcerptsList = (docId) => {
    dispatch(fetchStatisticsByType(docId, excerptType, selectedOrigin, projectId, contractId));
  };

  const changePageNumber = (page) => {
    dispatch(selectPageNumberExplore(page));
  };

  useEffect(() => {
    if (typeof prevExcerptsByDoc !== 'undefined') {
      let updatedDocsExcerpts = { ...excerptTypeAggregations };
      let docInfo = updatedDocsExcerpts[excerptsByDoc['docId']];
      if (docInfo) {
        updatedDocsExcerpts[excerptsByDoc['docId']].excerpts = excerptsByDoc['excerpts'];
      }
      setExcerptTypeAggregations(updatedDocsExcerpts);
    }
    // eslint-disable-next-line
  }, [excerptsByDoc]);

  const openDocument = (
    docId: string,
    excerptId: string,
    excerptType?: string,
    probability?: number,
  ) => {
    if (excerptType && probability) {
      dispatch(fetchAdvancedLearningIssues(docId, excerptType, round(probability)));
    }
    history.push(`review/${docId}/${excerptId}`);
  };

  const openReviewDocument = (docId) => {
    history.push('/review/' + docId);
  };

  const onThresholdChange = (updatedThreshold) => {
    setThreshold(updatedThreshold);
  };

  return (
    <div className="al-inspect-workspace">
      <Tabs>
        <TabNav>
          {staticFindingsCount > 0 && (
            <TabNavItem
              selectTab={() => handleSelectTab(ORIGIN.STATIC)}
              isSelected={selectedOrigin === ORIGIN.STATIC}
            >
              <label>
                <span>
                  <FormattedMessage id={'app.inspect.origin.static'} defaultMessage="" />
                </span>
                <span>({staticFindingsCount})</span>
              </label>
            </TabNavItem>
          )}
          {userFindingsCount && (
            <TabNavItem
              selectTab={() => handleSelectTab(ORIGIN.USER)}
              isSelected={selectedOrigin === ORIGIN.USER}
            >
              <label>
                <span>
                  <FormattedMessage id={'app.inspect.origin.user'} defaultMessage="" />
                </span>
                <span>({userFindingsCount})</span>
              </label>
            </TabNavItem>
          )}
          {advancedLearningFindingsCount && (
            <TabNavItem
              selectTab={() => handleSelectTab(ORIGIN.ADVANCED)}
              isSelected={selectedOrigin === ORIGIN.ADVANCED}
            >
              <label>
                <span>
                  <FormattedMessage id={'app.inspect.origin.advanced'} defaultMessage="" />
                </span>
                <span>({advancedLearningFindingsCount})</span>
              </label>
            </TabNavItem>
          )}
        </TabNav>
        <TabPanel>
          <TabPanelItem>
            <div className="al-inspect-result-group-wrapper">
              {isLoading || isRetrievingExcerpts ? (
                <Spinner />
              ) : Object.keys(excerptTypeAggregations).length > 0 ? (
                selectedOrigin === ORIGIN.STATIC ? (
                  <StaticExcerpts
                    excerptTypeAggregations={excerptTypeAggregations}
                    openDocument={openDocument}
                    openReviewDocument={openReviewDocument}
                    fetchExcerptsList={fetchExcerptsList}
                  />
                ) : selectedOrigin === ORIGIN.USER ? (
                  <UserExcerpts
                    excerptTypeAggregations={excerptTypeAggregations}
                    openDocument={openDocument}
                    openReviewDocument={openReviewDocument}
                    fetchExcerptsList={fetchExcerptsList}
                    hasTrainingPermission={hasTrainingPermission}
                  />
                ) : (
                  selectedOrigin === ORIGIN.ADVANCED && (
                    <AdvancedLearningExcerpts
                      excerptTypeAggregations={excerptTypeAggregations}
                      openDocument={openDocument}
                      threshold={threshold}
                      hasConvertToUserPermission={hasConvertToUserPermission}
                      hasRecognizeCorrectTrainPermission={hasRecognizeCorrectTrainPermission}
                      hasRecognizeIncorrectTrainPermission={hasRecognizeIncorrectTrainPermission}
                      onThresholdChange={onThresholdChange}
                      openReviewDocument={openReviewDocument}
                      fetchExcerptsList={fetchExcerptsList}
                    />
                  )
                )
              ) : error ? (
                'Error while fetching results'
              ) : selectedOrigin === ORIGIN.STATIC ? (
                'No static extraction results found'
              ) : selectedOrigin === ORIGIN.USER ? (
                'No user annotated findings found'
              ) : selectedOrigin === ORIGIN.ADVANCED ? (
                'No advanced learning results found'
              ) : (
                'There are no annotations'
              )}
            </div>
          </TabPanelItem>
        </TabPanel>
        <PageNumbering
          numPages={Math.ceil(docsExcerptsCounts.length / DOCS_PER_PAGE)}
          setPage={(page) => {
            changePageNumber(page);
          }}
          page={page}
        />
      </Tabs>
    </div>
  );
};

export default ExploreWorkspace;
