import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import type { SliderMarks } from '../../../types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 308,
    display: 'flex',
    zIndex: 0,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const iOSBoxShadow =
  '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const MySlider = withStyles({
  root: {
    color: '#008eb7',
    height: 2,
    padding: '15px 0',
    marginLeft: 15,
    marginBottom: 0,
  },
  thumb: {
    height: 22,
    width: 22,
    backgroundColor: '#008eb7',
    boxShadow: iOSBoxShadow,
    marginTop: -10,
    marginLeft: -10,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: (props) => (props.defaultValue === 100 ? 'calc(-50% - 3px)' : 'calc(-50% + 5px)'),
    top: -20,
    '& *': {
      background: 'transparent',
      color: '#008eb7',
      width: 'auto'
    },
    width: 'auto',
    background: '#c9d8e3',
    padding: 4,
    borderRadius: 5,
    height: 26,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    fontSize: 13,
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: 'transparent',
    // height: 8,
    // width: 1,
    // marginTop: -3,
  },
  // markActive: {
  //   opacity: 1,
  //   backgroundColor: 'currentColor',
  // },
})(Slider);

const initialMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
];

function valuetext(value) {
  return `${value} %`;
}

type Props = {
  title?: string,
  steps?: number,
  main: number,
  max: number,
  marks: Array<SliderMarks>,
  defaultValue: number | string,
  onChangeCommitted: Function,
};

const CustomSlider = ({
  title = 'show marks',
  steps = 10,
  marks = initialMarks,
  defaultValue = 10,
  onSliderValueChanged,
  min = 0,
  max = 100,
}: Props) => {
  const classes = useStyles();
  const [defaultValueState, setDefaultValueState] = useState(defaultValue);
  useEffect(() => {
    setDefaultValueState(defaultValue);
  }, [defaultValue]);

  return (
    <div className={classes.root}>
      <Typography id="discrete-slider-custom" gutterBottom>
        {title}
      </Typography>
      <MySlider
        key={`slider-${defaultValueState}`}
        min={min}
        max={max}
        defaultValue={defaultValueState}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-custom"
        step={steps}
        valueLabelDisplay="on"
        marks={marks}
        onChangeCommitted={(event, value) => onSliderValueChanged(event, value)}
        valueLabelFormat={valuetext}
        // ValueLabelComponent={StyledValueLabel}
      />
    </div>
  );
};

export default CustomSlider;
