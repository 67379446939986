//@flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as actions from '../../store/actions/index';
import FileList from './FileList/FileList';
import Button from '../../components/UI/Button/Button';
import Warning from '../../components/UI/Typo/Warning';
import CustomTooltip from '../../components/UI/Tooltip/Tooltip';
import type { AppState } from '../../types/reduxTypes';
import { sortBy } from '../../services/sorting';
import { PERMISSIONS, SERVER_EVENTS } from '../../constants/constants';
import { withRouter } from 'react-router-dom';
import { withPermissions } from '../../contexts/PermissionsContext/withPermissions';
import Spinner from '../../components/UI/Spinner/Spinner';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import UploadModal from '../../components/Dashboard/Modal/UploadModal';
import { CreateContractType } from '../ContractTypes/ContractTypes';
import { getAppEventSource } from '../../SSEApp';
import _ from 'lodash';
import { update } from 'immutable';

type Props = {
  isAuthenticated: boolean,
  loading: boolean,
  error: boolean,
  success: boolean,
  document: Object,
  fileName: string,
  projectId: string,
  uploadQueue: Array<string>,
  errorFiles: Array<{ fileName: string, error: string }>,
  downloadAllExcerptsToExcel: () => void,
};

const maxFiles = 200;
const DupicateErrorStatus = 409;

type State = {
  uploadedFiles: Array<Object>,
  error: string,
  contractTypeId?: string,
};
const SUCCESS_COLOR = '#14892C';
const FAILED_COLOR = 'red';

class Dashboard extends Component<Props, State> {
  dragAndDropAreaRef: ReactRefT<HTMLDivElement>;

  constructor(props) {
    super(props);
    this.dragAndDropAreaRef = React.createRef();
    this.updateDocStatus = this.updateDocStatus.bind(this);
    this.updateDocs = this.updateDocs.bind(this);
  }

  state = {
    error: '',
    contractTypeId: undefined,
    showFileUploadWarning: false,
    showUploadingModal: false,
    isDragging: false,
    showCreateContractTypeModal: false,
  };

  navigateTo = (path: string) => {
    this.props.history.push(`/${path}`);
  };

  updateDocStatus = (e) => {
    const document = JSON.parse(e.data);
    console.debug(`Emitter: ${e.type} ${document.title} ${document.analyzeStatus}`);
    if (document.projectId && document.projectId === this.props.projectId) {
      this.props.updateDocumentStatus(document);
    }
  };

  updateDocs = _.debounce((e) => {
    const document = JSON.parse(e.data);
    console.debug('Emitter: document status update');
    if (document.projectId && document.projectId === this.props.projectId) {
      // update Upload page only if the current project is the one receiving the server updates
      this.props.fetchDocumentsCountBy(this.props.projectId);
      this.props.getUpdatedDocumentsList(
        this.props.projectId,
        this.props.contractId,
        this.props.page,
        this.props.sortFileListBy,
        this.props.statusFilter,
        this.props.findingCategory,
      );
    }
  }, 1000);

  componentDidMount(): * {
    if (!this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_UPLOAD)) {
      if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH)) {
        this.navigateTo('search');
      } else if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_REVIEW)) {
        this.navigateTo('review');
      } else if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_PROJECTS)) {
        this.navigateTo('projects');
      } else if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_CONTRACT_TYPES)) {
        this.navigateTo(`projects/${this.props.projectId}/contractTypes`);
      } else if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_USERS)) {
        this.navigateTo('users');
      } else if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_ROLES)) {
        this.navigateTo('roles');
      } else if (
        this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_POST) ||
        this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_PUT) ||
        this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_DELETE)
      ) {
        this.navigateTo('searchQueries');
      } else if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_DYNAMIC_TABS)) {
        this.navigateTo('dynamicTabs');
      } else if (this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_EXPLORE)) {
        this.navigateTo('explore');
      }
    } else {
      this.props.fetchDocumentsCountBy(this.props.projectId);

      getAppEventSource().addEventListener(
        SERVER_EVENTS.UPDATE_DOCUMENT_ANALYZED_STATUS,
        this.updateDocStatus,
      );

      getAppEventSource().addEventListener(
        SERVER_EVENTS.UPDATE_DOCUMENT_ANALYZED_STATUS,
        this.updateDocs,
      );
    }
  }

  componentWillUnmount(): * {
    getAppEventSource().removeEventListener(
      SERVER_EVENTS.UPDATE_DOCUMENT_ANALYZED_STATUS,
      this.updateDocStatus,
    );

    getAppEventSource().removeEventListener(
      SERVER_EVENTS.UPDATE_DOCUMENT_ANALYZED_STATUS,
      this.updateDocs,
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State, prevContext: *): * {
    if (this.props.projectId !== prevProps.projectId) {
      this.props.fetchDocumentsCountBy(this.props.projectId);
    } else if (
      this.props.uploadQueue.length !== prevProps.uploadQueue.length &&
      this.props.uploadQueue.length === 0
    ) {
      this.props.fetchDocumentsCountBy(this.props.projectId);
    }
  }

  onDragStart = (event) => {
    if (!this.state.showUploadingModal) {
      event.stopPropagation();
      event.preventDefault();
      this.setState({ isDragging: true });
    }
  };

  onDragEnd = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // avoid drag leave on child of the dragAndDropAreaRef
    if (!this.state.showUploadingModal && event.target === this.dragAndDropAreaRef.current) {
      this.setState({ isDragging: false });
      if (event.dataTransfer.files.length > 0) {
        this.handleUploadFile(event.dataTransfer.files);
      }
    }
  };

  handleUploadFile = (files) => {
    if (files.length > maxFiles) {
      this.setState({ showFileUploadWarning: true });
      return;
    }
    if (files.length) {
      this.setState({ showUploadingModal: true });
    }
    for (let file of files) {
      this.props.uploadFile(file, this.props.projectId, this.props.contractId);
    }
  };

  selectContractId(contractTypeId) {
    this.props.selectContract(contractTypeId);
  }

  openDocument = (docId) => {
    this.props.history.push('/review/' + docId);
  };

  render() {
    const { selectedProject } = this.props;
    const { isDragging } = this.state;
    const contractTypeId = this.props.contractId;
    if (this.props.isAuthenticated) {
      return (
        <div
          className={`al-container al-dashboard ${isDragging ? 'onDrag' : ''}`}
          onDragEnter={this.onDragStart}
          onDragOver={(event) => event.preventDefault()}
          onDragLeave={this.onDragEnd}
          onDrop={this.onDragEnd}
          ref={this.dragAndDropAreaRef}
        >
          {this.state.showUploadingModal && (
            <UploadModal
              successFiles={this.props.successFiles}
              errorFiles={this.props.errorFiles}
              uploadQueue={this.props.uploadQueue}
              uploadingFiles={this.props.uploadingFiles}
              replaceFile={(f) =>
                this.props.replaceFile(f, this.props.projectId, this.props.contractId)
              }
              closeModal={() => {
                if (!this.props.uploadingFiles) {
                  this.props.resetUpload();
                  this.setState({ showUploadingModal: false });
                }
              }}
            />
          )}
          <div className={'al-project-info'}>
            <div className="al-headline">Upload</div>
            <div className="al-description-text">
              {'Upload documents to the project "'}
              <span>{selectedProject.projectType.toLowerCase()}</span>
              {'" and assign them to a document type'}
            </div>
          </div>
          <div className="al-uploader">
            <div className="al-upload-button-wrapper">
              <button>
                <FormattedMessage
                  id={'app.dashboard.drop-here'}
                  defaultMessage={'Drag & drop or click here to upload new documents (PDF or Word)'}
                />
              </button>
              <input
                type="file"
                onChange={(event) => this.handleUploadFile(event.target.files)}
                multiple
                title="Upload documents"
              />
            </div>
          </div>
          <div className="al-contracts-container">
            <div className={'al-contracts-info'}>
              <span className="al-contracts-info-wrapper">
                <label>
                  <span style={{ color: 'black' }}>{selectedProject.projectType}</span>
                  <div className="documents-details-wrapper">
                    {' (Total: '}
                    <CustomTooltip
                      title={'Total documents'}
                      enterDelay={1000}
                      leaveDelay={200}
                      placement="top-end"
                      classNames={{ root: 'al-tooltip' }}
                      interactive={true}
                    >
                      <span>
                        <b>{this.props.allTotalCount}</b>
                      </span>
                    </CustomTooltip>
                    {' / Success: '}
                    <CustomTooltip
                      title={'Successfully uploaded documents'}
                      enterDelay={1000}
                      leaveDelay={200}
                      placement="top-end"
                      classNames={{ root: 'al-tooltip' }}
                      interactive={true}
                    >
                      <span style={{ color: SUCCESS_COLOR }}>{this.props.allSuccessCount}</span>
                    </CustomTooltip>
                    {' / Failed: '}
                    <CustomTooltip
                      title={'Failed documents'}
                      enterDelay={1000}
                      leaveDelay={200}
                      placement="top-end"
                      classNames={{ root: 'al-tooltip' }}
                      interactive={true}
                    >
                      <span style={{ color: FAILED_COLOR }}>{this.props.allFailCount}</span>
                    </CustomTooltip>
                    {')'}
                  </div>
                </label>
                <ul>
                  {selectedProject.contractConfig.sort(sortBy('contractType')).map((contract) => {
                    const contractIdDocsCount = this.props.documentCounts[contract.id];
                    let totalCount = 0,
                      successCount = 0,
                      errorCount = 0;
                    if (typeof contractIdDocsCount !== 'undefined') {
                      totalCount = contractIdDocsCount[0].total;
                      successCount = contractIdDocsCount[1].success;
                      errorCount = contractIdDocsCount[2].error;
                    }
                    return (
                      <li
                        key={contract.id}
                        onClick={() => this.selectContractId(contract.id)}
                        className={contractTypeId === contract.id ? 'al-active' : ''}
                      >
                        <span className="contract-type-name">
                          {contract.contractType.toLowerCase() === 'others'
                            ? 'Unassigned'
                            : contract.contractType}
                        </span>
                        <div className="documents-details-wrapper">
                          {'(Total: '}
                          <CustomTooltip
                            title={'Total documents'}
                            enterDelay={1000}
                            leaveDelay={200}
                            placement="top-end"
                            classNames={{ root: 'al-tooltip' }}
                            interactive={true}
                          >
                            <span>
                              <b>{`${totalCount}`}</b>
                            </span>
                          </CustomTooltip>
                          {' / Success: '}
                          <CustomTooltip
                            title={'Successfully processed documents'}
                            enterDelay={1000}
                            leaveDelay={200}
                            placement="top-end"
                            classNames={{ root: 'al-tooltip' }}
                            interactive={true}
                          >
                            <span style={{ color: SUCCESS_COLOR }}>{`${successCount}`}</span>
                          </CustomTooltip>
                          {' / Failed: '}
                          <CustomTooltip
                            title={'Failed documents'}
                            enterDelay={1000}
                            leaveDelay={200}
                            placement="top-end"
                            classNames={{ root: 'al-tooltip' }}
                            interactive={true}
                          >
                            <span style={{ color: FAILED_COLOR }}>{`${errorCount}`}</span>
                          </CustomTooltip>
                          {')'}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </span>
              {this.props.PermissionsContext.hasPermission(PERMISSIONS.SHOW_CONTRACT_TYPES) && (
                <div className={'add-contract-type-button-container'}>
                  <Button
                    className={'al-primary al-create-new-contract'}
                    clicked={() => {
                      this.setState({ showCreateContractTypeModal: true });
                    }}
                  >
                    {' '}
                    ADD NEW DOCUMENT TYPE{' '}
                  </Button>
                </div>
              )}
            </div>
            <div className="file-list-wrapper">
              <FileList contractTypeId={contractTypeId} openDocument={this.openDocument} />
            </div>
          </div>

          {/*{!!errorFiles.length && (*/}
          {/*  <div className={'al-documents-upload-failed'}>*/}
          {/*    <label>Failed documents</label>*/}
          {/*    <ul>*/}
          {/*      {errorFiles.map(({ fileName, error }) => (*/}
          {/*        <li key={fileName}>*/}
          {/*          {fileName}: ({error.message})*/}
          {/*        </li>*/}
          {/*      ))}*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*)}*/}

          {this.state.showFileUploadWarning && (
            <Modal show={true}>
              <ModalHeader
                title={'Error on uploading files'}
                onClose={() => {
                  this.setState({ showFileUploadWarning: false });
                }}
              />
              <ModalContent>
                <Warning>{`Can't upload more than ${maxFiles} files at once `}</Warning>
              </ModalContent>
              <ModalFooter>
                <Button
                  className={'al-primary'}
                  clicked={() => {
                    this.setState({ showFileUploadWarning: false });
                  }}
                >
                  ok
                </Button>
              </ModalFooter>
            </Modal>
          )}

          {this.state.showCreateContractTypeModal && (
            <CreateContractType
              project={this.props.selectedProject}
              onCancel={() => {
                this.setState({ showCreateContractTypeModal: false });
              }}
            />
          )}
        </div>
      );
    } else {
      return '';
    }
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    isAuthenticated: localStorage.getItem('token') !== null,
    projectId: state.project.projectId,
    contractId: state.project.contractId,
    selectedProject: state.project.projects.get(state.project.projectId),
    uploadQueue: state.document.uploadQueue,
    errorFiles: state.document.errorFiles,
    successFiles: state.document.successFiles,
    uploadingFiles: state.document.uploadingFiles,
    documentCounts: state.project.counts,
    allTotalCount: state.project.allTotalCount,
    allSuccessCount: state.project.allSuccessCount,
    allFailCount: state.project.allFailCount,
    page: state.document.globallySelectedPageNumber,
    sortFileListBy: state.document.globallySelectedSortDocsBy,
    statusFilter: state.document.globallySelectedStatusFilter,
    findingCategory: state.document.globallySelectedFilterDocsBy,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDocumentsCountBy: (projectId: string) =>
      dispatch(actions.fetchDocumentsCountBy(projectId)),
    uploadFile: (file: File, projectId: string, contractTypeId: string) =>
      dispatch(actions.uploadFile(file, projectId, contractTypeId)),
    replaceFile: (file: File, projectId: string, contractTypeId: string) =>
      dispatch(actions.replaceFile(file, projectId, contractTypeId)),
    resetUpload: () => dispatch(actions.resetUpload()),
    downloadAllExcerptsToExcel: () =>
      dispatch(actions.downloadAllExcerptsToExcel(this.props.contractId, this.props.projectId)),
    selectContract: (contractId: string) => dispatch(actions.selectContract(contractId)),
    updateDocumentStatus: (document) => dispatch(actions.updateDocumentStatus(document)),
    getUpdatedDocumentsList: (
      projectId: string,
      contractId: string,
      page: number,
      sortBy: string,
      status: any,
      findingCategory: string,
    ) =>
      dispatch(
        actions.getUpdatedDocumentsList(
          projectId,
          contractId,
          page,
          sortBy,
          status,
          findingCategory,
        ),
      ),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withPermissions(Dashboard)));
