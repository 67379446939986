import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';
import * as FileSaver from 'file-saver';
import { exportIssuesFail, exportIssuesStart, exportIssuesSuccess } from './issues';
import { EXPORT_TYPE } from '../../constants/constants';

const fetchDocumentStart = (docId) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_START,
    docId: docId,
  };
};

const fetchDocumentSuccess = (docId, data) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_SUCCESS,
    data: data,
    docId: docId,
  };
};

const fetchDocumentFail = (docId, error) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_FAIL,
    error: error,
  };
};

export const fetchDocument = (docId) => {
  return (dispatch) => {
    dispatch(fetchDocumentStart(docId));
    axios
      .get('/api/documents/' + docId)
      .then((response) => {
        dispatch(fetchDocumentSuccess(docId, response.data));
      })
      .catch((err) => {
        dispatch(fetchDocumentFail(docId, err));
      });
  };
};

const updateDocumentsContractIdStart = () => {
  return {
    type: actionTypes.UPDATE_CONTRACT_TYPE_START,
  };
};

const updateDocumentsContractIdSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_CONTRACT_TYPE_SUCCESS,
    data,
  };
};

const updateDocumentsContractIdFail = (error) => {
  return {
    type: actionTypes.UPDATE_CONTRACT_TYPE_FAIL,
    error,
    showCustomError: true,
    errorHeader: 'Error',
    errorBody: 'An error occurred while moving the documents. Please try again.',
  };
};

export const updateDocumentsContractId = (
  docsContracts,
  projectId,
  contractId?: string,
  page?: number,
  sortBy?: string,
  statusFilter?: string,
  findingCategory?: string,
) => {
  return (dispatch) => {
    dispatch(updateDocumentsContractIdStart());
    axios
      .put(`/api/documents/contracts`, Object.fromEntries(docsContracts), {
        params: { projectId: projectId },
      })
      .then((response) => {
        dispatch(fetchDocumentsCountBy(projectId));
        dispatch(updateDocumentsContractIdSuccess(response.data));
        if (typeof page !== 'undefined') {
          // documents need to be fetch after prev request finishes to reflect changes
          dispatch(
            fetchDocumentsBy(projectId, contractId, page, sortBy, statusFilter, findingCategory),
          );
        }
      })
      .catch((err) => {
        dispatch(updateDocumentsContractIdFail(err));
      });
  };
};

const setStatusStart = (docId) => {
  return {
    type: actionTypes.SET_STATUS_START,
    docId: docId,
  };
};

const setStatusSuccess = (docId, data) => {
  return {
    type: actionTypes.SET_STATUS_SUCCESS,
    docId: docId,
    data: data,
  };
};

const setStatusFail = (docId, error) => {
  return {
    type: actionTypes.SET_STATUS_FAIL,
    error: error,
    docId: docId,
  };
};

export const setStatus = (docId, status) => {
  return (dispatch) => {
    dispatch(setStatusStart(docId));
    axios
      .put('/api/documents/update/' + docId, { status: status })
      .then((response) => {
        dispatch(setStatusSuccess(docId, response.data));
      })
      .catch((err) => {
        dispatch(setStatusFail(docId, err));
      });
  };
};

export const addExcerptTypeComment = (docId, comment) => {
  return (dispatch) => {
    dispatch(setStatusStart(docId));
    axios
      .put('/api/documents/update/' + docId, comment)
      .then((response) => {
        dispatch(setStatusSuccess(docId, response.data));
      })
      .catch((err) => {
        dispatch(setStatusFail(docId, err));
      });
  };
};

export const fetchDocumentsBy = (
  projectId: string,
  contractId?: string,
  page?: number,
  sortBy?: string, // possible values: nameAsc, nameDesc, dateAsc, dateDesc
  status?: any,
  findingCategory?: string,
) => {
  let params = { projectId: projectId };
  if (contractId) {
    params.contractId = contractId;
  }
  if (page) {
    params.page = parseInt(page);
    params.sortByField = sortBy?.indexOf('name') !== -1 ? 'title' : 'uploadedDate';
    params.sortByAsc = sortBy ? sortBy.indexOf('Asc') !== -1 : false;
    params.status = status === '' ? null : status;
    params.findingCategory = findingCategory;
  }

  return (dispatch) => {
    dispatch(fetchDocumentsByStart(projectId));
    axios
      .get(`/api/documents/${page ? 'page' : ''}`, { params })
      .then((response) => {
        const fileList = getFileList(response.data, page);
        const documentsCount = Object.keys(response.data)[0] || 0;
        dispatch(fetchDocumentsBySuccess(projectId, fileList, documentsCount));
      })
      .catch((err) => {
        dispatch(fetchDocumentsByFail(projectId, err));
      });
  };
};
const getFileList = (responseData, page) => {
  if (!page) {
    return responseData;
  } else {
    const filesPerPage = responseData;
    let fileList = [];
    let documentsCount = 0;
    if (Object.keys(filesPerPage) > 0) {
      documentsCount = Object.keys(filesPerPage)[0];
      fileList = filesPerPage[documentsCount];
    }
    return fileList;
  }
};

export const getUpdatedDocumentsList = (
  projectId,
  contractId,
  page,
  sortBy,
  status,
  findingCategory,
) => {
  let params = { projectId: projectId };
  params.contractId = contractId;
  params.page = parseInt(page);
  params.sortByField = sortBy.indexOf('name') !== -1 ? 'title' : 'uploadedDate';
  params.sortByAsc = sortBy.indexOf('Asc') !== -1;
  params.status = status === '' ? null : status;
  params.findingCategory = findingCategory;

  return (dispatch) => {
    dispatch(getUpdatedDocumentsListStart(projectId));
    axios
      .get(`/api/documents/${page ? 'page' : ''}`, { params })
      .then((response) => {
        const fileList = getFileList(response.data, page);
        const documentsCount = Object.keys(response.data)[0] || 0;
        dispatch(getUpdatedDocumentsListSuccess(projectId, fileList, documentsCount));
      })
      .catch((err) => {
        dispatch(getUpdatedDocumentsListFail(projectId, err));
      });
  };
};

const getUpdatedDocumentsListStart = (projectId) => {
  return {
    type: actionTypes.FETCH_LATEST_DOCS_START,
    projectId: projectId,
  };
};

const getUpdatedDocumentsListSuccess = (projectId, data, documentsCount) => {
  return {
    type: actionTypes.FETCH_LATEST_DOCS_SUCCESS,
    projectId: projectId,
    data,
    documentsCount,
  };
};

const getUpdatedDocumentsListFail = (projectId, error) => {
  return {
    type: actionTypes.FETCH_LATEST_DOCS_FAIL,
    projectId: projectId,
    error,
  };
};

const fetchDocumentsCountByStart = (projectId: string) => {
  return {
    type: actionTypes.FETCH_DOCUMENTS_COUNT_START,
    projectId,
  };
};

const fetchDocumentsCountBySuccess = (projectId: string, data) => {
  return {
    type: actionTypes.FETCH_DOCUMENTS_COUNT_SUCCESS,
    projectId,
    data,
  };
};

const fetchDocumentsCountByFail = (projectId: string, error: any) => {
  return {
    type: actionTypes.FETCH_DOCUMENTS_COUNT_FAIL,
    projectId,
    error,
  };
};

export const fetchDocumentsCountBy = (projectId) => {
  return (dispatch) => {
    dispatch(fetchDocumentsCountByStart(projectId));
    axios
      .get(`/api/documents/count`, { params: { projectId } })
      .then((response) => {
        dispatch(fetchDocumentsCountBySuccess(projectId, response.data));
      })
      .catch((err) => {
        dispatch(fetchDocumentsCountByFail(projectId, err));
      });
  };
};

const fetchDocumentsByStart = (projectId) => {
  return {
    type: actionTypes.FETCH_DOCUMENTS_START,
    projectId: projectId,
  };
};

const fetchDocumentsByFail = (projectId, error) => {
  return {
    type: actionTypes.FETCH_DOCUMENTS_FAIL,
    projectId: projectId,
    error,
  };
};

const fetchDocumentsBySuccess = (projectId, data, documentsCount) => {
  return {
    type: actionTypes.FETCH_DOCUMENTS_SUCCESS,
    projectId: projectId,
    data,
    documentsCount,
  };
};

const deleteMultiDocumentsByIdsStart = (docIds) => {
  return {
    type: actionTypes.DELETE_MULTI_DOCUMENTS_START,
    docIds: docIds,
  };
};

const deleteMultiDocumentsByIdsFail = (docIds, err) => {
  return {
    type: actionTypes.DELETE_MULTI_DOCUMENTS_FAIL,
    docIds: docIds,
    error: err,
  };
};

const deleteMultiDocumentsByIdsSuccess = (docIds, data) => {
  return {
    type: actionTypes.DELETE_MULTI_DOCUMENTS_SUCCESS,
    docIds: docIds,
    data: data,
  };
};

export const deleteDocumentsByIds = (
  docIds,
  projectId,
  contractId?: string,
  page?: number,
  sortBy?: string,
  statusFilter?: string,
  findingCategory?: string,
) => {
  return (dispatch) => {
    dispatch(deleteMultiDocumentsByIdsStart(docIds));
    axios
      .delete('/api/documents/', {
        data: docIds,
      })
      .then((response) => {
        dispatch(fetchDocumentsCountBy(projectId));
        dispatch(deleteMultiDocumentsByIdsSuccess(docIds, response.data));
        if (typeof page !== 'undefined') {
          // documents need to be fetch after prev request finishes to reflect changes
          dispatch(
            fetchDocumentsBy(projectId, contractId, page, sortBy, statusFilter, findingCategory),
          );
        }
      })
      .catch((err) => {
        dispatch(deleteMultiDocumentsByIdsFail(docIds, err));
      });
  };
};

export const downloadAllExcerptsToExcel = (contractId, projectId) => {
  return (dispatch) => {
    let parameters = [];
    parameters.push('fieldName=excerptType.keyword');
    parameters.push('baseUrl=' + window.location.origin);
    parameters.push('contractId=' + contractId);
    parameters.push('projectId=' + projectId);
    dispatch(exportIssuesStart(EXPORT_TYPE.EXCEL));
    let url = '/api/excerpts/export/excel/' + (parameters.length ? '?' + parameters.join('&') : '');
    axios
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        let blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        FileSaver.saveAs(blob, 'ProjectExcelReport.xlsx');
        dispatch(exportIssuesSuccess());
      })
      .catch((err) => {
        dispatch(exportIssuesFail(err));
      });
  };
};

export const updateDocumentStatus = (document) => {
  return {
    type: actionTypes.UPDATE_DOCUMENT_STATUS,
    document: document,
  };
};

const retryDocumentStart = (docIds) => {
  return {
    type: actionTypes.RETRY_PROCESS_DOCUMENT_START,
    docIds,
  };
};

const retryDocumentSuccess = (docIdsData) => {
  return {
    type: actionTypes.RETRY_PROCESS_DOCUMENT_SUCCESS,
    docIdsData,
  };
};

const retryDocumentFail = (docIds, error) => {
  return {
    type: actionTypes.RETRY_PROCESS_DOCUMENT_FAIL,
    error,
  };
};

export const retryDocument = (docIds) => {
  return (dispatch) => {
    dispatch(retryDocumentStart(docIds));
    axios
      .put(`/api/files/retry`, docIds)
      .then((response) => {
        dispatch(retryDocumentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(retryDocumentFail(docIds, err));
      });
  };
};

const reProcessDocumentStart = (docIdsWithStatus) => {
  return {
    type: actionTypes.RE_PROCESS_DOCUMENT_START,
    docIdsWithStatus,
  };
};

const reProcessDocumentSuccess = (docIdsWithStatus) => {
  return {
    type: actionTypes.RE_PROCESS_DOCUMENT_SUCCESS,
    docIdsWithStatus,
  };
};

const reProcessDocumentFail = (docIdsWithStatus, error) => {
  return {
    type: actionTypes.RE_PROCESS_DOCUMENT_FAIL,
    error,
  };
};

export const reProcessDocument = (docIdsWithStatus, ids) => {
  return (dispatch) => {
    dispatch(reProcessDocumentStart(docIdsWithStatus));
    axios
      .put(`/api/files/reprocess`, ids)
      .then((response) => {
        dispatch(reProcessDocumentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(reProcessDocumentFail(docIdsWithStatus, err));
      });
  };
};

const reProcessStaticFindingsStart = (docIds) => {
  return {
    type: actionTypes.RETRY_PROCESS_STATIC_FINDING_START,
    docIds,
  };
};

const reProcessStaticFindingsSuccess = (docIds, data) => {
  return {
    type: actionTypes.RETRY_PROCESS_STATIC_FINDING_SUCCESS,
    docIds,
    data,
  };
};

const reProcessStaticFindingsFail = (docIds, error) => {
  return {
    type: actionTypes.RETRY_PROCESS_STATIC_FINDING_FAIL,
    docIds,
    error,
  };
};

export const reProcessStaticFindings = (docIds: string) => {
  return (dispatch) => {
    dispatch(reProcessStaticFindingsStart(docIds));
    axios
      .put(`api/files/reprocess/findings`, docIds)
      .then((response) => {
        dispatch(reProcessStaticFindingsSuccess(docIds, response.data));
      })
      .catch((err) => {
        dispatch(reProcessStaticFindingsFail(docIds, err));
      });
  };
};

export const fetchDocumentNoFindingTypes = (docId) => {
  return (dispatch) => {
    dispatch(fetchDocumentNoFindingTypesStart());
    axios
      .get(`/api/documents/${docId}/no-findingtypes`)
      .then((response) => {
        dispatch(fetchDocumentNoFindingTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchDocumentNoFindingTypesFail(err));
      });
  };
};

const fetchDocumentNoFindingTypesStart = () => {
  return {
    type: actionTypes.FETCH_DOCUMENT_NO_FINDING_TYPES_START,
  };
};

const fetchDocumentNoFindingTypesSuccess = (noFindingTypes) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_NO_FINDING_TYPES_SUCCESS,
    noFindingTypes: noFindingTypes,
  };
};

const fetchDocumentNoFindingTypesFail = (error) => {
  return {
    type: actionTypes.FETCH_DOCUMENT_NO_FINDING_TYPES_FAIL,
    error: error,
  };
};

export const selectStatusFilterGlobally = (statusFilter) => {
  return {
    type: actionTypes.SELECT_STATUS_FILTER,
    statusFilter,
  };
};

export const selectSortDocsByGlobally = (sortBy) => {
  return {
    type: actionTypes.SELECT_SORT_DOCS_BY,
    sortBy,
  };
};

export const selectFilterDocsByGlobally = (filterBy) => {
  return {
    type: actionTypes.SELECT_FILTER_DOCS_BY,
    filterBy,
  };
};

export const selectPageNumberGlobally = (page) => {
  return {
    type: actionTypes.SELECT_PAGE,
    page,
  };
};
