// @flow
import React, { Component } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import * as pdfjsViewer from 'pdfjs-dist/web/pdf_viewer';
import PageControl from './PageControl';
import Spinner from '../UI/Spinner/Spinner';
// pdfjs.GlobalWorkerOptions.workerSrc = 'pdfjs-dist/build/pdf.worker.js';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


type Props = {
  fileUrl:{url:string,httpHeaders:Object},
  fileName: string,
  pageNumber?:number,
};

type State = {
  numPages: number,
  pageNumber: number,
};


class PdfView extends Component<Props, State> {
  constructor(props:Props) {

    super(props);
    this.state = {
      numPages: 0,
      pageNumber: props.pageNumber || 1,
    }

  }

  static defaultProps = {
    pageNumber: 1,
  };
  onPageChange = (pageNumber: number) => {
    this.setState({ pageNumber: pageNumber + 1 });
  };
  componentDidUpdate(){

  }

  componentDidMount(): void {
    // this.pdfLinkService = new pdfjsViewer.PDFLinkService();
    // this.pdfFindController = new pdfjsViewer.PDFFindController({
    //   linkService: this.pdfLinkService,
    // });
  }

  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    this.setState({ numPages });
  };
  onDocumentLoadError = ({ message }: { message: string }) => {
    console.error('error loading' + this.props.fileName);
    console.error(message);
  };

  render() {
    const { fileName,fileUrl} = this.props;

    const { numPages, pageNumber } = this.state;
    return (
      <div className={'al-pdfView'}>
        <Document
          loading={
            <div style={{ width: '100%' }}>
              <Spinner/>
            </div>
          }
          file={fileUrl}
          onLoadError={this.onDocumentLoadError}
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page
            key={pageNumber}
            pageNumber={pageNumber}
            loading={
              <div style={{ width: '100%' }}>
                <Spinner/>
              </div>
            }
          />
        </Document>
        <PageControl
          onPageChange={this.onPageChange}
          pageNumber={pageNumber - 1}
          numberOfPages={numPages - 1}
        />
      </div>
    );
  }
}

export default PdfView;
