import { axiosInstance as axios } from '../../services/axiosInstance';
import * as actionTypes from './actionType';

const fetchAdvancedLearningIssuesStart = (documentId) => {
  return {
    type: actionTypes.FETCH_ADVANCED_LEARNING_ISSUES_START,
    documentId: documentId,
  };
};

const fetchAdvancedLearningIssuesSuccess = (documentId, data, sortBy, forceUpdate) => {
  return {
    type: actionTypes.FETCH_ADVANCED_LEARNING_ISSUES_SUCCESS,
    data: data,
    documentId: documentId,
    sortBy: sortBy,
    forceUpdate: forceUpdate,
  };
};

const fetchAdvancedLearningIssuesFail = (error) => {
  return {
    type: actionTypes.FETCH_ADVANCED_LEARNING_ISSUES_FAIL,
    error: error,
  };
};

export const fetchAdvancedLearningIssues = (docId, excerptType, probability = null) => {
  return (dispatch) => {
    dispatch(fetchAdvancedLearningIssuesStart(docId));
    axios
      .get('/api/excerpts/advanced-learning', {
        params: {
          docId,
          excerptType,
          probability,
        },
      })
      .then((response) => {
        dispatch(fetchAdvancedLearningIssuesSuccess(docId, response.data));
      })
      .catch((err) => {
        dispatch(fetchAdvancedLearningIssuesFail(err));
      });
  };
};
