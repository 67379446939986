// @flow
import * as actionTypes from '../actions/actionType';
import type { Project, ProjectState } from '../../types';
import { Map } from 'immutable';

const DEFAULT_CONTRACT_TYPE = 'others';
const initialState: ProjectState = {
  projects: Map(),
  project: null,
  counts: {},
  allTotalCount: 0,
  allSuccessCount: 0,
  allFailCount: 0,
  projectId: null,
  contractId: null,
  fetchingProjects: false,
  deletingGlobalConfigs: {},
  creatingProject: false,
  creatingContractType: false,
  creatingContractTypeError: null,
  fetchingDocumentsCount: false /* currently not used */,
  fetchingDocumentsCountError: null /* currently not used */,
  renamingProject: false,
  renamingProjectError: null,
  renamingContractType: false,
  renamingContractTypeError: null,
  togglingStaticIssuesError: null,
  updatingIssuesCategoriesError: null,
  updateLoading: false,
  updateLoadingError: null,
  fetchingProjectById: false,
  fetchingProjectByIdError: null,
  /* selected values in Explore page */
  selectedFindingType: null,
  selectedOrigin: null,
  selectedUserAnnotationFilters: [true, false, false, false], // for [all, positive, negative, neutral] respectively
  selectedPageNumberExplore: 1,
};

const createProjectStart = (state, action) => {
  return {
    ...state,
    creatingProject: true,
  };
};

const createProjectSuccess = (state, action) => {
  let { projects } = state;
  if (action.data.id) {
    projects = projects.set(action.data.id, action.data);
  }
  return sanitizeProjectId({
    ...state,
    projects,
    creatingProject: false,
  });
};

const createProjectFail = (state, action) => {
  return {
    ...state,
    creatingProject: { error: action.error },
  };
};

const renameProjectStart = (state) => {
  return {
    ...state,
    renamingProject: true,
    renamingProjectError: null,
  };
};

const renameProjectSuccess = (state, action) => {
  let { projects } = state;
  projects = projects.map((project) => (project.id === action.projectId ? action.data : project));
  return {
    ...state,
    projects,
    renamingProject: false,
  };
};

const renameProjectFail = (state, action) => {
  return {
    ...state,
    renamingProject: false,
    renamingProjectError: { error: action.error },
  };
};

const createContractTypeStart = (state) => {
  return {
    ...state,
    creatingContractType: true,
    creatingContractTypeError: null,
  };
};

const createContractTypeSuccess = (state, action) => {
  let { projects } = state;
  if (action.data.id) {
    projects = projects.set(action.data.id, action.data);
  }
  return sanitizeContractId(
    sanitizeProjectId({
      ...state,
      projects,
      creatingContractType: false,
    }),
  );
};

const createContractTypeFail = (state, action) => {
  return {
    ...state,
    creatingContractType: false,
    creatingContractTypeError: { error: action.error },
  };
};

const renameContractTypeStart = (state) => {
  return {
    ...state,
    renamingContractType: true,
    renamingContractTypeError: null,
  };
};

const renameContractTypeSuccess = (state, action) => {
  let { projects } = state;
  projects = projects.map(
    (project) =>
      (project.contractConfig =
        project.id === action.projectId ? action.data : project.contractConfig),
  );
  return {
    ...state,
    projects,
    renamingContractType: false,
  };
};

const renameContractTypeFail = (state, action) => {
  return {
    ...state,
    renamingContractType: false,
    renamingContractTypeError: { error: action.error },
  };
};

const sanitizeProjectId = (state: ProjectState) => {
  let { projectId, projects } = state;
  if (!projects.size) {
    state.projectId = null;
  } else if (!projects.has(projectId)) {
    state.projectId = projects.first().id;
  }
  return state;
};

const getDefaultContractId = (projectConfig) => {
  try {
    return projectConfig.contractConfig.find(
      (contract) =>
        contract.contractType.trim().toLowerCase() === DEFAULT_CONTRACT_TYPE.toLowerCase(),
    ).id;
  } catch (e) {
    console.error(`cant' get default contract type for project ${projectConfig.projectType}`);
  }
};

const sanitizeContractId = (state: ProjectState) => {
  if (state.projectId) {
    let contractConfig = state.projects.get(state.projectId).contractConfig;
    if (!contractConfig.filter((cg) => cg.id === state.contractId).length) {
      state.contractId = getDefaultContractId(state.projects.get(state.projectId));
    }
  }
  return state;
};

const fetchProjectsStart = (state) => {
  return {
    ...state,
    fetchingProjects: state.projects?.size === 0 ? true : state.fetchingProjects,
    creatingProject: false,
  };
};

const fetchProjectsSuccess = (state, action) => {
  const projects = Map(action.projects.map((project: Project) => [project.id, project]));
  return sanitizeContractId(
    sanitizeProjectId({
      ...state,
      projects,
      fetchingProjects: false,
      creatingProject: false,
    }),
  );
};

const fetchProjectsFail = (state, action) => {
  return {
    ...state,
    fetchingProjects: { error: action.error },
  };
};

const fetchProjectByIdStart = (state) => {
  return {
    ...state,
    fetchingProjectById: true,
    fetchingProjectByIdError: null,
  };
};

const fetchProjectByIdSuccess = (state, action) => {
  return {
    ...state,
    project: action.project,
    fetchingProjectById: false,
  };
};

const fetchProjectByIdFail = (state, action) => {
  return {
    ...state,
    fetchingProjectById: false,
    fetchingProjectByIdError: { error: action.error },
  };
};

const deleteProjectStart = (state, action) => {
  return {
    ...state,
    deletingProject: true,
  };
};

const deleteProjectSuccess = (state, action) => {
  sessionStorage.clear();
  const { projectId } = action;
  let { projects } = state;
  projects = projects.filter((project) => project.id !== projectId);
  return sanitizeProjectId({
    ...state,
    projects,
    deletingProject: false,
  });
};

const deleteProjectFail = (state, action) => {
  return {
    ...state,
    deletingProject: { error: action.error },
  };
};

const selectProject = (state, action) => {
  return {
    ...state,
    projectId: action.projectId,
    contractId: getDefaultContractId(state.projects.get(action.projectId)),
    counts: {},
    fetchingDocumentsCount: false,
    fetchingDocumentsCountError: null,
  };
};

const selectContract = (state, action) => {
  return {
    ...state,
    contractId: action.contractId,
    selectedFindingType: null,
  };
};

const selectFindingType = (state, action) => {
  return {
    ...state,
    selectedFindingType: action.findingType,
  };
};

const selectOriginGlobally = (state, action) => {
  return {
    ...state,
    selectedOrigin: action.origin,
  };
};

const selectAnnotationFilters = (state, action) => {
  return {
    ...state,
    selectedUserAnnotationFilters: action.filters,
  };
};

const selectPageNumberExplore = (state, action) => {
  return {
    ...state,
    selectedPageNumberExplore: action.page,
  };
};

const createGlobalConfigStart = (state) => {
  return {
    ...state,
    updateLoading: true,
    updateLoadingError: null,
  };
};

const createGlobalConfigSuccess = (state, action) => {
  let { projects } = state;
  const { projectId, contractId, data } = action;
  const newExcerpt = data[0];
  projects = projects.map((project) => {
    if (project.id === projectId) {
      project.contractConfig.map((ct) => {
        if (ct.id === contractId) {
          return ct.globalConfig.push(newExcerpt);
        }
      });
    }
    return project;
  });

  return {
    ...state,
    projects,
    updateLoading: false,
  };
};

const createGlobalConfigFail = (state, action) => {
  return {
    ...state,
    updateLoading: false,
    updateLoadingError: { error: action.error },
  };
};

const deleteUserGlobalConfigsStart = (state, action) => {
  let { deletingGlobalConfigs } = state;
  let { projectId, contractId, globalConfigId } = action;
  deletingGlobalConfigs = { projectId, contractId, globalConfigId, loading: true };
  return {
    ...state,
    deletingGlobalConfigs,
  };
};

const deleteUserGlobalConfigsSuccess = (state, action) => {
  let { projects } = state;
  let { deletingGlobalConfigs } = state;
  let { projectId, contractId, globalConfigId } = action;
  deletingGlobalConfigs = { projectId, contractId, globalConfigId, loading: false };
  if (action.data.id) {
    projects = projects.set(action.data.id, action.data);
  }
  return sanitizeProjectId({
    ...state,
    projects,
    deletingGlobalConfigs,
  });
};

const deleteUserGlobalConfigsFail = (state, action) => {
  let { deletingGlobalConfigs } = state;
  let { projectId, contractId, globalConfigId, error } = action;
  deletingGlobalConfigs = { projectId, contractId, globalConfigId, loading: false, error };
  return {
    ...state,
    deletingGlobalConfigs,
  };
};

const updateGlobalConfigStart = (state) => {
  return {
    ...state,
    updateLoading: true,
    updateLoadingError: null,
  };
};

const updateGlobalConfigSuccess = (state, action) => {
  let { projects } = state;
  const { projectId, contractId, updatedGlobalConfigs } = action;
  projects = projects.map((project) => {
    if (project.id === projectId) {
      project.contractConfig = project.contractConfig.map((ct) => {
        if (ct.id === contractId) {
          ct.globalConfig = ct.globalConfig.map((config) => {
            updatedGlobalConfigs.map((ugc) => (config.id === ugc.id ? ugc : config));
            return config;
          });
        }
        return ct;
      });
    }
    return project;
  });
  return {
    ...state,
    projects,
    updateLoading: false,
  };
};

const updateGlobalConfigFail = (state, action) => {
  return {
    ...state,
    updateLoading: false,
    updateLoadingError: { error: action.error },
  };
};

const updateExcerptActionsStart = (state) => {
  return {
    ...state,
    updateLoading: true,
    updateLoadingError: null,
  };
};

const updateExcerptActionsSuccess = (state) => {
  return {
    ...state,
    updateLoading: false,
  };
};

const updateExcerptActionsFail = (state, action) => {
  return {
    ...state,
    updateLoading: false,
    updateLoadingError: { error: action.error },
  };
};

const fetchDocumentsCountByStart = (state, action) => {
  if (state.projectId === action.projectId) {
    return {
      ...state,
      fetchingDocumentsCount: true,
    };
  }
};
const fetchDocumentsCountBySuccess = (state, action) => {
  if (state.projectId === action.projectId) {
    const allTotalCount = Object.keys(action.data).reduce((acc, contractId) => {
      return action.data[contractId][0].total + acc;
    }, 0);
    const allSuccessCount = Object.keys(action.data).reduce((acc, contractId) => {
      return action.data[contractId][1].success + acc;
    }, 0);

    const allFailCount = Object.keys(action.data).reduce((acc, contractId) => {
      return action.data[contractId][2].error + acc;
    }, 0);
    return {
      ...state,
      fetchingDocumentsCount: false,
      counts: action.data,
      allTotalCount,
      allSuccessCount,
      allFailCount,
    };
  }
};
const fetchDocumentsCountByFail = (state, action) => {
  if (state.projectId === action.projectId) {
    return {
      ...state,
      fetchingDocumentsCount: false,
      fetchingDocumentsCountError: action.error,
    };
  }
};

const project = (state: ProjectState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS_START:
      return fetchProjectsStart(state);
    case actionTypes.FETCH_PROJECTS_SUCCESS:
      return fetchProjectsSuccess(state, action);
    case actionTypes.FETCH_PROJECTS_FAIL:
      return fetchProjectsFail(state, action);
    case actionTypes.FETCH_PROJECT_BY_ID_START:
      return fetchProjectByIdStart(state);
    case actionTypes.FETCH_PROJECT_BY_ID_SUCCESS:
      return fetchProjectByIdSuccess(state, action);
    case actionTypes.FETCH_PROJECT_BY_ID_FAIL:
      return fetchProjectByIdFail(state, action);
    case actionTypes.CREATE_PROJECT_START:
      return createProjectStart(state, action);
    case actionTypes.CREATE_PROJECT_SUCCESS:
      return createProjectSuccess(state, action);
    case actionTypes.CREATE_PROJECT_FAIL:
      return createProjectFail(state, action);
    case actionTypes.RENAME_PROJECT_START:
      return renameProjectStart(state, action);
    case actionTypes.RENAME_PROJECT_SUCCESS:
      return renameProjectSuccess(state, action);
    case actionTypes.RENAME_PROJECT_FAIL:
      return renameProjectFail(state, action);
    case actionTypes.CREATE_CONTRACT_TYPE_START:
      return createContractTypeStart(state, action);
    case actionTypes.CREATE_CONTRACT_TYPE_SUCCESS:
      return createContractTypeSuccess(state, action);
    case actionTypes.CREATE_CONTRACT_TYPE_FAIL:
      return createContractTypeFail(state, action);
    case actionTypes.RENAME_CONTRACT_TYPE_START:
      return renameContractTypeStart(state, action);
    case actionTypes.RENAME_CONTRACT_TYPE_SUCCESS:
      return renameContractTypeSuccess(state, action);
    case actionTypes.RENAME_CONTRACT_TYPE_FAIL:
      return renameContractTypeFail(state, action);
    case actionTypes.SELECT_PROJECT:
      return selectProject(state, action);
    case actionTypes.SELECT_CONTRACT:
      return selectContract(state, action);
    case actionTypes.SELECT_FINDING_TYPE:
      return selectFindingType(state, action);
    case actionTypes.SELECT_ORIGIN:
      return selectOriginGlobally(state, action);
    case actionTypes.SELECT_USER_ANNOTATION_FILTERS:
      return selectAnnotationFilters(state, action);
    case actionTypes.SELECT_PAGE_EXPLORE:
      return selectPageNumberExplore(state, action);

    case actionTypes.DELETE_PROJECT_START:
      return deleteProjectStart(state, action);
    case actionTypes.DELETE_PROJECT_FAIL:
      return deleteProjectFail(state, action);
    case actionTypes.DELETE_PROJECT_SUCCESS:
      return deleteProjectSuccess(state, action);

    case actionTypes.CREATE_GLOBAL_CONFIG_START:
      return createGlobalConfigStart(state, action);
    case actionTypes.CREATE_GLOBAL_CONFIG_SUCCESS:
      return createGlobalConfigSuccess(state, action);
    case actionTypes.CREATE_GLOBAL_CONFIG_FAIL:
      return createGlobalConfigFail(state, action);
    case actionTypes.DELETE_USER_GLOBAL_CONFIG_START:
      return deleteUserGlobalConfigsStart(state, action);
    case actionTypes.DELETE_USER_GLOBAL_CONFIG_SUCCESS:
      return deleteUserGlobalConfigsSuccess(state, action);
    case actionTypes.DELETE_USER_GLOBAL_CONFIG_FAIL:
      return deleteUserGlobalConfigsFail(state, action);
    case actionTypes.UPDATE_GLOBAL_CONFIG_START:
      return updateGlobalConfigStart(state);
    case actionTypes.UPDATE_GLOBAL_CONFIG_SUCCESS:
      return updateGlobalConfigSuccess(state, action);
    case actionTypes.UPDATE_GLOBAL_CONFIG_FAIL:
      return updateGlobalConfigFail(state, action);
    case actionTypes.UPDATE_EXCERPT_ACTIONS_START:
      return updateExcerptActionsStart(state);
    case actionTypes.UPDATE_EXCERPT_ACTIONS_SUCCESS:
      return updateExcerptActionsSuccess(state, action);
    case actionTypes.UPDATE_EXCERPT_ACTIONS_FAIL:
      return updateExcerptActionsFail(state, action);

    case actionTypes.FETCH_DOCUMENTS_COUNT_START:
      return fetchDocumentsCountByStart(state, action);
    case actionTypes.FETCH_DOCUMENTS_COUNT_SUCCESS:
      return fetchDocumentsCountBySuccess(state, action);
    case actionTypes.FETCH_DOCUMENTS_COUNT_FAIL:
      return fetchDocumentsCountByFail(state, action);

    default:
      return state;
  }
};

export default project;
