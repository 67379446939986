import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Spinner from '../../../components/UI/Spinner/Spinner';
import logo from '../../../assets/images/ca-logo.png';
import logoIntrafind from '../../../assets/images/IntraFind_Logo.png';
import validate from '../../../services/validation';
import { auth } from '../../../store/actions/index';

const Login = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);

  const [controls, setControls] = useState({
    email: {
      elementType: 'input',
      elementConfig: {
        autoComplete: 'username',
        type: 'email',
        placeholder: 'app.login.username',
      },
      value: '',
      validation: {
        required: true,
        isEmail: true,
      },
      valid: true,
      touched: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        autoComplete: 'current-password',
        placeholder: 'app.login.password',
      },
      value: '',
      validation: {
        required: true,
        minLength: 6,
      },
      valid: true,
      touched: false,
    },
  });

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = {
      ...controls,
      [controlName]: {
        ...controls[controlName],
        value: event.target.value,
        touched: true,
      },
    };
    setControls(updatedControls);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    let valid = true;
    let updatedControls = { ...controls };
    for (let key in controls) {
      updatedControls[key].valid = validate(controls[key].value, controls[key].validation);
      valid = updatedControls[key].valid && valid;
    }

    setControls(updatedControls);
    dispatch(auth(controls.email.value, controls.password.value));
  };

  return (
    <div className="al-login-container">
      <div className="intrafind-logo">
        <img src={logoIntrafind} alt="Intrafind" />
      </div>
      <div className="al-login">
        <div className="al-login-header">
          <img src={logo} width="270" alt="Document Analyzer" />
        </div>
        <div className="al-login-content">
          <form noValidate className="al-login-form" onSubmit={submitHandler}>
            {loading ? (
              <Spinner />
            ) : (
              Object.keys(controls).map((key, index) => {
                const config = controls[key];
                return (
                  <Input
                    key={key}
                    elementType={config.elementType}
                    elementConfig={config.elementConfig}
                    value={config.value}
                    invalid={!config.valid}
                    shouldValidate={config.validation}
                    touched={config.touched}
                    placeholder={config.elementConfig.placeholder}
                    changed={(event) => inputChangedHandler(event, key)}
                  />
                );
              })
            )}
            <div className="al-login-btn-container">
              <Button type="submit" btnType="al-primary" disabled={loading}>
                Login
              </Button>
            </div>
          </form>
          {error ? <div className="al-error-msg">{'Invalid username or password'}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default Login;
