import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteComment, updateComment } from '../../../store/actions';
import type { Comment } from '../../../types';
import Button from '../../UI/Button/Button';
import moment from 'moment';
import Input from '../../UI/Input/Input';

type Props = {
  comment: Comment,
};

const CommentItem = ({ comment }: Props) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [stateComment, setComment] = useState(comment);

  const commentBodyChanged = (e: Event) => {
    let updatedComment: Comment = { ...stateComment };
    updatedComment.body = e.target.value;
    setComment(updatedComment);
  };

  const onEdit = () => {
    setEdit(true);
  };

  const onSave = () => {
    dispatch(updateComment(stateComment));
    setEdit(false);
  };

  const onDelete = () => {
    dispatch(deleteComment(comment));
  };

  return (
    <div className="al-comment" key={stateComment.commentId}>
      <div className="al-comment-avatar">
        <i className="al-icons al-icon-account" />
      </div>
      <div className="al-comment-from-date">
        {stateComment.author} said on: <span>{moment(stateComment.timestamp).format('llll')}</span>
      </div>
      <div className="al-comment-edit">
        {edit ? (
          <Button withIcon={true} iconType={'check'} clicked={onSave} className="al-small" />
        ) : (
          <Button withIcon={true} iconType={'edit'} clicked={onEdit} className="al-small" />
        )}
        <Button withIcon={true} iconType={'delete'} clicked={onDelete} className="al-small" />
      </div>
      <div className="al-comment-message">
        <div>
          {edit ? (
            <Input
              value={stateComment.body}
              changed={commentBodyChanged}
              elementType={'textarea'}
              elementConfig={{ type: 'text' }}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: stateComment.body.replace(/\n/g, '<br />'),
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
