// combine all reducers

import { combineReducers } from 'redux';
import LanguageReducer from './translation';
import auth from './auth';
import health from './health';
import issues from './issues';
import comments from './comments';
import document from './document';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import statistics from './statistics/index';
import compare from './compare';
import type { AppState } from '../../types/reduxTypes';
import project from './project';
import version from './version';
import * as actionTypes from '../actions/actionType';
import dynamicTabs from './dynamicTabs';
import searchQueries from './searchQueries';
import user from './users';
import roles from './roles';
import permissions from './permissions';
import permissionSets from './permissionSets';
import advancedLearningIssues from './advancedLearningIssues';
import locationPathName from './locationPathName';
import selectedIssueToView from './viewSelectedIssue';
import dynamicThresholds from './dynamicThresholds';
import generalErrors from './generalErrors';
import globalSettings from './globalSettings';

const projectPersistConfig = {
  key: 'project',
  storage,
  whitelist: ['projectId', 'contractId'],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['loading', 'error'],
};

const appReducer = combineReducers({
  locale: LanguageReducer,
  auth: persistReducer(authPersistConfig, auth),
  issues,
  comments,
  document,
  health,
  dynamicTabs,
  statistics,
  compare,
  project: persistReducer(projectPersistConfig, project),
  version,
  searchQueries,
  user,
  roles,
  permissionSets,
  permissions,
  advancedLearningIssues,
  locationPathName,
  selectedIssueToView,
  dynamicThresholds,
  generalErrors,
  globalSettings,
});

const rootReducer = (state: AppState, action): AppState => {
  if (action.type === actionTypes.AUTH_LOGOUT) {
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['health'],
};

const reducers = persistReducer(persistConfig, rootReducer);

export default reducers;
