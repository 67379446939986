export const ENGLISH_TRANSLATION = {
  lang: 'en',
  messages: {
    'app.empty': ' ',
    'app.welcome': 'hi',

    // Login Container
    'app.login.username': 'Username',
    'app.login.password': 'Password',

    // Review Container
    'app.review.findings-mode': 'findings mode',
    'app.review.document-mode': 'Document Mode',
    'app.review.approve': 'Confirm all',
    'app.review.reviewed': 'Reviewed',
    'app.review.findings': 'Findings',
    'app.review.export': 'export',
    'app.review.tooltips': 'tooltips',
    'app.review.deleteAiIssues': 'Remove all',
    'app.review.search.document': 'Search within document',
    'app.review.error.document-not-found': 'Document has been deleted.',

    // Compare Component
    'app.compare.oldtext-placeholder': 'Enter text you want check for differences',
    'app.compare.text1': 'text 1',
    'app.compare.text2': 'text 2',
    'app.compare.compare': 'compare',
    'app.compare.green': 'green',
    'app.compare.red': 'red',
    'app.compare.green-info': 'passages added to Text 1',
    'app.compare.red-info': 'passages removed to Text 1',

    'app.review.commentModal.tab1': 'Findings with comments',
    'app.review.commentModal.tab2': 'Document Comments',

    'app.review.createIssueModel.help':
      'Select the text of the finding by marking it in the area below',
    'app.document.searchForType': 'Search for a finding type',

    // Document component
    'app.review.document.notvalid': 'Not valid selection',
    'app.review.document.edit': 'edit issue',
    'app.review.document.create': 'create finding',
    'app.review.document.btn.edit': 'save',
    'app.review.document.btn.create': 'create',

    // Issue component
    'app.review.issues.issue.advanced': 'Advanced Learning Result',
    'app.review.issues.issue.static': 'Static Extraction Result',
    'app.review.issues.issue.user': 'User-annotated Finding',
    'app.review.issues.search.finding': 'Search for finding',

    // Dashboard container
    'app.dashboard.error': 'The following files have not valid extensions',
    'app.dashboard.uploaded-contracts': 'uploaded documents',
    'app.dashboard.existing-contracts': 'saved documents',

    'app.dashboard.drop-here': 'Drag & drop or click here to upload new documents (PDF or Word)',
    'app.dashboard.valid-extensions': 'Supported formats: Word and PDF',
    // Global Config component
    'app.review.globalaction.description.placeholder': 'Description',
    'app.review.globalaction.description.errormsg': 'Description is required',
    'app.review.globalaction.key.placeholder': 'Key',
    'app.review.globalaction.key.errormsg': 'Key is required',
    'app.review.globalaction.placeholder': 'Action',
    'app.review.globalaction.label.errormsg': 'Action is required',
    'app.review.globalaction.modal.title': 'Assign Action',
    'app.review.globalaction.modal.tooltip': 'Assign Global Actions',

    // Search Container
    'app.search.search': 'Search',

    // Inspect Container
    'app.inspect.origin.advanced': 'Advanced learning results',
    'app.inspect.origin.user': 'User annotated findings',
    'app.inspect.origin.static': 'Static extraction results',

    // Comment Modal
    'app.container.review.comments.comment': 'Comment',

    // Notification
    'app.notifications.create.finding.loading': 'Creating new finding',
    'app.notifications.create.finding.success': 'A new finding type %s has been created',
    'app.notifications.create.finding.error': 'An issue occurred while creating a finding',

    'app.notifications.update.finding.loading': 'Changing finding',
    'app.notifications.update.finding.success': 'Finding has been successfully changed',
    'app.notifications.update.finding.error': 'An issue occurred while changing finding',

    'app.notifications.delete.finding.loading': 'Deleting finding',
    'app.notifications.delete.finding.success': 'Finding with type %s has been deleted',
    'app.notifications.delete.finding.error': 'An issue occurred while deleting finding',

    'app.notifications.bootstrap.static.finding.error': 'An issue occurred while bootstrapping %s',
    'app.notifications.bootstrap.static.finding.success':
      'New finding type %s has been created with %s findings',

    'app.settings.advanced.learning.placeholder': 'Enter name',
    'app.settings.contract.types.placeholder': 'Enter name',
    'app.settings.project.placeholder': 'Enter name',
  },
};
