// @flow
import React, { useEffect } from 'react';
import Button from '../../../components/UI/Button/Button';

type Props = {
  configKey: string,
  onExit: () => void,
  duration?: number,
  type: string,
  msg?: string,
  headerMsg?: string,
  show: Boolean,
};

export const Notification = ({
  configKey,
  onExit,
  duration,
  type,
  msg,
  headerMsg,
  show,
}: Props) => {

  useEffect(() => {
    if (duration) {
      setTimeout(() => {
        onExit(configKey, duration);
      }, duration);
    }
    // eslint-disable-next-line
  }, []);

  const ok = () => {
    onExit(configKey);
  };

  const getNotificationElement = () => {
    let notification;
    const colorClass = type === 'auto' ? 'al-auto' : 'al-critical';
    switch (type) {
      case 'default':
        notification = (
          <div className="alert al-notification">
            <div className="al-notification-header">
              <div className="al-notification-header-msg">{headerMsg}</div>
            </div>
            <div className="al-notification-message">{msg}</div>
          </div>
        );
        break;
      case 'auto':
      case 'critical':
        notification = (
          <div className={`al-notification ${colorClass} al-show`}>
            <div className="al-notification-header">
              <div className="al-notification-header-msg">{headerMsg}</div>
            </div>
            <div className="al-notification-message">{msg}</div>
            <div className="al-notification-toolbar al-single">
              <Button btnType="al-button" clicked={ok}>Ok</Button>
            </div>
          </div>
        );
        break;
      case 'action':
        notification = (
          <div className="al-notification al-action al-show">
            <div className="al-notification-header">
              <div className="al-notification-header-msg">{headerMsg}</div>
            </div>
            <div className="al-notification-message">{msg}</div>
            <div className="al-notification-toolbar al-single">
              <Button btnType="al-button">No</Button>
              <Button btnType="al-button" clicked={ok}>
                Yes
              </Button>
            </div>
          </div>
        );
        break;
      default:
        notification = (
          <div className="al-notification al-show">
            <div className="al-notification-header">
              <div className="al-notification-header-msg">{headerMsg}</div>
            </div>
            <div className="al-notification-message">{msg}</div>
          </div>
        );
    }
    return notification;
  };

  return (
    <>
      {show
        ? getNotificationElement()
        : null
      }
    </>
  );
}
