import * as actionTypes from '../actions/actionType';

const initialState = {
  comments: [],
  documentId: null,
  error: null,
  loading: true,
  success: false,
  issueId: null
};

const fetchDocumentCommentsStart = (state, action) => {
  return {
    ...state,
    error: null,
    loading: true,
    success: false,
    documentId: action.documentId
  };
};

const fetchDocumentCommentsSuccess = (state, action) => {
  let documentComments = action.data.filter(comment => {
    return comment.excerptId === null;
  });
  return {
    ...state,
    comments: documentComments,
    documentId: action.documentId,
    error: null,
    loading: false,
    success: true,
  };
};

const fetchDocumentCommentsFail = (state, action) => {
  return { ...state, error: true, loading: false, success: false, documentId: action.documentId };
};

const fetchIssueCommentsStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: null,
    success: false,
    issueId: action.issueId
  };
};

const fetchIssueCommentsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    success: true,
    error: null,
    comments: action.data,
    issueId: action.issueId,
  };
};

const fetchIssueCommentsFail = (state, action) => {
  return {
    ...state,
    loading: false,
    success: false,
    error: action.error,
    issueId: action.issueId
  };
};

const deleteCommentStart = (state, action) => {
  return {
    ...state,
    loading: true,
    success: false,
    error: null,
    issueId: action.issueId
  };
};

const deleteCommentSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    success: true,
    error: null,
    deletedComment: action.data,
    issueId: action.issueId
  };
};

const deleteCommentFail = (state, action) => {
  return {
    ...state,
    loading: false,
    success: false,
    error: action.error,
    issueId: action.issueId,
  };
};

const createCommentStart = (state, action) => {
  if (!action.issueId){
    return {
      ...state,
      documentId: action.documentId,
      error: null,
      loading: true,
      success: false
    };
  } else {
    return {
      ...state,
      issueId: action.issueId,
      error: null,
      loading: true,
      success: false
    };
  }
};

const createCommentSuccess = (state, action) => {
  if (!action.issueId){
    return {
      ...state,
      documentId: action.documentId,
      error: null,
      loading: false,
      success: true,
      insertedComment: action.data
    };
  } else {
    return {
      ...state,
      issueId: action.issueId,
      error: null,
      loading: false,
      success: true,
      insertedComment: action.data
    };
  }

};

const createCommentFail = (state, action) => {
  if (!action.issueId){
    return {
      ...state,
      documentId: action.documentId,
      error: action.error,
      loading: false,
      success: false
    };
  } else {
    return {
      ...state,
      issueId: action.issueId,
      error: action.error,
      loading: false,
      success: false
    };
  }
};

const updateCommentStart = (state, action) => {
  if (action.docId){
    return {
      ...state,
      documentId: action.documentId
    };
  } else {
    return {
      ...state,
      issueId: action.issueId
    };
  }
};

const updateCommentSuccess = (state, action) => {
  if (action.docId){
    return {
      ...state,
      documentId: action.documentId
    };
  } else {
    return {
      ...state,
      issueId: action.issueId
    };
  }
};

const updateCommentFail = (state, action) => {
  if (action.docId){
    return {
      ...state,
      documentId: action.documentId,
      error: action.error
    };
  } else {
    return {
      ...state,
      issueId: action.issueId,
      error: action.error,
      success: false
    };
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DOCUMENT_COMMENTS_START:
      return fetchDocumentCommentsStart(state, action);
    case actionTypes.FETCH_DOCUMENT_COMMENTS_SUCCESS:
      return fetchDocumentCommentsSuccess(state, action);
    case actionTypes.FETCH_DOCUMENT_COMMENTS_FAIL:
      return fetchDocumentCommentsFail(state, action);

    case actionTypes.FETCH_ISSUE_COMMENTS_START:
      return fetchIssueCommentsStart(state, action);
    case actionTypes.FETCH_ISSUE_COMMENTS_SUCCESS:
      return fetchIssueCommentsSuccess(state, action);
    case actionTypes.FETCH_ISSUE_COMMENTS_FAIL:
      return fetchIssueCommentsFail(state, action);

    case actionTypes.DELETE_COMMENT_START:
      return deleteCommentStart(state, action);
    case actionTypes.DELETE_COMMENT_SUCCESS:
      return deleteCommentSuccess(state, action);
    case actionTypes.DELETE_COMMENT_FAIL:
      return deleteCommentFail(state, action);

    case actionTypes.CREATE_COMMENT_START:
      return createCommentStart(state, action);
    case actionTypes.CREATE_COMMENT_SUCCESS:
      return createCommentSuccess(state, action);
    case actionTypes.CREATE_COMMENT_FAIL:
      return createCommentFail(state, action);


    case actionTypes.UPDATE_ISSUE_COMMENT_START:
      return updateCommentStart(state, action);
    case actionTypes.UPDATE_ISSUE_COMMENT_SUCCESS:
      return updateCommentSuccess(state, action);
    case actionTypes.UPDATE_ISSUE_COMMENT_FAIL:
      return updateCommentFail(state, action);


    default:
      return state;
  }
};

export default reducer;
