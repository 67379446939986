//@flow
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ExcerptTypeAggregations } from '../../types';
import { ANNOTATION } from '../../constants/constants';
import TabNav from '../UI/Tabs/TabNav/TabNav';
import TabNavItem from '../UI/Tabs/TabNavItem/TabNavItem';
import TabPanel from '../UI/Tabs/TabPanel/TabPanel';
import TabPanelItem from '../UI/Tabs/TabPanelItem/TabPanelItem';
import Tabs from '../UI/Tabs/Tabs';
import { selectAnnotationFilters } from '../../store/actions';
import UserExcerpt from './UserExcerpt';
import File from './File';

type Props = {
  excerptTypeAggregations: any,
  openDocument: (docId: string, excerptId: string) => void,
  openReviewDocument: (docId: string) => void,
  hasTrainingPermission: boolean,
  fetchExcerptsList: (docId: string) => void,
};

const UserExcerpts = ({
  excerptTypeAggregations,
  openDocument,
  openReviewDocument,
  hasTrainingPermission,
  fetchExcerptsList,
}: Props) => {
  const dispatch = useDispatch();
  const selectedUserAnnotationFilters = useSelector(
    (state) => state.project.selectedUserAnnotationFilters,
  );
  const [showAll, setShowAll] = useState(selectedUserAnnotationFilters[0]);
  const [showPositive, setShowPositive] = useState(selectedUserAnnotationFilters[1]);
  const [showNegative, setShowNegative] = useState(selectedUserAnnotationFilters[2]);
  const [showNeutral, setShowNeutral] = useState(selectedUserAnnotationFilters[3]);

  const toggleActive = (tab) => {
    let selectedAll = showAll;
    let selectedPositive = showPositive;
    let selectedNegative = showNegative;
    let selectedNeutral = showNeutral;

    if (tab === ANNOTATION.POSITIVE) {
      selectedPositive = !showPositive;
      setShowPositive(selectedPositive);
      if (selectedPositive && showAll) {
        selectedAll = false;
      }
    } else if (tab === ANNOTATION.NEGATIVE) {
      selectedNegative = !showNegative;
      setShowNegative(selectedNegative);
      if (selectedNegative && showAll) {
        selectedAll = false;
      }
    } else if (tab === ANNOTATION.NEUTRAL) {
      selectedNeutral = !showNeutral;
      setShowNeutral(selectedNeutral);
      if (selectedNeutral && showAll) {
        selectedAll = false;
      }
    }
    if (!selectedPositive && !selectedNegative && !selectedNeutral) {
      selectedAll = true;
    } else if (tab === ANNOTATION.ALL) {
      selectedAll = !showAll;
    }
    setShowAll(selectedAll);
    dispatch(
      selectAnnotationFilters([selectedAll, selectedPositive, selectedNegative, selectedNeutral]),
    );
  };

  const getFilteredExcerpts = (excerpts) => {
    return excerpts.filter(
      (e) =>
        showAll ||
        (e.aiTrained && showPositive) ||
        (e.isNegativeAiTrained && showNegative) ||
        (!e.aiTrained && !e.isNegativeAiTrained && showNeutral),
    );
  };

  return (
    <Tabs selectedTab={1}>
      <TabNav class={'user-annotations'}>
        <TabNavItem
          selectTab={() => toggleActive(ANNOTATION.ALL)}
          class={`${ANNOTATION.ALL} ${
            !showPositive && !showNegative && !showNeutral ? 'unclickable' : ''
          }`}
          isSelected={showAll}
        >
          <label>
            <span>All</span>
          </label>
        </TabNavItem>
        <TabNavItem
          selectTab={() => toggleActive(ANNOTATION.POSITIVE)}
          class={ANNOTATION.POSITIVE}
          isSelected={showPositive}
        >
          <label>
            <span>Positive</span>
          </label>
        </TabNavItem>
        <TabNavItem
          selectTab={() => toggleActive(ANNOTATION.NEGATIVE)}
          class={ANNOTATION.NEGATIVE}
          isSelected={showNegative}
        >
          <label>
            <span>Negative</span>
          </label>
        </TabNavItem>
        <TabNavItem
          selectTab={() => toggleActive(ANNOTATION.NEUTRAL)}
          class={ANNOTATION.NEUTRAL}
          isSelected={showNeutral}
        >
          <label>
            <span>Neutral</span>
          </label>
        </TabNavItem>
      </TabNav>
      <TabPanel>
        <TabPanelItem>
          {Object.keys(excerptTypeAggregations).map(
            (docId: ExcerptTypeAggregations, index: number) => {
              const statisticByType = excerptTypeAggregations[docId];
              const filteredExcerpts = getFilteredExcerpts(statisticByType.excerpts);
              return (
                <File
                  key={index}
                  filteredExcerpts={filteredExcerpts}
                  docId={docId}
                  openReviewDocument={openReviewDocument}
                  statisticByType={statisticByType}
                  fetchExcerptsList={fetchExcerptsList}
                >
                  <UserExcerpt
                    openDocument={openDocument}
                    hasTrainingPermission={hasTrainingPermission}
                  />
                </File>
              );
            },
          )}
        </TabPanelItem>
      </TabPanel>
    </Tabs>
  );
};

export default UserExcerpts;
