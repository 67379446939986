import * as actionTypes from '../actions/actionType';

const initialState = {
  fetchingPermission: false,
  fetchingPermissionsError: null,
  permissions: [],
};

const fetchPermissionsStart = (state, action) => {
  return {
    ...state,
    fetchingPermissions: true,
    fetchingPermissionsError: null,
  }
};

const fetchPermissionsSuccess = (state, action) => {
  const {permissions} = action;
  return {
    ...state,
    fetchingPermissions: false,
    fetchingPermissionsError: null,
    permissions
  }
};

const fetchPermissionsFail = (state, action) => {
  const {error} = action;
  return {
    ...state,
    fetchingPermissions: false,
    fetchingPermissionsError: error,
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PERMISSIONS_START:
      return fetchPermissionsStart(state, action);
    case actionTypes.FETCH_PERMISSIONS_SUCCESS:
      return fetchPermissionsSuccess(state, action);
    case actionTypes.FETCH_PERMISSIONS_FAIL:
      return fetchPermissionsFail(state, action);

    default:
      return state;
  }
};

export default reducer;
