import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../hooks/prevValue';
import Checkbox from '../../components/UI/Checkbox/Checkbox';
import CustomLogos from './CustomLogos';
import Spinner from '../../components/UI/Spinner/Spinner';
import {
  fetchGlobalSettings,
  updateGlobalSettingsAiTrainer,
  updateGlobalSettingsiFinder,
  updateGlobalSettingsDuplicateDocs,
  deleteImageFile,
  updateCSSLogo,
  updateImageFile,
  uploadImageFile,
  updateFreezeTraining,
} from '../../store/actions';
import { TextareaAutosize } from '@material-ui/core';
import Button from '../../components/UI/Button/Button';
import _ from 'lodash';
import { withPermissions } from '../../contexts/PermissionsContext/withPermissions';
import { PERMISSIONS } from '../../constants/constants';
import Warning from '../../components/UI/Typo/Warning';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '../../components/UI/Modal/Modal';

const General = ({ PermissionsContext }) => {
  const dispatch = useDispatch();
  const globalSettings = useSelector((state) => state.globalSettings.globalSettings);
  const updatingImage = useSelector((state) => state.globalSettings.updatingImage);
  const updatingSettings = useSelector((state) => state.globalSettings.updatingSettings);
  const [useAITrainer, setUseAITrainer] = useState(globalSettings.aiTrainerEnabled);
  const [useiFinder, setUseiFinder] = useState(globalSettings.indexAlFindingsiFinder);
  const [allowUploadingDuplicates, setAllowUploadingDuplicates] = useState(
    globalSettings.allowDuplicateDocs,
  );
  const isFrozenTraining = globalSettings.freezeTraining;

  const [showModal, setShowModal] = useState(false);
  const [allCustomLogos, setAllCustomLogos] = useState([]);
  const [customCSS, setCustomCSS] = useState(globalSettings.css);
  const [touched, setTouched] = useState(false);
  const prevUpdatingSettings = usePrevious(updatingSettings);

  useEffect(() => {
    dispatch(fetchGlobalSettings());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Object.keys(globalSettings).length > 0) {
      setUseAITrainer(globalSettings.aiTrainerEnabled);
      setUseiFinder(globalSettings.indexAlFindingsiFinder);
      setAllCustomLogos(globalSettings.logo);
      setCustomCSS(globalSettings.css);
    }
    // eslint-disable-next-line
  }, [globalSettings]);

  useEffect(() => {
    if (prevUpdatingSettings && !updatingSettings) {
      dispatch(fetchGlobalSettings());
    }
    // eslint-disable-next-line
  }, [updatingSettings]);

  useEffect(() => {
    if (customCSS !== globalSettings.css) {
      setTouched(customCSS !== globalSettings.css);
    } else {
      setTouched(false);
    }
    // eslint-disable-next-line
  }, [customCSS]);

  const toggleUseAITrainer = () => {
    dispatch(updateGlobalSettingsAiTrainer(globalSettings.id, !useAITrainer));
    setUseAITrainer(!useAITrainer);
  };

  const toggleUseiFinder = () => {
    dispatch(updateGlobalSettingsiFinder(globalSettings.id, !useiFinder));
    setUseiFinder(!useiFinder);
  };

  const toggleUploadDuplicatedDocs = () => {
    dispatch(updateGlobalSettingsDuplicateDocs(globalSettings.id, !allowUploadingDuplicates));
    setAllowUploadingDuplicates(!allowUploadingDuplicates);
  };

  const addLogo = (file, imageStat) => {
    dispatch(uploadImageFile(file, imageStat));
  };

  const deleteLogo = (logoId) => {
    dispatch(deleteImageFile(logoId));
  };

  const updateActiveLogo = (newLogo) => {
    dispatch(updateImageFile(newLogo));
  };

  const cssChanged = (event) => {
    setCustomCSS(event.target.value);
  };

  const updateCustomCSS = () => {
    dispatch(updateCSSLogo(customCSS));
    setTouched(false);
  };

  const onFreezeTraining = () => {
    dispatch(updateFreezeTraining(true));
  };

  const onUnfreezeTraining = () => {
    dispatch(updateFreezeTraining(false));
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className={'al-container al-general-settings-container with-scrollbar'}>
      <div className={'al-general-info'}>
        <div className={'al-headline'}>General settings</div>
        <div className="al-description-text">Change application settings</div>
      </div>
      {!globalSettings ? (
        <Warning> No data to be shown, please contact your system administrator </Warning>
      ) : (
        <div className={'general-dashboard-wrapper'}>
          {(PermissionsContext.hasPermission(PERMISSIONS.SHOW_AI_TRAINER_TOGGLE) ||
            PermissionsContext.hasPermission(PERMISSIONS.SHOW_IFINDER_TOGGLE)) && (
            <>
              <div className={'al-section-description-text'}>Advanced learning settings</div>
              <div className="general-row-section-wrapper">
                {PermissionsContext.hasPermission(PERMISSIONS.SHOW_AI_TRAINER_TOGGLE) && (
                  <Checkbox
                    className={'settings-item-container'}
                    onClick={toggleUseAITrainer}
                    checked={useAITrainer}
                  >
                    Use advanced learning suggestions
                  </Checkbox>
                )}
                {PermissionsContext.hasPermission(PERMISSIONS.SHOW_IFINDER_TOGGLE) && (
                  <Checkbox
                    className={'settings-item-container'}
                    onClick={toggleUseiFinder}
                    checked={useiFinder}
                  >
                    Use iFinder
                  </Checkbox>
                )}
              </div>
            </>
          )}
          <div className={'al-section-description-text'}>Upload documents settings</div>
          <div className="general-row-section-wrapper">
            <Checkbox
              className={'settings-item-container'}
              onClick={toggleUploadDuplicatedDocs}
              checked={allowUploadingDuplicates}
            >
              Upload duplicate documents
            </Checkbox>
          </div>
          <div className={'al-section-description-text'}>Custom logos</div>
          <div className="general-row-section-wrapper custom-logos">
            {updatingImage ? (
              <Spinner />
            ) : (
              <CustomLogos
                customLogos={_.sortBy(allCustomLogos, 'title')}
                addLogo={addLogo}
                deleteLogo={deleteLogo}
                updateActiveLogo={updateActiveLogo}
              />
            )}
          </div>

          <div
            className="general-row-section-wrapper general-css"
            style={{ width: '50%', paddingRight: '0' }}
          >
            <p className={'section-description'}>
              To change the logo style, please change <span className={'text-logo-id'}>#logo</span>{' '}
              and <span className={'text-logo-id'}>#footer-logo</span> selectors:
            </p>
            <TextareaAutosize
              aria-label="custom css"
              minRows={5}
              defaultValue={customCSS}
              onChange={cssChanged}
              placeholder="#logo {} #footer-logo {}"
            />

            {touched && (
              <div className={'save-css-footer'}>
                <Button className={'al-primary'} clicked={updateCustomCSS}>
                  Save
                </Button>
                <Button
                  clicked={() => {
                    setCustomCSS(globalSettings.css);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <div className="general-row-section-wrapper freeze-training">
            {!isFrozenTraining ? (
              <Button className={'al-primary'} clicked={onFreezeTraining}>
                Freeze training the system
              </Button>
            ) : (
              <Button
                clicked={() => {
                  setShowModal(true);
                }}
              >
                Unfreeze training the system
              </Button>
            )}
          </div>
        </div>
      )}
      <Modal show={showModal}>
        <ModalHeader title={`Warning`} onClose={closeModal} />
        <ModalContent>
          <Warning>
            Unfreezing advanced learning will re-process all the documents in the document type if
            any training in the specific document type has been triggered. This operation might take
            a while until all the documents are processed.
          </Warning>
        </ModalContent>
        <ModalFooter>
          <Button
            clicked={() => {
              onUnfreezeTraining();
              closeModal();
            }}
            className={'al-primary'}
          >
            Unfreeze
          </Button>
          <Button clicked={closeModal} preventDefault={true}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withPermissions(General);
