import { axiosInstance as axios } from '../../services/axiosInstance';
import * as actionTypes from './actionType';
import { IMG_FILE_EXTENSIONS_REGEX } from '../../constants/constants';
import { Dispatch } from 'redux';

const fetchGlobalSettingsStart = () => {
  return {
    type: actionTypes.FETCH_GLOBAL_SETTINGS_START,
  };
};

const fetchGlobalSettingsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SETTINGS_SUCCESS,
    data: data,
  };
};

const fetchGlobalSettingsFail = (error) => {
  return {
    type: actionTypes.FETCH_GLOBAL_SETTINGS_FAIL,
    error: error,
  };
};

export const fetchGlobalSettings = () => {
  return (dispatch) => {
    dispatch(fetchGlobalSettingsStart());
    axios
      .get('/api/globalsettings/')
      .then((response) => {
        dispatch(fetchGlobalSettingsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchGlobalSettingsFail(error));
      });
  };
};

const updateGlobalSettingsStart = () => {
  return {
    type: actionTypes.UPDATE_GLOBAL_SETTINGS_START,
  };
};

const updateGlobalSettingsSuccess = (id) => {
  return {
    type: actionTypes.UPDATE_GLOBAL_SETTINGS_SUCCESS,
    id: id,
  };
};

const updateGlobalSettingsFail = (id, error) => {
  return {
    type: actionTypes.UPDATE_GLOBAL_SETTINGS_FAIL,
    id: id,
    error: error,
  };
};

export const updateGlobalSettingsAiTrainer = (id, useAITrainer) => {
  return (dispatch) => {
    dispatch(updateGlobalSettingsStart());
    axios
      .put(`/api/globalsettings/aitrainer`, null, {
        params: {
          aiTrainerEnabled: useAITrainer,
        },
      })
      .then((response) => {
        dispatch(updateGlobalSettingsSuccess(id));
      })
      .catch((error) => {
        dispatch(updateGlobalSettingsFail(id, error));
      });
  };
};

export const updateGlobalSettingsiFinder = (id, useiFinder) => {
  return (dispatch) => {
    dispatch(updateGlobalSettingsStart());
    axios
      .put(`/api/globalsettings/ifinder`, null, {
        params: {
          indexAlFindingsiFinder: useiFinder,
        },
      })
      .then((response) => {
        dispatch(updateGlobalSettingsSuccess(id, response.data));
      })
      .catch((error) => {
        dispatch(updateGlobalSettingsFail(id, error));
      });
  };
};

export const updateGlobalSettingsDuplicateDocs = (id, allowUploadingDuplicates) => {
  return (dispatch) => {
    dispatch(updateGlobalSettingsStart());
    axios
      .put(`/api/globalsettings/duplicate-documents`, null, {
        params: {
          allowDuplicateDocs: allowUploadingDuplicates,
        },
      })
      .then((response) => {
        dispatch(updateGlobalSettingsSuccess(id));
      })
      .catch((error) => {
        dispatch(updateGlobalSettingsFail(id, error));
      });
  };
};

const uploadImageFileStart = (file: string, active: boolean) => ({
  type: actionTypes.UPLOAD_IMG_FILE_START,
  file,
  active,
});

const uploadImageFileSuccess = () => ({
  type: actionTypes.UPLOAD_IMG_FILE_SUCCESS,
});

const uploadImageFileFail = (error, file) => {
  return {
    type: actionTypes.UPLOAD_IMG_FILE_FAIL,
    error: error,
    file,
  };
};

export const uploadImageFile = (file, imageStat) => async (dispatch) => {
  await dispatch(uploadImageFileStart(file.name));
  if (!IMG_FILE_EXTENSIONS_REGEX.test(file.name)) {
    const error = { message: 'Invalid file extension', response: { status: null } };
    await dispatch(uploadImageFileFail(error, file));
  } else {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('imageStat', imageStat);
    axios
      .post(`api/globalsettings/logo/`, formData)
      .then((response) => {
        dispatch(uploadImageFileSuccess());
        dispatch(fetchGlobalSettings());
      })
      .catch((err) => {
        dispatch(uploadImageFileFail(err));
      });
  }
};

const updateImageFileStart = () => ({
  type: actionTypes.UPDATE_IMG_FILE_START,
});

const updateImageFileSuccess = () => ({
  type: actionTypes.UPDATE_IMG_FILE_SUCCESS,
});

const updateImageFileFail = (error) => {
  return {
    type: actionTypes.UPDATE_IMG_FILE_FAIL,
    error: error,
  };
};

export const updateImageFile = (logo) => {
  return (dispatch) => {
    dispatch(updateImageFileStart());
    axios
      .put(`api/globalsettings/logo/`, logo)
      .then((response) => {
        dispatch(updateImageFileSuccess());
        dispatch(fetchGlobalSettings());
      })
      .catch((err) => {
        dispatch(updateImageFileFail(err));
      });
  };
};

const deleteImageFileStart = (file: string) => ({
  type: actionTypes.DELETE_IMG_FILE_START,
  file,
});

const deleteImageFileSuccess = (file: string) => ({
  type: actionTypes.DELETE_IMG_FILE_SUCCESS,
  file,
});

const deleteImageFileFail = (file: string, error: any) => ({
  type: actionTypes.DELETE_IMG_FILE_FAIL,
  error,
  file,
});

export const deleteImageFile = (fileId) => async (dispatch: Dispatch) => {
  dispatch(deleteImageFileStart(fileId));
  try {
    await axios.delete(`api/globalsettings/logo/${fileId}`);
    dispatch(deleteImageFileSuccess(fileId));
    dispatch(fetchGlobalSettings());
  } catch (error) {
    dispatch(deleteImageFileFail(fileId, error));
  }
};

const updateCSSLogoStart = (css: string) => ({
  type: actionTypes.UPDATE_CSS_LOGO_START,
  css,
});

const updateCSSLogoSuccess = () => ({
  type: actionTypes.UPDATE_CSS_LOGO_SUCCESS,
});

const updateCSSLogoFail = (error, css) => {
  return {
    type: actionTypes.UPDATE_CSS_LOGO_FAIL,
    error: error,
    css,
  };
};

export const updateCSSLogo = (css) => {
  return (dispatch) => {
    dispatch(updateCSSLogoStart(css));
    axios
      .put(`api/globalsettings/css/`, null, {
        params: {
          css: css,
        },
      })
      .then((response) => {
        dispatch(updateCSSLogoSuccess());
        dispatch(fetchGlobalSettings());
      })
      .catch((err) => {
        dispatch(updateCSSLogoFail(err));
      });
  };
};

const updateFreezeTrainingStart = () => ({
  type: actionTypes.UPDATE_FREEZE_TRAINING_START,
});

const updateFreezeTrainingSuccess = () => ({
  type: actionTypes.UPDATE_FREEZE_TRAINING_SUCCESS,
});

const updateFreezeTrainingFail = (error) => {
  return {
    type: actionTypes.UPDATE_FREEZE_TRAINING_FAIL,
    error: error,
  };
};

export const updateFreezeTraining = (freezeTraining) => {
  return (dispatch) => {
    dispatch(updateFreezeTrainingStart());
    axios
      .put(`api/globalsettings/freeze-training/`, null, {
        params: {
          freezeTraining: freezeTraining,
        },
      })
      .then((response) => {
        dispatch(updateFreezeTrainingSuccess());
        dispatch(fetchGlobalSettings());
      })
      .catch((err) => {
        dispatch(updateFreezeTrainingFail(err));
      });
  };
};
