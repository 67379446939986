// @flow
import React, { Component } from 'react';
import Button from '../../UI/Button/Button';
import Checkbox from '../../UI/Checkbox/Checkbox';
import type { ContractType } from '../../../types';
import moment from 'moment';
import Spinner from '../../UI/Spinner/Spinner';
import { DOCUMENT_ANALYZED_STATUS, FINDING_CATEGORY } from '../../../constants/constants';
import { DocumentStatusBar } from '../DocumentStatusBar/DocumentStatusBar';
import Icon from '../../UI/Icon/Icon';

type Props = {
  selected: boolean,
  file: any,
  contractType: ContractType,
  onToggleSelect: (id: string) => void,
  id: string,
  deleteDocumentById: () => void,
  openDocument: (docId: string) => void,
  retryDocument: (fileId: Array<string>) => void,
  reProcessDocument: () => void,
  reProcessStaticFindings: (fileId: Array<string>) => void,
};

class FileItem extends Component<Props> {
  deleteDocument = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.props.deleteDocumentById(this.props.id);
  };

  openDocument = () => {
    this.props.openDocument(this.props.id);
  };

  hasFindingCategory = (category) => {
    return this.props.file.findingCategories?.filter((cat) => cat === category).length > 0;
  };

  render() {
    const {
      selected,
      file: { ocr, title, uploadedDate, analyzeStatus, withStaticFindings },
      contractType,
      onToggleSelect,
      retryDocument,
      reProcessStaticFindings,
    } = this.props;

    const docIcon = ocr ? 'al-icons al-icon-ocr' : 'al-icons al-icon-document';
    const hasMissingFindings = this.hasFindingCategory(FINDING_CATEGORY.IMPORTANT);
    const hasCriticalFindings = this.hasFindingCategory(FINDING_CATEGORY.CRITICAL);

    if (!!analyzeStatus) {
      return (
        <div className="al-filelist-entry">
          <div className="al-filelist-select">
            <Checkbox
              onClick={() => onToggleSelect(selected, this.props.file.id, analyzeStatus)}
              checked={selected}
            />
          </div>
          {/*<i className={docIcon} />*/}
          <div className="al-filelist-details">
            <div
              className="al-filelist-entry-name"
              disabled={
                [
                  DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED,
                  DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED_WITH_ERROR,
                ].indexOf(analyzeStatus) === -1
              }
              onClick={this.openDocument}
            >
              <span className="al-filelist-entry-title">{title}</span>
              <span className={'al-filelist-entry-info'}>
                <span>{moment(uploadedDate).format('llll')}</span>|
                <span>
                  {contractType.contractType.toLowerCase().trim() === 'others'
                    ? 'UNASSIGNED'
                    : contractType.contractType.toUpperCase()}
                </span>
              </span>
            </div>
          </div>
          <div className={'al-filelist-findings'}>
            <Icon
              className={'warning-icon'}
              title={`${hasMissingFindings ? 'Document is missing important findings' : ''}`}
              icon={`exclamation-triangle-fill ${hasMissingFindings ? 'active' : 'inactive'}`}
              show={true}
            />
            <Icon
              className={'warning-icon'}
              title={`${hasCriticalFindings ? 'Document has critical findings' : ''}`}
              icon={`exclamation-circle-fill ${hasCriticalFindings ? 'active' : 'inactive'}`}
              show={true}
            />
          </div>
          <DocumentStatusBar
            analyzeStatus={analyzeStatus}
            retryProcessDocument={retryDocument}
            withStaticFindings={withStaticFindings}
            reProcessStaticFindings={reProcessStaticFindings}
            reProcessDocument={this.props.reProcessDocument}
          />
          <Button
            iconType={'delete'}
            withIcon={true}
            clicked={this.deleteDocument}
            className={' al-ml-20'}
            title={'Delete document'}
          />
        </div>
      );
    } else {
      return (
        <div disabled className="al-filelist-entry">
          <div className="al-filelist-select">
            <Spinner />
          </div>
          <i className={docIcon} />
          <div className="al-filelist-entry-name" onClick={this.openDocument}>
            <span>{title}</span>
          </div>
        </div>
      );
    }
  }
}

export default FileItem;
