import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { usePrevious } from '../../../hooks/prevValue';
import Input from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import Spinner from '../../UI/Spinner/Spinner';
import Overlay from '../../UI/Overlay/Overlay';
import Clause from './Clause';
import { CreateGlobalConfig } from '../../../containers/ContractTypes/ContractTypes';
import { FormattedMessage } from 'react-intl';

const ClausesSelect = ({
  title,
  isStaticFinding,
  projectId,
  contractType,
  globalConfigs,
  bootStrapStaticType,
  deleteUserGlobalConfigs,
  isEditingAlFindings,
  isEditingStaticFindings,
  setIsEditingAlFindings,
  setIsEditingStaticFindings,
  updateGlobalConfigCategoriesList,
  updateGlobalConfigEnableDisableList,
  updateGlobalConfigAdvLearningTypesList,
}) => {
  const updateLoading = useSelector((state) => state.project.updateLoading);
  const [excerptType, setExcerptType] = useState('');
  const [classes] = useState(['al-clauses', isStaticFinding ? 'static' : 'advanced-learning']);
  const [createNewSettingShown, showCreateNewSettingModal] = useState(false);
  const [isDeletingAll, setIsDeletingAll] = useState(false);
  const prevIsEditingStaticFindings = usePrevious(isEditingStaticFindings);

  useEffect(() => {
    if (
      isStaticFinding &&
      prevIsEditingStaticFindings !== isEditingStaticFindings &&
      !isEditingStaticFindings
    ) {
      updateEnabledFindingsCount(
        getFilteredGlobalConfigsBy(excerptType).filter((item) => item.enabled).length,
      );
    }
    // eslint-disable-next-line
  }, [isEditingStaticFindings]);

  const inputChangeHandler = (event) => {
    setExcerptType(event.target.value);
  };

  const getFilteredGlobalConfigsBy = (excerptType: string) => {
    return globalConfigs.filter((item) => {
      return item.excerptType.toLowerCase().indexOf(excerptType.toLowerCase()) >= 0;
    });
  };

  const isAllStaticFindingsEnabled = () => {
    if (globalConfigs?.length) {
      return (
        globalConfigs.filter((globalConfig) => {
          return !globalConfig.enabled;
        }).length === 0
      );
    } else {
      return false;
    }
  };

  const [toggleAllGlobalConfigsTo, setToggleAllGlobalConfigsTo] = useState(
    isAllStaticFindingsEnabled(),
  );
  const [enabledFindingsCount, updateEnabledFindingsCount] = useState(
    getFilteredGlobalConfigsBy(excerptType).filter((item) => item.enabled).length,
  );

  const allFindingsCount = getFilteredGlobalConfigsBy(excerptType).length;

  const toggleAllEnabledGlobalConfigs = (enabling) => {
    globalConfigs.map((gc) => {
      gc.enabled = enabling;
      updateGlobalConfigEnableDisableList(gc);
    });
    setToggleAllGlobalConfigsTo(enabling);
    updateEnabledFindingsCount(enabling ? allFindingsCount : 0);
  };

  const isEditing = () => {
    if (isStaticFinding && isEditingStaticFindings) {
      return true;
    } else if (!isStaticFinding && isEditingAlFindings) {
      return true;
    }
    return false;
  };

  const updateGlobalConfigEnabled = (item, enable) => {
    if (enable) {
      updateEnabledFindingsCount((prevState) => prevState + 1);
    } else {
      updateEnabledFindingsCount((prevState) => prevState - 1);
    }
    updateGlobalConfigEnableDisableList(item);
  };

  return (
    <div className={classes.join(' ')}>
      {updateLoading && (
        <Overlay>
          <Spinner />
        </Overlay>
      )}
      <div className="al-settings-header">
        <div className="label-wrapper">
          <label className="findings-title">{title}</label>
          {isStaticFinding && (
            <label
              className="findings-count"
              title={`${enabledFindingsCount} out of ${allFindingsCount} findings are enabled`}
            >
              {'('}
              {enabledFindingsCount}
              {'/'}
              {allFindingsCount}
              {')'}
            </label>
          )}
        </div>
        <div className={'al-settings-header-icons'}>
          {!isStaticFinding && (
            <>
              <Button
                iconType={'add'}
                withIcon={true}
                clicked={() => showCreateNewSettingModal(true)}
                title="Add new finding type"
              />
            </>
          )}
          <Button
            iconType={'edit'}
            withIcon={true}
            clicked={() => {
              if (isStaticFinding) {
                setIsEditingStaticFindings(true);
              } else {
                setIsEditingAlFindings(true);
                setIsDeletingAll(false);
              }
            }}
          />
        </div>
      </div>

      <div className="al-search-filter">
        <Input
          key={'input'}
          placeholder={'app.document.searchForType'}
          className={'al-input-text'}
          elementType={'input'}
          value={excerptType}
          changed={(event) => inputChangeHandler(event)}
          elementConfig={{ type: 'text' }}
          keyDown={(e) => e.stopPropagation()}
        />
        <Button disabled={false} withIcon={true} iconType={'search'} />
      </div>
      <div
        className={`click-all-option ${!isEditing() ? 'read-only' : 'editing'}`}
        style={{ display: `${!globalConfigs.length ? 'none' : ''}`, textAlign: 'right' }}
      >
        {isStaticFinding ? (
          <span
            title={`${!toggleAllGlobalConfigsTo ? 'Select' : 'Deselect'} all static findings`}
            className={`al-dropdown-select-all`}
            onClick={() => {
              toggleAllEnabledGlobalConfigs(!toggleAllGlobalConfigsTo);
            }}
          >
            {!toggleAllGlobalConfigsTo ? 'Enable all' : 'Disable all'}
          </span>
        ) : (
          <span
            title={`Remove all models`}
            className={`al-dropdown-select-all`}
            onClick={() => {
              deleteUserGlobalConfigs('*');
              setIsDeletingAll(true);
              setIsEditingAlFindings(false);
            }}
          >
            <FormattedMessage id="app.review.deleteAiIssues" defaultMessage="Remove all" />
          </span>
        )}
      </div>
      <ul>
        {globalConfigs === 0 ? (
          <Spinner className={'al-globalConfigs-loader'} />
        ) : (
          getFilteredGlobalConfigsBy(excerptType).map((item) => {
            return (
              <Clause
                key={item.id}
                projectId={projectId}
                contractType={contractType}
                item={item}
                isStaticFinding={isStaticFinding}
                isEditing={isEditing}
                bootStrapStaticType={bootStrapStaticType}
                deleteUserGlobalConfigs={deleteUserGlobalConfigs}
                updateGlobalConfigCategoriesList={updateGlobalConfigCategoriesList}
                updateGlobalConfigAdvLearningTypesList={updateGlobalConfigAdvLearningTypesList}
                updateGlobalConfigEnabled={updateGlobalConfigEnabled}
                isDeletingAll={isDeletingAll}
                toggleAllGlobalConfigsTo={toggleAllGlobalConfigsTo}
              />
            );
          })
        )}
      </ul>

      {createNewSettingShown && (
        <CreateGlobalConfig
          projectId={projectId}
          contractId={contractType.id}
          globalConfigs={globalConfigs}
          onCancel={() => {
            showCreateNewSettingModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ClausesSelect;
