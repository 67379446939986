import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Notification } from '../../components/UI/Notifications/Notification';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { injectIntl } from 'react-intl';
import UploadFileError from './UploadFileError';
import { usePrevious } from '../../hooks/prevValue';

const NotificationCenter = injectIntl(({ intl }) => {
  const actionType = useSelector((state) => state.issues.actionType);
  const issuesLoading = useSelector((state) => state.issues.loading);
  const issuesError = useSelector((state) => state.issues.bootstrapError);
  const issuesSuccess = useSelector((state) => state.issues.bootstrapSuccess);
  const issuesData = useSelector((state) => state.issues.data);
  const excerptType = useSelector((state) => state.issues.excerptType);
  const uploadFileError = useSelector((state) => state.document.uploadError);
  const singleIssueLoading = useSelector((state) => state.issues.issue.updateLoading);
  const singleIssueError = useSelector((state) => state.issues.issue.updateError);
  const singleIssueSuccess = useSelector((state) => state.issues.issue.success);
  const issueId = useSelector((state) => state.issues.issue.issueId);
  const applyingQuery = useSelector((state) => state.searchQueries.applyingQuery);
  const applyingQueryError = useSelector((state) => state.searchQueries.applyingQueryError);
  const createdExcerptsIds = useSelector((state) => state.searchQueries.createdExcerptsIds);
  const trainingIssue = useSelector((state) => state.issues.trainingIssue);
  const trainingIssueError = useSelector((state) => state.issues.trainingIssueError);
  const updatingContract = useSelector((state) => state.document.updatingContract);
  const updatingContractError = useSelector((state) => state.document.updatingContractError);
  const creatingDBFinding = useSelector((state) => state.compare.creatingDBFinding);
  const creatingDBFindingError = useSelector((state) => state.compare.creatingDBFindingError);
  const currentLocation = useSelector((state) => state.locationPathName.currentLocation);
  const generalError = useSelector((state) => state.generalErrors);
  const prevApplyingQuery = usePrevious(applyingQuery);
  const prevIssuesSuccess = usePrevious(issuesSuccess);
  const prevCreatingDBFinding = usePrevious(creatingDBFinding);

  const allConfigs = {
    bootStrapStaticFail: {
      type: 'critical',
      header: () => 'Bootstrap static findings',
      message: (notification) => {
        let translatedMessage = intl.formatMessage({
          id: 'app.notifications.bootstrap.static.finding.error',
          defaultMessage: '',
        });
        let args = [
          notification.issuesError && notification.issuesError.data
            ? notification.issuesError.data
            : '',
        ];
        return parse(translatedMessage, args);
      },
      show: (notification) => {
        return notification.issuesError;
      },
    },
    bootStrapStaticSuccess: {
      type: 'auto',
      duration: 5000,
      header: () => 'Bootstrap static findings',
      message: (notification) => {
        let translatedMessage = intl.formatMessage({
          id: 'app.notifications.bootstrap.static.finding.success',
          defaultMessage: '',
        });
        let args = [
          notification.excerptType ? notification.excerptType : '',
          notification.data && notification.data.length ? notification.data.length + '' : 0,
        ];
        return parse(translatedMessage, args);
      },
      show: (notification) => {
        return notification.issuesSuccess;
      },
    },
    createIssueFail: {
      type: 'critical',
      header: () => 'Error on creating finding',
      message: () => {
        return intl.formatMessage({
          id: 'app.notifications.create.finding.error',
          defaultMessage: '',
        });
      },
      show: (notification) => {
        return notification.singleIssueError;
      },
    },
    uploadFail: {
      type: 'critical',
      header: () => 'Error on uploading file',
      message: (notification) => <UploadFileError error={notification.uploadFileError} />,
      show: (notification) => {
        return notification.uploadFileError && notification.uploadFileError.status === 409;
      },
    },
    applyingSearchQueriesSuccess: {
      type: 'auto',
      duration: 5000,
      header: () => 'Applying search queries',
      message: (notification) => {
        const createExcerptsIdsLen = notification.createdExcerptsIds.length;
        const verbForm = createExcerptsIdsLen === 1 ? ' has' : 's have';
        return `${createExcerptsIdsLen} finding${verbForm} been created.`;
      },
      show: (notification) => {
        return !notification.applyingQuery && notification.applyingQueryError === null;
      },
    },
    trainingSystemSuccess: {
      type: 'auto',
      duration: 5000,
      header: () => 'Train system',
      message: (notification) => {
        return `System has been trained from "${notification.trainingIssue.excerptType}" finding.`;
      },
      show: (notification) => {
        return !!notification.trainingIssue && !notification.trainingIssueError;
      },
    },
    movingDocsiFinderTabSuccess: {
      type: 'auto',
      duration: 5000,
      header: () => 'Reallocate documents',
      message: () => {
        return 'The request is being processed. Please wait some seconds and refresh the page to see the latest changes.';
      },
      show: (notification) => {
        return !notification.updatingContractError && notification.updatingContract;
      },
    },
    createDatabaseFindingSuccess: {
      type: 'auto',
      duration: 5000,
      header: () => 'Create database finding',
      message: () => {
        return 'The finding has been added to the database.';
      },
      show: (notification) => {
        return !notification.creatingDBFinding && !notification.creatingDBFindingError;
      },
    },
    generalError: {
      type: 'critical',
      header: (notification) => notification.generalError.errorHeader,
      message: (notification) => notification.generalError.errorBody,
      show: (notification) => {
        return notification.generalError.error;
      },
    },
  };
  const [configs, setConfigs] = useState([]);
  const configsRef = useRef(configs);
  configsRef.current = configs;

  const parse = (str, args) => {
    let i = 0;
    return str.replace(/%s/g, () => args[i++]);
  };

  const updateConfigs = (notificationCallback) => {
    let updatedConfigs = [...configs];
    const initialConfigsLen = updatedConfigs.length;
    notificationCallback(updatedConfigs);
    if (updatedConfigs.length !== initialConfigsLen) {
      setConfigs(updatedConfigs);
    }
  };

  useEffect(() => {
    updateConfigs(updateGeneralErrorNotification);
    // eslint-disable-next-line
  }, [generalError]);

  useEffect(() => {
    if (prevIssuesSuccess !== issuesSuccess) {
      updateConfigs(processIssuesNotification);
    }
    // eslint-disable-next-line
  }, [issuesLoading, issuesSuccess, issuesError]);

  useEffect(() => {
    updateConfigs(processUploadNotification);
    // eslint-disable-next-line
  }, [uploadFileError]);

  useEffect(() => {
    updateConfigs(processSingleIssueNotification);
    // eslint-disable-next-line
  }, [singleIssueLoading, singleIssueSuccess, singleIssueError, issueId]);

  useEffect(() => {
    if (typeof prevApplyingQuery !== 'undefined') {
      updateConfigs(processApplyingSearchQueriesNotification);
    }
    // eslint-disable-next-line
  }, [applyingQuery, applyingQueryError, createdExcerptsIds]);

  useEffect(() => {
    updateConfigs(trainSystemNotification);
    // eslint-disable-next-line
  }, [trainingIssue, trainingIssueError]);

  useEffect(() => {
    updateConfigs(updateDocsToContractIdNotification);
    // eslint-disable-next-line
  }, [updatingContract, updatingContractError]);

  useEffect(() => {
    // conditional prevents showing the notification on component load
    if (typeof prevCreatingDBFinding !== 'undefined') {
      updateConfigs(createDatabaseFinding);
    }
    // eslint-disable-next-line
  }, [creatingDBFinding, creatingDBFindingError]);

  const processIssuesNotification = (updatedQueue) => {
    let currentState = {
      actionType: actionType,
      issuesLoading: issuesLoading,
      issuesSuccess: issuesSuccess,
      issuesError: issuesError,
      data: issuesData,
      excerptType: excerptType,
    };
    addNotification(updatedQueue, allConfigs.bootStrapStaticSuccess, currentState);
    addNotification(updatedQueue, allConfigs.bootStrapStaticFail, currentState);
  };

  const processUploadNotification = (updatedQueue) => {
    let currentState = {
      uploadFileError: uploadFileError,
    };
    addNotification(updatedQueue, allConfigs.uploadFail, currentState);
  };

  const processSingleIssueNotification = (updatedQueue) => {
    let currentState = {
      singleIssueLoading: singleIssueLoading,
      singleIssueSuccess: singleIssueSuccess,
      singleIssueError: singleIssueError,
      actionType: actionType,
      issueId: issueId,
    };
    addNotification(updatedQueue, allConfigs.createIssueFail, currentState);
  };

  const processApplyingSearchQueriesNotification = (updatedQueue) => {
    let currentState = {
      applyingQuery: applyingQuery,
      applyingQueryError: applyingQueryError,
      createdExcerptsIds: createdExcerptsIds,
    };
    addNotification(updatedQueue, allConfigs.applyingSearchQueriesSuccess, currentState);
  };

  const trainSystemNotification = (updatedQueue) => {
    let currentState = {
      trainingIssue: trainingIssue,
      trainingIssueError: trainingIssueError,
    };
    addNotification(updatedQueue, allConfigs.trainingSystemSuccess, currentState);
  };

  const updateDocsToContractIdNotification = (updatedQueue): boolean => {
    const currentState = {
      updatingContract: updatingContract,
      updatingContractError: updatingContractError,
    };
    if (currentLocation === '/search') {
      addNotification(updatedQueue, allConfigs.movingDocsiFinderTabSuccess, currentState);
    }
  };

  const createDatabaseFinding = (updatedQueue): boolean => {
    const currentState = {
      creatingDBFinding: creatingDBFinding,
      creatingDBFindingError: creatingDBFindingError,
    };
    if (currentLocation === '/compareFindings') {
      addNotification(updatedQueue, allConfigs.createDatabaseFindingSuccess, currentState);
    }
  };

  const updateGeneralErrorNotification = (updatedQueue): boolean => {
    const currentState = {
      generalError: generalError,
    };
    addNotification(updatedQueue, allConfigs.generalError, currentState);
  };

  const addNotification = (updatedQueue, config, notification) => {
    if (config.show(notification)) {
      updatedQueue.push(getNotificationConfig(config, notification));
    }
  };

  const getNotificationConfig = (config, notification) => {
    const { type, duration, actionType, header, message, show } = config;
    return {
      type,
      duration,
      actionType,
      header: header(notification),
      message: message(notification),
      show: show(notification),
      key: Math.random().toString(),
    };
  };

  const deleteNotification = (key: string, duration?: number) => {
    const currentConfigs = duration ? configsRef.current : configs;
    const updatedConfigs = currentConfigs.filter((conf) => conf.key !== key);
    setConfigs(updatedConfigs);
  };

  return (
    <div className="al-notifications">
      <TransitionGroup className="items-section__list">
        {configs.map((config) => {
          return (
            <CSSTransition key={config.key} classNames="alert" timeout={500}>
              <Notification
                configKey={config.key}
                onExit={deleteNotification}
                duration={config.duration}
                type={config.type}
                msg={config.message}
                headerMsg={config.header}
                show={config.show}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
});

export default NotificationCenter;
