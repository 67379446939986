import React from 'react';

function Warning({ children, className }) {
  return (
    <div className={`al-warning ${className}`}>
      <div>{children}</div>
    </div>
  );
}

export default Warning;
