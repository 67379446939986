// @flow
import React from 'react';

import CustomTooltip from '../Tooltip/Tooltip';

type Props = {
  icon: string,
  clicked: (e: SyntheticInputEvent<HTMLElement>) => void,
  title: string,
  style: Object,
  className: string,
  show: boolean,
};

const Icon = ({ icon, clicked, title, style, className, show }: Props) => {
  if (show === false) {
    return null;
  }

  if (title) {
    return (
      <CustomTooltip 
        title={title} 
        enterDelay={1000} 
        leaveDelay={50}
        placement="top-end"
        classNames={{ root: 'al-tooltip'}}
        interactive={true}
      >
        <i
          style={style}
          className={['al-icons', 'al-icon-' + icon].join(' ') + ' ' + className }
          onClick={clicked}
        />
      </CustomTooltip>
    );
  } else {
    return (
      <i
        style={style}
        className={['al-icons', 'al-icon-' + icon].join(' ') + ' ' + className }
        onClick={clicked}
      />
    );
  }
}

Icon.defaultProps = {
  title: '',
  clicked: () => {},
  icon: 'close',
  style: {},
  className: '',
  show: true,
};

export default Icon;
