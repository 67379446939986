// @flow
import React, { useEffect, useState } from 'react';
import Button from '../UI/Button/Button';
import { groupBy } from '../../services/groupBy';
import type { CompareDatabaseFinding } from '../../types';

type Props = {
  activeType: string,
  onStartEditingDBFinding: (finding: any) => void,
  onDeleteDBFinding: (findingId: string) => void,
  compareDatabaseFindings: Array<CompareDatabaseFinding>,
  hasEditPermission: boolean,
  hasDeletePermission: boolean,
};

const DatabaseFindings = ({
  activeType,
  onStartEditingDBFinding,
  onDeleteDBFinding,
  compareDatabaseFindings,
  hasEditPermission,
  hasDeletePermission,
}: Props) => {
  const [DBFindingsForActiveType, setDBFindingsForActiveType] = useState([]);
  const [activeFindingId, setActiveFindingId] = useState(null);

  useEffect(() => {
    if (compareDatabaseFindings.length) {
      updateCompareDBFindingsForActiveType();
    } else {
      setDBFindingsForActiveType([]);
    }
    // eslint-disable-next-line
  }, [compareDatabaseFindings]);

  useEffect(() => {
    updateCompareDBFindingsForActiveType();
    // eslint-disable-next-line
  }, [activeType]);

  const groupByExcerptType = groupBy('excerptType');

  const updateCompareDBFindingsForActiveType = () => {
    const compareDBFindings = groupByExcerptType(compareDatabaseFindings)[
      activeType.trim().toLowerCase()
    ];
    setDBFindingsForActiveType(compareDBFindings || []);
    if (compareDBFindings?.length) {
      setActiveFindingId(compareDBFindings[0].id);
    }
  };

  const updateActiveFinding = (id) => {
    setActiveFindingId(id);
  };

  return (
    <div className={'al-compareExcerpt'}>
      {DBFindingsForActiveType.map((finding) => {
        return (
          <div
            key={finding.id}
            onClick={() => updateActiveFinding(finding.id)}
            className={'al-compareExcerpt-body-reference db-finding'}
          >
            <div
              className={`db-finding-text content-reference ${
                finding.id === activeFindingId ? 'active' : ''
              }`}
            >
              {finding.bodyHTML}
            </div>
            {(hasEditPermission || hasDeletePermission) && (
              <div className={'db-finding-actions'}>
                {hasEditPermission && (
                  <Button
                    withIcon={true}
                    className={'editDatabase'}
                    iconType={'edit'}
                    clicked={() => {
                      onStartEditingDBFinding(finding.id, finding.bodyHTML);
                    }}
                  />
                )}
                {hasDeletePermission && (
                  <Button
                    withIcon={true}
                    className={'deleteDatabaseFinding'}
                    iconType={'delete'}
                    clicked={() => {
                      onDeleteDBFinding(finding.id);
                    }}
                  />
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DatabaseFindings;
