import * as actionTypes from '../actions/actionType';

const initialState = {
  currentLocation: window.location.pathname,
  error: null,
  loading: false,
};

const locationPathNameChangeStart = (state, action) => {
  return {
    ...state,
    error: null,
    loading: true,
  };
};

const locationPathNameChangeSuccess = (state, action) => {
  return {
    ...state,
    currentLocation: action.currentLocation,
    error: null,
    loading: false,
  };
};

const locationPathNameChangeFail = (state, action) => {
  return { ...state, error: true, loading: false };
};

const locationPathName = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCATION_PATH_NAME_CHANGED_START:
      return locationPathNameChangeStart(state, action);
    case actionTypes.LOCATION_PATH_NAME_CHANGED_SUCCESS:
      return locationPathNameChangeSuccess(state, action);
    case actionTypes.LOCATION_PATH_NAME_CHANGED_FAIL:
      return locationPathNameChangeFail(state, action);
    default:
      return state;
  }
};

export default locationPathName;
