import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../UI/Checkbox/Checkbox';
import Icon from '../../UI/Icon/Icon';
import { ADV_LEARNING_TYPE, FINDING_CATEGORY, ORIGIN } from '../../../constants/constants';
import Button from '../../UI/Button/Button';
import _ from 'lodash';
import { usePrevious } from '../../../hooks/prevValue';
import Select from '../../UI/Select/Select';
import { fetchExcerptTypeOriginsStatistics } from '../../../store/actions';

const Clause = ({
  projectId,
  contractType,
  item,
  isStaticFinding,
  isEditing,
  bootStrapStaticType,
  deleteUserGlobalConfigs,
  updateGlobalConfigCategoriesList,
  updateGlobalConfigEnabled,
  toggleAllGlobalConfigsTo,
  isDeletingAll,
  updateGlobalConfigAdvLearningTypesList,
}) => {
  const dispatch = useDispatch();
  const allTypesAggregationsContractId = useSelector(
    (state) => state.statistics.statisticsAll.fetchingContractStatistics.contractId,
  );
  const allTypesAggregationsProps = useSelector(
    (state) => state.statistics.statisticsAll.fetchingContractStatistics.data,
  );
  const origins = useSelector(
    (state) => state.statistics.statisticsAll.fetchingContractStatistics.origins,
  );
  const aiTrainerEnabled = useSelector(
    (state) => state.globalSettings.globalSettings.aiTrainerEnabled,
  );
  const [allTypesAggregations, setAllTypesAggregations] = useState(allTypesAggregationsProps);
  const [hasAdvLearningOrigin, setHasAdvLearningOrigin] = useState(false);
  const [disableLoading, setDisableLoading] = useState(false);
  const [isStaticBootstrapped, setIsStaticBootstrapped] = useState(false);
  const [isEnabled, setIsEnabled] = useState(item.enabled);
  const prevToggleAllGlobalConfigsTo = usePrevious(toggleAllGlobalConfigsTo);
  const advLearningTypeOptions = [
    ADV_LEARNING_TYPE.AUTO,
    ADV_LEARNING_TYPE.TEXT,
    ADV_LEARNING_TYPE.DATAPOINT,
  ];
  const [advLearningType, setAdvLearningType] = useState(ADV_LEARNING_TYPE.AUTO);

  useEffect(() => {
    setIsEnabled(item.enabled);
    setAdvLearningType(item.alType);
    // eslint-disable-next-line
  }, [item]);

  useEffect(() => {
    if (
      origins['contractId'] === contractType.id &&
      origins['excerptType']?.toLowerCase().trim() === item.excerptType.toLowerCase().trim()
    ) {
      setHasAdvLearningOrigin(typeof origins[ORIGIN.ADVANCED] !== 'undefined');
    }
    // eslint-disable-next-line
  }, [origins]);

  useEffect(() => {
    if (typeof prevToggleAllGlobalConfigsTo !== 'undefined') {
      setIsEnabled(toggleAllGlobalConfigsTo);
    }
    // eslint-disable-next-line
  }, [toggleAllGlobalConfigsTo]);

  useEffect(() => {
    if (allTypesAggregationsContractId === contractType.id && allTypesAggregationsProps) {
      setAllTypesAggregations(allTypesAggregationsProps);
      if (!isStaticFinding) {
        fetchAdvancedLearningExcerptTypeCount(item.excerptType, allTypesAggregationsProps);
      }
    }
    // eslint-disable-next-line
  }, [allTypesAggregationsProps]);

  useEffect(() => {
    if (isStaticFinding) {
      contractType.globalConfig.filter((gc) => {
        if (gc.excerptType === item.excerptType && gc.origin === ORIGIN.STATIC) {
          const hasUserOrigin =
            contractType.globalConfig.filter(
              (gcUser) => gcUser.excerptType === item.excerptType && gcUser.origin === ORIGIN.USER,
            ).length > 0;
          // disable bootstrap icon if the static finding is already in the user findings' list
          setIsStaticBootstrapped(hasUserOrigin);
        }
      });
    }
    // eslint-disable-next-line
  }, [contractType]);

  const fetchAdvancedLearningExcerptTypeCount = (type, aggregationData) => {
    let aggregation = _.find(
      aggregationData,
      (aggregation) =>
        aggregation &&
        aggregation.topHits[0].excerptType.toLowerCase().trim() === type.toLowerCase().trim(),
    );
    if (aggregation) {
      dispatch(fetchExcerptTypeOriginsStatistics(projectId, contractType.id, aggregation.key));
    }
  };

  const toggleImportantFinding = (item) => {
    item.findingCategory =
      item.findingCategory && item.findingCategory === FINDING_CATEGORY.IMPORTANT
        ? FINDING_CATEGORY.UNDEFINED
        : FINDING_CATEGORY.IMPORTANT;
    updateGlobalConfigCategoriesList(item);
  };

  const toggleCriticalFinding = (item) => {
    item.findingCategory =
      item.findingCategory && item.findingCategory === FINDING_CATEGORY.CRITICAL
        ? FINDING_CATEGORY.UNDEFINED
        : FINDING_CATEGORY.CRITICAL;
    updateGlobalConfigCategoriesList(item);
  };

  const onSelectAdvancedLearningType = (type) => {
    setAdvLearningType(type);
    item.alType = type;
    updateGlobalConfigAdvLearningTypesList(item);
  };

  return (
    <li
      className={`al-settings-entry${disableLoading || isDeletingAll ? '-disabled' : ''} ${
        isEditing() ? 'editing' : 'read-only'
      }`}
      id={item.id}
    >
      {isStaticFinding ? (
        <Checkbox
          className={'settings-item-container'}
          onClick={() => {
            const toggledEnabled = !isEnabled;
            setIsEnabled(toggledEnabled);
            item.enabled = toggledEnabled;
            updateGlobalConfigEnabled(item, toggledEnabled);
          }}
          checked={isEnabled}
        >
          {item.excerptType}
        </Checkbox>
      ) : (
        <label>{item.excerptType}</label>
      )}

      <div className={'options-container'}>
        {!isStaticFinding && (
          <Select
            options={advLearningTypeOptions.map((type) => ({
              key: type,
              value: type,
              active: type === advLearningType,
            }))}
            size="small"
            label={advLearningType}
            onSelect={(type) => {
              onSelectAdvancedLearningType(type.key);
            }}
          />
        )}
        {!isStaticFinding &&
          allTypesAggregations.length > 0 &&
          (!hasAdvLearningOrigin ? (
            <Icon
              title={`No findings from this type`}
              icon={`exclamation-warning-sign warning-icon`}
              show={true}
            />
          ) : (
            <span className={'add-empty-space'} />
          ))}

        <span
          className={`warning-icon-clickable-container`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleImportantFinding(item);
          }}
        >
          <Icon
            title={`${
              item.findingCategory === FINDING_CATEGORY.IMPORTANT ? 'Remove' : 'Set'
            } as important finding`}
            icon={`exclamation-triangle-fill warning-icon ${
              item.findingCategory === FINDING_CATEGORY.IMPORTANT ? ' active' : ''
            }`}
            show={true}
          />
        </span>
        <span
          className={`warning-icon-clickable-container`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleCriticalFinding(item);
          }}
        >
          <Icon
            title={`${
              item.findingCategory === FINDING_CATEGORY.CRITICAL ? 'Remove' : 'Set'
            } as critical finding`}
            icon={`exclamation-circle-fill warning-icon ${
              item.findingCategory === FINDING_CATEGORY.CRITICAL ? ' active' : ''
            }`}
            show={true}
          />
        </span>

        {isStaticFinding ? (
          <>
            {' '}
            {aiTrainerEnabled && (
              <Button
                title={'Train the system'}
                className={'' + '' + '' + 'al-bootstrap-button'}
                disabled={isStaticBootstrapped}
                withIcon={true}
                iconType={'ai'}
                clicked={(e) => {
                  e.stopPropagation();
                  bootStrapStaticType(item.excerptType);
                }}
              />
            )}
          </>
        ) : (
          <Button
            withIcon={true}
            iconType={'delete'}
            clicked={() => {
              setDisableLoading(true);
              deleteUserGlobalConfigs(item.id, item.excerptType);
            }}
          />
        )}
      </div>
    </li>
  );
};

export default Clause;
