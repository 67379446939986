import config from '../../../config';
import { EventSourcePolyfill } from 'event-source-polyfill';

let eventSource;
export function initDocumentEventSource(id) {
  if (!id) return null;
  console.debug(`Initialize SSE connection for document ${id} ...`);
  const url = config.apiGateway.URL;
  eventSource = new EventSourcePolyfill(url + '/api/stream-sse/' + id, {
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });
}

export function getDocumentEventSource() {
  return eventSource;
}
