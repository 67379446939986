// @flow
import React, { useEffect, useState } from 'react';
import type { AppState } from '../../types/reduxTypes';
import {
  createContractType,
  renameContractType,
  deleteContractType,
  createUserGlobalConfig,
} from '../../store/actions';
import { connect, useDispatch } from 'react-redux';
import type { ContractTypeSetting, Project } from '../../types';
import ContractTypesList from '../../components/Projects/ContractTypesList';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import NamingForm from '../../components/Projects/NamingForm';
import Button from '../../components/UI/Button/Button';
import Warning from '../../components/UI/Typo/Warning';
import CreateWithPreselectionForm from '../../components/Projects/CreateWithPreselectionForm';
import { createContractType as initializeContractType } from '../../services/projectService';
import { ORIGIN, INPUT_TYPE } from '../../constants/constants';
import { getValidationError, normalizeWhitespaces } from '../../services/validation';

type Props = {
  project: Project,
  match: { params: { projectId: string } },
  projectId: string,
  history: any,
};
const modalTypes = {
  createContractType: 'createContractType',
  editContractType: 'editContractType',
  deleteContractType: 'deleteContractType',
};

type ContractTypeModalType = $Keys<typeof modalTypes>;
type ModalProps = {
  type: ContractTypeModalType,
  props: Object,
};

export const CreateContractType = ({ project, onCancel }) => {
  const dispatch = useDispatch();
  return (
    <CreateWithPreselectionForm
      existingItems={project.contractConfig}
      onSubmit={(data) => {
        dispatch(
          createContractType(project.id, initializeContractType(data), data?.copyFrom?.id ?? null),
        );
        onCancel();
      }}
      onCancel={onCancel}
      labelKey={'contractType'}
      typeToCreate={INPUT_TYPE.CONTRACT}
    />
  );
};

export const CreateGlobalConfig = ({ projectId, contractId, globalConfigs, onCancel }) => {
  const dispatch = useDispatch();

  const createNewSetting = (name: string) => {
    const contractTypeSetting: ContractTypeSetting = {
      description: '',
      enabled: true,
      excerptActions: [],
      excerptType: name,
      id: null,
      origin: ORIGIN.USER,
    };
    dispatch(createUserGlobalConfig(projectId, contractId, [contractTypeSetting]));
  };

  return (
    <NamingForm
      getValidationError={(name) => {
        const normalizedName = normalizeWhitespaces(name);
        return getValidationError(
          normalizedName,
          INPUT_TYPE.FINDING,
          globalConfigs.filter(
            (item) =>
              item.origin !== ORIGIN.STATIC &&
              item.excerptType.trim().toLowerCase() === normalizedName.trim().toLowerCase(),
          ).length > 0,
        );
      }}
      onSubmit={(name) => {
        createNewSetting(name);
        onCancel();
      }}
      onCancel={() => onCancel()}
      placeHolder={'app.settings.advanced.learning.placeholder'}
      modalTitle={`Create a new ${INPUT_TYPE.FINDING}`}
    />
  );
};

const ContractTypes = ({ project, projectId, history, allTypesAggregations }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (project) {
      history.push(`/settings/projects/${project.id}/contractTypes`);
    }
    // eslint-disable-next-line
  }, [projectId]);

  const [modalShown, showModal] = useState<ModalProps>({});

  const modalType = modalShown.type;
  const modalProps = modalShown.props;

  const showCreateContractTypeModal = () =>
    showModal({ type: modalTypes.createContractType, props: { project } });
  const showEditContractTypeModal = (contractType) =>
    showModal({ type: modalTypes.editContractType, props: { project, contractType } });
  const showDeleteContractTypeModal = (contractType) =>
    showModal({ type: modalTypes.deleteContractType, props: { project, contractType } });
  const hideModal = () => showModal({});

  return (
    <div className="al-container al-container--contractTypes with-scrollbar">
      {project ? (
        <ContractTypesList
          projectId={project.id}
          contractTypes={project.contractConfig}
          onCreateContractType={showCreateContractTypeModal}
          onDeleteContractType={(contractType) => showDeleteContractTypeModal(contractType)}
          allTypesAggregations={allTypesAggregations}
          showEditContractTypeModal={showEditContractTypeModal}
        />
      ) : (
        <div className={'al-error'}>Project Not Available</div>
      )}

      {modalType === modalTypes.createContractType && (
        <CreateContractType project={modalProps.project} onCancel={hideModal} />
      )}

      {modalType === modalTypes.editContractType && (
        <NamingForm
          getValidationError={(name) => {
            const normalizedName = normalizeWhitespaces(name);
            return normalizedName.toLowerCase().trim() !==
              modalProps.contractType.contractType.toLowerCase().trim()
              ? getValidationError(
                  normalizedName,
                  INPUT_TYPE.CONTRACT,
                  project.contractConfig.filter(
                    (item) =>
                      item.contractType.trim().toLowerCase() ===
                        normalizedName.trim().toLowerCase() ||
                      normalizedName.toLowerCase().trim() === 'UNASSIGNED'.toLowerCase().trim(),
                  ).length > 0,
                )
              : null;
          }}
          placeHolder={'app.settings.contract.types.placeholder'}
          name={modalProps.contractType.contractType}
          onSubmit={(name: string) => {
            dispatch(renameContractType(projectId, modalProps.contractType.id, name));
            hideModal();
          }}
          onCancel={hideModal}
          modalTitle={`Edit ${INPUT_TYPE.CONTRACT}`}
        />
      )}
      {modalType === modalTypes.deleteContractType && (
        <Modal show={true}>
          <ModalHeader
            title={`Delete document type ${modalProps.contractType.contractType}? `}
            onClose={hideModal}
          />
          <ModalContent>
            <Warning>
              Do you really want to delete document type "{modalProps.contractType.contractType}"?
            </Warning>
          </ModalContent>
          <ModalFooter>
            <Button
              clicked={() => {
                dispatch(deleteContractType(modalProps.project.id, modalProps.contractType.id));
                hideModal();
              }}
              btnType={'al-primary'}
            >
              Delete
            </Button>
            <Button clicked={hideModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  creatingProject: state.project.creatingProject,
  projectId: state.project.projectId,
  project: state.project.projects.get(state.project.projectId),
});

export default connect(mapStateToProps)(ContractTypes);
