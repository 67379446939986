import Select from '../../../components/UI/Select/Select';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchAdvancedLearningIssues, fetchDynamicThresholds } from '../../../store/actions';
import { round } from '../../../services/round';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Icon from '../../../components/UI/Icon/Icon';

type Props = {
  docId: string,
  preSelectedType: string,
  setParentAdvancedLearningType: (type: string) => void,
  isMarkedAs: (category: string, findingName: string) => boolean,
  findingCategory: string,
};

export const RightSideToolBar = ({
  docId,
  preSelectedType,
  setParentAdvancedLearningType,
  isMarkedAs,
  findingCategory,
}: Props) => {
  const dispatch = useDispatch();
  const {
    dynamicThresholds,
    fetchingDynamicThreshold,
    fetchingDynamicThresholdError,
    documentError,
  } = useSelector(
    (state) => ({
      dynamicThresholds: state.dynamicThresholds.dynamicThresholds,
      fetchingDynamicThreshold: state.dynamicThresholds.fetchingDynamicThreshold,
      fetchingDynamicThresholdError: state.dynamicThresholds.fetchingDynamicThresholdError,
      documentError: state.document.error,
    }),
    shallowEqual,
  );

  const getThresholdsOptions = () => {
    return Array.from(dynamicThresholds).map(([key, value]) => {
      return {
        key,
        value: key,
      };
    });
  };

  const [thresholdsOptions, setThresholdsOptions] = useState(getThresholdsOptions());

  useEffect(() => {
    if (!documentError) {
      dispatch(fetchDynamicThresholds(docId));
    }
    // eslint-disable-next-line
  }, [docId]);

  useEffect(() => {
    if (dynamicThresholds.size) {
      setThresholdsOptions(getThresholdsOptions());
    }
    // eslint-disable-next-line
  }, [dynamicThresholds]);
  const selectedTypeScore = round(dynamicThresholds.get(preSelectedType) * 100, 0);
  return (
    <div className={'al-review-entries-toolbar'}>
      <div className={'al-review-entries-toolbar-right'}>
        <Select
          options={[]}
          size="large"
          label={`${
            !fetchingDynamicThreshold
              ? preSelectedType !== ''
                ? `${preSelectedType} (${
                    !Number.isNaN(selectedTypeScore) ? selectedTypeScore : '0'
                  } %)`
                : 'Select Advanced Learning Type'
              : `Loading ...`
          }`}
          onSelect={null}
        >
          <ul className="advanced-learning-options">
            {fetchingDynamicThresholdError ? (
              <p> Error while fetching advanced learning types</p>
            ) : fetchingDynamicThreshold ? (
              <Spinner />
            ) : (
              thresholdsOptions
                .filter((alType) => dynamicThresholds.get(alType.value) > 0)
                .map((item, key) => {
                  let classNames = [];
                  if (preSelectedType === item.value) {
                    classNames.push('al-active');
                  }
                  if (item.className) {
                    classNames.push(item.className);
                  }
                  const className = classNames.join(' ');

                  return (
                    <li
                      style={item.style || {}}
                      key={item.key || key}
                      className={className}
                      onClick={(e) => {
                        e.preventDefault();
                        setParentAdvancedLearningType(item.value);
                        dispatch(
                          fetchAdvancedLearningIssues(
                            docId,
                            item.value,
                            round(dynamicThresholds.get(item.value)),
                          ),
                        );
                      }}
                    >
                      <span className="advanced-learning-value">{item.value}</span>
                      <Icon
                        className={'warning-icon'}
                        title=""
                        icon="exclamation-circle-fill active"
                        show={isMarkedAs(findingCategory, item.value, false, true)}
                      />
                      <span className="score-value">
                        {round(dynamicThresholds.get(item.value) * 100, 0)}
                      </span>
                    </li>
                  );
                })
            )}
          </ul>
        </Select>
      </div>
    </div>
  );
};
