import * as actionTypes from '../actions/actionType';

const initialState = {
  token: null,
  userId: null,
  username: null,
  error: null,
  loading: false,
  iFinderJwt: null,
  permissions: [],
  roles: [],
  assignedProjectsIds: [],
  accessAllProjects: [],
};

const authStart = (state, action) => {
  return {
    ...state,
    initialState,
    loading: true,
  };
};

const setAuthFromCache = (state, action) => {
  let idToken = localStorage.getItem('token');
  let userId = localStorage.getItem('userId');
  return {
    ...state,
    token: idToken,
    userId: userId,
    error: null,
    loading: false,
  };
};

const authSuccess = (state, action) => {
  localStorage.setItem('token', action.idToken);
  localStorage.setItem('iFinderJwt', action.iFinderJwt);
  localStorage.setItem('userId', action.userId);
  localStorage.setItem('username', action.authData.username);
  localStorage.setItem('password', action.authData.password);
  return {
    ...state,
    token: action.idToken,
    iFinderJwt: action.iFinderJwt,
    userId: action.userId,
    username: action.authData.username,
    error: null,
    loading: false,
    permissions: action.data.permissions,
    roles: action.data.roles,
    assignedProjectsIds: action.data.assignedProjectsIds,
    accessAllProjects: action.data.accessAllProjects,
  };
};

const authFail = (state, action) => {
  localStorage.removeItem('token');
  return { ...state, error: action.error, loading: false, token: null };
};

const authLogout = (state) => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.reload();
  localStorage.setItem('location', ''); // resets state in case the user logs out and then logs in
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_AUTHENTICATION_FROM_CACHE:
      return setAuthFromCache(state, action);
    default:
      return state;
  }
};

export default reducer;
