// @flow
/**
 *  Compare Database Modal Component
 *
 *  enables the user to add findings to the compare database
 *
 */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDBFinding, updateDBFinding } from '../../store/actions';
import Button from '../UI/Button/Button';
import Modal, { ModalContent, ModalFooter, ModalHeader } from '../UI/Modal/Modal';

const DatabaseFindingModal = ({
  activeType,
  editingFindingId,
  findingText,
  setFindingText,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.project.projectId);
  const textareaRef = useRef();

  useEffect(() => {
    textareaRef.current.setSelectionRange(findingText.length, findingText.length);
    // eslint-disable-next-line
  }, []);

  const onFindingTextChange = (event) => {
    setFindingText(event.target.value);
  };

  const onCreateDBFinding = () => {
    dispatch(createDBFinding(projectId, activeType, findingText));
  };

  const onUpdateDBFinding = () => {
    dispatch(updateDBFinding(editingFindingId, projectId, activeType, findingText));
  };

  return (
    <Modal show={true} width={1000} height={700}>
      <ModalHeader
        title={`${
          !editingFindingId ? 'Add a new finding to the compare database' : 'Edit finding'
        }`}
        onClose={closeModal}
      />
      <ModalContent>
        <textarea
          ref={textareaRef}
          autoFocus={true}
          aria-label="finding content"
          defaultValue={findingText}
          onChange={onFindingTextChange}
          placeholder="Add finding content"
        />
      </ModalContent>
      <ModalFooter>
        <Button
          clicked={() => {
            !editingFindingId ? onCreateDBFinding() : onUpdateDBFinding();
            closeModal();
          }}
          btnType={'al-primary'}
        >
          Save
        </Button>
        <Button clicked={closeModal}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DatabaseFindingModal;
