import * as actionTypes from '../actions/actionType';

const initialState = {
  apiVersion: '',
};

const setApiVersion = (state, action) => {
  localStorage.setItem('apiVersion', action.apiVersion);
  return {
    ...state,
    apiVersion: action.apiVersion,
  };
};

const version = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS_SUCCESS:
      return setApiVersion(state, action);
    case actionTypes.IS_LOGIN_SUCCESS:
      return setApiVersion(state, action);
    default:
      return state;
  }
};

export default version;
