// @flow
/**
 *  Compare Database Component
 *
 *  enables the user to add findings to the compare database
 *
 */
import React, { useEffect, useState } from 'react';
import Spinner from '../../components/UI/Spinner/Spinner';
import ExcerptDiffSelect from '../../components/CompareFindings/ExcerptDiffSelect';
import DatabaseFindings from './DatabaseFindings';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { deleteDBFinding, fetchDBFindings } from '../../store/actions';
import Button from '../UI/Button/Button';
import DatabaseFindingModal from './DatabaseFindingModal';
import { withPermissions } from '../../contexts/PermissionsContext/withPermissions';
import { PERMISSIONS } from '../../constants/constants';

const CompareDatabase = ({ PermissionsContext }) => {
  const dispatch = useDispatch();
  const { compareDatabaseFindings, loading, projectId, selectedProject } = useSelector(
    (state) => ({
      compareDatabaseFindings: state.compare.compareDatabaseFindings,
      loading: state.compare.loading,
      projectId: state.project.projectId,
      selectedProject: state.project.projects.get(state.project.projectId),
    }),
    shallowEqual,
  );
  const [activeType, setActive] = useState(null);
  const [createNewFindingShown, showCreateNewFindingModal] = useState(false);
  const [findingText, setFindingText] = useState('');
  const [editingFindingId, setEditingFindingId] = useState(null);

  useEffect(() => {
    dispatch(fetchDBFindings(projectId));
    // set default active type
    const sortedFindings = getAllFindings().sort((a, b) => a.localeCompare(b));
    updateActiveType(sortedFindings[0]);
    // eslint-disable-next-line
  }, [projectId]);

  const updateActiveType = (type?: string) => {
    if (type !== activeType) {
      setActive(type);
    }
  };

  const getAllFindings = () => {
    const findingTypesByContract = [].concat.apply(
      [],
      selectedProject.contractConfig.map((contract) => {
        return contract.globalConfig;
      }),
    );
    return [
      ...new Set(
        findingTypesByContract.map((type) => {
          return type.excerptType;
        }),
      ),
    ];
  };

  const onStartEditingDBFinding = (id, content) => {
    setEditingFindingId(id);
    setFindingText(content);
    showCreateNewFindingModal(true);
  };

  const onDeleteDBFinding = (id) => {
    dispatch(deleteDBFinding(id, projectId));
  };

  const closeModal = () => {
    setEditingFindingId(null);
    setFindingText('');
    showCreateNewFindingModal(false);
  };

  return (
    <div className={'al-container al-compare-container'}>
      <div className={'al-compare-header-container'}>
        <div>
          <div className="al-headline">
            Compare database of project{' '}
            {`"${
              selectedProject.projectType.charAt(0).toUpperCase() +
              selectedProject.projectType.slice(1)
            }"`}
          </div>
          <div className="al-description-text">
            Change the stored findings that are used for document comparison
          </div>
        </div>
      </div>

      <div className={'al-compare-content'}>
        <div className={'al-compare-content-header'} style={{ height: '46px' }}>
          <div className={'compare-table-header-label finding-types finding-types-header'}>
            Finding types
          </div>
          <div className={'compare-table-header-label stored-add-findings-container'}>
            <div>Stored findings {`${activeType ? `of type "${activeType}"` : ''}`}</div>
            {activeType &&
              PermissionsContext.hasPermission(PERMISSIONS.SHOW_COMPARE_DATABASE_POST) && (
                <Button
                  className={'al-primary'}
                  clicked={() => showCreateNewFindingModal(true)}
                  disabled={loading}
                >
                  Add new finding
                </Button>
              )}
          </div>
        </div>
        <div className={'al-compare-content-body'}>
          <div className={'finding-types finding-types-body'}>
            <div className={'al-compareFindings-selectType'}>
              <ExcerptDiffSelect
                activeType={activeType}
                onSelect={updateActiveType}
                findingTypes={getAllFindings()}
                isComparingFindings={false}
              />
            </div>
          </div>
          <div className={'al-compareExcerpt'}>
            {activeType ? (
              <DatabaseFindings
                activeType={activeType}
                onStartEditingDBFinding={onStartEditingDBFinding}
                onDeleteDBFinding={onDeleteDBFinding}
                compareDatabaseFindings={compareDatabaseFindings}
                hasEditPermission={PermissionsContext.hasPermission(
                  PERMISSIONS.SHOW_COMPARE_DATABASE_PUT,
                )}
                hasDeletePermission={PermissionsContext.hasPermission(
                  PERMISSIONS.SHOW_COMPARE_DATABASE_DELETE,
                )}
              />
            ) : (
              <>
                {loading ? <Spinner /> : null}
                {getAllFindings().length === 0 && (
                  <div className={'select-message-container'}>
                    <div className="al-info-msg al-mt-40">No data to show</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {createNewFindingShown && (
        <DatabaseFindingModal
          activeType={activeType}
          editingFindingId={editingFindingId}
          findingText={findingText}
          setFindingText={setFindingText}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default withPermissions(CompareDatabase);
