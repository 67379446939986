export const ISSUE_STATUS = {
  APPROVED: 'ACKNOWLEDGED',
  WORKING: 'OPEN',
  REDFLAG: 'RED FLAG',
};

export const DOCUMENT_STATUS = {
  APPROVED: 'PROCESSED',
  WORKING: 'OPEN',
};

export const DOCUMENT_ANALYZED_STATUS = {
  OPEN: 'open',
  CONVERTER_IN_PROGRESS: 'converterInProgress',
  CONVERTER_COMPLETED: 'converterCompleted',
  CONVERTER_ERRORED_OUT: 'converterErrorProcessing',
  TAGGER_EXCERPT_IN_PROGRESS: 'taggerExcerptInProgress',
  TAGGER_ANALYZE_COMPLETED: 'taggerCompleted',
  TAGGER_ERRORED_OUT: 'taggerErrorProcessing',
  SPACY_IN_PROGRESS: 'spacyInProgress',
  SPACY_COMPLETED: 'spacyCompleted',
  SPACY_ERRORED_OUT: 'spacyErrorProcessing',
  SPACY_COMPLETED_WITH_ERROR: 'spacyCompletedWithErrors',
  COMPLETE: 'complete',
};

export const PROCESSING_STATUS = {
  INITIAL: 'initial',
  PENDING: 'pending',
  FAILED: 'failed',
  SUCCESS: 'success',
  SUCCESS_WITH_ERROR: 'successWithError',
};

export const DEFAULT_NO_FILTER_STATUS = '';

export const ORIGIN = {
  STATIC: 'alTagger',
  ADVANCED: 'aiTrainer',
  USER: 'user',
};

export const ANNOTATION = {
  ALL: 'all',
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

export const FINDING_CATEGORY = {
  IMPORTANT: 'important',
  CRITICAL: 'critical',
  UNDEFINED: 'undefined',
};

export const SERVER_EVENTS = {
  UPDATE_ISSUES: 'update-excerpts',
  KEEP_ALIVE: 'keep-alive',
  DOCUMENT_PROCESSED: 'document-is-processed',
  UPDATE_DOCUMENT_ANALYZED_STATUS: 'update-document-analyzed-status',
  UPDATE_GLOBAL_CONFIGS: 'update-global-configs',
  COMPLETE: 'complete',
};

export const FILE_LIST_SORT_BY = {
  NAME_ASC: 'nameAsc',
  NAME_DESC: 'nameDesc',
  DATE_ASC: 'dateAsc',
  DATE_DESC: 'dateDesc',
};

export const DEFAULT_PAGE_NUMBER = 1;

export const FILE_EXTENSIONS_REGEX = /(\.txt|\.pdf|\.xlsFIX_IMPLEMENTATION|\.xlsxFIX_IMPLEMENTATION|\.doc|\.docx)$/i;

export const PDF_FILE_EXTENSION_REGEX = /(\.pdf)$/i;

export const IMG_FILE_EXTENSIONS_REGEX = /(\.png|\.jpg|\.jpeg)$/i;

export const HOT_KEY = {
  HELP: 191,
};

export const KEY_CODE = {
  8: 'Backspace',
  9: 'Tab',
  12: 'Clear',
  13: 'Enter',
  16: 'Shift',
  17: 'Control',
  18: 'Alt',
  19: 'Pause',
  20: 'CapsLock',
  27: 'Escape',
  32: ' ',
  33: 'PageUp',
  34: 'PageDown',
  35: 'End',
  36: 'Home',
  37: 'ArrowLeft',
  38: 'ArrowUp',
  39: 'ArrowRight',
  40: 'ArrowDown',
  45: 'Insert',
  46: 'Delete',
  112: 'F1',
  113: 'F2',
  114: 'F3',
  115: 'F4',
  116: 'F5',
  117: 'F6',
  118: 'F7',
  119: 'F8',
  120: 'F9',
  121: 'F10',
  122: 'F11',
  123: 'F12',
  144: 'NumLock',
  145: 'ScrollLock',
  224: 'Meta',
  75: 'k',
  74: 'j',
  67: 'c',
  82: 'r',
  191: 'h',
};

const errorMessageDict = {
  401: 'Invalid Credentials',
};
export const getErrorMessage = (error) => {
  if (error && error.response && errorMessageDict[error.response.status]) {
    return errorMessageDict[error.response.status];
  }
  return error.message;
};

export const PERMISSIONS = {
  SHOW_SEARCH: 'search-controller.get.search', // NEW show search tab only frontend
  SHOW_UPLOAD: 'file-controller.post.files.projectId.contractId', // show upload
  SHOW_REVIEW: 'document-controller.get.documents.id', // NEW show review both frontend/backend
  SHOW_PROJECTS: 'project-config-controller.post.projects', // show projects
  SHOW_CONTRACT_TYPES: 'project-config-controller.put.projects', // show contract types
  SHOW_USERS: 'user-controller.post.users.insert', // show users
  SHOW_DYNAMIC_TABS: 'dynamic-tabs-controller.post.dynamictabs', // show dynamic tabs
  SHOW_SEARCH_QUERY_POST: 'static-queries-controller.post', // NEW show hide search query both frontend/backend
  SHOW_SEARCH_QUERY_PUT: 'static-queries-controller.put',
  SHOW_SEARCH_QUERY_DELETE: 'static-queries-controller.delete',
  SHOW_EXPLORE: 'statistics-controller.get.excerpts.all', // NEW show explore only frontend
  SHOW_ROLES: 'role-controller.post.roles',
  SHOW_COMPARE: 'compare-controller.get.diff',
  SHOW_GENERAL: 'global-settings-controller.put.globalsettings',
  SHOW_AI_TRAINER_TOGGLE: 'global-settings-controller.put.aitrainer.flag',
  SHOW_IFINDER_TOGGLE: 'global-settings-controller.put.ifinder.flag',
  SHOW_COMPARE_DATABASE_POST: 'compare-controller.post.finding.type',
  SHOW_COMPARE_DATABASE_PUT: 'compare-controller.put.finding.type',
  SHOW_COMPARE_DATABASE_DELETE: 'compare-controller.delete.finding.type',
  EXCERPT_CONTROLLER_POST_EXCERPTS_CREATE_TRAIN: 'excerpt-controller.post.excerpts.create.train',
  EXCERPT_CONTROLLER_POST_EXCERPTS_CONVERT_TO_USER_ID:
    'excerpt-controller.post.excerpts.convert.to.user.id',
  /*EXCERPT_CONTROLLER_POST_EXCERPTS_RECOGNIZE_INCORRECT_ID:
  'excerpt-controller.post.excerpts.recognize.incorrect.id',*/
  EXCERPT_CONTROLLER_POST_EXCERPTS_RECOGNIZE_CORRECT_TRAIN_ID:
    'excerpt-controller.post.excerpts.recognize.correct.train.id',
  EXCERPT_CONTROLLER_POST_EXCERPTS_RECOGNIZE_INCORRECT_TRAIN_ID:
    'excerpt-controller.post.excerpts.recognize.incorrect.train.id',
};

export const EXPORT_TYPE = {
  WORD: 'word',
  EXCEL: 'excel',
};

export const INPUT_NAME_REGEX = /^[A-Za-zäüöÄÜÖß\-\s_0-9]+$/i;

export const INPUT_TYPE = {
  PROJECT: 'project',
  CONTRACT: 'contract type',
  FINDING: 'finding type',
  SEARCH_QUERY: 'search query',
  USER: 'user',
};

export const ADV_LEARNING_TYPE = {
  AUTO: 'auto',
  TEXT: 'text',
  DATAPOINT: 'datapoint',
};
