// @flow
import React, { useState } from 'react';
import type { Project } from '../../types';
import ProjectListItem from './ProjectListItem';
import NamingForm from './NamingForm';
import CreateWithPreselectionForm from './CreateWithPreselectionForm';
import Button from '../UI/Button/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../UI/Modal/Modal';
import Warning from '../UI/Typo/Warning';
import { INPUT_TYPE } from '../../constants/constants';
import { getValidationError, normalizeWhitespaces } from '../../services/validation';

type Props = {
  projects: Array<Project>,
  activeProject: string,
  hasUserRoles: boolean,
  onDeleteProject: (project: Project) => void,
  onRenameProject: (projectId: string, newName: string) => void,
  onCreateProject: (project: Project) => void,
};
const modalTypes = {
  createProject: 'createProject',
  updateProject: 'updateProject',
  deleteProject: 'deleteProject',
};

type ProjectModalType = $Keys<typeof modalTypes>;

type ModalProps = {
  type: ProjectModalType,
  props: Object,
};

const ProjectList = ({
  projects,
  activeProject,
  hasUserRoles,
  onDeleteProject,
  onRenameProject,
  onCreateProject,
}: Props) => {
  const [modalShown, showModal] = useState<ModalProps>({});
  const modalType = modalShown.type;
  const modalProps = modalShown.props;
  const showCreateProjectModal = () => {
    showModal({ type: modalTypes.createProject, props: {} });
  };
  const showEditProjectModal = ({ project }) =>
    showModal({ type: modalTypes.updateProject, props: { project } });
  const showDeleteProjectModal = ({ project }) =>
    showModal({ type: modalTypes.deleteProject, props: { project } });
  const hideModal = () => showModal({});

  const projectListItems = projects.valueSeq().toArray();

  return (
    <>
      <div className="al-projects-header">
        <div className="al-section-headline al-projects-headline">
          <span className={'al-headline'}>Projects</span>
          <Button className={'al-primary addProject'} clicked={showCreateProjectModal}>
            {' '}
            ADD NEW PROJECT{' '}
          </Button>
        </div>
        {activeProject !== null && (
          <div className="al-description-text">Select an existing project or add a new one.</div>
        )}
        {activeProject === null && (
          <div className="al-description-text al-error-msg">
            Create at least one project to continue.
          </div>
        )}
        {!hasUserRoles && (
          <div className="al-description-text al-error-msg">
            Contact the system administrator, because you don't have any user role.
          </div>
        )}
      </div>
      <div className={'al-projectList'}>
        {projectListItems.map((project: Project) => (
          <ProjectListItem
            key={project.id}
            project={project}
            active={project.id === activeProject}
            onEditProject={showEditProjectModal}
            onDeleteProject={projectListItems.length > 1 ? showDeleteProjectModal : null}
          />
        ))}
        {modalType === modalTypes.createProject && (
          <CreateWithPreselectionForm
            existingItems={projectListItems}
            onSubmit={(data) => {
              onCreateProject(
                {
                  id: null,
                  projectType: data.name,
                  contractConfig: data.copyFrom ? data.copyFrom.contractConfig : [],
                },
                data?.copyFrom?.id ?? null,
              );
              hideModal();
            }}
            onCancel={hideModal}
            labelKey={'projectType'}
            typeToCreate={INPUT_TYPE.PROJECT}
          />
        )}
        {modalType === modalTypes.updateProject && (
          <NamingForm
            getValidationError={(name) => {
              const normalizedName = normalizeWhitespaces(name);
              return normalizedName.toLowerCase().trim() !==
                modalProps.project.projectType.toLowerCase().trim()
                ? getValidationError(
                    normalizedName,
                    INPUT_TYPE.PROJECT,
                    projectListItems.filter(
                      (item) =>
                        item.projectType.trim().toLowerCase() ===
                        normalizedName.trim().toLowerCase(),
                    ).length > 0,
                  )
                : null;
            }}
            placeHolder={'app.settings.project.placeholder'}
            name={modalProps.project.projectType || ''}
            onSubmit={(name) => {
              onRenameProject(modalProps.project.id, name);
              hideModal();
            }}
            onCancel={hideModal}
            modalTitle={`Edit ${INPUT_TYPE.PROJECT}`}
          />
        )}
        {modalType === modalTypes.deleteProject && (
          <Modal show={true}>
            <ModalHeader
              title={`Delete Project ${modalProps.project.projectType}? `}
              onClose={hideModal}
            />
            <ModalContent>
              <Warning>
                Do you really want to delete Project "{modalProps.project.projectType}"?
              </Warning>
            </ModalContent>
            <ModalFooter>
              <Button
                btnType={'al-primary'}
                clicked={() => {
                  onDeleteProject(modalProps.project);
                  hideModal();
                }}
              >
                Delete
              </Button>
              <Button clicked={hideModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </>
  );
};

export default ProjectList;
