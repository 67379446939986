import config from './config';
import { EventSourcePolyfill } from 'event-source-polyfill';

let eventSource;
export function initAppEventSource() {
  console.debug(`Initialize SSE connection for App ...`);
  const url = config.apiGateway.URL;
  eventSource = new EventSourcePolyfill(url + '/api/stream-sse/', {
    headers: {
      Authorization: localStorage.getItem('token'),
    },
  });
}

export function getAppEventSource() {
  return eventSource;
}
