// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../../hooks/prevValue';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { CSSTransition } from 'react-transition-group';
import _ from 'lodash';
import FileItem from '../../../components/Dashboard/FileItem/FileItem';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../components/UI/Modal/Modal';
import Warning from '../../../components/UI/Typo/Warning';
import Button from '../../../components/UI/Button/Button';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import Select from '../../../components/UI/Select/Select';
import Icon from '../../../components/UI/Icon/Icon';
import CustomTooltip from '../../../components/UI/Tooltip/Tooltip';
import type { ContractType, SelectOption } from '../../../types';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_NO_FILTER_STATUS,
  PROCESSING_STATUS,
  FILE_LIST_SORT_BY,
  DOCUMENT_ANALYZED_STATUS,
  FINDING_CATEGORY,
} from '../../../constants/constants';
import PageNumbering from '../../../components/UI/PageNumbering/PageNumbering';
import {
  fetchDocumentsBy,
  deleteDocumentsByIds,
  updateDocumentsContractId,
  retryDocument,
  reProcessDocument,
  reProcessStaticFindings,
  selectStatusFilterGlobally,
  selectSortDocsByGlobally,
  selectFilterDocsByGlobally,
  selectPageNumberGlobally,
} from '../../../store/actions';
import ReprocessFilesModal from '../../../components/Dashboard/Modal/ReprocessFilesModal';

const DOCS_PER_PAGE = 10;

type Props = {
  contractTypeId: string,
  openDocument: (docId: string) => void,
};
type ActionProps = {
  actions: Array<ActionData>,
  contractTypeId: string,
};
type ActionData = {
  id: string,
  label: string,
  action?: Function,
};
type StatusFilterProps = {
  onFilter: (status: string) => void,
  statusFilter: string,
};

const ActionDropdown = ({ actions, contractTypeId }: ActionProps) => {
  const actionOptions = actions.map((actionData) => ({
    key: actionData.id,
    value: actionData.label,
    active: actionData.id === 'update-' + contractTypeId,
    ...actionData,
  }));
  return (
    <Select
      options={actionOptions}
      label={'Allocate to...'}
      onSelect={({ action }: ActionData) => {
        action && action();
      }}
    />
  );
};

const StatusFilter = ({ onFilter, statusFilter }: StatusFilterProps) => {
  const mapKeyToLabel = (statusFilter) => {
    switch (statusFilter) {
      case DEFAULT_NO_FILTER_STATUS:
        return 'All';
      case PROCESSING_STATUS.SUCCESS:
        return 'Success';
      case PROCESSING_STATUS.FAILED:
        return 'Failed';
      case PROCESSING_STATUS.INITIAL:
        return 'Initial';
      case PROCESSING_STATUS.PENDING:
        return 'Pending';
      case PROCESSING_STATUS.SUCCESS_WITH_ERROR:
        return 'Success with error';
    }
  };

  const filterOptions = [
    {
      key: DEFAULT_NO_FILTER_STATUS,
      value: mapKeyToLabel(DEFAULT_NO_FILTER_STATUS),
    },
    {
      key: PROCESSING_STATUS.SUCCESS,
      value: mapKeyToLabel(PROCESSING_STATUS.SUCCESS),
    },
    {
      key: PROCESSING_STATUS.FAILED,
      value: mapKeyToLabel(PROCESSING_STATUS.FAILED),
    },
    {
      key: PROCESSING_STATUS.INITIAL,
      value: mapKeyToLabel(PROCESSING_STATUS.INITIAL),
    },
    {
      key: PROCESSING_STATUS.PENDING,
      value: mapKeyToLabel(PROCESSING_STATUS.PENDING),
    },
    {
      key: PROCESSING_STATUS.SUCCESS_WITH_ERROR,
      value: mapKeyToLabel(PROCESSING_STATUS.SUCCESS_WITH_ERROR),
    },
  ];

  return (
    <Select
      id={`docs-status-filter-select`}
      options={filterOptions}
      label={mapKeyToLabel(statusFilter)}
      onSelect={(option: SelectOption) => {
        onFilter(option.key);
      }}
    />
  );
};

const FileList = ({ contractTypeId, openDocument }: Props) => {
  const dispatch = useDispatch();
  const projectId = useSelector((state) => state.project.projectId);
  const project = useSelector((state) => state.project.projects.get(state.project.projectId));
  const fileList = useSelector((state) => state.document.documents);
  const isUploadingFiles = useSelector((state) => state.document.uploadingFiles);
  const loading = useSelector((state) => state.document.loading);
  const documentsCount = useSelector((state) => state.document.documentsCount);
  const statusFilter = useSelector((state) => state.document.globallySelectedStatusFilter);
  const sortFileListBy = useSelector((state) => state.document.globallySelectedSortDocsBy);
  const findingCategory = useSelector((state) => state.document.globallySelectedFilterDocsBy);
  const page = useSelector((state) => state.document.globallySelectedPageNumber);

  const [modalType, setModalType] = useState('');
  const [modalProps, setModalProps] = useState({});
  const [selected, setSelected] = useState({});
  const [allPageSelected, setAllPageSelected] = useState(false);
  const [selectedFilesIdsForRetry, setSelectedFilesIdsForRetry] = useState([]);
  const [selectedFilesIdsForReprocess, setSelectedFilesIdsForReprocess] = useState([]);
  const [filterDocsWithImportantFindings, setFilterDocsWithImportantFindings] = useState(
    findingCategory === 'important' || findingCategory === 'all',
  );
  const [filterDocsWithCriticalFindings, setFilterDocsWithCriticalFindings] = useState(
    findingCategory === 'critical' || findingCategory === 'all',
  );

  const prevProjectId = usePrevious(projectId);
  const prevContractTypeId = usePrevious(contractTypeId);
  const prevSelected = usePrevious(selected);

  const showChangeContractTypeModal = (docsIds, contractId) => {
    setModalType('changeContractType');
    setModalProps({ docsIds, contractId });
  };

  const showWarningReprocessSelectedDocuments = () => {
    setModalType('reprocessSelectedDocuments');
  };

  const showModalDeleteDocsIds = (docsIds) => {
    setModalType('deleteDocuments');
    setModalProps({ docsIds });
  };

  const hideModal = () => {
    setModalType('');
    setModalProps({});
  };

  const onFilter = (status: string) => {
    clearSelected();
    dispatch(selectStatusFilterGlobally(status));
    changePageNumber(DEFAULT_PAGE_NUMBER);
    handleFetchDocuments(DEFAULT_PAGE_NUMBER, sortFileListBy, status, findingCategory);
  };

  const handleFetchDocuments = (page, sortBy, status, findingCategory) => {
    dispatch(fetchDocumentsBy(projectId, contractTypeId, page, sortBy, status, findingCategory));
  };

  useEffect(() => {
    if (!isUploadingFiles) {
      handleFetchDocuments(page, sortFileListBy, statusFilter, findingCategory);
    }
    // eslint-disable-next-line
  }, [isUploadingFiles]);

  useEffect(() => {
    // update 'Select all' checkbox when page or contractId changes
    const allDocsInPageSelected =
      fileList.length > 0 &&
      fileList.filter((file) => !Object.keys(selected).includes(file.id)).length === 0;
    setAllPageSelected(allDocsInPageSelected);
    // eslint-disable-next-line
  }, [fileList, contractTypeId]);

  useEffect(() => {
    if (prevProjectId && prevContractTypeId) {
      clearSelected();
      changePageNumber(DEFAULT_PAGE_NUMBER);
      handleFetchDocuments(DEFAULT_PAGE_NUMBER, sortFileListBy, statusFilter, findingCategory);
    }
    // eslint-disable-next-line
  }, [projectId, contractTypeId]);

  useEffect(() => {
    if (typeof prevSelected !== 'undefined') {
      let retryFiles = [];
      let reprocessFiles = [];

      if (Object.keys(selected).length > 0) {
        for (const [id, analyzeStatus] of Object.entries(selected)) {
          switch (analyzeStatus) {
            case DOCUMENT_ANALYZED_STATUS.CONVERTER_IN_PROGRESS:
            case DOCUMENT_ANALYZED_STATUS.CONVERTER_ERRORED_OUT:
            case DOCUMENT_ANALYZED_STATUS.TAGGER_EXCERPT_IN_PROGRESS:
            case DOCUMENT_ANALYZED_STATUS.TAGGER_ERRORED_OUT:
            case DOCUMENT_ANALYZED_STATUS.SPACY_IN_PROGRESS:
            case DOCUMENT_ANALYZED_STATUS.SPACY_ERRORED_OUT:
              retryFiles.push(id);
              break;
            default:
              reprocessFiles.push(id);
          }
        }
      }

      setSelectedFilesIdsForRetry(retryFiles);
      setSelectedFilesIdsForReprocess(reprocessFiles);
    }
    // eslint-disable-next-line
  }, [selected]);

  const deleteSelected = (docsIds: Array<string>) => {
    const page = pageToFetch(docsIds.length);
    dispatch(
      deleteDocumentsByIds(
        docsIds,
        projectId,
        contractTypeId,
        page,
        sortFileListBy,
        statusFilter,
        findingCategory,
      ),
    );
    removeFromSelectedIds(docsIds);
    changePageNumber(page);
  };

  const changePageNumber = (page) => {
    dispatch(selectPageNumberGlobally(page));
  };

  const reprocessSelected = (filesIdsToReprocess) => {
    let reprocessDocuments = {};
    filesIdsToReprocess.forEach((id) => (reprocessDocuments[id] = selected[id]));
    const reprocessDocumentsIds = Object.keys(reprocessDocuments);
    dispatch(reProcessDocument(reprocessDocuments, reprocessDocumentsIds));
    setSelectedFilesIdsForReprocess([]);
    return removeFromSelectedIds(reprocessDocumentsIds);
  };

  const retrySelected = (filesIdsToRetry, remainingSelected) => {
    dispatch(retryDocument(filesIdsToRetry));
    setSelectedFilesIdsForRetry([]);
    removeFromSelectedIds(filesIdsToRetry, remainingSelected);
  };

  const reProcessStaticFindingsSelected = (filesIdsToReprocess) => {
    dispatch(reProcessStaticFindings(filesIdsToReprocess));
    setSelectedFilesIdsForReprocess([]);
    removeFromSelectedIds(filesIdsToReprocess, selected);
  };

  const removeFromSelectedIds = (docsIdsList, remainingSelected) => {
    let updatedSelected = typeof remainingSelected !== 'undefined' ? remainingSelected : selected;
    updatedSelected = _.omit(updatedSelected, docsIdsList);
    setSelected(updatedSelected);
    return updatedSelected;
  };

  const clearSelected = () => {
    setSelectedFilesIdsForRetry([]);
    setSelectedFilesIdsForReprocess([]);
    setSelected({});
  };

  const pageToFetch = (updatingDocsLength) => {
    const prevNumPages = Math.ceil(documentsCount / DOCS_PER_PAGE);
    const updatedNumPages = Math.ceil((documentsCount - updatingDocsLength) / DOCS_PER_PAGE);
    if (prevNumPages > page || prevNumPages === updatedNumPages) {
      return page;
    } else if (prevNumPages === page && prevNumPages > updatedNumPages) {
      const currentPage = page - 1;
      return currentPage > 0 ? page - 1 : DEFAULT_PAGE_NUMBER;
    } else if (page === DEFAULT_PAGE_NUMBER) {
      return DEFAULT_PAGE_NUMBER;
    }
  };

  const moveDocumentsContractId = (selectedFilesIds: Array<string>, contractId: string) => {
    if (selectedFilesIds.length && contractTypeId !== contractId) {
      let docsContracts = new Map();
      for (const id of selectedFilesIds) {
        docsContracts.set(id, contractId);
      }

      const page = pageToFetch(docsContracts.size);
      dispatch(
        updateDocumentsContractId(
          docsContracts,
          projectId,
          contractTypeId,
          page,
          sortFileListBy,
          statusFilter,
          findingCategory,
        ),
      );
      changePageNumber(page);
    }
  };

  const onToggleSortAlphabeticalDocuments = () => {
    const toggledSort =
      sortFileListBy === FILE_LIST_SORT_BY.NAME_ASC
        ? FILE_LIST_SORT_BY.NAME_DESC
        : FILE_LIST_SORT_BY.NAME_ASC;
    dispatch(selectSortDocsByGlobally(toggledSort));
    handleFetchDocuments(page, toggledSort, statusFilter, findingCategory);
  };

  const onToggleSortDateDocuments = () => {
    const toggledSort =
      sortFileListBy === FILE_LIST_SORT_BY.DATE_ASC
        ? FILE_LIST_SORT_BY.DATE_DESC
        : FILE_LIST_SORT_BY.DATE_ASC;
    dispatch(selectSortDocsByGlobally(toggledSort));
    handleFetchDocuments(page, toggledSort, statusFilter, findingCategory);
  };

  const getFindingCategory = (isEnabledCategory, isEnabledSecondCategory, findingCategory) => {
    const secondCategory =
      findingCategory === FINDING_CATEGORY.IMPORTANT
        ? FINDING_CATEGORY.CRITICAL
        : FINDING_CATEGORY.IMPORTANT;

    if (isEnabledCategory) {
      if (isEnabledSecondCategory) {
        return 'all';
      } else {
        return findingCategory;
      }
    } else {
      if (isEnabledSecondCategory) {
        return secondCategory;
      } else {
        return null;
      }
    }
  };

  const toggleImportantFindings = () => {
    const toggledImportantFindings = !filterDocsWithImportantFindings;
    setFilterDocsWithImportantFindings(toggledImportantFindings);
    const findingCategory = getFindingCategory(
      toggledImportantFindings,
      filterDocsWithCriticalFindings,
      FINDING_CATEGORY.IMPORTANT,
    );
    clearSelected();
    dispatch(selectFilterDocsByGlobally(findingCategory));
    changePageNumber(DEFAULT_PAGE_NUMBER);
    handleFetchDocuments(DEFAULT_PAGE_NUMBER, sortFileListBy, statusFilter, findingCategory);
  };

  const toggleCriticalFindings = () => {
    const toggledCriticalFindings = !filterDocsWithCriticalFindings;
    setFilterDocsWithCriticalFindings(toggledCriticalFindings);
    const findingCategory = getFindingCategory(
      toggledCriticalFindings,
      filterDocsWithImportantFindings,
      FINDING_CATEGORY.CRITICAL,
    );
    clearSelected();
    dispatch(selectFilterDocsByGlobally(findingCategory));
    changePageNumber(DEFAULT_PAGE_NUMBER);
    handleFetchDocuments(DEFAULT_PAGE_NUMBER, sortFileListBy, statusFilter, findingCategory);
  };

  const contractType = project.contractConfig.find((contract) => contract.id === contractTypeId);

  const selectActions: Array<ActionData> = project.contractConfig.map((type: ContractType) => ({
    id: `update-${type.id}`,
    label: (
      <span style={{ paddingLeft: '20px' }}>
        {' '}
        {type.contractType.toLowerCase().trim() === 'others'
          ? 'UNASSIGNED'
          : type.contractType.toUpperCase()}
      </span>
    ),
    action: () => {
      showChangeContractTypeModal(Object.keys(selected), type.id);
    },
  }));

  const toggleSelectedFile = (isSelected, fileId, analyzeStatus) => {
    let updatedSelected = { ...selected };
    if (isSelected) {
      delete updatedSelected[fileId];
    } else {
      updatedSelected[fileId] = analyzeStatus;
    }
    setSelected(updatedSelected);
    const isAllSelected = Object.keys(updatedSelected).length === fileList.length;
    if (allPageSelected !== isAllSelected) {
      setAllPageSelected(isAllSelected);
    }
  };

  const selectAllPage = () => {
    let updatedSelected = { ...selected };
    for (const file of fileList) {
      updatedSelected[file.id] = file.analyzeStatus;
    }
    setAllPageSelected(true);
    setSelected(updatedSelected);
  };

  const unselectAllPage = () => {
    let updatedSelected = { ...selected };
    for (const file of fileList) {
      delete updatedSelected[file.id];
    }
    setAllPageSelected(false);
    setSelected(updatedSelected);
  };

  const getPaginationCount = () => {
    return Math.ceil(documentsCount / DOCS_PER_PAGE);
  };

  const selectedFilesIds = Object.keys(selected);

  return (
    <div className="al-filelist">
      <div className="al-filelist-head">
        <Checkbox
          checked={allPageSelected}
          onClick={() => (!allPageSelected ? selectAllPage() : unselectAllPage())}
        >
          {allPageSelected ? 'Deselect ' : 'Select '}
          {'all documents in page '}
          {`(${selectedFilesIds.length}/${documentsCount})`}
        </Checkbox>
        <div className={'list-view-options'}>
          <span className={`filter-by-finding`} onClick={toggleImportantFindings}>
            <Icon
              title="Filter by important missing findings"
              icon={`exclamation-triangle-fill ${filterDocsWithImportantFindings ? ' active' : ''}`}
              show={true}
            />
          </span>
          <span className={`filter-by-finding`} onClick={toggleCriticalFindings}>
            <Icon
              title="Filter by critical findings found"
              icon={`exclamation-circle-fill ${filterDocsWithCriticalFindings ? ' active' : ''}`}
              show={true}
            />
          </span>
          <span
            className={`alphabetical-sort ${sortFileListBy}`}
            onClick={() => {
              onToggleSortAlphabeticalDocuments();
            }}
          >
            <Icon
              title={`Sort by alphabetical ${
                sortFileListBy === FILE_LIST_SORT_BY.NAME_DESC ? 'descending' : 'ascending'
              }`}
              style={{
                color: `${
                  sortFileListBy === FILE_LIST_SORT_BY.NAME_ASC ||
                  sortFileListBy === FILE_LIST_SORT_BY.NAME_DESC
                    ? 'rgba(0, 142, 183)'
                    : ''
                }`,
              }}
              icon="ascending"
            />
            <ArrowRightAltIcon className="arrow-direction" />
          </span>
          <CustomTooltip
            title={`Sort by date ${
              sortFileListBy === FILE_LIST_SORT_BY.DATE_DESC ? 'descending' : 'ascending'
            }`}
            enterDelay={1000}
            leaveDelay={50}
            placement="top-end"
            classNames={{ root: 'al-tooltip' }}
            interactive={true}
          >
            <span
              className={`date-sort ${sortFileListBy}`}
              onClick={() => onToggleSortDateDocuments()}
            >
              <DateRangeIcon
                style={{
                  color: `${
                    sortFileListBy === FILE_LIST_SORT_BY.DATE_ASC ||
                    sortFileListBy === FILE_LIST_SORT_BY.DATE_DESC
                      ? 'rgba(0, 142, 183)'
                      : ''
                  }`,
                }}
              />

              <ArrowRightAltIcon className="arrow-direction" />
            </span>
          </CustomTooltip>
          <div>
            <label style={{ marginRight: '5px' }}>{'Document Status:'}</label>
            <StatusFilter onFilter={onFilter} statusFilter={statusFilter} />
          </div>
        </div>
      </div>
      <CSSTransition
        in={selectedFilesIds.length > 0}
        timeout={400}
        classNames={`fade-in`}
        className={`al-filelist-actions-container ${
          selectedFilesIds.length === 0 ? 'hide-section' : ''
        }`}
      >
        <div className="al-filelist-actions-container">
          <Button
            className={'al-filelist-actions-button'}
            clicked={() => {
              showModalDeleteDocsIds(selectedFilesIds);
            }}
            iconType={'delete'}
            withIcon={true}
            title={'Delete selected documents'}
          />
          <Button
            className={'al-filelist-actions-button'}
            clicked={showWarningReprocessSelectedDocuments}
            iconType={'reprocess-all'}
            withIcon={true}
            disabled={
              selectedFilesIdsForReprocess.length === 0 && selectedFilesIdsForRetry.length === 0
            }
            title={'Process selected documents again'}
          />
          <Button
            className={'al-filelist-actions-button'}
            clicked={() => reProcessStaticFindingsSelected(selectedFilesIdsForReprocess)}
            iconType={'retrieve-all'}
            withIcon={true}
            title={'Analyze selected documents again'}
            disabled={selectedFilesIdsForReprocess.length === 0}
          />
          <ActionDropdown actions={selectActions} contractTypeId={contractTypeId} />
        </div>
      </CSSTransition>
      {modalType === 'reprocessSelectedDocuments' && (
        <ReprocessFilesModal
          closeModal={hideModal}
          filesToReprocessCount={selectedFilesIdsForReprocess.length}
          reProcessDocument={(convertOriginal) => {
            let remainingSelected = selected;
            if (selectedFilesIdsForReprocess.length > 0) {
              remainingSelected = reprocessSelected(selectedFilesIdsForReprocess, convertOriginal);
            }
            if (selectedFilesIdsForRetry.length > 0) {
              retrySelected(selectedFilesIdsForRetry, remainingSelected, convertOriginal);
            }
          }}
        />
      )}
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="al-filelist-body">
            {fileList.length > 0
              ? fileList.map((file) => (
                  <FileItem
                    key={file.id}
                    id={file.id}
                    file={file}
                    selected={typeof selected[file.id] !== 'undefined'}
                    contractType={contractType}
                    onToggleSelect={toggleSelectedFile}
                    openDocument={openDocument}
                    retryDocument={() => retrySelected([file.id], selected)}
                    reProcessDocument={() => reprocessSelected([file.id])}
                    reProcessStaticFindings={() => reProcessStaticFindingsSelected([file.id])}
                    deleteDocumentById={() => {
                      showModalDeleteDocsIds([file.id]);
                    }}
                  />
                ))
              : documentsCount === 0 && (
                  <div style={{ textAlign: 'center', marginTop: '15px' }}>
                    {`${
                      findingCategory !== null // if the list is filtered by finding category
                        ? 'There are no warnings on the documents.'
                        : 'Could not find any documents.'
                    }`}
                  </div>
                )}

            {modalType === 'changeContractType' && (
              <Modal show={true}>
                <ModalHeader title={`Warning`} onClose={hideModal} />
                <ModalContent>
                  <Warning>
                    Allocating documents to another document type will delete all user annotations
                    and advanced learning findings.
                    <br />
                    Do you really want to allocate
                    {selectedFilesIds.length > 1
                      ? ' ' + selectedFilesIds.length + ' documents'
                      : ' this document'}
                    ?
                  </Warning>
                </ModalContent>
                <ModalFooter>
                  <Button
                    className={'al-primary'}
                    clicked={() => {
                      moveDocumentsContractId(modalProps.docsIds, modalProps.contractId);
                      hideModal();
                      clearSelected();
                    }}
                  >
                    Ok
                  </Button>
                  <Button clicked={hideModal}>Cancel</Button>
                </ModalFooter>
              </Modal>
            )}
            {modalType === 'deleteDocuments' && (
              <Modal show={true}>
                <ModalHeader title={'Delete documents'} onClose={hideModal} />
                <ModalContent>
                  <Warning>{`Are you sure you want to delete ${
                    modalProps.docsIds.length > 1
                      ? modalProps.docsIds.length + ' documents'
                      : 'this document'
                  }?`}</Warning>
                </ModalContent>
                <ModalFooter>
                  <Button
                    className={'al-primary'}
                    clicked={() => {
                      hideModal();
                      deleteSelected(modalProps.docsIds);
                    }}
                  >
                    Delete
                  </Button>
                  <Button className={'al-button'} clicked={hideModal}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            )}
          </div>
          <div className={'al-filelist-footer'}>
            <PageNumbering
              numPages={getPaginationCount()}
              setPage={(page) => {
                changePageNumber(page);
                handleFetchDocuments(page, sortFileListBy, statusFilter, findingCategory);
              }}
              page={page}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FileList;
