import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ContractType, SearchQuery, SelectOption } from '../../types';
import { getContractName } from '../../services/namesHelper';
import * as actions from '../../store/actions';
import Select from '../../components/UI/Select/Select';
import SearchQueriesForm from './SearchQueriesForm/SearchQueriesForm';
import SearchQueriesList from './SearchQueriesList/SearchQueriesList';
import SearchQueriesPreview from './SearchQueriesPreview/SearchQueriesPreview';
import Button from '../../components/UI/Button/Button';
import { withPermissions } from '../../contexts/PermissionsContext/withPermissions';
import { INPUT_TYPE, PERMISSIONS } from '../../constants/constants';
import { getValidationError, normalizeWhitespaces } from '../../services/validation';

const SearchQueries = ({ PermissionsContext }) => {
  const [showCreateSearchQueryModal, setShowCreateSearchQueryModal] = useState(false);
  const [queryName, setQueryName] = useState('');
  const [queryExcerptType, setQueryExcerptType] = useState('');
  const [queryCharacterLength, setQueryCharacterLength] = useState('');
  const [querySearchId, setQuerySearchId] = useState('');

  const dispatch = useDispatch();
  const createSearchQuery = (searchQuery: SearchQuery) =>
    dispatch(actions.createSearchQuery(searchQuery));
  const editSearchQuery = (searchQuery: SearchQuery) =>
    dispatch(actions.editSearchQuery(searchQuery));
  const fetchSearchQueries = (projectId: string) => dispatch(actions.fetchSearchQueries(projectId));
  const setSelectedContractId = (contractId: string) =>
    dispatch(actions.setSelectedContractId(contractId));
  const selectGlobalContract = (contractId: string) => dispatch(actions.selectContract(contractId));

  const loading = useSelector(
    (state) =>
      state.searchQueries.fetchingSearchQueries ||
      state.searchQueries.creatingSearchQueries ||
      state.searchQueries.editingSearchQueries ||
      state.searchQueries.applyingQuery ||
      state.searchQueries.deletingSearchQueries,
  );
  const project = useSelector((state) =>
    state.project.projects.find((project) => project.id === state.project.projectId),
  );
  const searchQueries = useSelector((state) => state.searchQueries.searchQueries);
  const selectedContractId = useSelector((state) => state.project.contractId);

  const { contractConfig } = project;
  const contractToSelect = contractConfig.find((el) => el.id === selectedContractId)
    ? contractConfig.find((el) => el.id === selectedContractId)
    : contractConfig[0];
  const [selectedContract: ContractType, setSelectedContract] = useState(contractToSelect);
  const filteredSearchQuery = searchQueries.filter((s) => s.contractId === selectedContract.id);

  // on component load
  useEffect(() => {
    fetchSearchQueries(project.id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedContractId(selectedContract.id);
    // eslint-disable-next-line
  }, [selectedContract.id]);

  useEffect(() => {
    fetchSearchQueries(project.id);
    // eslint-disable-next-line
  }, [project.id]);

  const selectOptions: Array<SelectOption> = contractConfig.map((contract) => {
    return {
      key: contract.id,
      value: getContractName(contract.contractType),
      active: selectedContract.id === contract.id,
    };
  });
  const onSelectContract = (item) => {
    const selectedContract = contractConfig.find((contact) => contact.id === item.key);
    setSelectedContract(selectedContract);
    selectGlobalContract(selectedContract.id);
  };

  const onCreateEditSearchQuery = (queryId, query, excerptType, characterLength) => {
    const searchQuery: SearchQuery = {
      id: queryId === '' ? null : queryId,
      contractId: selectedContract.id,
      projectId: project.id,
      query,
      excerptType,
      applied: false,
      minCharLength: characterLength,
    };
    if (queryId === '') {
      createSearchQuery(searchQuery);
    } else {
      editSearchQuery(searchQuery);
    }
    setShowCreateSearchQueryModal(false);
  };

  const setEditQueryParams = (queryName, queryExcerptType, queryCharacterLength, searchQueryId) => {
    setQueryName(queryName);
    setQueryExcerptType(queryExcerptType);
    setQueryCharacterLength(queryCharacterLength);
    setQuerySearchId(searchQueryId);
  };

  const onCreateNewSearchQuery = () => {
    setQueryName('');
    setQueryExcerptType('');
    setQueryCharacterLength('');
    setQuerySearchId('');
    setShowCreateSearchQueryModal(true);
  };

  return (
    <div className={'al-container al-search-queries'}>
      <div className={'al-headline'}>{'Search queries'}</div>
      <div className={'al-description-text'}>
        <span>Create advanced learning results based on search queries</span>
      </div>
      <div className={'al-search-queries-workspace'}>
        <div>
          <span className="contract-type-label">{'Select document type'}</span>
          <Select
            onSelect={(item) => onSelectContract(item)}
            label={getContractName(selectedContract.contractType)}
            options={selectOptions}
          />
        </div>
        {PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_POST) && (
          <Button className={'al-primary'} clicked={onCreateNewSearchQuery} disabled={loading}>
            Add new search query
          </Button>
        )}
      </div>
      <div className="search-queries-container">
        <SearchQueriesList
          searchQueries={filteredSearchQuery}
          contractId={selectedContract.id}
          projectId={project.id}
          onEditSearchQuery={(query, excerptType, characterLength, searchQueryId) => {
            setEditQueryParams(query, excerptType, characterLength, searchQueryId);
            setShowCreateSearchQueryModal(true);
          }}
          PermissionsContext={PermissionsContext}
        />
        <SearchQueriesPreview
          searchQueries={filteredSearchQuery}
          contractId={selectedContract.id}
        />
      </div>

      {showCreateSearchQueryModal && (
        <SearchQueriesForm
          onSetShowCreateSearchQueryModal={setShowCreateSearchQueryModal}
          onCreateEditSearchQuery={onCreateEditSearchQuery}
          queryName={queryName}
          queryExcerptType={queryExcerptType}
          queryCharacterLength={queryCharacterLength}
          querySearchId={querySearchId}
          getValidationError={(name) => {
            const normalizedName = normalizeWhitespaces(name);
            return getValidationError(
              normalizedName,
              INPUT_TYPE.SEARCH_QUERY,
              filteredSearchQuery.filter((item) => {
                return (
                  item.excerptType.trim().toLowerCase() === normalizedName.trim().toLowerCase() &&
                  item.id !== querySearchId
                );
              }).length > 0,
            );
          }}
        />
      )}
    </div>
  );
};

export default withPermissions(SearchQueries);
