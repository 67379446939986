import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRoles, fetchPermissionSets, deleteRole } from '../../store/actions';
import type { Role } from '../../types';
import Button from '../../components/UI/Button/Button';
import CreateRoleModal from './CreateRoleModal';
import PermissionSets from './PermissionSets';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../components/UI/Modal/Modal';
import Warning from '../../components/UI/Typo/Warning';

const ModalTypes = {
  edit: 'edit',
  create: 'create',
  delete: 'delete',
};

const Roles = () => {
  const [modalType, setModalType] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const roles = useSelector((state) => state.roles.roles);
  const permissionSets = useSelector((state) => state.permissionSets.permissionSets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchPermissionSets());
  }, [dispatch]);

  const onCreateNewRole = () => {
    setModalType(ModalTypes.create);
    let role: Role = { id: null, name: [], permissionSets: [] };
    setSelectedRole(role);
  };

  const onEditRole = (role: Role) => {
    setModalType(ModalTypes.edit);
    setSelectedRole(role);
  };

  const onDeleteRole = (role: Role) => {
    setModalType(ModalTypes.delete);
    setSelectedRole(role);
  };

  return (
    <div className={'al-container al-role-container with-scrollbar'}>
      <div className={'al-headline'}>{'Role administration'}</div>
      <div className={'al-description-text'}>
        Add, update or delete roles and assign permissions to them
      </div>
      <div className="role-dashboard-wrapper">
        <div className={'al-users'} style={{ flex: '0 0 50%' }}>
          <div className={'al-users-workspace'}>
            <span />
            <span />
            {/*<span>Filter roles</span>*/}
            {/*<Input elementType={'input'} elementConfig={{ type: 'text' }} />*/}
            <span />
            <Button show={true} className={'al-primary'} clicked={onCreateNewRole}>
              Add new role
            </Button>
          </div>
          <div className="al-users-list">
            <div className="al-users-list-header">
              <span>Rolename</span>
              <span>Permission set</span>
              <span></span>
            </div>
            <div className="al-users-list-role-wrapper">
              {roles.map((rl: Role) => {
                return (
                  <div key={rl.id} className="al-users-list-item">
                    <span>{rl.name}</span>
                    <span>
                      {rl.permissionSets
                        ? permissionSets
                            .filter((ps) => rl.permissionSets.indexOf(ps.id) > -1)
                            .map((ps) => ps.name)
                            .join(', ')
                        : ''}
                    </span>
                    <span>{''}</span>
                    <span className="al-users-list-item-actions">
                      <Button
                        withIcon={true}
                        iconType={'edit'}
                        clicked={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          onEditRole(rl);
                        }}
                      />

                      <Button
                        withIcon={true}
                        className={'deleteProject'}
                        iconType={'delete'}
                        clicked={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          onDeleteRole(rl);
                        }}
                      />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          {modalType === ModalTypes.create && (
            <CreateRoleModal
              onClose={() => setModalType('')}
              title={'Create a new role'}
              role={selectedRole}
            />
          )}
          {modalType === ModalTypes.edit && (
            <CreateRoleModal
              title={'Edit role'}
              role={selectedRole}
              onClose={() => setModalType('')}
            />
          )}
          {modalType === ModalTypes.delete && (
            <Modal show={true}>
              <ModalHeader title={'Delete role'} onClose={() => setModalType('')} />
              <ModalContent>
                <Warning>{`Are you sure you want to delete role ${selectedRole.name}?`}</Warning>
              </ModalContent>
              <ModalFooter>
                <Button
                  className={'al-primary'}
                  clicked={() => {
                    dispatch(deleteRole(selectedRole));
                    setModalType('');
                  }}
                >
                  Delete
                </Button>
                <Button className={'al-button'} clicked={() => setModalType('')}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          )}
        </div>
        <PermissionSets />
      </div>
    </div>
  );
};

export default Roles;
