import * as errorsTypes from './errorsType';

const initialState = {
  error: null,
};

export default function generalErrors(state = initialState, action) {
  const { error, showCustomError, errorHeader, errorBody, hideErrorModal } = action;
  if (error) {
    if (error.response && error.response.status === errorsTypes.FORBIDDEN) {
      return {
        error: error,
        errorHeader: 'Access Denied',
        errorBody: 'Please contact your system administrator',
      };
    } else if (!showCustomError) {
      if (hideErrorModal) {
        return state;
      }
      if (error.response) {
        if (
          error.response.status !== errorsTypes.FORBIDDEN &&
          error.response.status !== errorsTypes.AUTHENTICATIONERROR
        ) {
          return {
            error: error,
            errorHeader: 'Error',
            errorBody: `Request failed with status code ${error.response.status}`,
          };
        }
      } else {
        return {
          error: error,
          errorHeader: 'Error',
          errorBody: error.message ? error.message : 'Request failed',
        };
      }
    } else if (showCustomError) {
      return {
        error: error,
        errorHeader: errorHeader,
        errorBody: errorBody || `Request failed with status code ${error.response.status}`,
      };
    }
  }

  return state;
}
