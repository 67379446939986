import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentComments, fetchIssueComments } from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Icon from '../../../components/UI/Icon/Icon';
import CommentItem from '../../../components/Review/Comment/Comment';

type Props = {
  docId: string,
  issueId: string | null,
};

const Comments = ({ docId, issueId }: Props) => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.comments.comments);
  const loading = useSelector((state) => state.comments.loading);
  const error = useSelector((state) => state.comments.error);
  const [showComments, setShowComments] = useState(true);

  useEffect(() => {
    // enters both on component load and when issueId changes
    initComments();
    // eslint-disable-next-line
  }, [issueId]);

  const initComments = () => {
    if (issueId) {
      dispatch(fetchIssueComments(issueId));
    } else {
      dispatch(fetchDocumentComments(docId));
    }
  };

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  return (
    <div className={'al-comments'}>
      {!!comments.length && (
        <header onClick={toggleComments}>
          <Icon icon={showComments ? 'select-close' : 'select-open'} />
          <label>
            {`${showComments ? 'Hide ' : 'Show '} ${comments.length} comment${
              comments.length > 1 ? 's' : ''
            }`}
          </label>
        </header>
      )}
      {showComments && (
        <div className="al-comments-wrapper">
          {loading ? (
            <Spinner />
          ) : error !== null ? (
            <div className={'error'}>{"can't load data"} </div>
          ) : (
            comments.map((comment, key) => {
              return <CommentItem key={key} comment={comment} />;
            })
          )}
        </div>
      )}
    </div>
  );
};

export default Comments;
