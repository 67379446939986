import React from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

const FileHeader = ({
  docId,
  openReviewDocument,
  statisticByType,
  fetchExcerptsList,
  showExcerpts,
  setShowExcerpts,
  date,
  filteredExcerptsLength,
}) => {
  const handleShowExcerpts = (docId, docStatisticsByType) => {
    const expand = !showExcerpts;
    docStatisticsByType.expanded = expand;
    setShowExcerpts(expand);
    if (expand) {
      fetchExcerptsList(docId);
    }
  };

  const dateStyle = {
    flex: '1 0 auto',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
  };

  return (
    <div className={'header-wrapper'}>
      <header onClick={() => openReviewDocument(docId)}>{statisticByType.docTitle}</header>
      {date && (
        <label style={dateStyle} className={'al-explore-date'}>
          {moment(date).format('llll')}
        </label>
      )}

      <div onClick={() => handleShowExcerpts(docId, statisticByType)}>
        <span className={'expand-area'}>
          {`${showExcerpts ? 'Hide ' : 'Show '}`}
          {`${filteredExcerptsLength} excerpt${filteredExcerptsLength === 1 ? '' : 's'}`}
          {showExcerpts ? (
            <ExpandLessIcon className={'al-icons'} />
          ) : (
            <ExpandMoreIcon className={'al-icons'} />
          )}
        </span>
      </div>
    </div>
  );
};

export default FileHeader;
