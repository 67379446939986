// @flow
import React, { useState } from 'react';
import Button from '../UI/Button/Button';
import AutoComplete from '../UI/AutoComplete/AutoComplete';
import type { ContractType, Project } from '../../types';
import Input from '../UI/Input/Input';
import Warning from '../UI/Typo/Warning';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../UI/Modal/Modal';
import { getValidationError, normalizeWhitespaces } from '../../services/validation';

const flexStyle = {
  padding: '8px 0',
  display: 'flex',
  alignItems: 'center',
};
type Props = {
  name?: string,
  onSubmit: ({ name: string, copyFromId?: string, shouldCopyTraining: boolean }) => void,
  existingItems: Array<ContractType> | Array<Project>,
  onCancel: () => void,
  labelKey: string,
  typeToCreate: string,
};
// Contract type name:
// New contract type
//   Mietv
// Mietverträge
// Search for existing contract type to use as a preselection:
//   Copy ﬁnding types only Copy ﬁnding types and training
function CreateWithPreselectionForm({
  onSubmit,
  onCancel,
  name: initialName,
  existingItems,
  labelKey,
  typeToCreate,
}: Props) {
  const [name, setName] = useState(initialName || '');
  const [shouldUseExisting, setUseExisting] = useState(false);
  const [copyFromType, setCopyFromType] = useState(null);
  const toggleExisting = () => setUseExisting(!shouldUseExisting);
  const [shouldCopyTraining, setCopyTraining] = useState(false);

  const save = () => {
    const copyFrom = shouldUseExisting ? copyFromType : null;
    const normalizedName = normalizeWhitespaces(name);
    onSubmit({ name: normalizedName, copyFrom: copyFrom, shouldCopyTraining });
  };

  let create = {
    Enter: save,
    Escape: onCancel,
  };

  const normalizedName = normalizeWhitespaces(name);
  const validationError = getValidationError(
    normalizedName,
    typeToCreate,
    existingItems.filter(
      (item) =>
        item[labelKey].trim().toLowerCase() === normalizedName.trim().toLowerCase() ||
        normalizedName.trim().toLowerCase() === 'UNASSIGNED'.trim().toLowerCase(),
    ).length > 0,
  );

  return (
    <Modal show={true}>
      <ModalHeader title={`Create a new ${typeToCreate}`} onClose={onCancel} />
      <ModalContent>
        <Input
          placeholder={'Enter name'}
          value={name}
          changed={(e) => setName(e.target.value)}
          elementType={'input'}
          elementConfig={{ type: 'text' }}
          focus={true}
          keyPressMap={create}
        />
        {!!validationError && <Warning>{validationError.get('message')}</Warning>}
        {!!existingItems.length && (
          <div style={flexStyle}>
            <Button
              clicked={toggleExisting}
              withIcon={true}
              iconType={shouldUseExisting ? 'checkbox-checked' : 'checkbox-unchecked'}
            />
            <label onClick={toggleExisting}>
              {`Search for an existing ${typeToCreate} to use as a preselection:`}
            </label>
          </div>
        )}
        {shouldUseExisting && (
          // TODO   Copy ﬁnding types only Copy ﬁnding types and training
          <div>
            <AutoComplete
              isAlwaysShowing={true}
              onSelect={(id) => {
                setCopyFromType(id ? existingItems.find((c) => c.id === id) : null);
              }}
              current={copyFromType}
              labelKey={labelKey}
              placeholder={'Select ' + typeToCreate}
              idKey={'id'}
              items={existingItems}
            />
          </div>
        )}
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={!normalizedName.length || validationError}
          btnType={'al-primary'}
          clicked={save}
        >
          Save
        </Button>
        <Button bnType clicked={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CreateWithPreselectionForm;
