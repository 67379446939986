// @flow
import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';
import type { User } from '../../types';
import { Dispatch } from 'redux';

const createUserStart = () => ({
  type: actionTypes.CREATE_USER_START,
});

const createUserSuccess = (user: User) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  user,
});

const createUserFail = (userId: string, error: any) => {
  return {
    type: actionTypes.CREATE_USER_FAIL,
    error,
    userId,
  };
};

export const createUser = (data: User) => async (dispatch: Dispatch) => {
  dispatch(createUserStart());
  try {
    const response = await axios.post('api/users/', data);
    dispatch(createUserSuccess(response.data));
  } catch (err) {
    dispatch(createUserFail(err));
  }
};

const updateUserStart = () => ({
  type: actionTypes.UPDATE_USER_START,
});

const updateUserSuccess = (user: User) => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  user,
});

const updateUserFail = (user: User, error: any) => ({
  type: actionTypes.UPDATE_ROLE_FAIL,
  user,
  error,
});

export const updateUser = (data: User) => async (dispatch: Dispatch) => {
  dispatch(updateUserStart());
  try {
    const response = await axios.put('/api/users/', data);
    dispatch(updateUserSuccess(response.data));
  } catch (err) {
    dispatch(updateUserFail(data, err));
  }
};

const deleteUserStart = (userId: string) => ({
  type: actionTypes.DELETE_USER_START,
  userId,
});

const deleteUserSuccess = (userId: string) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  userId,
});

const deleteUserFail = (userId: string, error: any) => ({
  type: actionTypes.DELETE_USER_FAIL,
  error,
  userId,
});

export const deleteUser = (user: User) => async (dispatch: Dispatch) => {
  dispatch(deleteUserStart(user.id));
  try {
    await axios.delete('/api/users/' + user.id);
    dispatch(deleteUserSuccess(user.id));
  } catch (error) {
    dispatch(deleteUserFail(user.id, error));
  }
};

const fetchUsersStart = () => ({
  type: actionTypes.FETCH_USERS_START,
});
const fetchUsersSuccess = (users: Array<User>) => {
  return { type: actionTypes.FETCH_USERS_SUCCESS, users };
};

const fetchUsersFail = (error: any) => ({
  type: actionTypes.FETCH_USERS_FAIL,
  error,
});

export const fetchUsers = () => async (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());
  try {
    const response = await axios.get('/api/users/');
    dispatch(fetchUsersSuccess(response.data));
  } catch (err) {
    dispatch(fetchUsersFail(err));
  }
};
