import * as actionTypes from '../actions/actionType';
import type { DynamicTabsState } from '../../types';

const initialState: DynamicTabsState = {
  creatingDynamicTabsState: false,
  updatingDynamicTabsStats: false,
  fetchingDynamicTabsState: false,
  deletingDynamicTabsStats: false,
  dynamicTabs: [{id: "1", name:"amer", url:"url"}],
};

const addDynamicTabStart = (state, action) => {
  return {
    ...state,
    creatingDynamicTabsState: true,
  };
};

const addDynamicTabSuccess = (state, action) => {
  let dynamicTabs = [...state.dynamicTabs];
  dynamicTabs.push(action.data);
  return {
    ...state,
    creatingDynamicTabsState: false,
    dynamicTabs,
  };
};

const addDynamicTabFail = (state, action) => {
  return {
    ...state,
    creatingDynamicTabsState: { error: action.error },
  };
};

const updateDynamicTabStart = (state, action) => {
  return {
    ...state,
    updatingDynamicTabsState: true,
  };
};

const updateDynamicTabSuccess = (state, action) => {
  let updatedDynamicTabs = [...state.dynamicTabs];
  return {
    ...state,
    updatingDynamicTabsState: false,
    dynamicTabs: updatedDynamicTabs,
  };
};

const updateDynamicTabFail = (state, action) => {
  return {
    ...state,
    updatingDynamicTabsState: { error: action.error },
  };
};

const deleteDynamicTabStart = (state, action) => {
  return {
    ...state,
    deletingDynamicTabsState: true,
  };
};

const deleteDynamicTabSuccess = (state, action) => {
  let updatedDynamicTabs = [...state.dynamicTabs];
  const {dynamicTabId} = action;
  updatedDynamicTabs = updatedDynamicTabs.filter(dynamicTab => dynamicTab.id !== dynamicTabId);
  return {
    ...state,
    deletingDynamicTabsState: false,
    dynamicTabs: updatedDynamicTabs,
  };
};

const deleteDynamicTabFail = (state, action) => {
  return {
    ...state,
    deletingDynamicTabsState: { error: action.error },
  };
};

const fetchDynamicTabsStart = (state, action) => {
  return {
    ...state,
    fetchingDynamicTabsState: true,
  };
};

const fetchDynamicTabsSuccess = (state, action) => {
  return {
    ...state,
    fetchingDynamicTabsState: false,
    dynamicTabs: action.data,
  };
};

const fetchDynamicTabsFail = (state, action) => {
  return {
    ...state,
    fetchingDynamicTabsState: { error: action.error },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_DYNAMIC_TAB_START:
      return addDynamicTabStart(state, action);
    case actionTypes.ADD_DYNAMIC_TAB_SUCCESS:
      return addDynamicTabSuccess(state, action);
    case actionTypes.ADD_DYNAMIC_TAB_FAIL:
      return addDynamicTabFail(state, action);

    case actionTypes.UPDATE_DYNAMIC_TAB_START:
      return updateDynamicTabStart(state, action);
    case actionTypes.UPDATE_DYNAMIC_TAB_SUCCESS:
      return updateDynamicTabSuccess(state, action);
    case actionTypes.UPDATE_DYNAMIC_TAB_FAIL:
      return updateDynamicTabFail(state, action);

    case actionTypes.DELETE_DYNAMIC_TAB_START:
      return deleteDynamicTabStart(state, action);
    case actionTypes.DELETE_DYNAMIC_TAB_SUCCESS:
      return deleteDynamicTabSuccess(state, action);
    case actionTypes.DELETE_DYNAMIC_TAB_FAIL:
      return deleteDynamicTabFail(state, action);

    case actionTypes.FETCH_DYNAMIC_TABS_START:
      return fetchDynamicTabsStart(state, action);
    case actionTypes.FETCH_DYNAMIC_TABS_SUCCESS:
      return fetchDynamicTabsSuccess(state, action);
    case actionTypes.FETCH_DYNAMIC_TABS_FAIL:
      return fetchDynamicTabsFail(state, action);

    default:
      return state;
  }
};

export default reducer;
