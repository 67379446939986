// @flow
import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';
import type { Role } from '../../types';
import { Dispatch } from 'redux';

const createRolesStart = () => ({
  type: actionTypes.CREATE_ROLE_START,
});

const createRoleSuccess = (role: Role) => ({
  type: actionTypes.CREATE_ROLE_SUCCESS,
  role,
});

const createRoleFail = (role: Role, error: any) => {
  return {
    type: actionTypes.CREATE_USER_FAIL,
    error,
    role,
  };
};

export const createRole = (data: Role) => async (dispatch: Dispatch) => {
  dispatch(createRolesStart());
  try {
    const response = await axios.post('api/roles/', data);
    dispatch(createRoleSuccess(response.data));
  } catch (err) {
    dispatch(createRoleFail(err));
  }
};

const updateRoleStart = () => ({
  type: actionTypes.UPDATE_ROLE_START,
});

const updateRoleSuccess = (role: Role) => ({
  type: actionTypes.UPDATE_ROLE_SUCCESS,
  role,
});

const updateRoleFail = (role: Role, error: any) => ({
  type: actionTypes.UPDATE_ROLE_FAIL,
  role,
  error,
});

export const updateRole = (data: Role) => async (dispatch: Dispatch) => {
  dispatch(updateRoleStart());
  try {
    const response = await axios.put('/api/roles/', data);
    dispatch(updateRoleSuccess(response.data));
  } catch (err) {
    dispatch(updateRoleFail(data, err));
  }
};

const deleteRoleStart = (roleId: string) => ({
  type: actionTypes.DELETE_ROLE_START,
  roleId,
});

const deleteRoleSuccess = (roleId: string) => ({
  type: actionTypes.DELETE_ROLE_SUCCESS,
  roleId,
});

const deleteRoleFail = (roleId: string, error: any) => ({
  type: actionTypes.DELETE_ROLE_FAIL,
  error,
  roleId,
});

export const deleteRole = (role: Role) => async (dispatch: Dispatch) => {
  dispatch(deleteRoleStart(role.id));
  try {
    await axios.delete('/api/roles/' + role.id);
    dispatch(deleteRoleSuccess(role.id));
  } catch (error) {
    dispatch(deleteRoleFail(role.id, error));
  }
};

const fetchRolesStart = () => ({
  type: actionTypes.FETCH_ROLES_START,
});
const fetchRolesSuccess = (roles: Array<Role>) => {
  return { type: actionTypes.FETCH_ROLES_SUCCESS, roles };
};

const fetchRolesFail = (error: any) => ({
  type: actionTypes.FETCH_ROLES_FAIL,
  error,
});

export const fetchRoles = () => async (dispatch: Dispatch) => {
  dispatch(fetchRolesStart());
  try {
    const response = await axios.get('/api/roles/');
    dispatch(fetchRolesSuccess(response.data));
  } catch (err) {
    dispatch(fetchRolesFail(err));
  }
};
