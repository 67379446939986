import * as actionTypes from '../../actions/actionType';

const fetchDocsByOriginStart = (state, action) => {
  const { projectId, contractId } = action;
  return {
    ...state,
    projectId,
    contractId,
    error: null,
    loading: true,
    success: false,
    isRetrievingExcerpts: true,
  };
};

const fetchDocsByOriginSuccess = (state, action) => {
  const { projectId, contractId } = action;
  return {
    ...state,
    projectId,
    contractId,
    docsExcerptsCounts: action.data,
    error: null,
    loading: false,
    success: true,
  };
};

const fetchDocsByOriginFail = (state, action) => {
  const { projectId, contractId, error } = action;
  const excerpts = {};
  return {
    ...state,
    projectId,
    contractId,
    excerpts,
    error,
    loading: false,
    isRetrievingExcerpts: false,
  };
};

const fetchStatisticsByTypeStart = (state, action) => {
  return {
    ...state,
    error: null,
    loadingExcerptsDocId: action.docId,
  };
};

const fetchStatisticsByTypeSuccess = (state, action) => {
  const { projectId, contractId } = action;
  let excerptsByDoc = {};
  excerptsByDoc['docId'] = action.docId;
  excerptsByDoc['excerpts'] = action.data;
  return {
    ...state,
    projectId,
    contractId,
    excerpts: excerptsByDoc,
    loadingExcerptsDocId: null,
    isRetrievingExcerpts: false,
  };
};

const fetchStatisticsByTypeFail = (state, action) => {
  const { projectId, contractId, error } = action;
  const excerpts = {};
  return {
    ...state,
    projectId,
    contractId,
    excerpts,
    error,
    loadingExcerptsDocId: null,
    isRetrievingExcerpts: false,
  };
};

/* currently not used */
const updateStatisticsByTypeSuccess = (state, action) => {
  const { excerpts } = state;
  for (const index in excerpts) {
    if (excerpts[index].excerptId === action.updatedExcerpt.excerptId) {
      let update = { ...excerpts[index] };
      update = action.updatedExcerpt;
      excerpts[index] = update;
    }
  }
  return {
    ...state,
    excerpts,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STATISTICS_BY_TYPE_START:
      return fetchStatisticsByTypeStart(state, action);
    case actionTypes.FETCH_STATISTICS_BY_TYPE_SUCCESS:
      return fetchStatisticsByTypeSuccess(state, action);
    case actionTypes.FETCH_STATISTICS_BY_TYPE_FAIL:
      return fetchStatisticsByTypeFail(state, action);
    case actionTypes.UPDATE_STATISTICS_BY_TYPE_SUCCESS:
      return updateStatisticsByTypeSuccess(state, action);
    case actionTypes.FETCH_DOCS_BY_ORIGIN_START:
      return fetchDocsByOriginStart(state, action);
    case actionTypes.FETCH_DOCS_BY_ORIGIN_SUCCESS:
      return fetchDocsByOriginSuccess(state, action);
    case actionTypes.FETCH_DOCS_BY_ORIGIN_FAIL:
      return fetchDocsByOriginFail(state, action);
    default:
      return state;
  }
};

export default reducer;
