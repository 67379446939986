import axios from 'axios';
import promise from 'promise';
import config from '../config';
import _ from 'lodash';
import * as actions from '../store/actions/index';
import * as errorsTypes from '../store/reducers/errorsType';
const url = config.apiGateway.URL;

// Add a request interceptor
export const axiosInstance = axios.create({
  baseURL: url,
});

export const setupInterceptors = (store, persistor) => {
  axiosInstance.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      //If the header does not contain the token and the url not public, redirect to login
      //var accessToken = getAccessTokenFromCookies();
      let accessToken = localStorage.getItem('token');
      //if token is found add it to the header
      if (accessToken) {
        if (config.method !== 'OPTIONS') {
          config.headers = {
            Authorization: accessToken,
          };
        }
      }
      return config;
    },
    (error) => {
      _.debounce(() => {
        store.dispatch(actions.health());
      }, 500)();
      store.dispatch(actions.logout());
      return promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    function (response) {
      if (
        localStorage.getItem('apiVersion') &&
        response.headers['al-api-version'] &&
        response.headers['al-api-version'] !== localStorage.getItem('apiVersion')
      ) {
        store.dispatch(actions.logout());
        window.location.reload();
      }
      return response;
    },
    (error) => {
      _.debounce(() => {
        store.dispatch(actions.health());
      }, 500)();
      if (
        errorsTypes.AUTHENTICATIONERROR === error.response.status &&
        !error.config.url.indexOf('login')
      ) {
        console.error('unauthenticated user');
        store.dispatch(actions.logout());
      }
      return promise.reject(error);
    },
  );
};
