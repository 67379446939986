import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '../../../components/UI/Button/Button';
import Input from '../../../components/UI/Input/Input';
import Checkbox from '../../../components/UI/Checkbox/Checkbox';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../../../components/UI/Modal/Modal';
import Warning from '../../../components/UI/Typo/Warning';
import { INPUT_TYPE } from '../../../constants/constants';
import { normalizeWhitespaces } from '../../../services/validation';

type Props = {
  onSetShowCreateSearchQueryModal: (showHideModal: boolean) => void,
  onCreateEditSearchQuery: (
    queryId: string,
    query: string,
    excerptType: string,
    characterLength: number,
  ) => void,
  queryName: string,
  queryExcerptType: string,
  queryCharacterLength: number | null,
  querySearchId: string,
  getValidationError: (name: string) => any,
};

const SearchQueriesForm = ({
  onSetShowCreateSearchQueryModal,
  onCreateEditSearchQuery,
  queryName,
  queryExcerptType,
  queryCharacterLength,
  querySearchId,
  getValidationError,
}: Props) => {
  const loading = useSelector(
    (state) =>
      state.searchQueries.fetchingSearchQueries ||
      state.searchQueries.creatingSearchQueries ||
      state.searchQueries.editingSearchQueries ||
      state.searchQueries.applyingQuery ||
      state.searchQueries.deletingSearchQueries,
  );

  const [query, setQuery] = useState(queryName);
  const [excerptType, setExcerptType] = useState(queryExcerptType);
  const checked = !queryCharacterLength ? false : true;
  const [characterLengthCheckbox, setCharacterLengthCheckbox] = useState(checked);
  const [characterLength, setCharacterLength] = useState(queryCharacterLength);

  const validationError = getValidationError && getValidationError(excerptType);

  return (
    <div>
      <Modal show={true}>
        <ModalHeader
          title={`${querySearchId ? 'Edit' : 'Create a new'} ${INPUT_TYPE.SEARCH_QUERY}`}
          onClose={() => onSetShowCreateSearchQueryModal(false)}
        />
        <ModalContent>
          <Input
            value={excerptType}
            changed={(e) => setExcerptType(e.target.value)}
            elementType={'input'}
            elementConfig={{ type: 'text' }}
            placeholder={'Name'}
          />
          {!!validationError && (
            <Warning className="warning-search-query">{validationError.get('message')}</Warning>
          )}
          <Input
            value={query}
            changed={(e) => setQuery(e.target.value)}
            elementType={'input'}
            elementConfig={{ type: 'text' }}
            placeholder={'Query'}
          />
          <div className="query-characters-wrapper">
            <Checkbox
              onClick={() => setCharacterLengthCheckbox(!characterLengthCheckbox)}
              checked={characterLengthCheckbox}
            >
              {'Reject results shorter than '}
            </Checkbox>
            <Input
              value={characterLength}
              changed={(e) => setCharacterLength(e.target.value)}
              elementType={'input'}
              elementConfig={{ type: 'number' }}
              disabled={!characterLengthCheckbox}
            />
            {' characters'}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            className={'al-primary'}
            disabled={
              excerptType.trim() === '' || query.trim() === '' || loading || !!validationError
            }
            clicked={() =>
              onCreateEditSearchQuery(
                querySearchId,
                query,
                normalizeWhitespaces(excerptType),
                characterLength,
              )
            }
          >
            Save
          </Button>
          <Button clicked={() => onSetShowCreateSearchQueryModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SearchQueriesForm;
