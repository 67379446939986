import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';

export const fetchContractStatisticsStart = (projectId, contractId) => {
  return {
    type: actionTypes.FETCH_CONTRACT_STATISTICS_START,
    projectId,
    contractId,
  };
};

export const fetchContractAggregationsStatisticsSuccess = (projectId, contractId, data) => {
  return {
    type: actionTypes.FETCH_CONTRACT_STATISTICS_AGGREGATIONS_SUCCESS,
    data,
    projectId,
    contractId,
  };
};

export const fetchContractStatisticsFail = (projectId, contractId, error) => {
  return {
    type: actionTypes.FETCH_CONTRACT_STATISTICS_FAIL,
    error: error,
    projectId,
    contractId,
  };
};

export const fetchContractAggregationsStatistics = (projectId: string, contractId: string) => {
  return (dispatch) => {
    dispatch(fetchContractStatisticsStart(projectId, contractId));
    axios
      .get(`api/excerpts/statistics/typeAggregation`, {
        params: {
          projectId,
          contractId,
        },
      })
      .then((response) => {
        dispatch(fetchContractAggregationsStatisticsSuccess(projectId, contractId, response.data));
      })
      .catch((err) => {
        dispatch(fetchContractStatisticsFail(projectId, contractId, err));
      });
  };
};

export const fetchContractOriginsStatisticsSuccess = (
  projectId,
  contractId,
  origins,
  excerptType,
) => {
  return {
    type: actionTypes.FETCH_CONTRACT_STATISTICS_ORIGINS_SUCCESS,
    origins,
    projectId,
    contractId,
    excerptType,
  };
};

export const fetchExcerptTypeOriginsStatistics = (
  projectId: string,
  contractId: string,
  excerptType: string,
) => {
  return (dispatch) => {
    dispatch(fetchContractStatisticsStart(projectId, contractId));
    axios
      .get(`api/excerpts/statistics/origins/count`, {
        params: {
          projectId,
          contractId,
          excerptType,
        },
      })
      .then((response) => {
        dispatch(
          fetchContractOriginsStatisticsSuccess(projectId, contractId, response.data, excerptType),
        );
      })
      .catch((err) => {
        dispatch(fetchContractStatisticsFail(projectId, contractId, err));
      });
  };
};

export const fetchDocsByOriginStart = (excerptType, projectId, contractId) => {
  return {
    type: actionTypes.FETCH_DOCS_BY_ORIGIN_START,
    projectId,
    contractId,
    excerptType: excerptType,
  };
};

export const fetchDocsByOriginSuccess = (excerptType, projectId, contractId, data) => {
  return {
    type: actionTypes.FETCH_DOCS_BY_ORIGIN_SUCCESS,
    projectId,
    contractId,
    data: data,
    excerptType: excerptType,
  };
};

export const fetchDocsByOriginFail = (excerptType, projectId, contractId, error) => {
  return {
    type: actionTypes.FETCH_DOCS_BY_ORIGIN_FAIL,
    projectId,
    contractId,
    error: error,
    excerptType: excerptType,
  };
};

export const fetchDocsByOrigin = (projectId, contractId, excerptType, origin) => {
  return (dispatch) => {
    dispatch(fetchDocsByOriginStart(excerptType, projectId, contractId));
    axios
      .get(`api/excerpts/statistics/origin_type/docs_count`, {
        params: {
          excerptType,
          projectId,
          contractId,
          origin,
        },
      })
      .then((response) => {
        dispatch(fetchDocsByOriginSuccess(excerptType, projectId, contractId, response.data));
      })
      .catch((err) => {
        dispatch(fetchDocsByOriginFail(excerptType, projectId, contractId, err));
      });
  };
};

export const fetchStatisticsByTypeStart = (excerptType, docId) => {
  return {
    type: actionTypes.FETCH_STATISTICS_BY_TYPE_START,
    excerptType,
    docId,
  };
};

export const fetchStatisticsByTypeSuccess = (excerptType, docId, projectId, contractId, data) => {
  return {
    type: actionTypes.FETCH_STATISTICS_BY_TYPE_SUCCESS,
    projectId,
    contractId,
    data: data,
    excerptType: excerptType,
    docId,
  };
};

export const fetchStatisticsByTypeFail = (excerptType, projectId, contractId, error) => {
  return {
    type: actionTypes.FETCH_STATISTICS_BY_TYPE_FAIL,
    projectId,
    contractId,
    error: error,
    excerptType: excerptType,
  };
};

export const fetchStatisticsByType = (docId, excerptType, origin, projectId, contractId) => {
  return (dispatch) => {
    dispatch(fetchStatisticsByTypeStart(excerptType, docId));
    axios
      .get(`api/excerpts/origin_type`, {
        params: {
          docId,
          excerptType,
          origin,
        },
      })
      .then((response) => {
        dispatch(
          fetchStatisticsByTypeSuccess(excerptType, docId, projectId, contractId, response.data),
        );
      })
      .catch((err) => {
        dispatch(fetchStatisticsByTypeFail(excerptType, projectId, contractId, err));
      });
  };
};
