// @flow
import React, { useEffect, useState } from 'react';
import type { FileItemType } from '../../types';
import AutoComplete from '../UI/AutoComplete/AutoComplete';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CustomTooltip from '../UI/Tooltip/Tooltip';

type Props = {
  fileItems: Array<FileItemType>,
  onSelectReferenceFile: (id?: string) => void,
  onSelectComparedFile: (id?: string) => void,
  selectedReferenceFile: null | FileItemType,
  selectedComparedFile: null | FileItemType,
  swapDocsToCompare: () => void,
  isUsingCompareDatabase: boolean,
};

const SelectFiles = ({
  fileItems,
  onSelectReferenceFile,
  onSelectComparedFile,
  selectedReferenceFile,
  selectedComparedFile,
  swapDocsToCompare,
  isUsingCompareDatabase,
}: Props) => {
  const [reference, setReference] = useState(selectedReferenceFile);
  const [comparison, setComparison] = useState(selectedComparedFile);

  useEffect(() => {
    setReference(selectedReferenceFile);
    // eslint-disable-next-line
  }, [selectedReferenceFile]);

  useEffect(() => {
    setComparison(selectedComparedFile);
    // eslint-disable-next-line
  }, [selectedComparedFile]);

  return (
    <header className={'compare-table-header-select-container'}>
      <div className={'compare-reference'}>
        {isUsingCompareDatabase ? (
          <span className={'compare-table-header-label'}>Compare Database:</span>
        ) : (
          <>
            <span className={'compare-table-header-label'}>Reference document: </span>
            <AutoComplete
              current={reference}
              items={fileItems}
              onSelect={onSelectReferenceFile}
              placeholder={'Select reference document'}
            />
          </>
        )}
      </div>
      {!isUsingCompareDatabase && (
        <CustomTooltip
          title={`Swap reference and compared documents`}
          enterDelay={50}
          leaveDelay={50}
          placement="top-end"
          interactive={true}
        >
          <span className={'swap-icon-container'} onClick={swapDocsToCompare}>
            <SwapHorizIcon className={'al-icons swap-compared-docs-icon'} fontSize={'large'} />
          </span>
        </CustomTooltip>
      )}
      <div className={'compare-comparison'}>
        <span className={'compare-table-header-label'}>Comparison document:</span>
        <AutoComplete
          current={comparison}
          items={fileItems}
          onSelect={onSelectComparedFile}
          placeholder={'Select document to compare with'}
        />
      </div>
    </header>
  );
};

export default SelectFiles;
