// @flow
import React, { PureComponent } from 'react';
import Icon from '../Icon/Icon';
import { getContractName } from '../../../services/namesHelper';

type RefObject = { current?: any };
type DefaultProps = {
  labelKey: string,
  idKey: string,
  placeholder: string,
  items: Array<any>,
  isAlwaysShowing: boolean,
};

type Props = DefaultProps & {
  onSelect: (item: any) => void,
  current: any,
};

type State = {
  activeSuggestionIndex: number, // The active selection's index
  filteredSuggestions: Array<any>, // The suggestions that match the user's input
  showSuggestions: boolean, // Whether or not the suggestion list is shown
  userInput: string, // What the user has entered
};

class AutoComplete extends PureComponent<Props, State> {
  static defaultProps = {
    labelKey: 'title',
    placeholder: 'Select Document',
    idKey: 'id',
    items: [],
    isAlwaysShowing: false,
  };
  activeSuggestionRef: RefObject;
  wrapperRef: RefObject;

  constructor(props: Props) {
    super(props);
    this.activeSuggestionRef = React.createRef();
    this.wrapperRef = React.createRef();

    this.state = {
      activeSuggestionIndex: 0, // The active selection's index
      filteredSuggestions: props.items, // The suggestions that match the user's input
      showSuggestions: false, // Whether or not the suggestion list is shown
      userInput: '', // What the user has entered
    };
  }

  /**
   * closes if clicked outside
   */
  handleClickOutside = (event: SyntheticMouseEvent<any> | MouseEvent) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ showSuggestions: false });
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if (this.props.current?.title) {
      this.setState({ userInput: this.props.current.title });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps: Props, prevState: State, snapShot: any) {
    if (prevProps.items !== this.props.items) {
      this.onInputChange(this.state.userInput, this.state.showSuggestions);
    }
    if (
      prevState.activeSuggestionIndex !== this.state.activeSuggestionIndex &&
      this.activeSuggestionRef.current
    ) {
      this.activeSuggestionRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
    if (prevProps.current !== this.props.current) {
      if (this.props.current?.title) {
        this.setState({ userInput: this.props.current.title });
      } else {
        this.setState({ userInput: '' });
      }
    }
  }

  getItemName = (name) => {
    return this.props.labelKey === 'contractType'
      ? getContractName(name)
      : name.trim().toLowerCase();
  };

  // Event fired when the input value is changed
  onInputChange = (userInput: string, showSuggestions: boolean = true) => {
    const { items, labelKey } = this.props;

    // Filter our Suggestions that don't contain the user's input
    const filteredSuggestions = userInput
      ? items.filter(
          (item) =>
            this.getItemName(item[labelKey]).toLowerCase().indexOf(userInput.toLowerCase()) > -1,
        )
      : items;

    // Update the user input and filtered Suggestions, reset the active
    // item and make sure the Suggestions are shown
    this.setState({
      activeSuggestionIndex: 0,
      filteredSuggestions,
      showSuggestions,
      userInput,
    });
  };

  // Event fired when the user clicks on a item
  onReset = () => {
    const { items, onSelect } = this.props;
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestionIndex: 0,
      filteredSuggestions: items,
      showSuggestions: false,
      userInput: '',
    });
    onSelect(null);
  };

  // Event fired when the user clicks on a item
  onClick = (item: any) => {
    const { items, idKey, labelKey, onSelect } = this.props;
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestionIndex: 0,
      filteredSuggestions: items,
      showSuggestions: false,
      userInput: item[labelKey],
    });
    onSelect(item[idKey]);
  };

  // Event fired when the user presses a key down
  onKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    const { activeSuggestionIndex, filteredSuggestions } = this.state;
    const { onSelect, idKey, labelKey } = this.props;
    //User pressed the enter key, update the input and close the    Suggestions
    if (e.keyCode === 13 && filteredSuggestions[activeSuggestionIndex]) {
      this.setState({
        activeSuggestionIndex: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestionIndex][labelKey],
      });
      onSelect(filteredSuggestions[activeSuggestionIndex][idKey]);
    }
    // User pressed the up arrow, decrement the index
    if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      this.setState({ activeSuggestionIndex: activeSuggestionIndex - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestionIndex: activeSuggestionIndex + 1 });
    }
  };

  render() {
    const {
      onInputChange,
      onReset,
      onClick,
      onKeyDown,
      state: { filteredSuggestions, showSuggestions, userInput, activeSuggestionIndex },
    } = this;
    const { current, labelKey, idKey, placeholder, isAlwaysShowing } = this.props;

    let suggestionsListComponent;

    if (showSuggestions || isAlwaysShowing) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul
            className={isAlwaysShowing ? 'al-suggestions al-suggestions--inline' : 'al-suggestions'}
          >
            {filteredSuggestions.map((item, index) => {
              let className = 'al-suggestion';
              // Flag the active item with a class
              const isActive = index === !isAlwaysShowing && activeSuggestionIndex;
              if (current && item[idKey] === current[idKey]) {
                className += ' al-suggestion--active';
              }
              if (isActive) {
                className += ' al-suggestion--current';
              }

              return (
                <li
                  className={className}
                  ref={isActive ? this.activeSuggestionRef : null}
                  key={item[idKey]}
                  onClick={() => onClick(item)}
                >
                  {this.getItemName(item[labelKey])}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <ul className="al-suggestions">
            <li className="al-suggestion al-no-suggestions">Nothing to select</li>
          </ul>
        );
      }
    }
    return (
      <div className={'al-autoComplete'} ref={this.wrapperRef}>
        <div className={'al-autoComplete-input'}>
          <input
            type="text"
            onChange={(e) => onInputChange(e.currentTarget.value)}
            onFocus={() => this.setState({ showSuggestions: true })}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            value={this.getItemName(userInput)}
          />
          {userInput && (
            <div className={'al-autoComplete-reset'}>
              <Icon clicked={onReset} icon={'close'} />
            </div>
          )}
        </div>
        {suggestionsListComponent}
      </div>
    );
  }
}

export default AutoComplete;
