// @flow
import React, { Component } from 'react';
import type { DiffTypes } from '../../../services/diffService';
import { diffFunctions } from '../../../services/diffService';
type Props = {
  oldText: string,
  newText: string,
  type: DiffTypes,
};

class Diff extends Component<Props> {
  render() {
    let diff = diffFunctions[this.props.type](this.props.oldText, this.props.newText);

    let result = diff.map(function (part, index) {
      let tag = part.added ? 'ins' : part.removed ? 'del' : 'span';

      return React.createElement(tag, { key: index }, part.value);
    });

    return (
      <div className={'diff-result'} style={{ width: '100%', padding: '10px' }}>
        {result}
      </div>
    );
  }
}

export default Diff;
