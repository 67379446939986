import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteIssue, updateIssue } from '../../../../store/actions';
import { usePrevious } from '../../../../hooks/prevValue';
import { FormattedMessage } from 'react-intl';
import type { Excerpt } from '../../../../types';
import { ISSUE_STATUS as STATUS, ORIGIN } from '../../../../constants/constants';
import Spinner from '../../../UI/Spinner/Spinner';
import Button from '../../../UI/Button/Button';
import CustomTooltip from '../../../UI/Tooltip/Tooltip';
import ActionTypeModel from '../../ActionTypeModel/ActionTypeModel';
import CommentsModal from '../../../../components/Review/CommentsModal/CommentsModal';
import ExcerptRecognize from './ExcerptRecognize';
import ExcerptToggleAiTrainer from './ExcerptToggleAiTrainer';

type Props = {
  selectIssue: (issue: Excerpt) => void,
  globalConfig: any,
  isSelected: boolean,
  issueId: string,
  itemProps: Excerpt,
  updatedIssue: Excerpt,
  createAction: (globalConfig: any, actions: any) => void,
  hasConvertToUserPermission: boolean,
  hasRecognizeCorrectTrainPermission: boolean,
  hasRecognizeIncorrectTrainPermission: boolean,
  hasTrainingPermission: boolean,
};

const Issue = ({
  selectIssue,
  globalConfig,
  isSelected,
  issueId,
  itemProps,
  updatedIssue,
  createAction,
  hasConvertToUserPermission,
  hasRecognizeCorrectTrainPermission,
  hasRecognizeIncorrectTrainPermission,
  hasTrainingPermission,
}: Props) => {
  const issueRef = useRef();
  const dispatch = useDispatch();
  const isFrozenTraining = useSelector(
    (state) => state.globalSettings.globalSettings.freezeTraining,
  );
  const [stateIsSelected, setStateIsSelected] = useState(isSelected);
  const prevIsSelected = usePrevious(isSelected);
  const [item, setItem] = useState(itemProps);
  const prevItemProps = usePrevious(itemProps);

  const setIssueStatus = (issueId: string, status: string) => {
    dispatch(updateIssue(issueId, { status: status }));
  };

  const deleteIssueById = () => {
    dispatch(deleteIssue(issueId));
  };

  const toggleApprove = () => {
    let status = item.status !== STATUS.APPROVED ? STATUS.APPROVED : STATUS.WORKING;
    setIssueStatus(issueId, status);
  };

  const toggleRedFlag = () => {
    let status = item.status !== STATUS.REDFLAG ? STATUS.REDFLAG : STATUS.WORKING;
    setIssueStatus(issueId, status);
    if (controls.dropDown.show) {
      toggleDropDown();
    }
  };

  const showGlobalConfig = () => {
    toggleModal('globalConfigModal');
    if (controls.dropDown.show) {
      toggleDropDown();
    }
  };

  /**
   * Show comments related to the issue.
   */
  const showComment = () => {
    toggleModal('commentModal');
  };

  const [controls, setControls] = useState({
    commentModal: {
      key: 'commentModal',
      title: 'Comments on excerpt',
      modalType: 'issueComments',
      open: false,
      loading: false,
      error: null,
    },
    globalConfigModal: {
      key: 'globalConfigModal',
      title: 'app.review.globalaction.modal.title',
      modalType: 'issueComments',
      open: false,
      loading: false,
      error: null,
    },
    actions: {
      addComment: {
        show: item.origin !== ORIGIN.ADVANCED,
        className: 'al-comment',
        activeClassName: 'al-active',
        withIcon: true,
        icon: item.hasComment ? 'comments' : 'no-comments',
        clicked: showComment,
        title: 'Show comments',
      },
      approve: {
        show: item.origin !== ORIGIN.ADVANCED,
        className: 'al-approve',
        activeClassName: 'approveActiveButton',
        withIcon: true,
        icon: item.status === STATUS.APPROVED ? 'checkbox-checked' : 'checkbox-unchecked',
        active: false,
        clicked: toggleApprove,
        title: 'Mark Finding as Reviewed',
      },
      redFlag: {
        show: item.origin !== ORIGIN.ADVANCED,
        className: 'al-red-flag',
        withIcon: true,
        key: 'redFlag',
        icon: 'flag',
        title: item.status !== STATUS.REDFLAG ? 'Set Red Flag' : 'Unset Red Flag',
        clicked: toggleRedFlag,
      },
      addGlobalConfig: {
        show: item.origin !== ORIGIN.ADVANCED,
        withIcon: true,
        key: 'addGlobalConfig',
        icon: 'add-circle',
        clicked: showGlobalConfig,
        title: 'Assign Global Actions',
      },
    },
    dropDown: {
      show: false,
      actions: {
        redFlag: {
          key: 'redFlag',
          icon: 'flag',
          label: item.status !== STATUS.REDFLAG ? 'Set Red Flag' : 'Unset Red Flag',
          click: toggleRedFlag,
        },
        addGlobalConfig: {
          key: 'addGlobalConfig',
          icon: 'add-circle',
          click: showGlobalConfig,
          label: 'Assign Global Actions',
        },
      },
    },
    deleteConfirmationModal: {
      key: 'deleteConfirmationModal',
      open: false,
      submit: deleteIssueById,
      loading: false,
      error: null,
    },
  });
  const [loading, setLoading] = useState(false);

  /**
   * Toggle Modal by modal key
   * @param key {String} Modal key
   */
  const toggleModal = (key) => {
    let updatedControls = { ...controls };
    updatedControls[key].loading = false;
    updatedControls[key].error = null;
    updatedControls[key].open = !controls[key].open;
    setControls(updatedControls);
  };

  const toggleDropDown = (e) => {
    if (
      e &&
      e.type === 'mousedown' &&
      this.moreButton.contains(e.target) &&
      controls.dropDown.show
    ) {
      return;
    }
    let updatedControls = { ...controls };
    updatedControls.dropDown.show = !updatedControls.dropDown.show;
    setControls(updatedControls);
  };

  useEffect(() => {
    if (isSelected) {
      scroll();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updatedIssue.issueId === item.excerptId) {
      if (loading !== updatedIssue.updateLoading) {
        setLoading(updatedIssue.updateLoading);
      }
    }
    // eslint-disable-next-line
  }, [updatedIssue]);

  useEffect(() => {
    let updatedControls = { ...controls };
    let updatedItem = { ...itemProps };
    if (itemProps.status !== prevItemProps?.status) {
      updatedControls.actions.approve.icon =
        itemProps.status === STATUS.APPROVED ? 'checkbox-checked' : 'checkbox-unchecked';
      updatedControls.dropDown.actions.redFlag.label =
        itemProps.status !== STATUS.REDFLAG ? 'Set Red Flag' : 'Unset Red Flag';
      updatedControls.actions.redFlag.title =
        itemProps.status !== STATUS.REDFLAG ? 'Set Red Flag' : 'Unset Red Flag';
      setControls(updatedControls);
      updatedItem.status = itemProps.status;
    }
    if (itemProps.hasComment !== prevItemProps?.hasComment) {
      updatedControls.actions.addComment.icon = itemProps.hasComment ? 'comments' : 'no-comments';
      updatedItem.hasComment = itemProps.hasComment;
    }
    setControls(updatedControls);
    setItem(updatedItem);
    // eslint-disable-next-line
  }, [itemProps]);

  useEffect(() => {
    if (typeof prevIsSelected !== 'undefined') {
      if (isSelected && !stateIsSelected) {
        scroll();
      } else {
        setStateIsSelected(isSelected);
      }
    }
    // eslint-disable-next-line
  }, [isSelected]);

  const scroll = () => {
    if (issueRef.current) {
      issueRef.current.scrollIntoView({
        block: 'nearest',
      });
    }
  };

  let classes = ['al-review-entry'];
  if (item.status === STATUS.APPROVED) {
    classes.push('al-approved');
  } else if (item.status === STATUS.REDFLAG) {
    classes.push('al-red-flag');
  }
  if (isSelected) {
    classes.push('al-selected');
  }
  const actionsConfigs = [];
  for (let key in controls.actions) {
    actionsConfigs.push({
      id: key,
      config: controls.actions[key],
    });
  }

  let actionsButtons = actionsConfigs.map((action) => {
    let label = null;
    if (action.config.showCommentsNumber && item.comments && item.comments.length) {
      label = (
        <label>
          <sub>{item.comments.length}</sub>
        </label>
      );
    }
    return (
      <Button
        className={action.config.className}
        show={action.config.show}
        disabled={loading}
        key={action.id}
        active={action.config.active}
        activeClassName={action.config.activeClassName}
        withIcon={action.config.withIcon}
        iconType={action.config.icon}
        clicked={action.config.clicked}
        title={action.config.title}
        setRef={action.config.setRef}
      >
        {label}
      </Button>
    );
  });

  let score = Math.trunc(item.probability * 100);
  let commentModal = null;
  if (controls.commentModal.open) {
    commentModal = (
      <CommentsModal
        docId={item.docId}
        onClose={() => toggleModal(controls.commentModal.key)}
        show={controls.commentModal.open}
        title={controls.commentModal.title}
        issueId={item.excerptId}
      />
    );
  }
  let globalConfigModal = null;
  if (controls.globalConfigModal.open) {
    globalConfigModal = (
      <ActionTypeModel
        show={controls.globalConfigModal.open}
        title={controls.globalConfigModal.title}
        onClose={() => toggleModal(controls.globalConfigModal.key)}
        globalConfig={globalConfig}
        issueId={issueId}
        excerptType={item.excerptType}
        excerptOrigin={item.origin}
        submitHandler={(actions) => {
          toggleModal(controls.globalConfigModal.key);
          createAction(globalConfig, actions);
        }}
      />
    );
  }
  return (
    <div
      id={item.excerptId}
      data-excerptid={item.excerptId}
      className={classes.join(' ')}
      data-origin={`${item.origin}${item.isNegativeAiTrained ? '-negative' : ''}`}
      ref={issueRef}
    >
      <Spinner show={loading} />
      <div className="al-review-entry-type-marker" />
      <div className="al-review-entry-type-result">
        <FormattedMessage
          id={
            item.origin === ORIGIN.ADVANCED
              ? 'app.review.issues.issue.advanced'
              : item.origin === ORIGIN.STATIC
              ? 'app.review.issues.issue.static'
              : 'app.review.issues.issue.user'
          }
        >
          {(content) => <span className="al-entry-type-origin">{content}</span>}
        </FormattedMessage>
        {item.origin === ORIGIN.ADVANCED && (
          <>
            <span className={'al-current-score'}>{score + '%'}</span>
            <ExcerptRecognize
              excerptId={item.excerptId}
              loading={loading}
              disableEventsWhileRecognizing={() => {}}
              hasConvertToUserPermission={hasConvertToUserPermission}
              hasRecognizeCorrectTrainPermission={hasRecognizeCorrectTrainPermission}
              hasRecognizeIncorrectTrainPermission={hasRecognizeIncorrectTrainPermission}
            />
          </>
        )}
      </div>

      <div
        className="al-review-entry-type-info"
        onClick={() => {
          setStateIsSelected(true);
          selectIssue(item);
        }}
      >
        <CustomTooltip
          title={item.bodyHTML}
          enterDelay={1000}
          leaveDelay={200}
          placement="top-end"
          classes={{ root: 'al-tooltip' }}
          interactive={true}
        >
          <label>{item.bodyHTML}</label>
        </CustomTooltip>
      </div>

      <div className={'al-review-entry-actions'}>
        {actionsButtons}
        {item.origin === ORIGIN.USER && !item.isNegativeAiTrained && !isFrozenTraining && (
          <ExcerptToggleAiTrainer
            excerpt={item}
            loading={loading}
            hasTrainingPermission={hasTrainingPermission}
            setDisabledIssue={() => {}}
          />
        )}

        <div className="al-recognize-buttons">
          <Button
            show={item.origin !== ORIGIN.ADVANCED}
            iconType={'delete'}
            title={'Delete issue'}
            withIcon={true}
            disabled={loading}
            clicked={deleteIssueById}
          />
        </div>
      </div>

      <div className="al-review-entry-hl" />
      {commentModal}
      {globalConfigModal}
    </div>
  );
};

export default Issue;
