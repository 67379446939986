import * as actionTypes from './actionType';
import { Dispatch } from 'redux';
import { axiosInstance as axios } from '../../services/axiosInstance';

const fetchPermissionsStart = () => ({
  type: actionTypes.FETCH_PERMISSIONS_START,
});
const fetchPermissionsSuccess = (permissions: Array<string>) => {
  return { type: actionTypes.FETCH_PERMISSIONS_SUCCESS, permissions };
};

const fetchPermissionsFail = (error: any) => ({
  type: actionTypes.FETCH_PERMISSIONS_FAIL,
  error,
});

export const fetchPermissions = () => async (dispatch: Dispatch) => {
  dispatch(fetchPermissionsStart());
  try {
    const response = await axios.get('/api/permissionset/permissions/');
    dispatch(fetchPermissionsSuccess(response.data));
  } catch (err) {
    dispatch(fetchPermissionsFail(err));
  }
};
