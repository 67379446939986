import * as actionTypes from '../actions/actionType';
import type { RolesState } from '../../types/reduxTypes';

const initialState: RolesState = {
  creatingRole: false,
  creatingRoleError: null,
  updatingRole: false,
  updatingRoleError: null,
  deletingRole: false,
  deletingRoleError: null,
  fetchingRoles: false,
  fetchingRolesError: null,
  roles: [
    {id: '1', permissionsSet: [], roleName: 'role1'},
    {id: '2', permissionsSet: [], roleName: 'role2'},
    {id: '3', permissionsSet: [], roleName: 'role3'},
  ],
};

const createRoleStart = (state, action) => {
  return {
    ...state,
    creatingRole: true,
    creatingRoleError: null,
  }
};

const createRoleSuccess = (state, action) => {
  let updatedRoles = [...state.roles];
  updatedRoles.push(action.role);
  return {
    ...state,
    creatingRole: false,
    creatingRoleError: null,
    roles: updatedRoles
  }
};

const createRoleFail = (state, action) => {
  const {error} = action;
  return {
    ...state,
    creatingRole: true,
    creatingRoleError: error,
  }
};

const updateRoleStart = (state, action) => {
  return {
    ...state,
    updatingRole: true,
    updatingRoleError: null,
  }
};

const updateRoleSuccess = (state, action) => {
  const roles = state.roles.map(r => r.id === action.role.id ? action.role : r);
  return {
    ...state,
    updatingRole: false,
    updatingRoleError: null,
    roles
  }
};

const updateRoleFail = (state, action) => {
  const {error} = action;
  return {
    ...state,
    updatingRole: false,
    updatingRoleError: error
  }
};

const deleteRoleStart = (state, action) => {
  return {
    ...state,
    deletingRole: true,
    deletingRoleError: null
  }
};

const deleteRoleSuccess = (state, action) => {
  const roles = state.roles.filter(r => r.id !== action.roleId);
  return {
    ...state,
    deletingRole: false,
    deletingRoleError: null,
    roles
  }
};

const deleteRoleFail = (state, action) => {
  const {error} = action;
  return {
    ...state,
    deletingRole: false,
    deletingRoleError: error,
  }
};

const fetchRolesStart = (state, action) => {
  return {
    ...state,
    fetchingRoles: true,
    fetchingRolesError: null,
  }
};

const fetchRolesSuccess = (state, action) => {
  const {roles} = action;
  return {
    ...state,
    fetchingRoles: false,
    fetchingRolesError: null,
    roles
  }
};

const fetchRolesFail = (state, action) => {
  const {error} = action;
  return {
    ...state,
    fetchingRoles: true,
    fetchingRolesError: error
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ROLE_START:
      return createRoleStart(state, action);
    case actionTypes.CREATE_ROLE_SUCCESS:
      return createRoleSuccess(state, action);
    case actionTypes.CREATE_ROLE_FAIL:
      return createRoleFail(state, action);

    case actionTypes.FETCH_ROLES_START:
      return fetchRolesStart(state, action);
    case actionTypes.FETCH_ROLES_SUCCESS:
      return fetchRolesSuccess(state, action);
    case actionTypes.FETCH_ROLES_FAIL:
      return fetchRolesFail(state, action);

    case actionTypes.UPDATE_ROLE_START:
      return updateRoleStart(state, action);
    case actionTypes.UPDATE_ROLE_SUCCESS:
      return updateRoleSuccess(state, action);
    case actionTypes.UPDATE_ROLE_FAIL:
      return updateRoleFail(state, action);

    case actionTypes.DELETE_ROLE_START:
      return deleteRoleStart(state, action);
    case actionTypes.DELETE_ROLE_SUCCESS:
      return deleteRoleSuccess(state, action);
    case actionTypes.DELETE_ROLE_FAIL:
      return deleteRoleFail(state, action);

    default:
      return state;
  }
};

export default reducer;
