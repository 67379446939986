// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '../Button/Button';
import Spinner from '../Spinner/Spinner';
import Overlay from '../Overlay/Overlay';

type Props = {
  show: boolean,
  className?: string,
  loading?: boolean,
  error?: any,
  errorMessage?: string,
  width?: number,
  height?: number,
};

type modalHeaderProps = {
  title: string,
  onClose?: Function
}
export const ModalHeader = ({title, onClose}: modalHeaderProps) => {
  return (
    <div className="al-modal-dialog-header">
      {title && (
        <div className="al-modal-dialog-header-title">
          <FormattedMessage id={title} defaultMessage={title} />
        </div>
      )}
      <div className="al-modal-dialog-header-close">
        <Button btnType={'al-close'} clicked={onClose} />
      </div>
    </div>
  )
}

type modalContentProps = {
  children: React$Node,
  contentStyle?: Object,
}

export const ModalContent = ({contentStyle, children}: modalContentProps) => {
  return (
    <div className="al-modal-dialog-wrapper" style={contentStyle || {}}>{children}</div>
  )
}

type modalFooterProps = {
  children: React$Node,
}
export const ModalFooter = ({children}: modalFooterProps) => {
  return (<div className="al-modal-dialog-footer">{children}</div>)
}

function Modal({
  className,
  show,
  loading,
  errorMessage,
  error,
  children,
  width,
  height,
}: Props) {
  if (!show) {
    return null;
  }
  let content;
  if (loading) {
    content = <Spinner />;
  } else if (error) {
    content = <div className={'al-error-msg'}> {errorMessage}</div>;
  } else {
    content = children;
  }

  return (
    <div className={className || ''}>
      <Overlay>
        <div className="al-modal al-show">
          <div className="al-modal-dialog" style={{ width: `${width}px`, height: `${height}px` }}>
            {content}
          </div>
        </div>
      </Overlay>
    </div>
  );
}

export default Modal;
