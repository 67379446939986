import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { exportIssues, setStatus } from '../../../store/actions';
import { FormattedMessage } from 'react-intl';
import Button from '../../UI/Button/Button';
import { DOCUMENT_STATUS, EXPORT_TYPE } from '../../../constants/constants';
import Icon from '../../UI/Icon/Icon';

type Props = {
  approveAll: () => void,
  approvedIssueCount: number,
  document: Document,
  showComments: Object,
  contractConfig: any,
  history: any,
  showCompareOption: boolean,
  sortBy: string,
};

const ToolBar = ({
  approveAll,
  approvedIssueCount,
  document,
  showComments,
  contractConfig,
  history,
  showCompareOption,
  sortBy,
}: Props) => {
  const dispatch = useDispatch();
  const exportLoading = useSelector((state) => state.issues.exportLoading);
  const exportType = useSelector((state) => state.issues.exportType);
  const updateLoading = useSelector((state) => state.document.updateLoading);

  const exportIssuesTo = (exportType) => {
    dispatch(exportIssues(document.id, exportType, document.title, sortBy, window.location.origin));
  };

  const openCompareTab = () => {
    history.push(`/compareFindings/${document.id}`);
  };

  return (
    <div className="al-review-toolbar">
      <div className={`al-review-toolbar-sidebar-container`}>
        <div className="al-review-filename-container">
          <span className="al-review-filename">
            {contractConfig ? (
              <>
                {contractConfig.contractType === 'Others'
                  ? 'Unassigned'
                  : contractConfig.contractType}
                <Icon
                  icon={'keyboard-arrow-right'}
                  className={'al-icon'}
                  style={{ verticalAlign: 'middle' }}
                  show={true}
                />
              </>
            ) : (
              ''
            )}
            {document?.title !== null ? document.title : 'Unnamed'}
          </span>
          <Button
            withIcon={showComments.withIcon}
            iconType={showComments.icon}
            clicked={showComments.clicked}
            className="al-small"
            title={'Show comments'}
          />
        </div>
      </div>
      <div className="al-review-toolbar-buttons">
        {showCompareOption && (
          <Button
            clicked={openCompareTab}
            disabled={false}
            className="al-mr-20 "
            title={'Compare excerpts between two documents'}
          >
            Compare
          </Button>
        )}
        <Button
          clicked={approveAll}
          disabled={approvedIssueCount === 0}
          className="al-mr-20 "
          title={'Mark all findings as reviewed'}
          iconType={'checkbox-unchecked'}
        >
          <FormattedMessage id="app.review.approve" defaultMessage="Mark" />
        </Button>
        <Button
          disabled={updateLoading}
          clicked={() =>
            dispatch(
              setStatus(
                document.id,
                document.status !== DOCUMENT_STATUS.APPROVED
                  ? DOCUMENT_STATUS.APPROVED
                  : DOCUMENT_STATUS.WORKING,
              ),
            )
          }
          className="al-hide-icon al-mr-20"
          title={'Mark document as reviewed'}
          id={'markAsProgressed'}
          iconType={
            document.status === DOCUMENT_STATUS.APPROVED ? 'checkbox-checked' : 'checkbox-unchecked'
          }
        >
          {/* Reviewed */}
          <FormattedMessage id="app.review.reviewed" defaultMessage="Reviewed" />
        </Button>
        <Button
          disabled={exportLoading && exportType === EXPORT_TYPE.WORD}
          clicked={() => {
            exportIssuesTo(EXPORT_TYPE.WORD);
          }}
          className="al-hide-icon al-mr-20"
          id={'exportToWordButton'}
          iconType={'word'}
          title={'Export all findings to Word'}
        >
          <FormattedMessage id="app.review.export.word" defaultMessage="Export to " />
          <span style={{ textTransform: 'none' }}>{'Word'}</span>
        </Button>
        <Button
          disabled={exportLoading && exportType === EXPORT_TYPE.EXCEL}
          clicked={() => {
            exportIssuesTo(EXPORT_TYPE.EXCEL);
          }}
          className="al-hide-icon al-mr-20"
          id={'exportToExcelButton'}
          iconType={'excel'}
          title={'Export all findings to Excel'}
        >
          <FormattedMessage id="app.review.export.excel" defaultMessage="Export to " />
          <span style={{ textTransform: 'none' }}>{'Excel'}</span>
        </Button>
      </div>
    </div>
  );
};

export default ToolBar;
