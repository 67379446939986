import { axiosInstance as axios } from '../../services/axiosInstance';
import { EXPORT_TYPE, ISSUE_STATUS, ORIGIN } from '../../constants/constants';

import * as actionTypes from './actionType';
import * as FileSaver from 'file-saver';
import { uuid } from '../../services/idService';
import type { Excerpt } from '../../types';

export const fetchIssuesStart = (documentId) => {
  return {
    type: actionTypes.FETCH_ISSUE_START,
    documentId: documentId,
  };
};

export const fetchIssuesSuccess = (documentId, data, sortBy, forceUpdate) => {
  return {
    type: actionTypes.FETCH_ISSUE_SUCCESS,
    data: data,
    documentId: documentId,
    sortBy: sortBy,
    forceUpdate: forceUpdate,
  };
};

export const fetchIssuesFail = (error) => {
  return {
    type: actionTypes.FETCH_ISSUE_FAIL,
    error: error,
  };
};

export const fetchIssues = (
  documentId,
  debug = false,
  sortingBy = { fieldName: 'excerptType.keyword', asc: true },
  forceUpdate,
  excerptId,
) => {
  return (dispatch) => {
    dispatch(fetchIssuesStart(documentId));
    axios
      .get(`/api/excerpts/`, {
        params: {
          docId: documentId,
          sortingBy: sortingBy,
        },
      })
      .then((response) => {
        dispatch(fetchIssuesSuccess(documentId, response.data, sortingBy, forceUpdate));
      })
      .catch((err) => {
        dispatch(fetchIssuesFail(err));
      });
  };
};

export const updateIssuesStart = (issueId) => {
  return {
    type: actionTypes.UPDATE_ISSUE_START,
    issueId: issueId,
  };
};

export const updateIssuesSuccess = (issueId, data) => {
  return {
    type: actionTypes.UPDATE_ISSUE_SUCCESS,
    data: data,
    issueId: issueId,
  };
};

export const updateIssuesFail = (issueId, error) => {
  return {
    type: actionTypes.UPDATE_ISSUE_FAIL,
    error: error,
    issueId: issueId,
  };
};

const updateStatisticsByTypeSuccess = (updatedExcerpt) => {
  return {
    type: actionTypes.UPDATE_STATISTICS_BY_TYPE_SUCCESS,
    updatedExcerpt,
  };
};

export const updateIssue = (issueId, data) => {
  return (dispatch) => {
    dispatch(updateIssuesStart(issueId));
    axios
      .post('/api/excerpts/update/' + issueId, data)
      .then((response) => {
        dispatch(updateIssuesSuccess(issueId, response.data));
      })
      .catch((err) => {
        dispatch(updateIssuesFail(issueId, err));
      });
  };
};

export const startUpdate = () => {
  return {
    type: actionTypes.START_UPDATE,
  };
};

export const deleteIssuesStart = (issueId) => {
  return {
    type: actionTypes.DELETE_ISSUE_START,
    issueId: issueId,
  };
};

export const deleteIssuesSuccess = (issueId, data) => {
  return {
    type: actionTypes.DELETE_ISSUE_SUCCESS,
    data: data,
    issueId: issueId,
  };
};

export const deleteIssuesFail = (issueId, error) => {
  return {
    type: actionTypes.DELETE_ISSUE_FAIL,
    error: error,
    issueId: issueId,
  };
};

export const recognizeIssueStart = (issueId) => {
  return {
    type: actionTypes.RECOGNIZE_ISSUE_START,
    issueId: issueId,
  };
};

export const recognizeIssueSuccess = (issueId, data, isCorrect) => {
  return {
    type: actionTypes.RECOGNIZE_ISSUE_SUCCESS,
    data: data,
    issueId: issueId,
    isCorrect,
  };
};

export const recognizeIssueFail = (issueId, error) => {
  return {
    type: actionTypes.RECOGNIZE_ISSUE_FAIL,
    error: error,
    issueId: issueId,
  };
};

export const deleteIssue = (issueId) => {
  return (dispatch) => {
    dispatch(deleteIssuesStart(issueId));
    axios
      .delete('/api/excerpts/' + issueId)
      .then((response) => {
        dispatch(deleteIssuesSuccess(issueId, response.data));
      })
      .catch((err) => {
        dispatch(deleteIssuesFail(issueId, err));
      });
  };
};

export const recognizeIssue = (issueId, isCorrect, useForTraining) => {
  let recognize = isCorrect ? 'correct' : 'incorrect';
  return (dispatch) => {
    dispatch(recognizeIssueStart(issueId));
    axios
      .post(`/api/excerpts/${recognize}/${useForTraining ? 'train/' : ''}${issueId}`)
      .then((response) => {
        dispatch(recognizeIssueSuccess(issueId, response.data, isCorrect));
      })
      .catch((err) => {
        dispatch(recognizeIssueFail(issueId, err));
      });
  };
};

export const approveAllStart = () => {
  return {
    type: actionTypes.APPROVE_ALL_ISSUES_START,
  };
};

export const approveAllSuccess = (data) => {
  return {
    type: actionTypes.APPROVE_ALL_ISSUES_SUCCESS,
    data: data,
  };
};

export const approveAllFail = (error) => {
  return {
    type: actionTypes.APPROVE_ALL_ISSUES_FAIL,
    error: error,
  };
};

export const approveAll = (docId, issueIds) => {
  return (dispatch) => {
    dispatch(approveAllStart());
    axios
      .post(`/api/excerpts/approve/${docId}`, {
        ids: issueIds,
        fields: { status: ISSUE_STATUS.APPROVED },
      })
      .then((response) => {
        dispatch(approveAllSuccess(response.data));
      })
      .catch((err) => {
        dispatch(approveAllFail(err));
      });
  };
};

export const createIssuesStart = (tempId) => {
  return {
    type: actionTypes.CREATE_ISSUE_START,
    tempId,
  };
};

export const createIssuesSuccess = (data, tempId, sortBy) => {
  return {
    type: actionTypes.CREATE_ISSUE_SUCCESS,
    data,
    tempId,
    sortBy,
  };
};

export const createIssuesFail = (tempId, error) => {
  return {
    type: actionTypes.CREATE_ISSUE_FAIL,
    error: error,
    tempId,
  };
};

export const createIssue = (data, useForTraining, sortBy?) => {
  return (dispatch) => {
    let tempId = uuid();
    if (typeof data.excerptId === 'undefined') {
      dispatch(createIssuesStart(tempId));
    }
    axios
      .post(`/api/excerpts/create${useForTraining ? '/train' : ''}`, data)
      .then((response) => {
        if (typeof data.excerptId === 'undefined') {
          dispatch(createIssuesSuccess(response.data, tempId, sortBy));
        } else {
          dispatch(trainIssuesSuccess(data));
        }
      })
      .catch((err) => {
        if (typeof data.excerptId === 'undefined') {
          dispatch(createIssuesFail(tempId, err));
        } else {
          dispatch(trainIssueFail(data, err, err.response?.status));
        }
      });
  };
};

export const fetchExcerptByIdStart = (id) => {
  return {
    type: actionTypes.FETCH_EXCERPT_BY_ID_START,
    id,
  };
};

export const fetchExcerptByIdSuccess = (data, id) => {
  return {
    type: actionTypes.FETCH_EXCERPT_BY_ID_SUCCESS,
    data,
    id,
  };
};

export const fetchExcerptByIdFail = (id, error) => {
  return {
    type: actionTypes.FETCH_EXCERPT_BY_ID_FAIL,
    error: error,
    id,
  };
};

export const fetchExcerptById = (id) => {
  return (dispatch) => {
    dispatch(fetchExcerptByIdStart(id));
    axios
      .get(`/api/excerpts/${id}`)
      .then((response) => {
        dispatch(fetchExcerptByIdSuccess(response.data, id));
      })
      .catch((err) => {
        dispatch(fetchExcerptByIdFail(id, err));
      });
  };
};

const trainIssuesSuccess = (excerpt: Excerpt) => {
  return {
    type: actionTypes.TRAIN_ISSUE_SUCCESS,
    excerpt,
  };
};

const trainIssueFail = (excerpt: Excerpt, error, errorStatus) => {
  return {
    type: actionTypes.TRAIN_ISSUE_FAIL,
    error,
    excerpt,
    showCustomError: true,
    errorHeader: 'Error',
    errorBody:
      errorStatus === 409
        ? `The system was already trained from "${excerpt.excerptType}" finding`
        : `Couldn't train the system from "${excerpt.excerptType}" finding`,
  };
};

export const bootStrapStaticStart = (type) => {
  return {
    type: actionTypes.BOOTSTRAP_STATICS_ISSUES_START,
    excerptType: type,
  };
};

export const bootStrapStaticSuccess = (excerptType, projectId, contractId, data) => {
  return {
    type: actionTypes.BOOTSTRAP_STATICS_ISSUES_SUCCESS,
    excerptType,
    projectId,
    contractId,
    data: data,
  };
};

export const bootStrapStaticFail = (excerptType, projectId, contractId, err) => {
  return {
    type: actionTypes.BOOTSTRAP_STATICS_ISSUES_FAIL,
    excerptType,
    projectId,
    contractId,
    error: err,
  };
};

export const bootStrapStatic = (type, projectId, contractId) => {
  return (dispatch) => {
    dispatch(bootStrapStaticStart(type, projectId, contractId));
    axios
      .post('api/excerpts/bootstrap/', null, {
        params: {
          type,
          projectId,
          contractId,
        },
      })
      .then((response) => {
        dispatch(bootStrapStaticSuccess(type, projectId, contractId, response.data));
      })
      .catch((err) => {
        dispatch(bootStrapStaticFail(type, projectId, contractId, err));
      });
  };
};

export const exportIssuesStart = (type) => {
  return {
    type: actionTypes.EXPORT_ISSUES_DOCUMENT_START,
    exportType: type,
  };
};

export const exportIssuesSuccess = () => {
  return {
    type: actionTypes.EXPORT_ISSUES_DOCUMENT_SUCCESS,
  };
};

export const exportIssuesFail = (error) => {
  return {
    type: actionTypes.EXPORT_ISSUES_DOCUMENT_FAIL,
    error: error,
  };
};

export const exportIssues = (
  docId,
  type,
  title,
  sortingBy = { fieldName: '', asc: false },
  baseUrl,
) => {
  return (dispatch) => {
    let parameters = [];
    parameters.push('format=' + type);
    parameters.push('baseUrl=' + baseUrl);
    dispatch(exportIssuesStart(type));
    if (sortingBy.fieldName !== '') {
      parameters.push('fieldName=' + sortingBy.fieldName);
      parameters.push('asc=' + sortingBy.asc);
    }
    let url =
      '/api/excerpts/export/' +
      type +
      '/' +
      docId +
      (parameters.length ? '?' + parameters.join('&') : '');
    axios
      .get(url, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const extension = type === EXPORT_TYPE.WORD ? '.docx' : '.xlsx';
        let blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        FileSaver.saveAs(blob, title + extension);
        /*FileSaver.saveAs(response.data, docId);*/
        dispatch(exportIssuesSuccess());
      })
      .catch((err) => {
        dispatch(exportIssuesFail(err));
      });
  };
};
