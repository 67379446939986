import * as React from "react";
export const PermissionsContext = React.createContext(
// default values used by a Consumer when it does not have a
// matching Provider above it in the tree, useful for testing
  {
    PermissionsContext: {
      userPermissions: [],
      userRoles: [],
      hasPermission: (permission: string) => {}
    }
  }
);
