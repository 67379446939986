// @flow
import { axiosInstance as axios } from '../../services/axiosInstance';

import * as actionTypes from './actionType';
import { Dispatch } from 'redux';
import { buildExcerptDict, buildExcerptDiffResult } from '../../services/compareFindingsService';
import type { FetchDiffParams } from '../../types';

export const fetchDiffStart = (params: FetchDiffParams) => {
  return {
    type: actionTypes.FETCH_DIFF_START,
    params,
  };
};

export const fetchDiffSuccess = (params: FetchDiffParams, data: any) => {
  return {
    ...params,
    type: actionTypes.FETCH_DIFF_SUCCESS,
    data,
  };
};

export const fetchDiffFail = (error: string) => {
  return {
    type: actionTypes.FETCH_DIFF_FAIL,
    error,
  };
};

const apiGetExcerpts = (docId) =>
  axios
    .get(`/api/excerpts/`, {
      params: {
        docId: docId,
        sortingBy: { fieldName: 'excerptType.keyword', asc: true },
      },
    })
    .then((response) => response.data);

const apiGetDatabaseExcerpts = (projectId) =>
  axios
    .get(`/api/compare/finding-type/${projectId}`, {
      params: {
        debug: true,
        sortingBy: { fieldName: 'excerptType.keyword', asc: true },
      },
    })
    .then((response) => response.data);

export const fetchDiff = (
  { referenceFile, comparedFile }: FetchDiffParams,
  isUsingCompareDatabase,
  projectId,
) => async (dispatch: Dispatch) => {
  dispatch(fetchDiffStart({ referenceFile, comparedFile }));
  //TODO bundle calls and diff calc in backend
  const promises = [
    !isUsingCompareDatabase ? apiGetExcerpts(referenceFile.id) : apiGetDatabaseExcerpts(projectId),
    apiGetExcerpts(comparedFile.id),
  ];
  try {
    let [referenceExcerpts, comparedExcerpts] = await Promise.all(promises); // responses will be an array
    let [referenceData, comparedData] = [
      {
        id: referenceFile?.id,
        file: referenceFile,
        excerpts: referenceExcerpts,
      },
      { id: comparedFile.id, file: comparedFile, excerpts: comparedExcerpts },
    ];
    const referenceExcerptMap = buildExcerptDict(referenceExcerpts);
    const comparedExcerptMap = buildExcerptDict(comparedExcerpts);
    const diffResult = buildExcerptDiffResult(referenceData, comparedData);

    dispatch(
      fetchDiffSuccess(
        { referenceFile, comparedFile },
        { diffResult, referenceExcerptMap, comparedExcerptMap },
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(fetchDiffFail(err));
  }
};

export const toggleUseCompareDatabase = (isUsingDB) => {
  return {
    type: actionTypes.SET_IS_USING_COMPARE_DATABASE,
    isUsingDB,
  };
};

export const selectReferenceFile = (referenceFile) => {
  return {
    type: actionTypes.SET_REFERENCE_FILE,
    referenceFile,
  };
};

export const selectComparedFile = (comparedFile) => {
  return {
    type: actionTypes.SET_COMPARED_FILE,
    comparedFile,
  };
};

export const resetReferenceFile = () => {
  return {
    type: actionTypes.RESET_REFERENCE_FILE,
  };
};

export const resetComparedFile = () => {
  return {
    type: actionTypes.RESET_COMPARED_FILE,
  };
};

export const fetchDiffTypesStart = () => ({
  type: actionTypes.FETCH_DIFF_TYPES_START,
});

export const fetchDiffTypesSuccess = (data) => ({
  type: actionTypes.FETCH_DIFF_TYPES_SUCCESS,
  data,
});

export const fetchDiffTypesFail = (error: any) => ({
  type: actionTypes.FETCH_DIFF_TYPES_FAIL,
  error,
});

export const fetchDiffTypes = (referenceFileId, comparedFileId) => {
  return (dispatch) => {
    dispatch(fetchDiffTypesStart());
    axios
      .get(`/api/compare/docs-type-diff/${referenceFileId}/${comparedFileId}/`, null)
      .then((response) => {
        dispatch(fetchDiffTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchDiffTypesFail(err));
      });
  };
};

export const fetchDiffDatabaseTypes = (projectId, compareFileId) => {
  return (dispatch) => {
    dispatch(fetchDiffTypesStart());
    axios
      .get(`/api/compare/fixed-type-doc-diff/${projectId}/${compareFileId}`, null)
      .then((response) => {
        dispatch(fetchDiffTypesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchDiffTypesFail(err));
      });
  };
};

export const fetchDBFindingsStart = () => ({
  type: actionTypes.FETCH_COMPARE_DB_FINDINGS_START,
});

export const fetchDBFindingsSuccess = (data) => ({
  type: actionTypes.FETCH_COMPARE_DB_FINDINGS_SUCCESS,
  data,
});

export const fetchDBFindingsFail = (error: any) => ({
  type: actionTypes.FETCH_COMPARE_DB_FINDINGS_FAIL,
  error,
});

export const fetchDBFindings = (projectId) => {
  return (dispatch) => {
    dispatch(fetchDBFindingsStart());
    axios
      .get(`/api/compare/finding-type/${projectId}`)
      .then((response) => {
        dispatch(fetchDBFindingsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchDBFindingsFail(err));
      });
  };
};

export const createDBFindingStart = () => ({
  type: actionTypes.CREATE_COMPARE_DB_FINDING_START,
});

export const createDBFindingSuccess = (data) => ({
  type: actionTypes.CREATE_COMPARE_DB_FINDING_SUCCESS,
  data,
});

export const createDBFindingFail = (error: any) => ({
  type: actionTypes.CREATE_COMPARE_DB_FINDING_FAIL,
  error,
});

export const createDBFinding = (projectId, excerptType, bodyHTML) => {
  return (dispatch) => {
    dispatch(createDBFindingStart());
    const compareFindingType = {
      bodyHTML: bodyHTML,
      excerptType: excerptType,
      projectId: projectId,
    };
    axios
      .post(`/api/compare/finding-type`, compareFindingType)
      .then((response) => {
        dispatch(createDBFindingSuccess(response.data));
        dispatch(fetchDBFindings(projectId));
      })
      .catch((err) => {
        dispatch(createDBFindingFail(err));
      });
  };
};

export const updateDBFindingStart = () => ({
  type: actionTypes.UPDATE_COMPARE_DB_FINDINGS_START,
});

export const updateDBFindingSuccess = (data) => ({
  type: actionTypes.UPDATE_COMPARE_DB_FINDINGS_SUCCESS,
  data,
});

export const updateDBFindingFail = (error: any) => ({
  type: actionTypes.UPDATE_COMPARE_DB_FINDINGS_FAIL,
  error,
});

export const updateDBFinding = (findingId, projectId, excerptType, bodyHTML) => {
  return (dispatch) => {
    dispatch(updateDBFindingStart());
    const compareFindingType = {
      id: findingId,
      bodyHTML: bodyHTML,
      excerptType: excerptType,
      projectId: projectId,
    };
    axios
      .put(`/api/compare/finding-type`, compareFindingType)
      .then((response) => {
        dispatch(updateDBFindingSuccess(response.data));
        dispatch(fetchDBFindings(projectId));
      })
      .catch((err) => {
        dispatch(updateDBFindingFail(err));
      });
  };
};

export const deleteDBFinding = (findingId, projectId) => {
  return (dispatch) => {
    dispatch(updateDBFindingStart());
    axios
      .delete(`/api/compare/finding-type/${findingId}`)
      .then((response) => {
        dispatch(updateDBFindingSuccess(response.data));
        dispatch(fetchDBFindings(projectId));
      })
      .catch((err) => {
        dispatch(updateDBFindingFail(err));
      });
  };
};
