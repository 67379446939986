import { Component } from 'react';
import config from './config';

export default class SwaggerUiRoute extends Component {
  componentDidMount() {
    window.location.href = config.apiGateway.URL + '/swagger-ui.html';
  }

  render() {
    return null;
  }
}
