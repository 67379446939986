import React, { useEffect, useState } from 'react';
import { usePrevious } from '../../hooks/prevValue';
import logoIntrafind from '../../assets/images/IntraFind_Logo.png';
import logoIntrafind_small from '../../assets/images/IntraFind_Logo_small.png';
import _ from 'lodash';

const tagLine = 'Document Analyzer - ...finds what is essential for decision making.';

const Logo = ({ globalSettings }) => {
  const [maxLogoFormat, setMaxLogoFormat] = useState('');
  const [desktopLogoSrc, setDesktopLogoSrc] = useState(logoIntrafind);
  const [desktopLogoTitle, setDesktopLogoTitle] = useState(tagLine);
  const [minLogoFormat, setMinLogoFormat] = useState('');
  const [mobileLogoSrc, setMobileLogoSrc] = useState(logoIntrafind_small);
  const [mobileLogoTitle, setMobileLogoTitle] = useState(tagLine);

  const prevCustomCSS = usePrevious(globalSettings.css);

  useEffect(() => {
    if (Object.keys(globalSettings).length > 0) {
      let customLogoMax = null;
      let customLogoMin = null;
      let onlyMaxCustomLogo = false;
      let onlyMinCustomLogo = false;

      for (const logo of globalSettings.logo) {
        if (logo.imageStat === 'max') {
          customLogoMax = logo;
        } else if (logo.imageStat === 'min') {
          customLogoMin = logo;
        }
      }

      if (customLogoMax && !customLogoMin) {
        onlyMaxCustomLogo = true;
      } else if (customLogoMin && !customLogoMax) {
        onlyMinCustomLogo = true;
      }

      if (customLogoMax || customLogoMin) {
        setMaxLogoFormat('data:image/png;base64,');
        setMinLogoFormat('data:image/png;base64,');
        if (onlyMaxCustomLogo) {
          setDesktopLogoSrc(customLogoMax.picByte);
          setDesktopLogoTitle(customLogoMax.title);
          setMobileLogoSrc(customLogoMax.picByte);
          setMobileLogoTitle(customLogoMax.title);
        } else {
          setMobileLogoSrc(customLogoMin.picByte);
          setMobileLogoTitle(customLogoMin.title);
        }

        if (onlyMinCustomLogo) {
          setDesktopLogoSrc(customLogoMin.picByte);
          setDesktopLogoTitle(customLogoMin.title);
          setMobileLogoSrc(customLogoMin.picByte);
          setMobileLogoTitle(customLogoMin.title);
        } else {
          setDesktopLogoSrc(customLogoMax.picByte);
          setDesktopLogoTitle(customLogoMax.title);
        }
      }

      if (!customLogoMax && !customLogoMin) {
        setMaxLogoFormat('');
        setDesktopLogoSrc(logoIntrafind);
        setDesktopLogoTitle(tagLine);
        setMinLogoFormat('');
        setMobileLogoSrc(logoIntrafind_small);
        setDesktopLogoTitle(tagLine);
      }

      if (!_.isEqual(prevCustomCSS, globalSettings.css)) {
        const oldCustomSheet = document.querySelector('[data-id=custom]');
        if (oldCustomSheet) {
          const customSheetParent = oldCustomSheet.parentNode;
          customSheetParent.removeChild(oldCustomSheet);
        }

        // create CSS rules from text
        let styleElement = document.createElement('style');
        styleElement.innerHTML = globalSettings.css;
        styleElement.setAttribute('data-id', 'custom');
        document.body.appendChild(styleElement);
      }
    }
    // eslint-disable-next-line
  }, [globalSettings]);

  return (
    <div className={'al-logo'}>
      <img
        className={'desktop-logo'}
        id={'logo'}
        src={`${maxLogoFormat} ${desktopLogoSrc}`}
        alt={desktopLogoTitle}
      />

      <img
        className={'mobile-logo'}
        id={'mobile-logo'}
        src={`${minLogoFormat} ${mobileLogoSrc}`}
        alt={mobileLogoTitle}
      />
    </div>
  );
};

export default Logo;
