// @flow
import React, { useState, forwardRef } from 'react';
import Button from '../UI/Button/Button';
import Input from '../UI/Input/Input';
import HotKeys from '../UI/HotKeys/HotKeys';
import Warning from '../UI/Typo/Warning';
import Modal, { ModalHeader, ModalContent, ModalFooter } from '../UI/Modal/Modal';
import { normalizeWhitespaces } from '../../services/validation';

type Props = {
  name?: string,
  onSubmit: (name: string) => void,
  onCancel: () => void,
  placeHolder: string,
  getValidationError: (name: string) => any,
  modalTitle: string,
};

function NamingForm({
  onSubmit,
  onCancel,
  name: initialName,
  placeHolder,
  getValidationError,
  modalTitle,
}: Props) {
  let editModal = React.createRef();
  const [name, setName] = useState(initialName || '');
  const save = () => {
    const normalizedName = normalizeWhitespaces(name);
    onSubmit(normalizedName);
  };

  let editContractType = {
    Enter: save,
    Escape: onCancel,
  };
  const normalizedName = normalizeWhitespaces(name);
  const validationError = getValidationError(normalizedName);

  return (
    <Modal show={true}>
      <ModalHeader onClose={onCancel} title={modalTitle} />
      <ModalContent>
        <div ref={editModal} className={'namingForm'}>
          <HotKeys outerRef={editModal.current} keyPressMap={editContractType}>
            <Input
              focus={true}
              key={'input'}
              placeholder={placeHolder}
              className={'al-input-name'}
              elementType={'input'}
              value={name}
              changed={(event) => {
                setName(event.target.value);
              }}
              elementConfig={{ type: 'text' }}
              keyPressMap={editContractType}
            />
            {!!validationError && <Warning>{validationError.get('message')}</Warning>}
          </HotKeys>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          disabled={initialName === normalizedName || !normalizedName.length || validationError}
          btnType={'al-primary'}
          clicked={save}
        >
          Save
        </Button>
        <Button bnType className={'cancel'} clicked={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default forwardRef(NamingForm);
