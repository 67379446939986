// @flow
import * as actionTypes from '../actions/actionType';
import type { CompareState } from '../../types';

const initialState: CompareState = {
  referenceFile: null,
  comparedFile: null,
  referenceExcerptMap: null,
  comparedExcerptMap: null,
  diffResult: null,
  error: false,
  loading: false,
  success: false,
  diffTypesResult: {},
  loadingDiffTypes: false,
  errorDiffTypes: null,
  compareDatabaseFindings: [],
  loadingDBFindings: false,
  errorDBFindings: null,
  diffTypesDatabase: {},
  isUsingCompareDatabase: true,
  creatingDBFinding: false,
  creatingDBFindingError: null,
};

const compare = (state: CompareState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FETCH_DIFF_START:
      return fetchDiffStart(state, action);
    case actionTypes.FETCH_DIFF_SUCCESS:
      return fetchDiffSuccess(state, action);
    case actionTypes.FETCH_DIFF_FAIL:
      return fetchDiffFail(state, action);
    case actionTypes.SET_IS_USING_COMPARE_DATABASE:
      return toggleIsUsingCompareDB(state, action);
    case actionTypes.SET_REFERENCE_FILE:
      return selectReferenceFile(state, action);
    case actionTypes.SET_COMPARED_FILE:
      return selectComparedFile(state, action);
    case actionTypes.RESET_REFERENCE_FILE:
      return resetReferenceFile(state);
    case actionTypes.RESET_COMPARED_FILE:
      return resetComparedFile(state);
    case actionTypes.FETCH_DIFF_TYPES_START:
      return fetchDiffTypesStart(state, action);
    case actionTypes.FETCH_DIFF_TYPES_SUCCESS:
      return fetchDiffTypesSuccess(state, action);
    case actionTypes.FETCH_DIFF_TYPES_FAIL:
      return fetchDiffTypesFail(state, action);
    case actionTypes.FETCH_COMPARE_DB_FINDINGS_START:
      return fetchCompareDBFindingsStart(state, action);
    case actionTypes.FETCH_COMPARE_DB_FINDINGS_SUCCESS:
      return fetchCompareDBFindingsSuccess(state, action);
    case actionTypes.FETCH_COMPARE_DB_FINDINGS_FAIL:
      return fetchCompareDBFindingsFail(state, action);
    case actionTypes.CREATE_COMPARE_DB_FINDING_START:
      return createDBFindingStart(state, action);
    case actionTypes.CREATE_COMPARE_DB_FINDING_SUCCESS:
      return createDBFindingSuccess(state, action);
    case actionTypes.CREATE_COMPARE_DB_FINDING_FAIL:
      return createDBFindingFail(state, action);
    case actionTypes.UPDATE_COMPARE_DB_FINDINGS_START:
      return updateCompareDBFindingsStart(state, action);
    case actionTypes.UPDATE_COMPARE_DB_FINDINGS_SUCCESS:
      return updateCompareDBFindingsSuccess(state, action);
    case actionTypes.UPDATE_COMPARE_DB_FINDINGS_FAIL:
      return updateCompareDBFindingsFail(state, action);
    default:
      return state;
  }
};

const fetchDiffStart = (state: CompareState, action) => {
  const { referenceFile, comparedFile } = action;
  return {
    ...state,
    loading: true,
    success: false,
    referenceFile,
    comparedFile,
  };
};

const fetchDiffSuccess = (state, action) => {
  const { referenceFile, comparedFile, data } = action;
  const { referenceExcerptMap, comparedExcerptMap, diffResult } = data;

  return {
    ...state,
    loading: false,
    error: false,
    success: true,
    referenceFile,
    comparedFile,
    referenceExcerptMap,
    comparedExcerptMap,
    diffResult,
  };
};

const fetchDiffFail = (state, action) => {
  return { ...state, error: true, loading: false, success: false };
};

const toggleIsUsingCompareDB = (state, action) => {
  return {
    ...state,
    isUsingCompareDatabase: action.isUsingDB,
  };
};

const selectReferenceFile = (state, action) => {
  return {
    ...state,
    referenceFile: action.referenceFile,
  };
};

const selectComparedFile = (state, action) => {
  return {
    ...state,
    comparedFile: action.comparedFile,
  };
};

const resetReferenceFile = (state) => {
  return {
    ...state,
    referenceFile: null,
    referenceExcerptMap: null,
    diffResult: null,
    diffTypesResult: {},
  };
};

const resetComparedFile = (state) => {
  return {
    ...state,
    comparedFile: null,
    comparedExcerptMap: null,
    diffResult: null,
    diffTypesResult: {},
  };
};

const fetchDiffTypesStart = (state) => {
  return {
    ...state,
    loadingDiffTypes: true,
    errorDiffTypes: null,
  };
};

const fetchDiffTypesSuccess = (state, action) => {
  return {
    ...state,
    diffTypesResult: action.data,
    loadingDiffTypes: false,
  };
};

const fetchDiffTypesFail = (state, action) => {
  return {
    ...state,
    loadingDiffTypes: false,
    errorDiffTypes: action.error,
  };
};

const fetchCompareDBFindingsStart = (state) => {
  return {
    ...state,
    loadingDBFindings: true,
    errorDBFindings: null,
  };
};

const fetchCompareDBFindingsSuccess = (state, action) => {
  return {
    ...state,
    compareDatabaseFindings: action.data,
    loadingDBFindings: false,
  };
};

const fetchCompareDBFindingsFail = (state, action) => {
  return {
    ...state,
    loadingDBFindings: false,
    errorDBFindings: action.error,
  };
};

const createDBFindingStart = (state) => {
  return {
    ...state,
    creatingDBFinding: true,
    creatingDBFindingError: null,
  };
};

const createDBFindingSuccess = (state) => {
  return {
    ...state,
    creatingDBFinding: false,
  };
};

const createDBFindingFail = (state, action) => {
  return {
    ...state,
    creatingDBFinding: false,
    creatingDBFindingError: action.error,
  };
};

const updateCompareDBFindingsStart = (state) => {
  return {
    ...state,
    loadingDBFindings: true,
    errorDBFindings: null,
  };
};

const updateCompareDBFindingsSuccess = (state) => {
  return {
    ...state,
    loadingDBFindings: false,
  };
};

const updateCompareDBFindingsFail = (state, action) => {
  return {
    ...state,
    loadingDBFindings: false,
    errorDBFindings: action.error,
  };
};

export default compare;
