import React, { useEffect, useState } from 'react';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import type { DynamicTab } from '../../types';
import type { AppState } from '../../types/reduxTypes';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';

type Props = {
  addDynamicTab: (dynamicTab: DynamicTab) => void,
  updateDynamicTab: (dynamicTab: DynamicTab) => void,
  deleteDynamicTab: (id: string) => void,
  dynamicTabs: Array<DynamicTab>,
};

const DynamicTabs = ({
  addDynamicTab,
  deleteDynamicTab,
  creatingDynamicTabs,
  dynamicTabs,
} = Props) => {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (creatingDynamicTabs) {
      setName('');
      setUrl('');
    }
  }, [creatingDynamicTabs]);

  const createDynamicTab = () => {
    addDynamicTab({ name, url });
  };

  return (
    <div className={'al-container'}>
      <label className={'al-headline'}>Dynamic Tabs</label>
      <div
        style={{
          display: 'grid',
          gridColumn: 1 / 6,
          justifyContent: 'start',
          columnGap: '25px',
          gridTemplateColumns: '86px 198px 20px 40px 300px 40px 100px',
        }}
      >
        <span style={{ gridColumn: 1, padding: '5px 0px' }}>Tab Name:</span>
        <div style={{ gridColumn: 2, width: '100%' }}>
          <Input
            value={name}
            changed={e => setName(e.target.value)}
            elementType={'input'}
            elementConfig={{ type: 'text' }}
          />
        </div>
        <div style={{ gridColumn: 3 }} />
        <span style={{ gridColumn: 4, padding: '5px' }}>URL:</span>
        <div style={{ gridColumn: 5, width: '100%' }}>
          <Input
            value={url}
            changed={e => setUrl(e.target.value)}
            elementType={'input'}
            elementConfig={{ type: 'text' }}
          />
        </div>
        <div style={{ gridColumn: 6 }} />
        <div style={{ gridColumn: 7 }}>
          <Button
            className={'al-primary'}
            disabled={name.trim() === '' || url.trim() === ''}
            clicked={createDynamicTab}
          >
            {'Add new tab'}
          </Button>
        </div>
      </div>
      {dynamicTabs.length > 0 && (
        <div className={'al-projectList'}>
          {dynamicTabs.map(dynamicTab => {
            return (
              <div className={'al-projectListItem'} key={dynamicTab.id}>
                <div className={'al-projectListItem-header'}>
                  <span className={'al-project-title'}>{dynamicTab.name}</span>
                  <a href={dynamicTab.url} target={'_blank'} className={'al-project-title'}>
                    {dynamicTab.url}
                  </a>
                  <span className={'al-projectListItem-actions'}>
                    <Button
                      withIcon={true}
                      className={'editProject'}
                      iconType={'edit'}
                      clicked={e => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      show={false}
                    />

                    <Button
                      withIcon={true}
                      className={'deleteProject'}
                      iconType={'delete'}
                      clicked={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        deleteDynamicTab(dynamicTab.id);
                      }}
                    />
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    dynamicTabs: state.dynamicTabs.dynamicTabs,
    creatingDynamicTabs: state.dynamicTabs.creatingDynamicTabsState,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addDynamicTab: (dynamicTab: DynamicTab) => dispatch(actions.addDynamicTab(dynamicTab)),
    updateDynamicTab: (dynamicTab: DynamicTab) => dispatch(actions.updateDynamicTab(dynamicTab)),
    deleteDynamicTab: (id: string) => dispatch(actions.deleteDynamicTab(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DynamicTabs);
