import * as React from 'react';
import { PermissionsContext } from './permissions-context';

export function withPermissions(Component) {
  return function ThemeComponent(props) {
    return (
      <PermissionsContext.Consumer>
        {(contexts) => <Component {...props} {...contexts} />
        }
      </PermissionsContext.Consumer>
    )
  }
}


