// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import HelpModal from '../../components/Help/HelpModal';
import HotKeys from '../../components/UI/HotKeys/HotKeys';
import UL from '../../components/UI/UL/UL';
import Button from '../../components/UI/Button/Button';
import CustomTooltip from '../../components/UI/Tooltip/Tooltip';
import DropDown from '../../components/UI/DropDown/DropDown';
import type { ReactRefT } from '../../types';
import type { Document } from '../../types/reduxTypes';
import ProjectSelect from '../../components/Projects/ProjectSelect';
import { withPermissions } from '../../contexts/PermissionsContext/withPermissions';
import { PERMISSIONS } from '../../constants/constants';
import Logo from './Logo';
import { changeLanguage, logout } from '../../store/actions/index';

const ACTIVE_CLASS_NAME = 'al-active';

const Header = ({ globalSettings, PermissionsContext }) => {
  const dispatch = useDispatch();
  const history: { push: Function } = useHistory();
  const isAuthenticated: boolean = localStorage.getItem('token') !== null;
  const lang: string = useSelector((state) => state.locale.lang);
  const document: Document = useSelector((state) => state.document.data);
  const projectId: string = useSelector((state) => state.project.projectId);
  const hasUserRoles: boolean = useSelector((state) => state.auth.roles?.length > 0);
  const dynamicTabs: any = useSelector((state) => state.dynamicTabs.dynamicTabs);
  const currentLocation: string = useSelector((state) => state.locationPathName.currentLocation);

  const getClassName = (path: string, excludedPaths?: Array<string>) => {
    const containsExcludedPaths =
      excludedPaths?.filter((excluded) => window.location.pathname.includes(excluded)).length > 0;
    if (window.location.pathname.includes(path) && !containsExcludedPaths) {
      return ACTIVE_CLASS_NAME;
    }
    return '';
  };

  const [controls, setControls] = useState({
    /*languageSelect: {
      elementType: 'select',
      elementConfig: {
        options: [
          {
            value: 'en',
            displayValue: 'English',
          },
          {
            value: 'de',
            displayValue: 'German',
          },
        ],
      },
    },*/
    helpModal: {
      key: 'helpModal',
      open: false,
    },
    dropDowns: {
      settings: {
        show: false,
        actions: {
          general: {
            key: 'general',
            label: 'General',
            show: PermissionsContext.hasPermission(PERMISSIONS.SHOW_GENERAL),
            click: () => navigateTo('/settings/general'),
            className: getClassName('/general'),
          },
          projects: {
            key: 'projects',
            label: 'Projects',
            show: PermissionsContext.hasPermission(PERMISSIONS.SHOW_PROJECTS),
            click: () => navigateTo('/settings/projects'),
            className: getClassName('/projects', ['/contractTypes']),
          },
          contractTypes: {
            key: 'contractTypes',
            label: 'Document types',
            show: PermissionsContext.hasPermission(PERMISSIONS.SHOW_CONTRACT_TYPES),
            click: () => navigateTo(`/settings/projects/${projectId}/contractTypes`),
            className: getClassName('/contractTypes'),
          },
          dynamicTabs: {
            key: 'dynamicTabs',
            label: 'Dynamic tabs',
            show: PermissionsContext.hasPermission(PERMISSIONS.SHOW_DYNAMIC_TABS),
            click: () => navigateTo('/settings/dynamicTabs'),
            className: getClassName('/dynamicTabs'),
          },
          searchQueries: {
            key: 'searchQueries',
            label: 'Search queries',
            show:
              PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_POST) ||
              PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_PUT) ||
              PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH_QUERY_DELETE),
            click: () => navigateTo('/settings/searchQueries'),
            className: getClassName('/searchQueries'),
          },
          compareDatabase: {
            key: 'compareDatabase',
            label: 'Compare database',
            show: PermissionsContext.hasPermission(PERMISSIONS.SHOW_COMPARE),
            click: () => navigateTo('/settings/compareDatabase'),
            className: getClassName('/compareDatabase'),
          },
          users: {
            key: 'users',
            label: 'User dashboard',
            show: PermissionsContext.hasPermission(PERMISSIONS.SHOW_USERS),
            click: () => navigateTo('/settings/users'),
            className: getClassName('/users'),
          },
          roles: {
            key: 'roles',
            label: 'Role dashboard',
            show: PermissionsContext.hasPermission(PERMISSIONS.SHOW_ROLES),
            click: () => navigateTo('/settings/roles'),
            className: getClassName('/roles'),
          },
        },
      },
      userInfo: {
        show: false,
      },
    },
  });

  useEffect(() => {
    const storedLocation = localStorage.getItem('location');
    if (localStorage.getItem('location') && currentLocation === '/login' && isAuthenticated) {
      navigateTo(storedLocation);
    }
    // eslint-disable-next-line
  }, []);

  const toggleModal = (key) => {
    let updatedControls = { ...controls };
    updatedControls[key].open = !updatedControls[key].open;
    setControls(updatedControls);
  };

  const keyPressMap: Object = {
    h: () => toggleModal('helpModal'),
  };

  const toggleUserDropDown = (event, value) => {
    event.preventDefault();
    event.stopPropagation();
    let updatedControls = { ...controls };
    updatedControls.dropDowns.userInfo.show = value
      ? value
      : !updatedControls.dropDowns.userInfo.show;
    setControls(updatedControls);
  };

  const isSettingsEnabled = () => {
    for (const [key, setting] of Object.entries(controls.dropDowns.settings.actions)) {
      if (setting.show) {
        return true;
      }
    }
    return false;
  };

  const navigateTo = (path: string) => {
    history.push(`${path}`);

    let updatedControls = controls;
    let actions = updatedControls.dropDowns.settings.actions;
    actions['general'].className = getClassName('/general');
    actions['projects'].className = getClassName('projects', ['/contractTypes']);
    actions['contractTypes'].className = getClassName('/contractTypes');
    actions['dynamicTabs'].className = getClassName('/dynamicTabs');
    actions['searchQueries'].className = getClassName('/searchQueries');
    actions['compareDatabase'].className = getClassName('/compareDatabase');
    actions['users'].className = getClassName('/users');
    actions['roles'].className = getClassName('/roles');

    setControls(updatedControls);
  };

  /*const onChange = (e) => {
    dispatch(changeLanguage(e.target.value));
  };*/

  const myHeader: ReactRefT<HTMLDivElement> = React.createRef();

  return (
    <header>
      <HotKeys outerRef={myHeader.current} keyPressMap={keyPressMap}>
        <Logo globalSettings={globalSettings} />
        <nav>
          {projectId && hasUserRoles && (
            <ul>
              {PermissionsContext.hasPermission(PERMISSIONS.SHOW_UPLOAD) && (
                <li>
                  <NavLink
                    id={'nav-upload'}
                    exact
                    to="/"
                    title="Upload"
                    activeClassName={ACTIVE_CLASS_NAME}
                  >
                    Upload
                  </NavLink>
                  <div />
                </li>
              )}
              {PermissionsContext.hasPermission(PERMISSIONS.SHOW_SEARCH) &&
                globalSettings.indexAlFindingsiFinder && (
                  <li>
                    <NavLink
                      id={'nav-search'}
                      to={`/search${
                        localStorage.getItem('deeplinkSearch')
                          ? localStorage.getItem('deeplinkSearch')
                          : ''
                      }`}
                      title="Search"
                      className={getClassName('/search', ['/searchQueries'])}
                    >
                      Search
                    </NavLink>
                    <div />
                  </li>
                )}
              {PermissionsContext.hasPermission(PERMISSIONS.SHOW_REVIEW) && (
                <li>
                  <NavLink
                    id={'nav-review'}
                    to={'/review' + (document ? '/' + document.id : '')}
                    title="Review"
                    activeClassName={ACTIVE_CLASS_NAME}
                  >
                    Review
                  </NavLink>
                  <div />
                </li>
              )}
              {PermissionsContext.hasPermission(PERMISSIONS.SHOW_COMPARE) && (
                <li>
                  <NavLink
                    id={'nav-compare-findings'}
                    to="/compareFindings"
                    title="Compare"
                    activeClassName={ACTIVE_CLASS_NAME}
                  >
                    Compare
                  </NavLink>
                  <div />
                </li>
              )}
              {isSettingsEnabled() && (
                <li>
                  <a className={getClassName('/settings')}>
                    Settings <ExpandMoreIcon />
                  </a>
                  <div className="drop-down-wrapper">
                    <DropDown
                      className={'nav-submenu'}
                      show={true}
                      left={-1}
                      top={2}
                      toggleDropDown={() => {}}
                    >
                      <div key="header" />
                      <div key="body">
                        <UL actions={controls.dropDowns.settings.actions} />
                      </div>
                    </DropDown>
                  </div>
                </li>
              )}
              {PermissionsContext.hasPermission(PERMISSIONS.SHOW_EXPLORE) && (
                <li className={'al-inspect'}>
                  <NavLink to="/explore" title="Explore" activeClassName={ACTIVE_CLASS_NAME}>
                    Explore
                  </NavLink>
                  <div />
                </li>
              )}
              {/*dynamic tabs*/}
              {dynamicTabs.map((dynamicTab) => {
                return (
                  <li key={dynamicTab.id}>
                    <a
                      href={dynamicTab.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={dynamicTab.name}
                    >
                      {dynamicTab.name}
                    </a>
                    <div />
                  </li>
                );
              })}
            </ul>
          )}
        </nav>
        <div className={'al-tools'}>
          {isAuthenticated && <ProjectSelect />}
          <CustomTooltip
            title={'Documentation & Help'}
            enterDelay={1000}
            leaveDelay={200}
            placement="top-end"
            classNames={{ root: 'al-tooltip' }}
            interactive={true}
          >
            <div className="icon-wrapper icon-help" onClick={() => toggleModal('helpModal')}>
              <i className="al-icons al-icon-help" />
            </div>
          </CustomTooltip>
          <CustomTooltip
            title={`${PermissionsContext.username}`}
            enterDelay={1000}
            leaveDelay={200}
            placement="top-end"
            classNames={{ root: 'al-tooltip' }}
            interactive={true}
          >
            <span className="icon-wrapper" onClick={toggleUserDropDown}>
              <i className="al-icons al-icon-account" />
            </span>
          </CustomTooltip>
          <div>
            <DropDown
              show={controls.dropDowns.userInfo.show}
              right={25}
              top={50}
              toggleDropDown={(event) => toggleUserDropDown(event, false)}
            >
              <div key="header" />
              <div key="body">
                <div className="user-info-wrapper">
                  <div>User: {PermissionsContext.username}</div>
                  <div>
                    {PermissionsContext.userRoles
                      ? `Roles: ${PermissionsContext.userRoles.join(', ')}`
                      : 'Roles: Unknown'}
                  </div>
                  <div className="user-info-actions">
                    <Button
                      show={true}
                      clicked={() => {
                        dispatch(logout());
                      }}
                    >
                      {'Logout'}
                    </Button>
                  </div>
                </div>
              </div>
            </DropDown>
          </div>
        </div>
      </HotKeys>
      <HelpModal
        onClose={() => toggleModal('helpModal')}
        show={controls.helpModal.open}
        lang={lang}
        currentLocation={currentLocation}
      />
    </header>
  );
};

export default withPermissions(Header);
