import * as actionTypes from '../actions/actionType';
import type { ViewSelectedIssueState } from '../../types/reduxTypes';

const initialState: ViewSelectedIssueState = {
  selectedIssue: null,
};

const setViewSelectedIssue = (state, action) => {
  return {
    ...state,
    selectedIssue: action.selectedIssueToView,
  };
};

const deleteViewSelectedIssue = (state, action) => {
  return {
    ...state,
    selectedIssue: null,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VIEW_SELECTED_ISSUE:
      return setViewSelectedIssue(state, action);
    case actionTypes.DELETE_VIEW_SELECTED_ISSUE:
      return deleteViewSelectedIssue(state, action);

    default:
      return state;
  }
};

export default reducer;
