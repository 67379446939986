// @flow
import React from 'react';

type Props = {
  children: any,
  selectTab: () => void,
  isSelected: boolean,
  class?: string,
};

const TabNavItem = (props: Props) => {
  let classes = ['al-tab-nav-item', props.class];
  if (props.isSelected) {
    classes.push('al-active');
  }

  return (
    <div
      className={classes.join(' ')}
      onClick={() => {
        props.selectTab();
      }}
    >
      {' '}
      {props.children}{' '}
    </div>
  );
};
TabNavItem.defaultProps = {
  selectTab: () => {},
};

export default TabNavItem;
