import { axiosInstance as axios } from '../../services/axiosInstance';

import * as actionTypes from './actionType';
import type { QueryHighlight, SearchQuery } from '../../types';

const createSearchQueryStart = () => {
  return {
    type: actionTypes.CREATE_SEARCH_QUERY_START,
  };
};

const createSearchQuerySuccess = (searchQuery: SearchQuery) => {
  return {
    type: actionTypes.CREATE_SEARCH_QUERY_SUCCESS,
    searchQuery,
  };
};

const createSearchQueryFail = (error) => {
  return {
    type: actionTypes.CREATE_SEARCH_QUERY_FAIL,
    error,
    showCustomError: true,
    errorHeader: 'Error',
    errorBody: error.response.data.message,
  };
};

export const createSearchQuery = (searchQuery: SearchQuery) => {
  return (dispatch) => {
    dispatch(createSearchQueryStart());
    axios
      .post('/api/staticquery/', searchQuery)
      .then((response) => {
        dispatch(createSearchQuerySuccess(response.data));
      })
      .catch((err) => {
        dispatch(createSearchQueryFail(err));
      });
  };
};

const editSearchQueryStart = () => {
  return {
    type: actionTypes.EDIT_SEARCH_QUERY_START,
  };
};

const editSearchQuerySuccess = (searchQuery: SearchQuery) => {
  return {
    type: actionTypes.EDIT_SEARCH_QUERY_SUCCESS,
    searchQuery,
  };
};

const editSearchQueryFail = (error) => {
  return {
    type: actionTypes.EDIT_SEARCH_QUERY_FAIL,
    error,
  };
};

export const editSearchQuery = (searchQuery: SearchQuery) => {
  return (dispatch) => {
    dispatch(editSearchQueryStart());
    axios
      .put('/api/staticquery/', searchQuery)
      .then((response) => {
        dispatch(editSearchQuerySuccess(response.data));
      })
      .catch((err) => {
        dispatch(editSearchQueryFail(err));
      });
  };
};

const deleteSearchQueryStart = () => {
  return {
    type: actionTypes.DELETE_SEARCH_QUERY_START,
  };
};

const deleteSearchQuerySuccess = (searchQuery: SearchQuery) => {
  return {
    type: actionTypes.DELETE_SEARCH_QUERY_SUCCESS,
    searchQuery,
  };
};

const deleteSearchQueryFail = (error) => {
  return {
    type: actionTypes.DELETE_SEARCH_QUERY_FAIL,
    error,
  };
};

const fetchSearchQueriesStart = () => {
  return {
    type: actionTypes.FETCH_SEARCH_QUERY_START,
  };
};

const fetchSearchQueriesSuccess = (searchQueries: Array<SearchQuery>) => {
  return {
    type: actionTypes.FETCH_SEARCH_QUERY_SUCCESS,
    searchQueries,
  };
};

const fetchSearchQueriesFail = (error) => {
  return {
    type: actionTypes.FETCH_SEARCH_QUERY_FAIL,
    error,
  };
};

export const fetchSearchQueries = (projectId: string) => {
  return (dispatch) => {
    dispatch(fetchSearchQueriesStart());
    axios
      .get(`/api/staticquery/`, { params: { projectId } })
      .then((response) => {
        dispatch(fetchSearchQueriesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchSearchQueriesFail(err));
      });
  };
};

export const deleteSearchQuery = (searchQuery: SearchQuery) => {
  return (dispatch) => {
    dispatch(deleteSearchQueryStart());
    axios
      .delete(`/api/staticquery/${searchQuery.id}`)
      .then((response) => {
        dispatch(deleteSearchQuerySuccess(searchQuery));
      })
      .catch((err) => {
        dispatch(deleteSearchQueryFail(err));
      });
  };
};

const fetchSearchQueryHighlightsStart = (searchQueryId: string, searchQueryName: string) => {
  return {
    type: actionTypes.FETCH_SEARCH_QUERY_HIGHLIGHTS_START,
    searchQueryId,
    searchQueryName,
  };
};

const fetchSearchQueryHighlightsSuccess = (
  searchQueryId: string,
  searchQueryName: string,
  queryHighlights: Array<QueryHighlight>,
) => {
  return {
    type: actionTypes.FETCH_SEARCH_QUERY_HIGHLIGHTS_SUCCESS,
    queryHighlights,
    searchQueryName,
    searchQueryId,
  };
};

const fetchSearchQueryHighlightsFail = (searchQueryId: string, error: any) => {
  return {
    type: actionTypes.FETCH_SEARCH_QUERY_HIGHLIGHTS_FAIL,
    searchQueryId,
    error,
  };
};

export const clearSearchQueryHighlights = () => {
  return {
    type: actionTypes.CLEAR_SEARCH_QUERY_HIGHLIGHTS,
    queryHighlights: [],
    searchQueryName: '',
    searchQueryId: '',
  };
};

export const fetchSearchQueryHighlights = (
  searchQueryId: string,
  searchQueryName: string,
  page,
) => {
  return (dispatch) => {
    dispatch(fetchSearchQueryHighlightsStart(searchQueryId, searchQueryName));
    axios
      .get(`/api/staticquery/highlights/docs`, {
        params: {
          queryId: searchQueryId,
          page: page,
        },
      })
      .then((response) => {
        dispatch(fetchSearchQueryHighlightsSuccess(searchQueryId, searchQueryName, response.data));
      })
      .catch((err) => {
        dispatch(fetchSearchQueryHighlightsFail(searchQueryId, err.response.data));
      });
  };
};

const applyQueryStart = (projectId: string, contractId: string) => {
  return {
    type: actionTypes.APPLY_QUERY_START,
    projectId,
    contractId,
  };
};

const applyQuerySuccess = (
  projectId: string,
  contractId: string,
  createdExcerptsIds: Array<string>,
) => {
  return {
    type: actionTypes.APPLY_QUERY_SUCCESS,
    projectId,
    contractId,
    createdExcerptsIds,
  };
};

const applyQueryFail = (projectId: string, contractId: string, error: any) => {
  return {
    type: actionTypes.APPLY_QUERY_FAIL,
    projectId,
    contractId,
    error,
  };
};

export const applyQuery = (projectId: string, contractId: string, searchQueryId: string) => {
  return (dispatch) => {
    dispatch(applyQueryStart(projectId, contractId));
    axios
      .post(`/api/staticquery/highlights/${projectId}/${contractId}/${searchQueryId}`)
      .then((response) => {
        dispatch(applyQuerySuccess(projectId, contractId, response.data));
        dispatch(fetchSearchQueries(projectId, contractId));
      })
      .catch((err) => {
        dispatch(applyQueryFail(projectId, contractId, err));
      });
  };
};

export const setSelectedContractId = (contractId: string) => {
  return {
    type: actionTypes.SET_SELECTED_CONTRACTID,
    selectedContractId: contractId,
  };
};

export const selectPageQueryHighlights = (page: string) => {
  return {
    type: actionTypes.SELECT_PAGE_QUERY_HIGHLIGHTS,
    page,
  };
};
