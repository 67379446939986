// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import DropDown from '../../UI/DropDown/DropDown';
import type { SectionPart } from '../../../types';
import UL from '../../UI/UL/UL';
import Checkbox from '../../UI/Checkbox/Checkbox';
import { useSelector } from 'react-redux';

type Props = {
  show: boolean,
  left: number,
  top: number,
  mode: {
    insert: boolean,
  },
  keyPressMap: Object,

  toggleDropDown: () => void,
  issuesTypes?: Array<string>,
  valid: boolean,
  excerptType: string,
  createIssue: (
    excerptType: string,
    sectionParts: Array<SectionPart>,
    selectedText: string,
  ) => void,
  inputChangeHandler: Function,
  isEmptyFinding: boolean,
  excerptUsedForTraining: boolean,
  toggleExcerptUsedForTraining: () => void,
  hasCreateWithTrainingPermission: boolean,
};

function DocumentContextMenu({
  show,
  left,
  top,
  toggleDropDown,
  issuesTypes,
  excerptType,
  mode,
  keyPressMap,
  valid,
  createIssue,
  inputChangeHandler,
  isEmptyFinding,
  excerptUsedForTraining,
  toggleExcerptUsedForTraining,
  hasCreateWithTrainingPermission,
}: Props) {
  const isFrozenTraining = useSelector(
    (state) => state.globalSettings.globalSettings.freezeTraining,
  );

  return (
    <DropDown show={show} left={left} top={top} toggleDropDown={toggleDropDown}>
      <div key={'header'} className="al-dropdown-header">
        <FormattedMessage id={'app.review.document.create'} defaultMessage="Add Finding" />
      </div>
      <div key={'body'}>
        <Input
          invalid={!valid}
          focus={true}
          placeholder={'app.document.searchForType'}
          className={'al-input-text'}
          elementType={'input'}
          value={excerptType}
          changed={inputChangeHandler}
          elementConfig={{ type: 'text' }}
          keyPressMap={keyPressMap}
        />
        <UL actions={issuesTypes} />
        {hasCreateWithTrainingPermission && !isFrozenTraining && (
          <Checkbox
            className={'use-training-checkbox'}
            onClick={toggleExcerptUsedForTraining}
            checked={excerptUsedForTraining}
          >
            Use for training
          </Checkbox>
        )}
      </div>

      <div key={'footer'} className="al-dropdown-footer">
        <Button
          show={mode.insert}
          btnType="al-button al-primary al-full-width al-no-border-radius"
          clicked={createIssue}
          disabled={!isEmptyFinding}
        >
          <FormattedMessage id="app.review.document.btn.create" defaultMessage="create" />
        </Button>
      </div>
    </DropDown>
  );
}

export default DocumentContextMenu;
