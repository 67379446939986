import React from 'react';

const TabPanel = props => {
  if (!props.children.length){
    return <div className="al-tab-panel"> {props.children} </div>;
  }
  return <div className="al-tab-panel"> {props.children[props.selectedTab - 1]} </div>;
};

export default TabPanel;
