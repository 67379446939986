// @flow
import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';
import type { Project } from '../../types';
import { Dispatch } from 'redux';

export const selectProject = (projectId: string) => ({
  type: actionTypes.SELECT_PROJECT,
  projectId,
});

export const selectContract = (contractId: string) => ({
  type: actionTypes.SELECT_CONTRACT,
  contractId,
});

export const selectFindingType = (findingType: string) => ({
  type: actionTypes.SELECT_FINDING_TYPE,
  findingType,
});

export const selectOriginGlobally = (origin: string) => ({
  type: actionTypes.SELECT_ORIGIN,
  origin,
});

export const selectAnnotationFilters = (filters: Array<boolean>) => ({
  type: actionTypes.SELECT_USER_ANNOTATION_FILTERS,
  filters,
});

export const selectPageNumberExplore = (page: string) => ({
  type: actionTypes.SELECT_PAGE_EXPLORE,
  page,
});

export const createProjectStart = () => ({
  type: actionTypes.CREATE_PROJECT_START,
});

export const createProjectSuccess = (data: Project, tempId: string) => ({
  type: actionTypes.CREATE_PROJECT_SUCCESS,
  data,
  tempId,
});

export const createProjectFail = (projectId: string, error: any) => {
  return {
    type: actionTypes.CREATE_PROJECT_FAIL,
    error,
    projectId,
    showCustomError: true,
    errorHeader: 'Error',
    errorBody: error.message,
  };
};

export const createProject = (data: Project, projectIdToCopyOver: string = null) => async (
  dispatch: Dispatch,
) => {
  const tempId = Math.random().toString();
  dispatch(createProjectStart());
  try {
    let params = null;
    if (projectIdToCopyOver) {
      params = { projectIdToCopyOver };
    }
    const response = await axios.post('api/projects/', data, { params });
    dispatch(createProjectSuccess(response.data, tempId));
  } catch (err) {
    dispatch(createProjectFail(tempId, err));
  }
};

export const renameProjectStart = () => ({
  type: actionTypes.RENAME_PROJECT_START,
});

export const renameProjectSuccess = (data, projectId) => ({
  type: actionTypes.RENAME_PROJECT_SUCCESS,
  data,
  projectId,
});

export const renameProjectFail = (error: any) => ({
  type: actionTypes.RENAME_PROJECT_FAIL,
  error,
});

export const renameProject = (projectId: string, newName: string) => {
  return (dispatch) => {
    dispatch(renameProjectStart());
    axios
      .put(`/api/projects/${projectId}/`, null, {
        params: {
          newName: newName,
        },
      })
      .then((response) => {
        dispatch(renameProjectSuccess(response.data, projectId));
      })
      .catch((err) => {
        dispatch(renameProjectFail(err));
      });
  };
};

export const deleteProjectStart = (projectId: string) => ({
  type: actionTypes.DELETE_PROJECT_START,
  projectId,
});

export const deleteProjectSuccess = (projectId: string) => ({
  type: actionTypes.DELETE_PROJECT_SUCCESS,
  projectId,
});

export const deleteProjectFail = (projectId: string, error: any) => ({
  type: actionTypes.DELETE_PROJECT_FAIL,
  error,
  projectId,
});

export const deleteProject = (project: Project) => async (dispatch: Dispatch) => {
  dispatch(deleteProjectStart(project.id));
  try {
    await axios.delete('/api/projects/' + project.id);
    dispatch(deleteProjectSuccess(project.id));
  } catch (error) {
    dispatch(deleteProjectFail(project.id, error));
  }
};

export const fetchProjectsStart = () => ({
  type: actionTypes.FETCH_PROJECTS_START,
});
export const fetchProjectsSuccess = (apiVersion, projects: Array<Project>) => {
  localStorage.setItem('apiVersion', apiVersion);
  return { type: actionTypes.FETCH_PROJECTS_SUCCESS, projects, apiVersion };
};

export const fetchProjectsFail = (error: any) => ({
  type: actionTypes.FETCH_PROJECTS_FAIL,
  error,
});

export const fetchProjects = () => async (dispatch: Dispatch) => {
  dispatch(fetchProjectsStart());
  axios
    .get('/api/projects/')
    .then((response) => {
      dispatch(fetchProjectsSuccess(response.headers['al-api-version'], response.data));
    })
    .catch((err) => {
      dispatch(fetchProjectsFail(err));
    });
};

export const fetchProjectByIdStart = () => ({
  type: actionTypes.FETCH_PROJECT_BY_ID_START,
});

export const fetchProjectByIdSuccess = (project: Project) => {
  return { type: actionTypes.FETCH_PROJECT_BY_ID_SUCCESS, project };
};

export const fetchProjectByIdFail = (error: any) => ({
  type: actionTypes.FETCH_PROJECT_BY_ID_FAIL,
  error,
});

/* currently not used */
export const fetchProjectById = (projectId: string) => (dispatch: Dispatch) => {
  dispatch(fetchProjectByIdStart());
  axios
    .get(`/api/projects/project/${projectId}`)
    .then((response) => {
      dispatch(fetchProjectByIdSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchProjectByIdFail(err));
    });
};

/* CONTRACT TYPES */

export const createContractTypeStart = () => ({
  type: actionTypes.CREATE_CONTRACT_TYPE_START,
});

export const createContractTypeSuccess = (data: Project, tempId: string) => ({
  type: actionTypes.CREATE_CONTRACT_TYPE_SUCCESS,
  data,
  tempId,
});

export const createContractTypeFail = (projectId: string, error: any) => {
  return {
    type: actionTypes.CREATE_CONTRACT_TYPE_FAIL,
    error,
    projectId,
    showCustomError: true,
    errorHeader: 'Error',
    errorBody: error.message,
  };
};

export const createContractType = (
  projectId,
  contractConfig,
  contractIdToCopyOver: string = null,
) => async (dispatch: Dispatch) => {
  dispatch(createContractTypeStart());
  try {
    const params = contractIdToCopyOver ? { contractIdToCopyOver } : null;
    const response = await axios.post(`api/projects/${projectId}/contract/`, contractConfig, {
      params,
    });
    dispatch(createContractTypeSuccess(response.data, projectId));
  } catch (err) {
    dispatch(createContractTypeFail(projectId, err));
  }
};

export const renameContractTypeStart = () => ({
  type: actionTypes.RENAME_CONTRACT_TYPE_START,
});

export const renameContractTypeSuccess = (data, projectId, contractTypeId: string) => ({
  type: actionTypes.RENAME_CONTRACT_TYPE_SUCCESS,
  data,
  projectId,
});

export const renameContractTypeFail = (error: any) => ({
  type: actionTypes.RENAME_CONTRACT_TYPE_FAIL,
  error,
});

export const renameContractType = (projectId: string, contractId: string, newName: string) => {
  return (dispatch) => {
    dispatch(renameContractTypeStart());
    axios
      .put(`/api/projects/${projectId}/contract/${contractId}`, null, {
        params: {
          newName: newName,
        },
      })
      .then((response) => {
        dispatch(renameContractTypeSuccess(response.data, projectId));
      })
      .catch((err) => {
        dispatch(renameContractTypeFail(err));
      });
  };
};

export const deleteContractStart = () => ({
  type: actionTypes.DELETE_CONTRACT_START,
});

export const deleteContractSuccess = (contractId: string) => ({
  type: actionTypes.DELETE_CONTRACT_SUCCESS,
  contractId,
});

export const deleteContractFail = (contractId: string, error: any) => ({
  type: actionTypes.DELETE_CONTRACT_FAIL,
  error,
  contractId,
});

export const deleteContractType = (projectId: string, contractId: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(deleteContractStart());
  try {
    const response = await axios.delete(`/api/projects/${projectId}/contract/${contractId}`);
    dispatch(deleteContractSuccess(response.data));
  } catch (err) {
    dispatch(deleteContractFail(contractId, err));
  }
};

/* GLOBAL CONFIGS FOR CONTRACT TYPES */

export const createGlobalConfigStart = () => {
  return {
    type: actionTypes.CREATE_GLOBAL_CONFIG_START,
  };
};

export const createGlobalConfigSuccess = (data, projectId, contractId) => {
  return {
    type: actionTypes.CREATE_GLOBAL_CONFIG_SUCCESS,
    data,
    projectId,
    contractId,
  };
};

export const createGlobalConfigFail = (error) => {
  return {
    type: actionTypes.CREATE_GLOBAL_CONFIG_FAIL,
    error,
  };
};

export const createUserGlobalConfig = (projectId, contractId, newGlobalConfigs) => {
  return (dispatch) => {
    dispatch(createGlobalConfigStart());
    axios
      .post(`api/projects/${projectId}/contract/${contractId}`, newGlobalConfigs)
      .then((response) => {
        dispatch(createGlobalConfigSuccess(response.data, projectId, contractId));
      })
      .catch((err) => {
        dispatch(createGlobalConfigFail(err));
      });
  };
};

export const deleteUserGlobalConfigsStart = (projectId, contractId, globalConfigId) => {
  return {
    type: actionTypes.DELETE_USER_GLOBAL_CONFIG_START,
    projectId,
    contractId,
    globalConfigId,
  };
};

export const deleteUserGlobalConfigsSuccess = (projectId, contractId, globalConfigId, data) => {
  return {
    type: actionTypes.DELETE_USER_GLOBAL_CONFIG_SUCCESS,
    data,
    projectId,
    contractId,
    globalConfigId,
  };
};

export const deleteUserGlobalConfigsFail = (projectId, contractId, globalConfigId, error) => {
  return {
    type: actionTypes.DELETE_USER_GLOBAL_CONFIG_FAIL,
    error,
    projectId,
    contractId,
    globalConfigId,
  };
};

export const deleteUserGlobalConfigs = (type = '*', projectId, contractId, globalConfigId) => {
  return (dispatch) => {
    dispatch(deleteUserGlobalConfigsStart(projectId, contractId, globalConfigId));
    axios
      .delete(`api/projects/${projectId}/contract/${contractId}/globalconfig/`, {
        params: {
          type,
        },
      })
      .then((response) => {
        dispatch(
          deleteUserGlobalConfigsSuccess(projectId, contractId, globalConfigId, response.data),
        );
      })
      .catch((err) => {
        dispatch(deleteUserGlobalConfigsFail(projectId, contractId, globalConfigId, err));
      });
  };
};

export const updateGlobalConfigStart = () => {
  return {
    type: actionTypes.UPDATE_GLOBAL_CONFIG_START,
  };
};

export const updateGlobalConfigSuccess = (data, projectId, contractId, updatedGlobalConfigs) => {
  return {
    type: actionTypes.UPDATE_GLOBAL_CONFIG_SUCCESS,
    data,
    projectId,
    contractId,
    updatedGlobalConfigs,
  };
};

export const updateGlobalConfigFail = (projectId, contractId, error) => {
  return {
    type: actionTypes.UPDATE_GLOBAL_CONFIG_FAIL,
    error,
  };
};

export const toggleEnableStaticGlobalConfig = (projectId, contractId, updatedGlobalConfigs) => {
  return (dispatch) => {
    dispatch(updateGlobalConfigStart());
    axios
      .put(
        `api/projects/${projectId}/contract/${contractId}/globalconfigs-settings/`,
        updatedGlobalConfigs,
      )
      .then((response) => {
        dispatch(
          updateGlobalConfigSuccess(response.data, projectId, contractId, updatedGlobalConfigs),
        );
      })
      .catch((err) => {
        dispatch(updateGlobalConfigFail(err));
      });
  };
};

export const updateGlobalConfigCategories = (projectId, contractId, updatedGlobalConfigs) => {
  return (dispatch) => {
    dispatch(updateGlobalConfigStart());
    axios
      .put(
        `api/projects/${projectId}/contract/${contractId}/globalconfigs-categories/`,
        updatedGlobalConfigs,
      )
      .then((response) => {
        dispatch(
          updateGlobalConfigSuccess(response.data, projectId, contractId, updatedGlobalConfigs),
        );
      })
      .catch((err) => {
        dispatch(updateGlobalConfigFail(err));
      });
  };
};

export const updateGlobalConfigAdvLearningTypes = (projectId, contractId, updatedGlobalConfigs) => {
  return (dispatch) => {
    dispatch(updateGlobalConfigStart());
    axios
      .put(
        `api/projects/${projectId}/contract/${contractId}/globalconfigs-al-type/`,
        updatedGlobalConfigs,
      )
      .then((response) => {
        dispatch(
          updateGlobalConfigSuccess(response.data, projectId, contractId, updatedGlobalConfigs),
        );
      })
      .catch((err) => {
        dispatch(updateGlobalConfigFail(err));
      });
  };
};

export const updateExcerptActionsStart = () => {
  return {
    type: actionTypes.UPDATE_EXCERPT_ACTIONS_START,
  };
};

export const updateExcerptActionsSuccess = () => {
  return {
    type: actionTypes.UPDATE_EXCERPT_ACTIONS_SUCCESS,
  };
};

export const updateExcerptActionsFail = (error) => {
  return {
    type: actionTypes.UPDATE_EXCERPT_ACTIONS_FAIL,
    error,
  };
};

export const updateExcerptActions = (projectId, contractId, updatedExcerptActions) => {
  return (dispatch) => {
    dispatch(updateExcerptActionsStart());
    axios
      .put(
        `api/projects/${projectId}/contract/${contractId}/globalconfigs-excerpts-actoins/`,
        updatedExcerptActions,
      )
      .then((response) => {
        dispatch(updateExcerptActionsSuccess());
      })
      .catch((err) => {
        dispatch(updateExcerptActionsFail(err));
      });
  };
};
