// @flow
import * as actionTypes from './actionType';
import { axiosInstance as axios } from '../../services/axiosInstance';
import type { DynamicTab } from '../../types';

const addDynamicTabStart = () => ({
  type: actionTypes.ADD_DYNAMIC_TAB_START,
});

const addDynamicTabSuccess = data => ({
  type: actionTypes.ADD_DYNAMIC_TAB_SUCCESS,
  data,
});

const addDynamicTabFail = error => {
  return {
    type: actionTypes.ADD_DYNAMIC_TAB_FAIL,
    error: error.message,
  };
};

export const addDynamicTab = (dynamicTab: DynamicTab) => {
  return dispatch => {
    dispatch(addDynamicTabStart());
    axios
      .post(`api/dynamictabs`, dynamicTab)
      .then(response => {
        dispatch(addDynamicTabSuccess(response.data));
      })
      .catch(err => {
        dispatch(addDynamicTabFail(err));
      });
  };
};

const updateDynamicTabStart = () => ({
  type: actionTypes.UPDATE_DYNAMIC_TAB_START,
});

const updateDynamicTabSuccess = data => ({
  type: actionTypes.UPDATE_DYNAMIC_TAB_SUCCESS,
  data,
});

const updateDynamicTabFail = error => {
  return {
    type: actionTypes.UPDATE_DYNAMIC_TAB_FAIL,
    error: error.message,
  };
};

export const updateDynamicTab = (dynamicTab: DynamicTab) => {
  return dispatch => {
    dispatch(updateDynamicTabStart());
    axios
      .put(`api/dynamictabs`, dynamicTab)
      .then(response => {
        dispatch(updateDynamicTabSuccess(response.data));
      })
      .catch(err => {
        dispatch(updateDynamicTabFail(err));
      });
  };
};

const deleteDynamicTabStart = () => ({
  type: actionTypes.DELETE_DYNAMIC_TAB_START,
});

const deleteDynamicTabSuccess = (dynamicTabId, data) => ({
  type: actionTypes.DELETE_DYNAMIC_TAB_SUCCESS,
  data,
  dynamicTabId
});

const deleteDynamicTabFail = error => {
  return {
    type: actionTypes.DELETE_DYNAMIC_TAB_FAIL,
    error: error.message,
  };
};

export const deleteDynamicTab = (dynamicTabId: string) => {
  return dispatch => {
    dispatch(deleteDynamicTabStart());
    axios
      .delete(`api/dynamictabs/${dynamicTabId}`)
      .then(response => {
        dispatch(deleteDynamicTabSuccess(dynamicTabId, response.data));
      })
      .catch(err => {
        dispatch(deleteDynamicTabFail(err));
      });
  };
};

const fetchDynamicTabStart = () => ({
  type: actionTypes.FETCH_DYNAMIC_TABS_START,
});

const fetchDynamicTabSuccess = data => ({
  type: actionTypes.FETCH_DYNAMIC_TABS_SUCCESS,
  data,
});

const fetchDynamicTabFail = error => {
  return {
    type: actionTypes.FETCH_DYNAMIC_TABS_FAIL,
    error: error.message,
  };
};

export const fetchDynamicTabs = () => {
  return dispatch => {
    dispatch(fetchDynamicTabStart());
    axios
      .get(`api/dynamictabs`)
      .then(response => {
        dispatch(fetchDynamicTabSuccess(response.data));
      })
      .catch(err => {
        dispatch(fetchDynamicTabFail(err));
      });
  };
};
