// @flow
import React from 'react';
import { DOCUMENT_ANALYZED_STATUS, PROCESSING_STATUS } from '../../../constants/constants';
import Status from './Status';

type Props = {
  analyzeStatus: string,
  retryProcessDocument: () => void,
  withStaticFindings: any,
  reProcessStaticFindings: any,
  reProcessDocument: () => void,
};

export const getDocumentStatus = (analyzeStatus: string) => {
  switch (analyzeStatus) {
    case DOCUMENT_ANALYZED_STATUS.OPEN:
      return PROCESSING_STATUS.INITIAL;
    case DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED:
      return PROCESSING_STATUS.SUCCESS;
    case DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED_WITH_ERROR:
      return PROCESSING_STATUS.SUCCESS_WITH_ERROR;
    case DOCUMENT_ANALYZED_STATUS.CONVERTER_ERRORED_OUT:
    case DOCUMENT_ANALYZED_STATUS.TAGGER_ERRORED_OUT:
    case DOCUMENT_ANALYZED_STATUS.SPACY_ERRORED_OUT:
      return PROCESSING_STATUS.FAILED;
    default:
      return PROCESSING_STATUS.PENDING;
  }
};

export const DocumentStatusBar = ({
  analyzeStatus,
  retryProcessDocument,
  withStaticFindings,
  reProcessStaticFindings,
  reProcessDocument,
}: Props) => {
  const getDocumentStatusText = () => {
    switch (analyzeStatus) {
      case DOCUMENT_ANALYZED_STATUS.OPEN:
        return `Progress hasn't started yet`;
      case DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED:
        return `Document is processed`;
      case DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED_WITH_ERROR:
        return `Document is completed with error, document's language is not supported`;
      case DOCUMENT_ANALYZED_STATUS.CONVERTER_ERRORED_OUT:
        return `Converter error`;
      case DOCUMENT_ANALYZED_STATUS.TAGGER_ERRORED_OUT:
        return `Tagger error`;
      case DOCUMENT_ANALYZED_STATUS.SPACY_ERRORED_OUT:
        return `Spacy error`;
      case DOCUMENT_ANALYZED_STATUS.CONVERTER_IN_PROGRESS:
        return `Document in converting stage`;
      case DOCUMENT_ANALYZED_STATUS.CONVERTER_COMPLETED:
      case DOCUMENT_ANALYZED_STATUS.TAGGER_EXCERPT_IN_PROGRESS:
        return `Document in tagging stage`;
      case DOCUMENT_ANALYZED_STATUS.TAGGER_ANALYZE_COMPLETED:
      case DOCUMENT_ANALYZED_STATUS.SPACY_IN_PROGRESS:
        return `Document in spacy stage`;
      default:
        return `Progress hasn't started yet`;
    }
  };

  const getStaticFindingStatus = (): string => {
    if (withStaticFindings) {
      return PROCESSING_STATUS.SUCCESS;
    }
    if (
      [
        DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED,
        DOCUMENT_ANALYZED_STATUS.SPACY_COMPLETED_WITH_ERROR,
      ].indexOf(analyzeStatus) > -1
    ) {
      return PROCESSING_STATUS.PENDING;
    } else {
      return PROCESSING_STATUS.INITIAL;
    }
  };

  return (
    <>
      {/*Converting*/}
      <Status
        title={`${getDocumentStatusText(analyzeStatus)}`}
        status={getDocumentStatus(analyzeStatus)}
        retryProcessDocument={retryProcessDocument}
        reProcessDocument={reProcessDocument}
      />
      {/*Static Finding*/}
      <Status
        title={`${
          withStaticFindings
            ? 'Document has static findings'
            : 'Document does not have static findings'
        } `}
        status={getStaticFindingStatus()}
        reProcessStaticFindings={reProcessStaticFindings}
        withStaticFindings={withStaticFindings}
      />
    </>
  );
};
